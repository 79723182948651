import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {Router} from '@angular/router'
import {AuthService} from '../Services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor
{

  constructor(private router : Router, private authService: AuthService) { }

  
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    if(httpRequest.url.includes('api/') && !httpRequest.url.includes('api/Users/Token'))
    {
      let auth = localStorage.getItem('authorizationData');
      if(auth)
      {
        const Authorization = 'Bearer ' + JSON.parse(localStorage.getItem('authorizationData')).token;

        return next.handle(httpRequest.clone({ setHeaders: { Authorization}})).pipe(
          
          catchError(error => {
            if(error.status === 401 || error.status === 403)
            {
              //ToDo:
              //need to logout user, check error later?
              this.authService._logOut();

              this.router.navigate(['/login', {}]);
              return throwError(error);//throwError(error);
            }
            else
            {
              return throwError(error);
            }
          })
        );
      }
      else
      {
        //important to have else here for cases no auth in localstorage but need to still send out the httprequest(activate component)
        //return next.handle(httpRequest);
        //test here
        return next.handle(httpRequest).pipe(
          //in addition to API error, handle all errors with 401 and 403 mostly when the api without token needed.
          catchError(error => {
            if(error.status === 401 || error.status === 403)
            {
              //ToDo:
              //need to logout user, check error later?
              this.authService._logOut();
              this.router.navigate(['/login', {}]);
              return throwError(error);//throwError(error);
            }
            else
            {
              return throwError(error);
            }
          })
        );
        //test here find the better way to handle this instead of catching in 3 places
      }
    }
    else{
      
      return next.handle(httpRequest).pipe(
        //in addition to API error, handle all errors with 401 and 403 mostly when the api without token needed.
        catchError(error => {
          if(error.status === 401 || error.status === 403)
          {
            //ToDo:
            //need to logout user, check error later?
            this.authService._logOut();
            this.router.navigate(['/login', {}]);
            return throwError(error);//throwError(error);
          }
          else
          {
            return throwError(error);
          }
        })
      );
    }
  }
}

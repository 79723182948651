
<div class="col-md-8">
    <div class="row">
        <div id = "DriverHarshPRToggleDv">
            <button id= 'driverHarshPRToggleBtn'class="btn btn-info btn" style="float: right;margin-right: 2%;" (click)="togglePercentileDriverHarshEventTbl(driverHarshPercentile)">{{ driverHarshPercentile ? '# ' : 'PR' }}</button>
        </div>
        <div id = "DriverHarshPRDropDownDv" style="float:right;" [hidden] = '!driverHarshPercentile'>
            <select class="form-control" [(ngModel)]="companyInsProgramDr" style="height:auto;">
                <option [(ngValue)] = "dashboardCompanyProgram">{{dashboardCompanyProgram}}</option>
                <option value = 2>All Drivers</option>
            </select>
        </div>
    </div>
    <div class="table-responsive dashtable">
        <table id = 'driverHarshEvent' mat-table [dataSource] = "driverHarshEventTblDataSource" #sortDriverHarshEvent ='matSort' matSort  class="table table-condensed table-striped">
                
            <ng-container matColumnDef="driverName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300">
                    Driver Name
                </th>
                <td mat-cell *matCellDef="let element" > {{element.driverName}} </td>
            </ng-container>
                
            <ng-container matColumnDef="sum_Harsh_Brake">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    Brakes
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.sum_Harsh_Brake == 0 ? "_": element.sum_Harsh_Brake }}

                </td>
            </ng-container>
            <ng-container matColumnDef="derived_sum_Harsh_Brake_Per1k" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    Brakes (per 1k)
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.sum_TotalMiles == 0 || element.sum_Harsh_Brake == 0 ? "_": ((element.sum_Harsh_Brake  * 1000 / element.sum_TotalMiles ) | number: '1.01-2')  }}
                </td>
            </ng-container>

            <ng-container matColumnDef="brake_Percentile" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    Brakes (per 1k)
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ (companyInsProgramDr == 2 ? element.brake_Percentile_AllProgm : element.brake_Percentile) | number: '1.01-1' }} PR
                </td>
            </ng-container>

            <ng-container matColumnDef="sum_Harsh_Corner">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    Corner
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.sum_Harsh_Corner == 0 ? "_": element.sum_Harsh_Corner }}
                </td>
            </ng-container>
            <ng-container matColumnDef="derived_sum_Harsh_Corner_Per1k" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    Corner (per 1k)
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.sum_TotalMiles == 0 || element.sum_Harsh_Corner == 0 ? "_": ((element.sum_Harsh_Corner * 1000 / element.sum_TotalMiles) | number: '1.01-2' ) }}
                </td>
            </ng-container>
            <ng-container matColumnDef="corner_Percentile" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    Corner (per 1k)
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ (companyInsProgramDr == 2 ? element.corner_Percentile_AllProgm : element.corner_Percentile) | number: '1.01-1' }} PR
                </td>
            </ng-container>

            <ng-container matColumnDef="sum_Harsh_Quick_Accelaration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    Accel.
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.sum_Harsh_Quick_Accelaration == 0 ? "_" : element.sum_Harsh_Quick_Accelaration }}

                </td>
            </ng-container>
            <ng-container matColumnDef="derived_sum_Harsh_Quick_Accelaration_Per1k" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    Accel. (per 1k)
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.sum_TotalMiles == 0 || element.sum_Harsh_Quick_Accelaration == 0 ? "_" : ((element.sum_Harsh_Quick_Accelaration * 1000 / element.sum_TotalMiles) | number: '1.01-2') }}
                </td>
            </ng-container>
            <ng-container matColumnDef="accel_Percentile" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    Accel. (per 1k)
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ (companyInsProgramDr == 2 ? element.accel_Percentile_AllProgm : element.accel_Percentile) | number: '1.01-1' }} PR
                </td>
            </ng-container>

            <ng-container matColumnDef="sum_TotalMiles" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    Total Mi.
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.sum_TotalMiles | number: '1.01-1' }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="driverHarshEventColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: driverHarshEventColumns;" style="height:0px;" (click) = 'setActiveHarshEventDriver(row, $event)'></tr>
        </table>
        <mat-paginator #paginatorDriverHarshEvent [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons style="margin-bottom: 4%;margin-top:-1%; background: none; color: whitesmoke;"></mat-paginator>
        
    </div>
</div>
<div class="col-md-4">
    <div class="row" *ngIf="!activeHarshEventDriver">
        <br>
        <h4 class="text-center" style="margin-left: 1%;margin-right: 8%;">Please select a driver from the table for more information.</h4>
    </div>
    <div class="row" *ngIf="activeHarshEventDriver">
        <div class="col-md-12">
            <h3 class="text-center">
                Driver: {{activeHarshEventDriver.driverName}}
            </h3>
            <h5 style="font-weight:bold;margin-top: 2.5%;margin-left: -3%;">Events in Traffic</h5>
            <!--<h6 style="font:400 12px Arial; text-align: bottom;">Road Type</h6> -->
            <nvd3 id="eventInTrafficChart" [options]="harshEventInTrafficOptions" [data]="driverHarshEventInTrafficChartData"></nvd3>

            <h5 style="font-weight:bold;margin-top: -5%;margin-left: -3%">Categorical Events</h5>
            <nvd3 id = "harshEventCategoryChart" [options]="harshEventCategoryOptions" [data]="driverHarshEventCategoryChartData"> </nvd3>
        </div>
    </div>
</div>

import { Injectable, Injector } from '@angular/core';
import { DataService } from './data.service';
import {Router} from '@angular/router'
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service'
import { MatSnackBar } from '@angular/material/snack-bar';
import { appConst } from '../app.config'

@Injectable({
  providedIn: 'root'
})
export class CommonAppService {

  constructor(private dataService : DataService, private httpClient : HttpClient, private router : Router, private injector : Injector) { 
    
  }

  
  _loginSuccessRoute() {
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    let smIndexData = this.injector.get(AuthService)._smIndex;
    //let smIndexData = JSON.parse(localStorage.getItem('smIndexData'));

    if(!authData.userAgreed){
      this.router.navigate(['/userAgreement']);//todo add this component
    }
    else {
        if (authData.groupType == appConst.groupType.MC) {
            //MC
            this.dataService._getGroupInfo().subscribe((groupInfo) => {
                this.dataService._getMCStatus(authData.groupID).subscribe((status) => {
                    if (status && status.ConsentStatus) {
                        this.router.navigate(['/companyInfo']); //todo: add this component                                                                                                          
                    }
                    else{
                        if(smIndexData.ConsentHistoryHyperlink){
                            this.router.navigate(['/consentHistory']); //todo: add this component                                     
                        } else{
                            this.router.navigate(['/companyInfo']); //todo: add this component
                        }                                    
                    }
                });                            
            });
        } else if (authData.groupType == appConst.groupType.ICC){
            //ICC
            this.dataService._getGroupInfo().subscribe((groupInfo) => {
                this.router.navigate(['/mcList']); //todo: add this component
            });
        } else if (authData.groupType == appConst.groupType.TSP) {
            //TSP
            this.dataService._getGroupInfo().subscribe((groupInfo) => {
                this.router.navigate(['/mcList']);
            });
        } 
        else if (authData.groupType == appConst.groupType.system) 
        {
          //super user
            this.dataService._getGroupInfo().subscribe((groupInfo) => {
                this.router.navigate(['/superAdmin']);// todo: add this component
            });
        }  
        else {
            alert('GroupType: Mismatch');
        }                      
    }
  }

  _userAgreementRoute() 
  {
     this.router.navigate(['/userAgreement']);
  }

  _snackBar(gOb : boolean, message : string, duration?: number)
  {
    let snackBar = this.injector.get(MatSnackBar);

    if(gOb)
    {
        return snackBar.open(message, 'X', {duration : duration ? duration : 3000, horizontalPosition: 'left', panelClass: ['snackbar-success']});
    }
    else{
        return snackBar.open(message, 'X', {duration : duration ? duration : 3000, horizontalPosition: 'left', panelClass: ['snackbar-error']});
    }
    //return this.snackBar.open(message, 'X', {duration : duration ? duration : 3000, horizontalPosition: 'left', panelClass: ['snackbar-info']});
  }

  
  /*moved to AuthService to prevent from circular dependecy, which is sticter than angularJs here.04/19/2021
  _getHereMapAccess(RoleId : number, GroupType : number) : Observable<any>
  {
     let serviceBase = this.serviceBase.serviceBase;
     var getURL = serviceBase + 'api/Values/GetHereMapAccess?RoleId=' + RoleId + "&GroupType=" + GroupType;

    return this.httpClient.get(getURL);
  }

  _getHelloSignAccess() : Observable<any>
  {
    let serviceBase = this.serviceBase;
    let getURL = serviceBase.serviceBase + 'api/Values/GetHelloSignAccess?isTest=' + serviceBase.isTest;

    return this.httpClient.get(getURL);
  }*/




}

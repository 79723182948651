<div class="modal-header">
    <button type="button" class="close" aria-hidden="true" (click)="close()">x</button>
    <h3 class="modal-title">Export Dashboard</h3>
</div>
<div class="modal-body">
    <!-- <pre>{{newUser | json}}</pre> -->
    <div class="row">
        <div class="col-md-7">
            <div class="form-group label-floating">
                <input type="checkbox" [(ngModel)]="selection.enableCbxScoring" (change) = "checkAll(selection)" />
                <label for="i4" class="control-label">Scoring</label>
            </div>
            <div class="form-group label-floating">
                <input type="checkbox" [(ngModel)]="selection.enableCbxUtil" (change) = "checkAll(selection)" />
                <label for="i4" class="control-label">Utilization</label>
            </div>
            <div class="form-group label-floating">
                <input type="checkbox" [(ngModel)]="selection.enableCbxRadiusAddr" (change) = "checkAll(selection)" />
                <label for="i4" class="control-label">Radius & Garaging</label>
            </div>
            <div class="form-group label-floating">
                <input type="checkbox" [(ngModel)]="selection.enableCbxTerritory" (change) = "checkAll(selection)" />
                <label for="i4" class="control-label">Territory</label>
            </div>
            <div class="form-group label-floating">
                <input type="checkbox" [(ngModel)]="selection.enableCbxSpeeding" (change) = "checkAll(selection)" />
                <label for="i4" class="control-label">Speeding</label>
            </div>
            <div class="form-group label-floating">
                <input type="checkbox" [(ngModel)]="selection.enableCbxHarshEvent" (change) = "checkAll(selection)" />
                <label for="i4" class="control-label">Harsh Event</label>
            </div>
            <!-- <div class="form-group label-floating">
                <input type="checkbox" ng-model="selection.enableCbxDriverScoreCard" ng-init ="selection.enableCbxDriverScoreCard = true" ng-change = "checkAll(selection)" />
                <label for="i4" class="control-label">Driver Score Card</label>
             </div> -->
            <div class="form-group label-floating">
                <input type="checkbox" [(ngModel)]="selection.enableCbxAll" (change) = "checkAll(selection, selection.enableCbxAll)" />
                <label for="i4" class="control-label">ALL</label>
            </div>
        </div>
       
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-warning" type="button" (click)="close()">Cancel</button>
    <button class="btn btn-info" type="button" (click)="export()">Export</button>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="dashhead">
            <div class="dashhead-titles">
                <h6 class="dashhead-subtitle">Consent</h6>
                <h2 class="dashhead-title">{{groupInfo.CompanyName}}</h2>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="panel panel-info">
            <div class="panel-heading"></div>
            <div class="panel-body">
                <div class="row" style="margin-bottom: 5%">
                    <div class="col-md-12 text-center">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-md-offset-3">
                        <div class="row" *ngIf="!other">
                            <div class="col-md-12">
                                <p><em>Begin by selecting your Telematic Service Provider from the dropdown below.  To revoke consent from a previous transaction, reference the table below.</em></p>
                            </div>
                        </div>
                        <div class="row" *ngIf="!other">
                            <div class="form-group label-floating">
                                <div class="col-sm-10">
                                    <select class="form-control" [(ngModel)]="activeUser.tsp" (change)="changeSelection(activeUser.tsp)">
                                        <option value = '' selected>--Choose Your Telematic Service Provider--</option>
                                        <option *ngFor="let tsp of tspList" [value]="tsp.TSPId">{{ tsp.CompanyName }}</option>
                                    </select>
                                </div>
                                <div class="col-sm-2">
                                    <button class="btn btn-raised btn-info" (click)="selectTSP(activeUser.tsp)" [disabled]="activeUser.tsp === '' || selectTSPBtnDisable || !MCTSPAcctNbr" >Select TSP</button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-10 text-center">
                                <a (click)="toggleOther()" *ngIf="!other">Don't see your Telematics Provider?</a>
                                <div *ngIf="other">
                                    <div class="form-group label-floating">
                                        <input placeholder="Enter your Telematics Provider" type="text" class="form-control" [(ngModel)]="newRequest.tsp_string">
                                        <pre>{{newRequest.tsp_string}}</pre>
                                        <h5 class="dashhead-title goBack" (click) = 'toggleOther()'><i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i> Back to Selection</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-2" *ngIf="other">
                                <button class="btn btn-raised btn-info" (click)="submitOther(newRequest.tsp_string)" [disabled] = '!newRequest.tsp_string'>Submit</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                               <div [hidden] = "!selectedTSPCompanyName">{{selectedTSPCompanyName}} Account Number: </div>
                            </div>
                            <div class="col-sm-7" [hidden] ="!selectedTSPCompanyName">
                               <input type="text" class="form-control"  name = 'mcTSPAcct' [(ngModel)]="MCTSPAcctNbr" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-md-offset-3">
                            <h3 class="jumboTitle">Consent History</h3>
                            <div class="table-responsive">
                                <table id = 'consentTbl' mat-table [dataSource] = "consentTblDataSource" class="table table-hover table-condensed table-condensedNew">
                                    <ng-container matColumnDef="TSPCompanyName">
                                        <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300">
                                            TSP Name
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.TSPCompanyName}} </td>
                                    </ng-container>
                                        
                                    <ng-container matColumnDef="ModifiedDate">
                                        <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                                            Date
                                        </th>
                                        <td mat-cell *matCellDef="let element"> {{element.ModifiedDate | date:"MMM dd, yyyy"}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="TemplateType">
                                        <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                                            Type
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            <!-- <span *ngIf="element.TemplateType === 0">Revoke</span>
                                            <span *ngIf="element.TemplateType === 1">Consent</span> -->
                                            {{element.TemplateType === 1 ? 'Consent' : 'Revoke'}}
                                        </td>
                                        
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="consentColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: consentColumns; let index = index" style="height:0px;" 
                                    (click) = 'open(row)'
                                    ></tr>
                                </table>
                                <mat-paginator #paginatorConsent [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons style="margin-bottom: 2%;margin-top:-1%; background: none; color: #fff;"></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import {DashService}  from '../../../Services/dash.service';
import {CommonAppService}  from '../../../Services/common-app.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'underscore';

import {firstBy} from "../../../../lib/thenBy/thenby";
declare var $: any;// for convertion purpose using the jQuery here, which supposed not to in Angular 2x. will find workaround after convertion
declare let d3 : any;
declare var H: any; 
import * as noUiSlider from '../../../../lib/noUiSlider/nouislider';
import { hexColors } from '../../../app.config';




@Component({
  selector: 'app-footprint',
  templateUrl: './footprint.component.html',
  styleUrls: ['./footprint.component.css']
})
export class FootprintComponent implements OnInit {

  subNotifier = new Subject();

  @Input() app_id: string;
  @Input() app_code : string;
  @Input() apikey : string;
  @Input() geoFileLst : any;

  loadingHex : boolean = false;
  hexSlider : any;
  geoQuarter : any;
  footprintMapObj : any;
  footprintLayer : any;
  saveGeoFileWithQuarter : any;

  constructor(private dashService : DashService, private commonAppService : CommonAppService) { }

  initiateSlider ()
  {
    let slider = document.getElementById('footprintSlider');

    noUiSlider.create(slider, {
    start: [0.6],
    connect: [false, false],
    range: {
        'min': [0.4],
        'max': [2]
    },
    step: 0.2,
    tooltips: false
    });
    let connect = slider.querySelectorAll('.noUi-connect');
    let background = slider.getElementsByClassName('noUi-connects');
    background[0].classList.add('connect-color-tele');
    //connect[0].classList.add('connect-color-tele');
    //connect.classList.add('connect-color-tele');
    /*var classes = ['c-1-color', 'c-2-color', 'c-3-color', 'c-4-color'];
    for ( var i = 0; i < connect.length; i++ ) {
        connect[i].classList.add(classes[i]);
    }*/

    this.hexSlider = slider;
  }

  hexToRGB(hex) {
    
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) =>{
        return r + r + g + g + b + b;
    });

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  displayFootprint ()
  {
    //testing
    //testHex();
    //return;

    let platform = new H.service.Platform({
      //'app_id': this.app_id,
      //'app_code': this.app_code,
      'apikey': this.apikey,
      'useHTTPS': true
    });

    let defaultLayers = platform.createDefaultLayers();

    //for initiating the map object, use the first vehicle in the MC list with lat and long to define the center
    //var vehicleInArea = _.find($scope.vehicleSource, function(item){ return item.Latitude !== null && item.Longitude !== null});
    let vehicleInArea = null; //temp
    let areaLat = vehicleInArea && !vehicleInArea.Latitude ? vehicleInArea.Latitude : 42.4072;
    let areaLong = vehicleInArea && !vehicleInArea.Longitude ? vehicleInArea.Longitude :  -71.3824;

    let map = new H.Map(
      document.getElementById('heatMapGeoContainer'),
      defaultLayers.raster.normal.map,
      {
        zoom: 7,
        //center: { lat: 42.4072, lng: -71.3824 } 
        center: { lat: areaLat, lng: areaLong },
        engineType: H.map.render.RenderEngine.EngineType.P2D//use legacy rendering which is way faster than 3.1 version
      });

    let ui = H.ui.UI.createDefault(map, defaultLayers);
    let mapEvents = new H.mapevents.MapEvents(map);// instantiate the event
    let behavior = new H.mapevents.Behavior(mapEvents) // give the map default behavior
    
    let group = new H.map.Group();
    //$scope.footprintMapObj = map;

    let reader = new H.data.geojson.Reader('',
        {
          disableLegacyMode: true,
          style: (mapObject) =>
            {
              mapObject.addEventListener('tap', (ev) => {
                
              let bubble = new H.ui.InfoBubble({lat:mapObject.getBoundingBox().getCenter().lat, lng:mapObject.getBoundingBox().getCenter().lng}, {
                // read custom data
                content: '<div style="min-width:180px;min-height:30px;">' + mapObject.data.NAME + ',' + mapObject.data.STATE_CODE + '</div>'
                });
                ui.addBubble(bubble);
                ev.target.addEventListener('pointerleave', (evt) => {
                    bubble.close();
                }, false);
              });
          
              if (mapObject instanceof H.map.Polygon)
              {
                //rgb(11,108,205)
                let rgb = this.hexToRGB('C732FF');
                let rgbFill = 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + '1)';

                mapObject.setStyle({
                  fillColor: 'rgba(11,108,205, 0.1)',
                  //strokeColor: 'rgba(0, 0, 102, 0.5)',
                  strokeColor: 'rgba(11,108,205, 1)',
                  lineWidth: 2
                });
              }
          }
        }
      );

    this.dashService._getPlaintiffCountyGeoJson().pipe(takeUntil(this.subNotifier)).subscribe((data) => {
      if(data)
      {
        //modify multipolygon to several polygons
        let multiPolygonLst = [];
        data.features.forEach((item, index) => {
          if(item.geometry.type == 'MultiPolygon')
          {
            multiPolygonLst.push(item);
            //data.features.splice(index, 1);
            data.features[index] = null;
          }
        });
        //remove null 
        data.features = data.features.filter((el) => {
            return el != null;
        });

        let modifiedPolygon = [];
        multiPolygonLst.forEach((item, index) => {

          item.geometry.coordinates.forEach((subItem, index) =>{

            let newPolygon = { type: "Feature" , properties : item.properties, geometry: { type: "Polygon", coordinates: subItem }};
            modifiedPolygon.push(newPolygon);
          });
        });

        if(modifiedPolygon.length > 0)
        {
          data.features = data.features.concat(modifiedPolygon);
        }
        //modify multipolygon to several polygons

        reader.parseData(data);
        let layer = reader.getLayer();
        //layer.opacity = 0.8;
        map.addLayer(layer);
      }
      this.footprintMapObj = map;
    });
  }

  hotspot (geofileName)
  {
    this.loadingHex = true;
    this.dashService._getHexHotspotFile(geofileName, hexColors.hotspotPercentile).pipe(takeUntil(this.subNotifier)).subscribe((geoFile) =>
    {
      this.renderHotspot(geoFile, true);
    });
  }
  renderHotspot (geoFile, setCenter)
  {
    let mapObj = this.footprintMapObj;
    if(this.footprintLayer)
    {
      mapObj.removeLayer(this.footprintLayer);
      this.footprintLayer = null;
    }

    if(geoFile)
    {
      //for initiating the map object, use the first vehicle in the MC list with lat and long to define the center
      let areaLat = geoFile.features[0].geometry.coordinates[0][0][1];
      let areaLong = geoFile.features[0].geometry.coordinates[0][0][0];

      if(setCenter)
      {
        mapObj.setCenter({ lat: areaLat , lng: areaLong}, true);
      }

      let reader = new H.data.geojson.Reader('',
        {
          disableLegacyMode: true,
          style: (mapObject) =>//have to use arrow funtion here to work for callback funtion invoking hexToRGB
          {
            if (mapObject instanceof H.map.Polygon) 
            {
              let rgb = this.hexToRGB(hexColors.hotspotColor);
              let rgbFill = 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + '0.7)';

              mapObject.setStyle({
                fillColor: rgbFill,
                //strokeColor: 'rgba(0, 0, 102, 0.5)',
                strokeColor: rgbFill,
                lineWidth: 1
              });
            }
          }
        }
      );
      reader.parseData(geoFile);
      //reader.parse();
      let layer = reader.getLayer();
      mapObj.addLayer(layer);

      this.footprintLayer = layer;
      this.footprintMapObj = mapObj;
      this.loadingHex = false;
      // optimize performance if only update on slider value and quarter file is same
      //$scope.saveGeoFileWithQuarter = { geoFile: geoFile, QuarterFileName: geofileName};
      //geoFile = null;
    }
  }

  updateFootprint(geofileName)
  {
    if(!geofileName)
    {
      this.commonAppService._snackBar(false, 'Please Select Footprint Quarter!', 2000);
      return;
    }

    this.loadingHex = true;
    // if same quarter, do not call API
    if(this.saveGeoFileWithQuarter && geofileName === this.saveGeoFileWithQuarter.QuarterFileName)
    {
      this.renderGeoHex(this.saveGeoFileWithQuarter.geoFile, geofileName, false);
    }
    else
    {
      this.dashService._getHexGeoJsonFile(geofileName).pipe(takeUntil(this.subNotifier)).subscribe((geoFile) =>
      {
        this.renderGeoHex(geoFile, geofileName, true);
      });
    }
  }
   
  renderGeoHex (geoFile, geofileName, setCenter)
  {
    let colorLst : any = hexColors.colorLst;
    let sliderValue =this.hexSlider.noUiSlider.get();
    let mapObj = this.footprintMapObj;
    if(this.footprintLayer)
    {
      mapObj.removeLayer(this.footprintLayer);
      this.footprintLayer = null;
    }

    let percentile2 = [];
    if(geoFile)
    {
      //changing colors based on slider value
      geoFile.features.sort(
        firstBy((a, b) => {return b.properties.COUNT - a.properties.COUNT; })
      );

      for(let i =0; i <= Math.ceil(geoFile.features.length * hexColors.percentileCutoff); i++)
      {
        percentile2.push(geoFile.features[i]);
      }
      
      colorLst.forEach((item : any, i) => {
        item.LinearDataSet = (percentile2[percentile2.length -1].properties.COUNT - 1) / (colorLst.length -1) * i + 1;
        item.NewColorIndex = parseInt((Math.pow(i, sliderValue) / Math.pow((colorLst.length -1), sliderValue) * (colorLst.length -1)).toString());
      });
      //changing colors based on slider value

      //for initiating the map object, use the first vehicle in the MC list with lat and long to define the center
      let areaLat = geoFile.features[0].geometry.coordinates[0][0][1];
      let areaLong = geoFile.features[0].geometry.coordinates[0][0][0];

      if(setCenter)
      {
        mapObj.setCenter({ lat: areaLat , lng: areaLong}, true);
      }

      let reader = new H.data.geojson.Reader('',
            {
              disableLegacyMode: true,
              style: (mapObject) =>
              {
                if (mapObject instanceof H.map.Polygon) 
                {
                  let newColorObj = null;
                  if(mapObject.data.COUNT == 1)
                  {
                    newColorObj = _.find(colorLst, (item) => {
                      return item.index == colorLst[0].index;
                    });
                  }
                  else if(mapObject.data.COUNT > colorLst[colorLst.length -1].LinearDataSet)
                  {
                    newColorObj = colorLst[colorLst.length -1];
                  }
                  else
                  {
                    let potentialColorLst = _.filter(colorLst, (item) => {
                      return item.LinearDataSet >= mapObject.data.COUNT;
                    });

                    if(potentialColorLst.length >1)
                    {
                      potentialColorLst.sort(
                        firstBy((a, b) => {return a.LinearDataSet - b.LinearDataSet; }));
                    }

                    newColorObj = _.find(colorLst, (item) => {
                      return item.index == potentialColorLst[0].NewColorIndex;
                    });
                  }
                  
                  let rgb = this.hexToRGB(newColorObj.hexCode); 
                  let rgbFill = 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + '0.7)';

                  mapObject.setStyle({
                    fillColor: rgbFill,
                    //strokeColor: 'rgba(0, 0, 102, 0.5)',
                    strokeColor: rgbFill,
                    lineWidth: 1
                  });
                }
              }
            }
          );
          reader.parseData(geoFile);
          //reader.parse();
          let layer = reader.getLayer();
          mapObj.addLayer(layer);

          this.footprintLayer = layer;
          this.footprintMapObj = mapObj;
          this.loadingHex = false;
          // optimize performance if only update on slider value and quarter file is same
          this.saveGeoFileWithQuarter = { geoFile: geoFile, QuarterFileName: geofileName};
          geoFile = null;
    }
    else
    {
      this.loadingHex = false;
      this.commonAppService._snackBar(false, 'No Footprint Found!', 2000);
    }
  }



  ngOnInit(): void {
    this.initiateSlider();
    this.displayFootprint();
  }
  ngOnChanges(): void {
    //this.initiateSlider();
    //this.displayFootprint();
  }
  ngOnDestroy()
  {
    this.subNotifier.next();
    this.subNotifier.complete();
  }

}

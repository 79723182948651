import { Component, OnInit } from '@angular/core';
import { map, tap, switchMap, mergeMap, filter, concatMap } from 'rxjs/operators';
import {DataService} from '../../Services/data.service';
import {AuthService} from '../../Services/auth.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {


  authData = JSON.parse(localStorage.getItem('authorizationData'));
  userInfo : any = {};
  groupInfo : any = {};

  submitted : boolean = false;

  contact : Contact = {
    name: '',
    email: '',
    company: '',
    phone: '',
    message: ''
  };
   
  

  constructor(private dataService : DataService, private authService : AuthService) { }

  submit(contactInfo : Contact)
  {
    let toEmail = 'tesupport@telematicexchange.com';//'jbuck@gravoc.com';
    let toName = 'Litian Liu';
    let fromEmail = contactInfo.email;
    let fromName = contactInfo.name;
    let subject = "Telematic Exchange Contact Form Submission";
    let lineOne = '<h3>From:</h3><p>' + fromName + '</p>';
    let lineTwo = '<h3>Email:</h3><p>' + fromEmail + '</p>';
    let lineThree = '<h3>Phone:</h3><p>' + contactInfo.phone + '</p>';
    let lineFour = '<h3>Company:</h3><p>' + contactInfo.company + '</p>';
    let lineFive = '<h3>Message:</h3><p>' + contactInfo.message + '</p>';
    let content = lineOne + lineTwo + lineThree + lineFour + lineFive;
    
 

    this.dataService._sendEmail(toEmail, toName, fromEmail, fromName, subject, content).subscribe((response) => {
        //console.log(response);
      if (response) {
        if (response.status === 200) {
          this.contact = {} as Contact;
          this.submitted = true;
        }
      }
    });
  }

  ngOnInit(): void {
    if (this.authService._authentication.isAuth) {
      this.dataService._getUser(this.authData.userID)
        .pipe(
          concatMap((user) => {
            this.userInfo = user;
            this.contact.name = this.userInfo.Name;
            this.contact.email = this.userInfo.UserName;
            this.contact.phone = this.userInfo.Phone;
            
            return this.dataService._getGroupInfo(this.userInfo.GroupId, this.userInfo.GroupType);
          })
        )
        .subscribe((group) => {
          this.groupInfo = group;
          this.contact.company = this.groupInfo.CompanyName;
        });
    }
  }
}

interface Contact
{
  name : string;
  email : string;
  company : string;
  phone: string;
  message : string;
}

<div class="row">
    <div class="col-sm-4">
        <div class="form-group">
            <select class="form-control" [(ngModel)]="geoQuarter" [disabled]="loadingHex">
                <option  [ngValue] = 'undefined' selected>--Choose Footprint in Quarter--</option>
                <option *ngFor="let geo of geoFileLst" [(ngValue)]="geo.fileName">{{ geo.caption }}</option>
            </select>
        </div>
    </div>
    <div class="col-md-4" [hidden] = "true"> <!-- not using -->
        <div class="form-group label-floating"> 
            <label style="color: white; margin-left: 3%;">Vehicle Type:</label>
            <div class="form-group text-center">
                <label class="checkbox-inline" style="color: white;">
                  <input type="checkbox" value="" ng-disabled = 'true'>Extra Heavy
                </label>
                <label class="checkbox-inline" style="color: white;">
                  <input type="checkbox" value="" ng-disabled = 'true'>Heavy
                </label>
                <label class="checkbox-inline" style="color: white;">
                  <input type="checkbox" value="" ng-disabled = 'true'>Medium
                </label>
                <label class="checkbox-inline" style="color: white;">
                  <input type="checkbox" value="" ng-disabled = 'true'>Light
                </label>
                <label class="checkbox-inline" style="color: white;">
                  <input type="checkbox" value="" ng-disabled = 'true' checked>All
                </label>
            </div>
        </div>
    </div>
    <div class="col-md-6 blockFootp">
        <div class="col-md-10" style="display: grid;">
            <div class="form-group">
                <!--<div id= "footprintSlider" style="top:33.5%;"  ng-init = "initiateSlider()"> </div> -->
                <div id= "footprintSlider" style="top:10px;"> </div>
            </div>
        </div>
        <div class="col-md-2" style="margin-top: 0.4%;">
            <div class="form-group">
                <button class="btn btn-info btn"  (click)="updateFootprint(geoQuarter)" [disabled] = "loadingHex">Footprint</button>
            </div>
        </div>
    </div>
    <div class="col-sm-2" style="margin-top: 0.1%;">
        <div class="form-group">
            <button class="btn btn-info btn" style="float:right;" (click)="hotspot(geoQuarter)" [disabled] = "!geoQuarter || loadingHex">Hotspot</button>
        </div>
    </div>
</div>
<div class="row">
    <span *ngIf="loadingHex">
        <div class="row"> 
            <div class="col-md-12 text-center">
                <img class="img-responsive spinner" src="/assets/img/ripple.svg" alt="loading_spinner">
            </div>
        </div>
    </span>
    <span>
        <div>
            <div id= "heatMapGeoContainer" style="width: 98%; height: 608px;" class="img-responsive"> </div> 
        </div>
    </span>
</div>
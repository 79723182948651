<div class="row">
    <div class="col-md-12">
        <div class="panel panel-info">
            <div class="panel-heading"></div>
            <div class="panel-body">
		        <h1 class="text-center">Privacy Policy</h1>
		        <h3>General</h3>
		        <p>Technology Services (“eTech Services”) takes user privacy seriously. This Privacy Policy explains how we collect, handle, and use personal data when you use our website <a href ='https://telematicexchange.com'>https://telematicexchange.com</a> (the “Website”) on any platform and in any format.</p>
		        <p>&nbsp;</p>
		        <p>This privacy policy is not intended to limit any data usage provisions that a user has agreed to with an insurer</p>
		        <p>&nbsp;</p>
		        <h3>No Children Under 13</h3>
		        <p>This Website is not directed at or intended for use by children under 13 years old. We do not knowingly collect personal data from children under 13 through this Website.</p>
                <p>&nbsp;</p>

                <h3>Users Outside the United States and Canada</h3>
                <p>This Website is not directed at or intended for users outside the U.S or Canada. Please be advised that if you are located in or access the Website from the European Union or another region with laws related to data collection and use that may differ from U.S. and Canadian law, any personal data submitted through this Website and transferred to the U.S. may not have the same legal data protection as in that jurisdiction. </p>
                <p>&nbsp;</p>

                <h3>Personal Data We Collect</h3>
                <p>When you use the services offered on our Website, we may collect certain personal data from you and your computer. We primarily collect personal data from you through our Website when you complete online forms or register an account for use with our services. Personal data we collect may include:</p>
                <ul>
		        	<li>Your name, company name, position, email address, mailing address, and telephone number.</li>
		        	<li>Any password or other login credentials you create as part of the eTech Services account activation process.</li>
		        	<li>Any other information you voluntarily provide when you contact us through the Website that may identify you or allow online or offline contact with you.</li>
		        </ul>
		        <p>Users who have activated accounts via the Website may also bound be by the terms of a Telematics Exchange User Agreement and/or a Data Sharing Agreement with eTech Services. Please refer to the terms of those agreements for additional information related to the privacy of telematics and/or customer data collected by or provided to eTech Services via the Telematics Exchange Solution.</p>
		        <p>&nbsp;</p>

		        <h3>How We Use Collected Personal Data</h3>
		        <p>We use personal data collected through the Website as follows:</p>
		        <ul>
		        	<li>To provide you with telematics services through our online software platform known as the Telematics Exchange.</li>
		        	<li>To act on and respond to your requests and inquiries.</li>
		        	<li>To analyze Website usage and improve our Website functionality.</li>
		        	<li>To notify you regarding any concerns with the Website, including, as required by law, in the event that any sensitive personal data you provided to us is exposed to unauthorized third-parties. In the event of such unauthorized exposure, we expressly reserve the right to provide notice to you electronically via any email address you have provided to us if permitted to do so by law.</li>
		        </ul>
		        <p>&nbsp;</p>

		        <h3>Disclosure of Your Data</h3>
		        <p>We will not share your personal data collected through this Website except in the following limited circumstances:</p>
		        <ul>
		        	<li>We may share personal data with third parties that perform services on our behalf, for example, our technical and marketing service providers. We may also share certain information with third-party providers of cloud, web and/or server hosting services. Certain third-party service providers utilized by us may collect personal data from you as authorized by us. Our service providers may provide data collected by them through this Website to us, such as reports of email addresses and account information they collect, store and/or process for us, which we may use for our business purposes. We are not responsible for the privacy practices of these service providers or of other websites. By using our Website, you consent to the processing and handling of your personal data by our service providers in the manner and for the purposes described here and in their respective privacy policies. We will not authorize these third parties to use your personal data except as necessary to provide services to us or for purposes authorized by us.</li>
		        	<li>Additionally, we may disclose your personal data (1) to comply with a law, regulation or other governmental request, for example, to comply with subpoenas, court orders, legal process or requests by law enforcement or governmental entities; (2) to investigate or defend against third-party claims against us; (3) to detect, prevent or investigate fraud or other illegal activity or to enforce this policy; (4) to repair or resolve technical issues in the operation of this Website; (5) if required by law, to respond to a request by you to disclose the information, including a request to allow access to your account and personal data by third-parties designated by you; (6) with your knowledge and consent; and (7) to address emergencies or acts of God. We may also share your personal data as an asset of the business in the event of a sale, merger or other business combination or transfer involving us or affiliated entities.</li>
		        </ul>
		        <p>&nbsp;</p>

		        <h3>How Do We Protect Your Personal Data</h3>
		        <p>eTech Services takes the security of your personal data seriously. We implement commercially reasonable technical, physical and administrative security measures to protect against the loss, misuse and unauthorized access, disclosure, alteration and destruction of personal data collected through this Website. However, we cannot and do not guarantee that your personal data will never be accessed by an unauthorized party or otherwise breached during transmission through the Internet or while stored on our system or otherwise in our care. Additionally, any contact with us by email or by way of our Website might not be secure. Others could view this information in transit.</p>
		        <p>&nbsp;</p>
		        <p>In the event that we believe any sensitive personal data that you provided to us has been exposed to unauthorized third-parties, we will notify you as required by law or upon our own choice. In the event of such unauthorized exposure, we expressly reserve the right to provide notice to you electronically via any email address you have provide to us if permitted to do so by law.</p>
		        <p>&nbsp;</p>

		        <h3>Retention of Personal data</h3>
		        <p>We will retain your personal data for only as long as we deem reasonably necessary or desirable. We reserve the right to delete or destroy any information collected, including personal data, at any time.</p>
		        <p>&nbsp;</p>

		        <h3>Do-Not-Track Disclosure</h3>
		        <p>This Website does not respond to "Do Not Track Signals"</p>
		        <p>&nbsp;</p>

		        <h3>Updates to this Privacy Policy</h3>
		        <p>We may update or change our privacy practices and this policy from time to time. If we update the policy, we will publish the revised policy on this page of the Website or as otherwise required by law. The date at the top of this policy reflects the date of the most recent update.</p>
		        <p>&nbsp;</p>

		        <h3>How to Contact Us</h3>
		        <p>If you have any questions, comments or requests regarding this privacy policy or the processing of your Telematics Data, please contact us by email at <a href ='mailto:Contact@etechnologyservices.com'>Contact@etechnologyservices.com</a>.</p>
	        </div>
        </div>
    </div>
</div>
import { Component, OnInit, Input, OnChanges, ViewChild, Renderer2, AfterViewInit, ElementRef } from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import  {CommonAppService}  from '../../../Services/common-app.service';
import { AuthService} from '../../../Services/auth.service';

import * as _ from 'underscore';
import {firstBy} from "../../../../lib/thenBy/thenby";

declare var H: any; 




@Component({
  selector: 'app-radius-garaging',
  templateUrl: './radius-garaging.component.html',
  styleUrls: ['./radius-garaging.component.css']
})
export class RadiusGaragingComponent implements OnInit {


  @Input() radiusData : any;
  @Input() app_id: string;
  @Input() app_code : string;
  @Input() apikey : string;
 

  mapObj : any;
  @ViewChild('mapContainer') mapContainer: ElementRef;
  
  percentage : number = 0;
  activeGarageIndex : number = null;
  activeVehicleGaraging : any = null;
  addrSet : any = [];

  radiusTblDataSource : any =[];
  radiusColumns : string[] = ['sortBase', 'vehicleType', 'sum_Miles_Under_50','derived_sum_Miles_50_200', 'derived_sum_Miles_Over_200'];
  @ViewChild('sortRadius') sortRadius: MatSort;
  @ViewChild('paginatorRadius') paginatorRadius: MatPaginator;

  garagesTblDataSource : any =[];
  garagesColumns : string[] = ['derived_#', 'street', 'city','state', 'count'];
  //@ViewChild('sortGarages') sortGarages: MatSort;
  @ViewChild('paginatorGarages') paginatorGarages: MatPaginator;



  
  constructor(private commonAppService : CommonAppService , private authService : AuthService, private render : Renderer2) { 

  }

  generateRadiusTable() {
    if(!this.radiusData) return;
    
    //for sorting on conditional VIN or Vehicleid 
    this.radiusData.forEach((item) => {
      item.sortBase = item.vin && item.vin !== 'N/A' ? item.vin.replace(item.vin.slice(0, (item.vin.length) - 6), "").toString() +'/' : '/' + item.vehicleId

    });

    let radiusViewData = _.toArray(this.radiusData);
    this.radiusTblDataSource = new MatTableDataSource(radiusViewData);

    //sort on derived columns
    this.radiusTblDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'derived_sum_Miles_50_200':
          return item.sum_Miles_50_100 + item.sum_Miles_100_150 + item.sum_Miles_150_200;
        case 'derived_sum_Miles_Over_200':
          return item.sum_Miles_200_400 + item.sum_Miles_400_700 + item.sum_Miles_Over_700;

        case 'derived_percent_sum_Miles_Under_50' :
          return item.sum_TotalMiles == 0 ? 0 : item.sum_Miles_Under_50 / item.sum_TotalMiles * 100;
        
        case 'derived_percent_sum_Miles_50_200':
          return (item.sum_TotalMiles == 0 ? 0 : (item.sum_Miles_50_100 + item.sum_Miles_100_150 + item.sum_Miles_150_200) / item.sum_TotalMiles * 100);
        case 'derived_percent_sum_Miles_Over_200':
          return (item.sum_TotalMiles == 0 ? 0 : (item.sum_Miles_200_400 + item.sum_Miles_400_700 + item.sum_Miles_Over_700) / item.sum_TotalMiles * 100);
        
        default:
          return item[property];
      }
    };
    this.radiusTblDataSource.sort = this.sortRadius;
    this.radiusTblDataSource.paginator = this.paginatorRadius;
  }

  generateGaragesTable() {
    if(!this.radiusData) return;
    this.addrSet = [];
    this.radiusData.forEach((vehicle) => {

      let thisAddr : any = {
        street: vehicle.primary_Garaging_Address1,
        city: vehicle.primary_Garaging_city,
        state: vehicle.primary_Garaging_State,
        zip: vehicle.primary_Garaging_Zip,
        country: vehicle.primary_Garaging_Country,
        //primary_Garaging_Latitude: '',
        //primary_Garaging_Longitude:''
      }

      if (this.addrSet.length == 0) {
          //console.log('Adding First Item');
          thisAddr.count = 1;
          this.addrSet.push(thisAddr);
      } else {
          if (_.findIndex(this.addrSet, thisAddr) < 0) {
              thisAddr.count = 1;
              this.addrSet.push(thisAddr);
          } else {
              this.addrSet[_.findIndex(this.addrSet, thisAddr)].count++;
          }
      }
    });

    this.addrSet.forEach((item) =>
    {
      let found = _.find(this.radiusData, (r) =>
      {
        return item.street === r.primary_Garaging_Address1 && item.city === r.primary_Garaging_city && item.state === r.primary_Garaging_State
          && r.Primary_Garaging_Latitude !== null;
      });

      if(found)
      {
        item.latitude = found.Primary_Garaging_Latitude;
        item.longitude = found.Primary_Garaging_Longitude;
      }
    });

    this.addrSet.sort(
     firstBy(function(a, b){return b.count - a.count; })
    .thenBy("state")
    .thenBy("city")
    );
    //console.log('$scope.addrSet', $scope.addrSet);

    let garagesViewData = _.toArray(this.addrSet);
    this.garagesTblDataSource = new MatTableDataSource(garagesViewData);
    this.garagesTblDataSource.paginator = this.paginatorGarages;
  }

  togglePercentRadiusTbl(percent) {
    //new: change the defintion of the materal table columns to hide or show the percentage columns instead of on column in the html file(it is not allowed in Angular)
    if(percent == 0)
    {
      this.percentage = 1;
      this.radiusColumns = ['sortBase', 'vehicleType','derived_percent_sum_Miles_Under_50','derived_percent_sum_Miles_50_200', 'derived_percent_sum_Miles_Over_200'];
    }
    else if(percent == 1)
    {
      this.percentage = 0;
      this.radiusColumns =['sortBase', 'vehicleType', 'sum_Miles_Under_50','derived_sum_Miles_50_200', 'derived_sum_Miles_Over_200'];
    }
  }

  setActiveGaragingVehicle(activeGaraging, event) {
    this.activeGarageIndex = null;

    let rows = Array.from(document.getElementById('garaging').getElementsByTagName('tr'));
    rows.forEach(item => {
      this.render.removeClass(item, 'selected');
    });
    this.render.addClass(event.currentTarget, 'selected');

    
    this.activeVehicleGaraging = activeGaraging;
    this.activeGarageIndex = this.getGaragingNumber(this.activeVehicleGaraging) - 1;

    //hightlight marker
    this.hightlightMarkerFromVehicleTable(activeGaraging, event);
  }
  setActiveGarage(index) {
    this.activeGarageIndex = null;
    this.activeGarageIndex = index;
    
    this.hightlightMarkerFromAddrTable(index);
  }
  getGaragingNumber(vehicle) {
    let vehicleAddr = {
      street: vehicle.primary_Garaging_Address1,
      city: vehicle.primary_Garaging_city,
      state: vehicle.primary_Garaging_State,
      zip: vehicle.primary_Garaging_Zip,
      country: vehicle.primary_Garaging_Country,
    }

    let garageNumber = _.findIndex(this.addrSet, vehicleAddr); 
    return garageNumber + 1;
  }

  displayMap = function()
  {
      /*var fakeLatlong = ["42.5259945,-70.9299857", "42.6264419,-70.7008647", "42.3633609,-71.0561167", "42.5606889,-70.9497387", "42.5466303,-70.9458802", "42.5154397,-71.0345397",
      "42.5408819,-70.9457327", "42.5307617,-70.9643435", "42.5261737, -70.955233"]; */
    
    let platform = new H.service.Platform({
      //'app_id': this.app_id,
      //'app_code': this.app_code,
      'apikey' :  this.apikey,
      'useHTTPS': true,
    });

    let defaultLayers = platform.createDefaultLayers();

    //for initiating the map object, use the first vehicle in the MC list with lat and long to define the center
    let vehicleInArea = _.find(this.radiusData, function(item){ return item.Primary_Garaging_Latitude !== null && item.Primary_Garaging_Longitude !== null});
    let areaLat = vehicleInArea && vehicleInArea.Primary_Garaging_Latitude ? vehicleInArea.Primary_Garaging_Latitude : 42.4072;
    let areaLong = vehicleInArea && vehicleInArea.Primary_Garaging_Longitude ? vehicleInArea.Primary_Garaging_Longitude :  -71.3824;

    let map = new H.Map(

      this.mapContainer.nativeElement,
      //document.getElementById('mapContainer'),
      defaultLayers.raster.normal.map,
      {
        zoom: 9,
        //center: { lat: 42.4072, lng: -71.3824 } 
        center: { lat: areaLat, lng: areaLong }
      }
    );

    let ui = H.ui.UI.createDefault(map, defaultLayers);
    let mapEvents = new H.mapevents.MapEvents(map);// instantiate the event
    let behavior = new H.mapevents.Behavior(mapEvents) // give the map default behavior

    let coord;
    let marker;
    let group = new H.map.Group();
      
    //add lat and long to addrSet
    let garageList = this.addrSet; //console.log("garageListhere",  garageList);
  
    for(let i =0; i< garageList.length; i++)
    {
      let findGeo = _.find(this.radiusData, function(item) { return item.primary_Garaging_Address1 == garageList[i].street && item.primary_Garaging_city == garageList[i].city; });

      if(findGeo !== null && findGeo.Primary_Garaging_Latitude != null ) 
      {
        garageList[i].latitude = findGeo.Primary_Garaging_Latitude;
        garageList[i].longitude = findGeo.Primary_Garaging_Longitude;
      }
    }

    //pushpins to map
    for(let i = 0; i< garageList.length; i++)
    {
      if(garageList[i].latitude)
      {
        coord = { lat: garageList[i].latitude, lng: garageList[i].longitude };

        let html =  '<div style="opacity:0.7;font-size:small;max-width:100px;">' + garageList[i].street + ' ' + garageList[i].city + ' ' + garageList[i].state + ' ' + garageList[i].country+'</div>';

        marker = new H.map.Marker(coord);

        marker.setData(html);

        //add eventlistener
        marker.addEventListener('tap', (evt) => {
          let bubble =  new H.ui.InfoBubble(evt.target.getGeometry(), {
          // read custom data
          content: evt.target.getData()
          });
          
          ui.addBubble(bubble);
          evt.target.addEventListener('pointerleave', (evt) =>{
            bubble.close();
          }, false);
            
        }, false);

        group.addObject(marker);
        //group.removeObject(marker);
      }
    }
    map.addObject(group);

    this.mapObj = map;
  }

  hightlightMarkerFromVehicleTable (vehicle, event)
  {
    let overwriteSameAddrGeo = _.find(this.addrSet, (item) => { 
      return item.street == vehicle.primary_Garaging_Address1 && item.city == vehicle.primary_Garaging_city;});

      
    vehicle.Primary_Garaging_Latitude = overwriteSameAddrGeo.latitude;
    vehicle.Primary_Garaging_Longitude = overwriteSameAddrGeo.longitude;

    let platform = new H.service.Platform({
      //'app_id': this.app_id,
      //'app_code': this.app_code,
      'apikey' :  this.apikey,
      'useHTTPS': true
    });
    let mapObj = this.mapObj;
    
    // reset center when the vehicle is selected from vehicle table
    if(!vehicle.Primary_Garaging_Latitude)
    {
      this.commonAppService._snackBar(false,'Insufficient Data', 2000);
    }
    else
    {
      mapObj.setCenter({ lat: vehicle.Primary_Garaging_Latitude , lng: vehicle.Primary_Garaging_Longitude}, true);
    }

    let group = new H.map.Group();
    let defaultLayers = platform.createDefaultLayers();
    let ui = H.ui.UI.createDefault(mapObj, defaultLayers);

    if(mapObj.getObjects().length != 0)
    {
      // remove the group of markers
      mapObj.removeObject(mapObj.getObjects()[0]);
      //re-render the markers with hightligh
      let garageList = this.addrSet; 
      // set same geo if the
      for(let i =0; i< garageList.length; i++)
      {  
        let findGeo = _.find(this.radiusData, function(item) { return item.primary_Garaging_Address1 == garageList[i].street && item.primary_Garaging_city == garageList[i].city; });

        if(findGeo !== null && findGeo.Primary_Garaging_Latitude !== null ) 
        {
          garageList[i].latitude = findGeo.Primary_Garaging_Latitude;
          garageList[i].longitude = findGeo.Primary_Garaging_Longitude;
        }
      }

      //pushpins to map
      let svgMarkup = '<svg xmlns="http://www.w3.org/2000/svg" width="28px" height="36px"><path d="M 19 31 C 19 32.7 16.3 34 13 34 C 9.7 34 7 32.7 7 31 C 7 29.3 9.7 28 13 28 C 16.3 28 19 29.3 19 31 Z" fill="#000" fill-opacity=".2"/><path d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z" fill="#fff"/><path d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z" fill="red"/></svg>';

      //var svgMarkup = '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"><circle cx="20" cy="20" r="9" fill="pink" stroke="light" stroke-width="3" /></svg>'
      let icon = new H.map.Icon(svgMarkup);

      for(let i = 0; i< garageList.length; i++)
      {
        if(garageList[i].latitude && garageList[i].latitude !== vehicle.Primary_Garaging_Latitude && garageList[i].longitude !== vehicle.Primary_Garaging_Longitude)
        {
          let coord = { lat: garageList[i].latitude, lng: garageList[i].longitude };
          let marker = new H.map.Marker(coord);
          let html =  '<div style="opacity:0.7;font-size:small;max-width:100px;">' + garageList[i].street + ' ' + garageList[i].city + ' ' + garageList[i].state + ' ' + garageList[i].country+'</div>';
        
          marker.setData(html);

          //add eventlistener
          marker.addEventListener('tap', function(evt) {
            let bubble =  new H.ui.InfoBubble(evt.target.getGeometry(), {
            // read custom data
            content: evt.target.getData()
            });
            
            ui.addBubble(bubble);

            evt.target.addEventListener('pointerleave', function(evt){
                bubble.close();
            }, false);
              
          }, false);
          
          group.addObject(marker);
          //group.removeObject(marker);
        }
        else if (garageList[i].latitude && garageList[i].latitude === vehicle.Primary_Garaging_Latitude && garageList[i].longitude === vehicle.Primary_Garaging_Longitude)
        {
          let coord = { lat: garageList[i].latitude, lng: garageList[i].longitude };
          let marker = new H.map.Marker(coord, {icon: icon}); //set different icon for highlight
          let html =  '<div style="opacity:0.5;font-size:small;max-width:100px;">' + garageList[i].street + ' ' + garageList[i].city + ' ' + garageList[i].state + ' ' + garageList[i].country+'</div>';
        
          marker.setData(html);
          marker.setZIndex(1); // display the selected address always on the top to avoid overlap

          //add eventlistener
          marker.addEventListener('tap', function(evt) {
            let bubble =  new H.ui.InfoBubble(evt.target.getGeometry(), {
            // read custom data
            content: evt.target.getData()
            });
            
            ui.addBubble(bubble);

            evt.target.addEventListener('pointerleave', function(evt){
              bubble.close();
                
            }, false);
              
          }, false);
          
          group.addObject(marker);
        }
      }
      mapObj.addObject(group);

      this.mapObj = mapObj;
    }
    //console.log("mapobjlist", mapObj.getObjects());
  }

  hightlightMarkerFromAddrTable(tableIndex)
  {
    let addrTable = this.addrSet;

    let platform = new H.service.Platform({
      //'app_id': this.app_id,
      //'app_code': this.app_code,
      'apikey' :  this.apikey,
      'useHTTPS': true
    });

    let mapObj = this.mapObj;
    
    let group = new H.map.Group();
    let defaultLayers = platform.createDefaultLayers();
    let ui = H.ui.UI.createDefault(mapObj, defaultLayers);

    if(mapObj.getObjects().length != 0)
    {
      // remove the group of markers
      mapObj.removeObject(mapObj.getObjects()[0]);

      //re-render the markers with hightligh
      let garageList = addrTable;
      
      //get the geo from summary table response and populate to addr table
      for(let i =0; i< garageList.length; i++)
      {  
        let findGeo = _.find(this.radiusData, (item) => { return item.primary_Garaging_Address1 == garageList[i].street && item.primary_Garaging_city == garageList[i].city; });

        if(findGeo !== null && findGeo.Primary_Garaging_Latitude != null ) 
        {
          garageList[i].latitude = findGeo.Primary_Garaging_Latitude;
          garageList[i].longitude = findGeo.Primary_Garaging_Longitude;
        }
      }

      //pushpins to map
      let svgMarkup = '<svg xmlns="http://www.w3.org/2000/svg" width="28px" height="36px"><path d="M 19 31 C 19 32.7 16.3 34 13 34 C 9.7 34 7 32.7 7 31 C 7 29.3 9.7 28 13 28 C 16.3 28 19 29.3 19 31 Z" fill="#000" fill-opacity=".2"/><path d="M 13 0 C 9.5 0 6.3 1.3 3.8 3.8 C 1.4 7.8 0 9.4 0 12.8 C 0 16.3 1.4 19.5 3.8 21.9 L 13 31 L 22.2 21.9 C 24.6 19.5 25.9 16.3 25.9 12.8 C 25.9 9.4 24.6 6.1 22.1 3.8 C 19.7 1.3 16.5 0 13 0 Z" fill="#fff"/><path d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z" fill="red"/></svg>';
      let icon = new H.map.Icon(svgMarkup);

      for(let i = 0; i< garageList.length; i++)
      {
        if(garageList[i].latitude && i !== tableIndex)
        {
          let coord = { lat: garageList[i].latitude, lng: garageList[i].longitude };
          let marker = new H.map.Marker(coord);
          let html =  '<div style="opacity:0.5;font-size:small;max-width:100px;">' + garageList[i].street + ' ' + garageList[i].city + ' ' + garageList[i].state + ' ' + garageList[i].country+'</div>';
        
          marker.setData(html);

          //add eventlistener
          marker.addEventListener('tap', function(evt) {
            let bubble =  new H.ui.InfoBubble(evt.target.getGeometry(), {
            // read custom data
            content: evt.target.getData()
            });
            
            ui.addBubble(bubble);

            evt.target.addEventListener('pointerleave', function(evt){
                bubble.close();
            }, false);
          }, false);

          group.addObject(marker);
          //group.removeObject(marker);
        }
        else if (garageList[i].latitude && i == tableIndex)
        {
          let coord = { lat: garageList[i].latitude, lng: garageList[i].longitude };
          let marker = new H.map.Marker(coord, {icon: icon}); //set different icon for highlight
          let html =  '<div style="opacity:0.7;font-size:small;max-width:100px;">' + garageList[i].street + ' ' + garageList[i].city + ' ' + garageList[i].state + ' ' + garageList[i].country+'</div>';
        
          marker.setData(html);
          marker.setZIndex(1); // display the selected address always on the top to avoid overlap

          //add eventlistener
          marker.addEventListener('tap', function(evt) {
            let bubble =  new H.ui.InfoBubble(evt.target.getGeometry(), {
            // read custom data
            content: evt.target.getData()
            });
            
            ui.addBubble(bubble);

            evt.target.addEventListener('pointerleave', function(evt){
              bubble.close();
            }, false);
              
          }, false);
          
          group.addObject(marker);
        }
      }

      //reset center 
      if(!garageList[tableIndex].latitude)
      {
        this.commonAppService._snackBar(false, 'Insufficient Data', 2000);
      }
      else
      {
        mapObj.setCenter({ lat: garageList[tableIndex].latitude , lng: garageList[tableIndex].longitude}, true);
      }
      
      mapObj.addObject(group);
      this.mapObj = mapObj;
    }
  }

  ngOnInit(): void {

  }

  ngOnChanges(): void {
    
    this.generateRadiusTable();//for data update
    this.generateGaragesTable(); //for data update

    this.activeGarageIndex = null;//clear the selected rows in the both tables when data change

    //clear up map objects at every data change(date picker change)
    this.mapObj == null || this.mapObj.getObjects()[0].setVisibility(false);//set objects on the map invisible
  }
  ngAfterViewInit(): void{
    
    //put here aganin after ngOnInit to prevent from mat table sort and paging mal-function and ExpressionChangedAfterItHasBeenCheckedError 
    this.generateRadiusTable();
    this.generateGaragesTable();
   
    //display map after generating tables for addrset to be populated
    this.displayMap();
  }

}

<div class="row">
    <div class="col-md-6">
        <div class="dashhead">
            <div class="dashhead-titles">
                <h6 class="dashhead-subtitle">{{groupInfo.CompanyName}}</h6>
                <h2 class="dashhead-title">
                    <span *ngIf="authentication.groupType == 1002">Policyholders</span>
                    <span *ngIf="authentication.groupType == 1003">Customers</span>
                </h2>
            </div>
        </div>
    </div>
    <div class="col-md-6 text-right">
        <button class="btn btn-narrow btn-info" type="button" (click)="renderCSV('current')" download><i class="fa fa-download" aria-hidden="true"></i> Export Current View</button>
        <button class="btn btn-narrow btn-info" type="button" (click)="renderCSV('all')" download> Export All</button>
    </div>
    <div class="col-md-12">
        <span *ngIf="loading">
            <div class="row"> 
                <div class="col-md-12 text-center">
                    <img class="img-responsive spinner" src="/assets/img/ripple.svg" alt="loading_spinner">
                </div>
            </div>
        </span>
        <span *ngIf="!loading">
            <div class="table-responsive">
                <table mat-table @rowsAnimation [dataSource] = "mcLstDataSource" class="table table-condensed table-striped" matSort>
                    <ng-container matColumnDef="CompanyName">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="row">
                                <div class="col-md-7 text-right dashhead-title" onClick = "st1.click()">Company Name</div>
                                <span id='st1' mat-sort-header></span>
                            </div>    
                            <div class="row"> 
                                <div style="width: 100%; padding-right: 15px;">
                                    <input class="form-control" name = 'companyFilter' [(ngModel)] = "filterInput.CompanyName" (keyup)="applyFilter($event.target.value)" />
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" class="col-md-3"> {{element.CompanyName}} </td>
                      </ng-container>
                      
                      <ng-container matColumnDef="PolicyNumber">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="row">
                                <div class="text-right col-md-7" onClick = "st2.click()" >Policy Number</div>
                                <span id = 'st2' mat-sort-header></span>
                            </div>    
                            <div style="width: 100%;">
                                <input class="form-control" name = 'policyNumberFilter' [(ngModel)] = "filterInput.PolicyNumber" (keyup)="applyFilter($event.target.value)"/>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" class="col-md-2"> {{element.PolicyNumber}} </td>
                      </ng-container>
                      
                      <ng-container matColumnDef="Agent">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="row">
                                <div class="text-right col-md-6" onClick = "st3.click()" >Agent</div>
                                <span id = 'st3' mat-sort-header></span>
                            </div>    
                          
                            <div style="width: 100%;">
                                <input class="form-control" name = 'agentFilter' [(ngModel)] = "filterInput.Agent" (keyup)="applyFilter($event.target.value)"/>
                            </div>
                            
                        </th>
                        <td mat-cell *matCellDef="let element" class="col-md-2"> {{element.Agent}} </td>
                      </ng-container>
                      
                      <ng-container matColumnDef="timestamp">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="row">
                                <div class="text-right col-md-8" onClick = "st4.click()">Timestamp</div>
                                <span id= 'st4' mat-sort-header></span>
                            </div>    
                            <div class="row" style="margin: auto;">
                                <input class="form-control" name = 'timestampFilter' [(ngModel)] = "filterInput.Timestamp" (keyup)="applyFilter($event.target.value)" />
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" class="col-md-1  text-center"> {{element.timestamp | date: "MM/dd/yyyy"}} </td>
                      </ng-container>
                      
                      <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>
                            <div class="row" style="margin-left: auto;">
                                <div class="text-right col-md-8" onClick = "st5.click()">Status</div>
                                <span id = 'st5' mat-sort-header></span>
                            </div>    
                            <div class="row" style="margin-left: auto;">
                                <select class="form-control" name ='statusFilter' [(ngModel)] = "filterInput.Status" (ngModelChange) = "applyFilter()">
                                    <option *ngFor="let status of filters.status" [ngValue]="status">{{status.title}}</option>
                                </select>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" class="col-md-1 text-center">
                            <i class="fa fa-lg"
                            [ngClass]="element.status== 1 ? 'fa-check' : (element.status == 0 ? 'fa-times' : (element.status == 3 ? 'fa-ban' : (element.status == 4 ? 'fa-link':(element.status == 5 ? 'fa-chain-broken' :'fa-ellipsis-h'))))" 
                             
                            matTooltip= "{{(element.status== 1 ? 'Consented' : (element.status == 0 ? 'Revoked' : (element.status == 3 ? 'DeActivated' : (element.status == 4 ? 'Connected': (element.status == 5 ? 'Disconnected' : 'Pending'))))) | uppercase}}"
                            matTooltipPosition = "right"
                            matTooltipClass="tooltipCustomStyle"
                            ></i>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="mcLstColumns"></tr>
                      <tr mat-row [routerLink]="['/mcList', row.McId]" *matRowDef="let row; columns: mcLstColumns;" style="height:0px;" [ngClass]="row.status== 1 ? 'consented' : (row.status == 0 ? 'revoked' : (row.status == 3 ? 'deActivated' : (row.status == 4 ? 'connected': (row.status == 5 ? 'disconnected' : 'pending'))))"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons style="margin-bottom: 4%;margin-top:-1%; background: none; color: whitesmoke;"></mat-paginator>
            </div>
        </span>
    </div>
</div>
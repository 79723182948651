import { Component, OnInit , ViewChild, ElementRef, AfterViewInit, HostListener, Renderer2, Input, OnChanges } from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

import * as moment from 'moment';
import * as _ from 'underscore';
declare var $: any;// for convertion purpose using the jQuery here, which supposed not to in Angular 2x. will find workaround after convertion
declare let d3 : any;

@Component({
  selector: 'app-speeding',
  templateUrl: './speeding.component.html',
  styleUrls: ['./speeding.component.css']
})
export class SpeedingComponent implements OnInit {


  @Input() speedingData : any;
  @Input() speedingRoadTypeData : any;
  @Input() dashboardCompanyProgram : any;
  @Input() dashboardDate : any;

  //Speeding
  activeSpeedingVehicle = null;
  speedingTblDataSource :any = [];
  speedingColumns : string[] = ['sortBase', 'vehicleType', 'derived_Under_5', 'derived_5To10', 'derived_10To15', 'derived_Over_15', 'derived_Total_Speeding', 'sum_TotalMiles'];
  @ViewChild('sortSpeeding') sortSpeeding: MatSort;
  @ViewChild('paginatorSpeeding') paginatorSpeeding: MatPaginator;

  companyInsProgramDr: any;
  FleetData : any;
  percentile : number = 0;

  toggleSpeedingbtnClassLst : any;
  SpeedingProgmDropClassLst : any;

  speedingChartRoadTypeData = [{ key: "Under 5", values: [] }, { key: "5-10", values: [] }, { key: "10-15", values: [] }, { key: "Over 15", values: [] }];
  speedingRoadTypeStackedBarOptions : any;
  
  speedingChartData = [{ key: "Under 5", values: [] }, { key: "5-10", values: [] }, { key: "10-15", values: [] }, { key: "Over 15", values: [] }];
  speedingStackedBarOptions : any;

  speedingTrendChartData = [{ key: "Total Speeding", values: [] }];
  speedingTrendBarOptions : any;

  //Driver
  @Input() speedingData_Driver : any;
  @Input() speedingRoadTypeData_Driver : any;
  
  driverOrVeh : string = 'vehicleView';
  //Driver




  constructor(private render : Renderer2) { }

  generateSpeedingTable() {

    if(!this.speedingData) return;
    //for sorting on contional VIN or Vehicleid 
    this.speedingData.forEach((item) => {
      item.sortBase = item.vin && item.vin !== 'N/A' ? item.vin.replace(item.vin.slice(0, (item.vin.length) - 6), "").toString() +'/' : '/' + item.vehicleId
    });

    let speedingViewData = _.toArray(this.speedingData);
    
    this.speedingTblDataSource = new MatTableDataSource(speedingViewData);

    //sort on derived columns
    this.speedingTblDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'derived_Under_5':
          return (item.sum_TotalMovingHr == 0 ? 0 : item.sum_Speeding_Under_5mph_ByTime / 3600 * 100 / item.sum_TotalMovingHr);
        case 'derived_5To10':
          return (item.sum_TotalMovingHr == 0 ? 0 : item.sum_Speeding_5_10mph_ByTime / 3600 * 100 / item.sum_TotalMovingHr);

        case 'derived_10To15' :
          return (item.sum_TotalMovingHr == 0 ? 0 : item.sum_Speeding_10_15mph_ByTime / 3600 * 100 / item.sum_TotalMovingHr);
        
        case 'derived_Over_15':
          return (item.sum_TotalMovingHr == 0 ? 0 : item.sum_Speeding_Over_15mph_ByTime / 3600 * 100 / item.sum_TotalMovingHr);
        case 'derived_Total_Speeding':
          return item.sum_TotalMovingHr == 0 ? 0 : 
          (item.sum_Speeding_Under_5mph_ByTime + item.sum_Speeding_5_10mph_ByTime + item.sum_Speeding_10_15mph_ByTime + item.sum_Speeding_Over_15mph_ByTime) / 3600 *100 / item.sum_TotalMovingHr;
        
        default:
          return item[property];
      }
    };

    this.speedingTblDataSource.sort = this.sortSpeeding;
    this.speedingTblDataSource.paginator = this.paginatorSpeeding;
  }

  togglePercentileSpeedingTbl(percentile)
  {
    if(percentile == 0)
    {
        this.percentile = 1;
        this.speedingColumns = 
          ['sortBase', 'vehicleType', 'speeding_Under_5mph_ByTime_Percentile', 'speeding_5_10mph_ByTime_Percentile', 'speeding_10_15mph_ByTime_Percentile', 'speeding_Over_15mph_ByTime_Percentile', 'total_Speeding_ByTime_Percentile', 'sum_TotalMiles'];
        
        let element = document.getElementById("PRToggleDv");
        element.classList.remove('col-md-12');
        element.classList.add('col-md-10');

        let elementDropdown = document.getElementById("PRDropDownDv");
        elementDropdown.classList.add('col-md-2');

        let toggleElement = document.getElementById("speedingPRToggleBtn");
        toggleElement.style.marginRight = '0%';

        this.toggleSpeedingbtnClassLst = element.classList;
        this.SpeedingProgmDropClassLst = elementDropdown.classList;
    }
    else if(percentile == 1)
    {
        this.speedingColumns = 
          ['sortBase', 'vehicleType', 'derived_Under_5', 'derived_5To10', 'derived_10To15', 'derived_Over_15', 'derived_Total_Speeding', 'sum_TotalMiles'];
        
        let element = document.getElementById("PRToggleDv");
        element.classList.remove('col-md-10');
        element.classList.add('col-md-12');

        let elementDropdown = document.getElementById("PRDropDownDv");
        elementDropdown.classList.remove('col-md-2');

        let toggleElement = document.getElementById("speedingPRToggleBtn");
        toggleElement.style.marginRight = '0%';
        this.percentile = 0; 


        this.toggleSpeedingbtnClassLst = element.classList;
        this.SpeedingProgmDropClassLst = elementDropdown.classList;
    }
  }

  vehicleFleetSum()
  {
    if(!this.speedingData) return;
    let dataSet = this.speedingData;
    let u5Sum = 0, _5T10Sum = 0, _10T15Sum = 0, o15Sum = 0, totalMovingHrSum =0;// totalMileSum = 0;

    dataSet.forEach((data) =>
    {
        u5Sum += data.sum_Speeding_Under_5mph_ByTime;
        _5T10Sum += data.sum_Speeding_5_10mph_ByTime;
        _10T15Sum += data.sum_Speeding_10_15mph_ByTime;
        o15Sum += data.sum_Speeding_Over_15mph_ByTime;
        totalMovingHrSum += data.sum_TotalMovingHr;
    })
    //console.log("obj", {"u5Sum": u5Sum, "_5T10Sum" : _5T10Sum, "_10T15Sum": _10T15Sum, "o15Sum": o15Sum, "totalMileSum": totalMovingHrSum });
    return {"u5Sum": u5Sum, "_5T10Sum" : _5T10Sum, "_10T15Sum": _10T15Sum, "o15Sum": o15Sum, "totalMovingHrSum": totalMovingHrSum };
  }


  setSpeedingRoadTypeStackedBarOptions()
  {
    this.speedingRoadTypeStackedBarOptions = 
    {
      chart: {
        type: 'multiBarHorizontalChart',
        height: 430, //470
        //width: 365,
        x: function(d){return d.label;},
        y: function(d){return d.value;},
        //yErr: function(d){ return [-Math.abs(d.value * Math.random() * 0.3), Math.abs(d.value * Math.random() * 0.3)] },
        showControls: false,
        showValues: true,
        duration: 500,
        xAxis: {
          showMaxMin: false,
          //axisLabel: "Road Type",
          //axisLabelDistance: -8,
        },
        yAxis: {
          axisLabel: '',
          tickFormat: function(d){
              return d3.format(',.1f')(d)  + "%";
          }
        },
        margin: {
            top: 0,
            right: 18, //110
            bottom: 80,
            left:100
        },

        showYAxis: true,
        showXAxis: true,
        stacked: true,
        showLegend: false,
        //color: ['rgb(11,108,205)', 'rgb(13,139,197)', 'rgb(220,220,220)', 'rgb(244,20,228)'],
        color: ['rgb(11,108,205)', 'rgb(116,179,255)', 'rgb(220,220,220)', 'rgb(244,20,228)'], //04/27/2022 color change requested by LP
        forceY: [0,20],
        //yRange: [0,242]
        //yRange: [0,385]
      }
    }
  }
  
  setSpeedingStackedBarOptions()
  {
    this.speedingStackedBarOptions = {
      chart: {
        type: 'multiBarHorizontalChart',
        height: 130, //150
        //width: 365,
        x: (d) => {return d.label;},
        y: (d) => {return d.value;},
        //yErr: function(d){ return [-Math.abs(d.value * Math.random() * 0.3), Math.abs(d.value * Math.random() * 0.3)] },
        showControls: false,
        showValues: true,
        duration: 500,
        xAxis: {
            showMaxMin: false
        },
        yAxis: {
          axisLabel: '',
          tickFormat: (d) =>{
            return d3.format(',.1f')(d) + "%";
          }
        },
        margin: {
          top: -1,
          right: 18,//110,
          bottom: 55,
          left:100
        },
        showYAxis: true,
        showXAxis: true,
        stacked: true,
        //color: ['rgb(11,108,205)', 'rgb(13,139,197)', 'rgb(220,220,220)', 'rgb(244,20,228)'],
        color: ['rgb(11,108,205)', 'rgb(116,179,255)', 'rgb(220,220,220)', 'rgb(244,20,228)'], //04/27/2022 color change requested by LP
        showLegend:true,
        forceY: [0, 15],
        //yRange: [0,385]
      }
    }
  }

  setSpeedingTrendBarOptions()
  {
    this.speedingTrendBarOptions = {
      chart: {
        type: 'multiBarHorizontalChart',
        height: 130, //150
        //width: 365,
        x: function(d){return d.label;},
        y: function(d){return d.value;},
        //yErr: function(d){ return [-Math.abs(d.value * Math.random() * 0.3), Math.abs(d.value * Math.random() * 0.3)] },
        showControls: false,
        showValues: false,
        duration: 500,
        xAxis: {
            showMaxMin: false,
            //axisLabel: "Road Type",
            //axisLabelDistance: -8,
        },
        yAxis: {
            axisLabel: '',
            tickFormat: function(d){
              return d3.format(',.1f')(d)  + "%";
            }
        },
        margin: {
          top: 0,
          right: 18,//110,
          bottom: 50,
          left:100
        },

        showYAxis: true,
        showXAxis: true,
        stacked: false,
        showLegend: false,
        color: ['rgb(11,108,205)'],//['rgb(11,108,205)', 'rgb(13,139,197)', 'rgb(220,220,220)', 'rgb(244,20,228)'],
        forceY: [0,50],
        //yRange: [0,242]
        //yRange: [0,385]
      }
    }
  }

  setActiveSpeeding(vehicle, event)
  {
    let rows = Array.from(document.getElementById('speeding').getElementsByTagName('tr'));
    rows.forEach(item => {
      this.render.removeClass(item, 'selected');
    });

    this.render.addClass(event.currentTarget, 'selected');

    this.activeSpeedingVehicle = vehicle;

    //traffic chart
    //establish the data object
    let mediumU5 = {'label': "Medium", 'value': vehicle.sum_TotalMovingHr == 0 ? 0 : vehicle.sum_MTrafficSpeeding_Under_5_ByTime / 3600 * 100 / vehicle.sum_TotalMovingHr};
    let heavyU5 = {'label': "Heavy", 'value': vehicle.sum_TotalMovingHr == 0 ? 0 : vehicle.sum_HTrafficSpeeding_Under_5_ByTime / 3600 * 100 / vehicle.sum_TotalMovingHr};
    let medium5_10 = {'label': "Medium", 'value': vehicle.sum_TotalMovingHr == 0 ? 0 : vehicle.sum_MTrafficSpeeding_5_10_ByTime /3600 * 100 / vehicle.sum_TotalMovingHr};
    let heavy5_10 = {'label': "Heavy", 'value': vehicle.sum_TotalMovingHr == 0 ? 0 : vehicle.sum_HTrafficSpeeding_5_10_ByTime /3600 * 100 / vehicle.sum_TotalMovingHr};
    let medium10_15 = {'label': "Medium", 'value': vehicle.sum_TotalMovingHr == 0 ? 0 : vehicle.sum_MTrafficSpeeding_10_15_ByTime /3600 * 100 / vehicle.sum_TotalMovingHr};
    let heavy10_15 = {'label': "Heavy", 'value': vehicle.sum_TotalMovingHr == 0 ? 0 : vehicle.sum_HTrafficSpeeding_10_15_ByTime /3600 * 100 / vehicle.sum_TotalMovingHr};
    let mediumO15 = {'label': "Medium", 'value': vehicle.sum_TotalMovingHr == 0 ? 0 : vehicle.sum_MTrafficSpeeding_Over_15_ByTime / 3600 * 100 / vehicle.sum_TotalMovingHr};
    let heavyO15 = {'label': "Heavy", 'value': vehicle.sum_TotalMovingHr == 0 ? 0 : vehicle.sum_HTrafficSpeeding_Over_15_ByTime /3600 * 100 / vehicle.sum_TotalMovingHr};


    //clear existing data
    //re assign the value to clear up to force d3 to update the chart
    this.speedingChartData = [{ key: "Under 5", values: [] }, { key: "5-10", values: [] }, { key: "10-15", values: [] }, { key: "Over 15", values: [] }];
    /*
    _.find(this.speedingChartData, {"key": "Under 5"}).values = [];               
    _.find(this.speedingChartData, {"key": "5-10"}).values = [];           
    _.find(this.speedingChartData, {"key": "10-15"}).values = [];  
    _.find(this.speedingChartData, {"key": "Over 15"}).values = [];*/
    

    //push new value
    _.find(this.speedingChartData, {"key": "Under 5"}).values.push(mediumU5);
    _.find(this.speedingChartData, {"key": "Under 5"}).values.push(heavyU5);

    _.find(this.speedingChartData, {"key": "5-10"}).values.push(medium5_10);
    _.find(this.speedingChartData, {"key": "5-10"}).values.push(heavy5_10);

    _.find(this.speedingChartData, {"key": "10-15"}).values.push(medium10_15);
    _.find(this.speedingChartData, {"key": "10-15"}).values.push(heavy10_15);

    _.find(this.speedingChartData, {"key": "Over 15"}).values.push(mediumO15);
    _.find(this.speedingChartData, {"key": "Over 15"}).values.push(heavyO15);    
    //traffic chart

    //road type
    //clear existing data
    this.speedingChartRoadTypeData = [{ key: "Under 5", values: [] }, { key: "5-10", values: [] }, { key: "10-15", values: [] }, { key: "Over 15", values: [] }];


    let roadTypeLst = _.filter(this.speedingRoadTypeData, (vehicleObj) => { return vehicleObj.vehicleId == vehicle.vehicleId;});
    roadTypeLst = roadTypeLst.sort((a, b) => { return a.roadType - b.roadType});
    //console.log('roadTypeLst', roadTypeLst);
    
    let valueUnder5Lst = [];
    let value5_10Lst = [];
    let value10_15Lst = [];
    let valueOver15Lst = [];

    for(var i = 0; i < roadTypeLst.length ; i++)
    {
      valueUnder5Lst.push({"label": roadTypeLst[i].roadTypeDesc, "value": vehicle.sum_TotalMovingHr == 0 ? 0 : roadTypeLst[i].sum_Speeding_Under_5mph_ByTime / 3600 * 100 / vehicle.sum_TotalMovingHr });
      value5_10Lst.push({"label": roadTypeLst[i].roadTypeDesc, "value": vehicle.sum_TotalMovingHr == 0 ? 0 : roadTypeLst[i].sum_Speeding_5_10mph_ByTime / 3600 * 100 / vehicle.sum_TotalMovingHr });
      value10_15Lst.push({"label": roadTypeLst[i].roadTypeDesc, "value": vehicle.sum_TotalMovingHr == 0 ? 0 : roadTypeLst[i].sum_Speeding_10_15mph_ByTime / 3600 * 100 / vehicle.sum_TotalMovingHr });
      valueOver15Lst.push({"label": roadTypeLst[i].roadTypeDesc, "value": vehicle.sum_TotalMovingHr == 0 ? 0 : roadTypeLst[i].sum_Speeding_Over_15mph_ByTime / 3600 * 100 / vehicle.sum_TotalMovingHr });
    }
      
    for (var i = 0 ; i < valueUnder5Lst.length; i++) 
    {
      _.find(this.speedingChartRoadTypeData, {"key": "Under 5"}).values.push(valueUnder5Lst[i]);
      _.find(this.speedingChartRoadTypeData, {"key": "5-10"}).values.push(value5_10Lst[i]);
      _.find(this.speedingChartRoadTypeData, {"key": "10-15"}).values.push(value10_15Lst[i]);
      _.find(this.speedingChartRoadTypeData, {"key": "Over 15"}).values.push(valueOver15Lst[i]);
    }
    //console.log('speedingChartRoadTypeData', $scope.speedingChartRoadTypeData);
    //console.log('what', d3.select('#roadTypeChart').select('.nv-axislabel') );

    //speeding trend chart data
    //the trend displaying on UI is only 3 months and less. for the period all 3 months, whcihc will be for excel. so to limit the months in dashboard, only grab data from endDate and previous 2 months
    let startMonth  = this.dashboardDate.start
    let endMonth = this.dashboardDate.end
    let monthLst = [];

    for(let i = 0; i < 3 ; i++)
    {
      let yearMonth =  moment(endMonth, 'MM/YYYY').add(-i, 'months').year() + ('0' + (moment(endMonth, 'MM/YYYY').add(-i, 'months').month() + 1)).slice(-2);
      monthLst.push(yearMonth);
    }

    let vehicleTrend3M = [];
    //clear up
    //_.find(this.speedingTrendChartData, {"key": "Total Speeding"}).values = [];
    this.speedingTrendChartData = [{ key: "Total Speeding", values: [] }];

    vehicle.speedingTrendLst = vehicle.speedingTrendLst.sort((a, b) => { return a.yearMonth - b.yearMonth});

    let count = vehicle.speedingTrendLst.length >= 3 ? 3 : vehicle.speedingTrendLst.length;

    for(let i = vehicle.speedingTrendLst.length - count; i < vehicle.speedingTrendLst.length; i++)
    {
      if(_.find(monthLst, function(item) {return item == vehicle.speedingTrendLst[i].yearMonth}))
      {
        vehicleTrend3M.push(
          {
            label: vehicle.speedingTrendLst[i].yearMonth.toString().slice(4) + '-' + vehicle.speedingTrendLst[i].yearMonth.toString().slice(0,4),
            value: vehicle.speedingTrendLst[i].sum_MovingTime_Hr == 0 ? 0 : vehicle.speedingTrendLst[i].totalSpeedingSec / 3600 / vehicle.speedingTrendLst[i].sum_MovingTime_Hr * 100
          });
      }
    }
    _.find(this.speedingTrendChartData, {"key": "Total Speeding"}).values = vehicleTrend3M;
  }






  ngOnInit(): void {
    this.companyInsProgramDr = this.dashboardCompanyProgram;// set initial value for percentil program dropdown

    this.setSpeedingStackedBarOptions();
    this.setSpeedingTrendBarOptions();
    this.setSpeedingRoadTypeStackedBarOptions();
  }
  ngOnChanges() : void
  {
    this.FleetData = this.vehicleFleetSum();
    this.generateSpeedingTable();

    this.activeSpeedingVehicle = null;
  }
  ngAfterViewInit() : void
  {
    
  }

}

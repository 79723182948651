<div class="modal-header">
    <h3 class="modal-title">{{popUpModelConfig.title}}</h3>
</div>
<div class="modal-body">
    <div class="span5">{{popUpModelConfig.message}}</div>
    <br/>
    <div>
        <label>Note:</label>
        <textarea style="width:100%; min-height: 50px;" [(ngModel)]="popUpModelConfig.note" *ngIf="!popUpModelConfig.isReadOnlyNote"></textarea>
        <div style="width:100%; min-height: 50px;" *ngIf="popUpModelConfig.isReadOnlyNote">{{popUpModelConfig.note}}</div>
    </div>
    <br/>
    <div *ngIf="popUpModelConfig.checkBox">
        <input type="checkbox" [(ngModel)]="popUpModelConfig.checkBox.checked">
        <label>{{popUpModelConfig.checkBox.label}}</label>
    </div>
     <div *ngIf="popUpModelConfig.dropDown">
        <br/>
        <label>{{popUpModelConfig.dropDown.label}}</label>
        <!-- <select class="form-control" ng-model="popUpModelConfig.dropDown.selectedOption" 
            ng-options="option as option.value for option in popUpModelConfig.dropDown.options" ></select> -->
        <input type="text" [(ngModel)]="popUpModelConfig.dropDown.selectedOption" class="form-control" list="popUpModelDataList" />
        <datalist id="popUpModelDataList" >
            <option *ngFor="let option of popUpModelConfig.dropDown.options" [value]="option.value"></option>
        </datalist>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
    <button type="button" *ngFor="let button of popUpModelConfig.buttons" (click)="executeDialogAction(button.action)" class="btn btn-primary">{{button.label}}</button>
</div>
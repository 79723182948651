import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { CommonAppService } from '../../Services/common-app.service';
import * as _ from 'underscore';
import * as moment from 'moment';
import * as XLSX from '../../../lib/js-xlsx/xlsx.full.min';


@Component({
  selector: 'app-dashdataexport-modal',
  templateUrl: './dashdataexport-modal.component.html',
  styleUrls: ['./dashdataexport-modal.component.css']
})
export class DashdataexportModalComponent implements OnInit {

  selection : any = {
    enableCbxScoring: true,
    enableCbxUtil: true,
    enableCbxRadiusAddr: true,
    enableCbxTerritory: true,
    enableCbxSpeeding: true,
    enableCbxHarshEvent: true,
    enableCbxAll: true

  };

  constructor(public dialogRef: MatDialogRef<DashdataexportModalComponent>,
    @Inject(MAT_DIALOG_DATA) private modalData: any,
    private commonAppService : CommonAppService
    ) { }

  close() {
    this.dialogRef.close();
  }

  checkAll(selection, isAll?)
  {
    if(isAll)
    {
      this.selection.enableCbxUtil = true;
      this.selection.enableCbxRadiusAddr = true;
      this.selection.enableCbxTerritory = true;
      this.selection.enableCbxSpeeding = true;
      this.selection.enableCbxScoring = true;
      this.selection.enableCbxHarshEvent = true;

      return;
    }
    else if(isAll == false)
    {
      this.selection.enableCbxAll = false;

      this.selection.enableCbxUtil = false;
      this.selection.enableCbxRadiusAddr = false;
      this.selection.enableCbxTerritory = false;
      this.selection.enableCbxSpeeding = false;
      this.selection.enableCbxScoring = false;
      this.selection.enableCbxHarshEvent = false;

      return;
    }

    if(selection.enableCbxUtil && selection.enableCbxRadiusAddr &&
        selection.enableCbxTerritory && selection.enableCbxSpeeding && selection.enableCbxScoring && selection.enableCbxHarshEvent)
    {
      this.selection.enableCbxAll = true;
    }
    else
    {  this.selection.enableCbxAll = false; }
  }

  export()
  {
    const exportDataObjLst = this.modalData.data;
    const date = this.modalData.date;

    const Start = date.start.split("/")[1].toString() + date.start.split("/")[0].toString(); 
    const End = date.end.split("/")[1].toString() + date.end.split("/")[0].toString(); 
   
    if(Start > End)
    {
      this.commonAppService._snackBar(false, 'Start Date Must be Eariler than End Date', 5000);
      return;
    }

    if(exportDataObjLst && exportDataObjLst.length > 0)
    {
      let exportCbxSheets = [];

      exportCbxSheets.push({"id": 0, "cbxName": "enableCbxScoring", "value": this.selection.enableCbxScoring});
      exportCbxSheets.push({"id": 1, "cbxName": "enableCbxUtil", "value": this.selection.enableCbxUtil});
      exportCbxSheets.push({"id": 2, "cbxName": "enableCbxRadiusAddr", "value": this.selection.enableCbxRadiusAddr});
      exportCbxSheets.push({"id": 3, "cbxName": "enableCbxTerritory", "value": this.selection.enableCbxTerritory});
      exportCbxSheets.push({"id": 4, "cbxName": "enableCbxSpeeding", "value": this.selection.enableCbxSpeeding});
      exportCbxSheets.push({"id": 5, "cbxName": "enableCbxHarshEvent", "value": this.selection.enableCbxHarshEvent});
      //exportCbxSheets.push({"id": 6, "cbxName": "enableCbxDriverScoreCard", "value": $scope.selection.enableCbxDriverScoreCard});

      const wb = XLSX.utils.book_new();

      for(let j = 0 ;j < exportCbxSheets.length; j++ )
      {
        if(exportCbxSheets[j].value)
        {
          let renderingSheet = _.find(exportDataObjLst, (item) => {
            return  item.id == exportCbxSheets[j].id;
          });

          // commented out, do not need address tab
          /*if(renderingSheet && renderingSheet.id == 2)// radius&address have two sheets
          {
              var ws = XLSX.utils.json_to_sheet(renderingSheet.content[0]);
              var ws2 = XLSX.utils.json_to_sheet(renderingSheet.content[1]);

              XLSX.utils.book_append_sheet(wb, ws, renderingSheet.sheet[0]);
              XLSX.utils.book_append_sheet(wb, ws2, renderingSheet.sheet[1]);
          }*/
          
          
          if(renderingSheet.content)
          {
            let ws = XLSX.utils.json_to_sheet(renderingSheet.content);

            if(renderingSheet.side)
            {
              if(renderingSheet.id == 4 && renderingSheet.content.length > 0 && renderingSheet.side.value.length > 1 && renderingSheet.side2)
              {
                //speeding 
                let startAtR = 0;
                let startAtC = 20;
                ws[XLSX.utils.encode_cell({r: 0 /* 2 */, c: startAtC + 1 /* C */})] = {t: 's' , v: renderingSheet.side.name /* value */};

                for(let k =0; k < renderingSheet.side.value.length ; k++)
                {
                  ws[XLSX.utils.encode_cell({r: startAtR +1 /* 2 */, c: startAtC + k + 1 /* C */})] = {t: 's' , v: renderingSheet.side.value[k].name /* value */};
                  ws[XLSX.utils.encode_cell({r: startAtR +2 /* 2 */, c: startAtC + k + 1 /* C */})] = {t: 's' , v: renderingSheet.side.value[k].value /* value */};

                  let range = XLSX.utils.decode_range(ws['!ref']);

                  range.s.c = 0;
                  range.s.r = 0;
                  range.e.c = startAtC + range.e.c;
                  range.e.r = range.e.r;

                  ws['!ref'] = XLSX.utils.encode_range(range);
                }

                //speeding trend
                let yearMonthColumn = [];
                let yearMonthsInt = [];
                let startAtCTrend = 26;


                renderingSheet.side2.value.forEach((item) => { yearMonthsInt.push(item.yearMonth)});
                let minMo = Math.min.apply(null, yearMonthsInt);
                let maxMo = Math.max.apply(null, yearMonthsInt);
                let minMoFormat = minMo.toString().slice(0 , 4) + '/' + minMo.toString().slice(4) ;
                let maxMoFormat = maxMo.toString().slice(0 , 4) + '/' + maxMo.toString().slice(4) ;

                
                for(let i = moment(minMoFormat, 'YYYY/MM'); i <= moment(maxMoFormat, 'YYYY/MM'); i = moment(i, 'YYYY/MM').add(1, 'months'))
                {
                  yearMonthColumn.push({
                    sheetColumn: ++ startAtCTrend,
                    yearMonth: i.year() + ('0' + (i.month() + 1)).slice(-2)
                  });
                }

                //populate yearMonth to the header
                ws[XLSX.utils.encode_cell({r: 0 /* 2 */, c: 26 /* C */})] = {t: 's' , v: 'Vehicle ID' /* value */};
                for(var i = 0; i < yearMonthColumn.length; i++)
                {
                  ws[XLSX.utils.encode_cell({r: 0 /* 2 */, c: yearMonthColumn[i].sheetColumn /* C */})] = 
                  {
                    t: 's' , 
                    v: yearMonthColumn[i].yearMonth.toString().slice(4) + '-' + yearMonthColumn[i].yearMonth.toString().slice(0,4)
                  };

                  let range = XLSX.utils.decode_range(ws['!ref']);

                  range.s.c = 0;
                  range.s.r = 0;
                  range.e.c = startAtCTrend + range.e.c;
                  range.e.r = range.e.r;

                  ws['!ref'] = XLSX.utils.encode_range(range);
                }
                // add percentage change column at the last of max yearMonth
                ws[XLSX.utils.encode_cell({r: 0 /* 2 */, c: yearMonthColumn[yearMonthColumn.length -1].sheetColumn + 1  /* C */})] = { t: 's', v: 'Percentage Change' };
                /* var range = XLSX.utils.decode_range(ws['!ref']);
                range.s.c = 0;
                range.s.r = 0;
                range.e.c = startAtCTrend + range.e.c;
                range.e.r = range.e.r;
                ws['!ref'] = XLSX.utils.encode_range(range);*/



                //populate vehicle data to the column
                let groupedTrend = _.toArray(_.groupBy(renderingSheet.side2.value, g => g.vehicleId));
                for(let i =0; i < groupedTrend.length ; i++)
                {
                  for(let m = 0; m < groupedTrend[i].length; m ++)
                  {
                    ws[XLSX.utils.encode_cell({r: i + 1 , c: 26 })] = {t: 's' , v: groupedTrend[i][m].vehicleId };

                    let yearMonthObj = _.find(yearMonthColumn, (item) => {
                      return item.yearMonth == groupedTrend[i][m].yearMonth;
                    });
                    if(yearMonthObj)
                    {
                      ws[XLSX.utils.encode_cell({r: i + 1 , c: yearMonthObj.sheetColumn})] = {t: 's' , v: groupedTrend[i][m].totalSpeedingPerc };
                    }
                    //percentage change
                    if(m == groupedTrend[i].length -1)
                    {
                      ws[XLSX.utils.encode_cell({r: i + 1 , c: yearMonthColumn[yearMonthColumn.length -1].sheetColumn + 1})] = {t: 's' , v: (groupedTrend[i][0].totalSpeedingPerc.split('%')[0] - groupedTrend[i][m].totalSpeedingPerc.split('%')[0]).toFixed(1) + '%'  };
                    }

                    let range = XLSX.utils.decode_range(ws['!ref']);

                    range.s.c = 0;
                    range.s.r = 0;
                    range.e.c = startAtCTrend + range.e.c;
                    range.e.r = range.e.r;

                    ws['!ref'] = XLSX.utils.encode_range(range);
                  }
                }
              }
              else if(renderingSheet.id === 3 && renderingSheet.side.value.length === 1)
              {
                  //Territory
                let startAtR = 0;
                let startAtC = 10;

                ws[XLSX.utils.encode_cell({r: 0 /* 2 */, c: startAtC /* C */})] = {t: 's' , v: renderingSheet.side.name /* value */};
                ws[XLSX.utils.encode_cell({r: 0 /* 2 */, c: startAtC + 1 /* C */})] = {t: 's' , v: renderingSheet.side.value[0].value /* value */};

                let range = XLSX.utils.decode_range(ws['!ref']);

                range.s.c = 0;
                range.s.r = 0;
                range.e.c = startAtC + range.e.c;
                range.e.r = range.e.r;

                ws['!ref'] = XLSX.utils.encode_range(range);
              }
              else if (renderingSheet.id === 0)
              {
                //Scoring
                let startAtR = 0;
                let startAtC = 4;

                ws[XLSX.utils.encode_cell({r: 0 /* 2 */, c: startAtC /* C */})] = {t: 's' , v: renderingSheet.side.name /* value */};
                ws[XLSX.utils.encode_cell({r: 0 /* 2 */, c: startAtC + 1 /* C */})] = {t: 's' , v: renderingSheet.side.value /* value */};

                let range = XLSX.utils.decode_range(ws['!ref']);

                range.s.c = 0;
                range.s.r = 0;
                range.e.c = startAtC + range.e.c;
                range.e.r = range.e.r;

                ws['!ref'] = XLSX.utils.encode_range(range);
              }
              else if(renderingSheet.id === 6)
              {
                //Driver score card
                //trending chart
                let startAtR = 0;
                let startAtC = 4;

                //comparison tbl
                startAtR = 0;
                startAtC = 10;

                XLSX.utils.sheet_add_json(ws, renderingSheet.side2.value, {skipHeader: false, origin: {r: startAtR, c: startAtC}});
              }
            }
            XLSX.utils.book_append_sheet(wb, ws, renderingSheet.sheet);

            //Driver
            if(renderingSheet.id === 1)//driver utilization
            {
              let ws2 = XLSX.utils.json_to_sheet(renderingSheet.content_driver);
              
              if(renderingSheet.content_driver.length === 0)
              {
                ws2[XLSX.utils.encode_cell({r: 0 /* 2 */, c: 0})] = { 
                  t: 's', 
                  v: 'No speeding by driver data is available for the time period that was selected due to your telematic service provider does not provide driver data or your driver information is not in your telematic service provider system.' };
              }
              XLSX.utils.book_append_sheet(wb, ws2, 'Utilization_Driver');

            }
            else if(renderingSheet.id === 5)//driver harshevent
            {
              let ws2 = XLSX.utils.json_to_sheet(renderingSheet.content_driver);
              XLSX.utils.book_append_sheet(wb, ws2, 'HarshEvent_Driver');

            }
            else if(renderingSheet.id === 4)//driver speeding
            {
              let ws2 = XLSX.utils.json_to_sheet(renderingSheet.content_driver);

              if(renderingSheet.content_driver.length === 0)
              {
                ws2[XLSX.utils.encode_cell({r: 0 /* 2 */, c: 0})] = { 
                  t: 's', 
                  v: 'No speeding by driver data is available for the time period that was selected due to your telematic service provider does not provide driver data or your driver information is not in your telematic service provider system.' };
              }

              //speeding trend
              let yearMonthColumn = [];
              let yearMonthsInt = [];
              let startAtCTrend = 20;//26;


              renderingSheet.side_driver.value.forEach((item) => { yearMonthsInt.push(item.yearMonth)});
              let minMo = Math.min.apply(null, yearMonthsInt);
              let maxMo = Math.max.apply(null, yearMonthsInt);
              let minMoFormat = minMo.toString().slice(0 , 4) + '/' + minMo.toString().slice(4) ;
              let maxMoFormat = maxMo.toString().slice(0 , 4) + '/' + maxMo.toString().slice(4) ;

              
              for(let i = moment(minMoFormat, 'YYYY/MM'); i <= moment(maxMoFormat, 'YYYY/MM'); i = moment(i, 'YYYY/MM').add(1, 'months'))
              {
                yearMonthColumn.push({
                  sheetColumn: ++ startAtCTrend,
                  yearMonth: i.year() + ('0' + (i.month() + 1)).slice(-2)
                });
              }

              //populate yearMonth to the header
              ws2[XLSX.utils.encode_cell({r: 0 /* 2 */, c: 20 /* C */})] = {t: 's' , v: 'Driver ID' /* value */};
              for(var i = 0; i < yearMonthColumn.length; i++)
              {
                ws2[XLSX.utils.encode_cell({r: 0 /* 2 */, c: yearMonthColumn[i].sheetColumn /* C */})] = 
                {
                  t: 's' , 
                  v: yearMonthColumn[i].yearMonth.toString().slice(4) + '-' + yearMonthColumn[i].yearMonth.toString().slice(0,4)
                };

                let range = XLSX.utils.decode_range(ws2['!ref']);

                range.s.c = 0;
                range.s.r = 0;
                range.e.c = startAtCTrend + range.e.c;
                range.e.r = range.e.r;

                ws2['!ref'] = XLSX.utils.encode_range(range);
              }
              // add percentage change column at the last of max yearMonth
              if(yearMonthColumn.length > 0) ws2[XLSX.utils.encode_cell({r: 0 /* 2 */, c: yearMonthColumn[yearMonthColumn.length -1]?.sheetColumn + 1  /* C */})] = { t: 's', v: 'Percentage Change' };

              //populate driver data to the column
              let groupedTrend = _.toArray(_.groupBy(renderingSheet.side_driver.value, g => g.driverId));
              for(let i =0; i < groupedTrend.length ; i++)
              {
                for(let m = 0; m < groupedTrend[i].length; m ++)
                {
                  ws2[XLSX.utils.encode_cell({r: i + 1 , c: 20 })] = {t: 's' , v: groupedTrend[i][m].driverId };

                  let yearMonthObj = _.find(yearMonthColumn, (item) => {
                    return item.yearMonth == groupedTrend[i][m].yearMonth;
                  });
                  if(yearMonthObj)
                  {
                    ws2[XLSX.utils.encode_cell({r: i + 1 , c: yearMonthObj.sheetColumn})] = {t: 's' , v: groupedTrend[i][m].totalSpeedingPerc };
                  }
                  //percentage change
                  if(m == groupedTrend[i].length -1)
                  {
                    ws2[XLSX.utils.encode_cell({r: i + 1 , c: yearMonthColumn[yearMonthColumn.length -1].sheetColumn + 1})] = {t: 's' , v: (groupedTrend[i][0].totalSpeedingPerc.split('%')[0] - groupedTrend[i][m].totalSpeedingPerc.split('%')[0]).toFixed(1) + '%'  };
                  }

                  let range = XLSX.utils.decode_range(ws2['!ref']);

                  range.s.c = 0;
                  range.s.r = 0;
                  range.e.c = startAtCTrend + range.e.c;
                  range.e.r = range.e.r;

                  ws2['!ref'] = XLSX.utils.encode_range(range);
                }
              }

              XLSX.utils.book_append_sheet(wb, ws2, 'Speeding_Driver');

            }
          }
        }
      }

      /* write workbook and force a download */
      if(!_.isEmpty(wb.Sheets))
      { XLSX.writeFile(wb, "TE Dash_" + this.modalData.mcCompany + " " + date.start + "--" + date.end + ".xlsx");}
    }
  }

  ngOnInit(): void {

    
  }

}

const Recommendations = [
    {
        yearMonth : 202101,
        recommendation : 'According to the National Highway Transportation Safety Administration (NHTSA), speeding remains the number one factor in more than 25% of fatal accidents each year. Speeding while driving is a choice—a negligent choice that can result in thousands of preventable deaths each year.'
    },
    {
        yearMonth : 202102,
        recommendation : "Why Not to Speed. You're More Likely to Lose Control of Your Vehicle. Faster Speeds Increase the Risk of More Serious Injuries. Speeding Impedes the Effectiveness of Vehicle Safety Equipment. You'll Pay More for Gas as Speeding Increases Fuel Consumption."
    },
    {
        yearMonth : 202103,
        recommendation : 'More than 38,000 people die every year in crashes on U.S. Highways.'
    },
    {
        yearMonth : 202104,
        recommendation : 'The U.S. National Highway Traffic Safety Administration estimates that, annually, more than 240,000 motor accidents in the U.S. are caused because of driver fatigue alone.'
    },
    {
        yearMonth : 202105,
        recommendation : 'While many people expect a larger number of accidents to occur in urban areas, the reality is that far more fatal accidents take place on rural two lane roads. In fact, reliable data indicates that as many as 57 percent of all fatal car crashes occur on rural roads. From Insurance Institute for Highway Safety.'
    },
    {
        yearMonth : 202106,
        recommendation : 'The single biggest cause of fatal car accidents is distracted driving. Distractions come in many forms: looking at others in the car, playing with the radio, reaching for something on the floor, or answering a phone call or text message. From Insurance Institute for Highway Safety.'
    },
    {
        yearMonth : 202107,
        recommendation : 'In January of 2021, The Zebra reached out to American drivers to understand the habits they engage in behind the wheel of a car. While many claim they never drive while distracted, others admit to engaging in many other behaviors while driving, including texting while driving. 52.5% of respondents reporting eating while driving. 23.6 % reporting texting, 11.7% taking photos, 6.5% applying makeup and 3.4% admitted to drinking while driving.'
    },
    {
        yearMonth : 202108,
        recommendation : 'The National Highway Transportation Safety Administration (NHTSA) found that somewhere between 94% and 96% of all motor vehicle accidents are caused by some type of human error.'
    },
    {
        yearMonth : 202109,
        recommendation : "In January of 2021, The Zebra, a leading insurance comparison website, reached out to American drivers to understand the habits they engage in behind the wheel of a car. While many claim they never drive while distracted, others admit to engaging in many other behaviors while driving, including texting while driving. 52.5% of respondents reporting eating while driving, down 4.2% from last year's respondents. Other behaviors include: Texting (23.6%) Taking photos (11.7%) Applying makeup (6.5%) 3.4% admitted to drinking while driving!"
    },
    {
        yearMonth : 202110,
        recommendation : 'Communicating Telematics Results with Drivers: Football coaches review the game films with players and sales managers review sales calls with sales reps. It only makes sense for the telematics results to be reviewed with drivers. The intentions are all the same. To help them and make them better.'
    },
    {
        yearMonth : 202111,
        recommendation : 'Driver Coaching Tip. Make it Personal: For example, if a driver follows too close, ask your driver, would you be following that car as close if your wife and kids were in it. Treat Drivers with Respect: Clarify expectations. Establish trust. Explain upfront how telematics can/has helped. If change in driving, ask what is going on. You want to know if something is happening with them in life or their mind set.'
    },
    {
        yearMonth : 202112,
        recommendation : "Driver Coaching Tip. Informal Coaching: Coaching doesn't always have to be formal. Much driver coaching should involve one on one conversations. These informal coaching events are critical in maintaining a consistent communication, being proactive and building a supportive company culture. Positive and Negative Feedback: By providing both positive and negative feedback, drivers felt they are being evaluated fairly and will be more responsive to negative feedback. A good job comment on a small speeding improvement can go a long way."
    },
    {
        yearMonth : 202201,
        recommendation : 'According to the National Highway Transportation Safety Administration (NHTSA), speeding remains the number one factor in more than 25% of fatal accidents each year. Speeding while driving is a choice—a negligent choice that can result in thousands of preventable deaths each year.'
    },
    {
        yearMonth : 202202,
        recommendation : "Why Not to Speed. You're More Likely to Lose Control of Your Vehicle. Faster Speeds Increase the Risk of More Serious Injuries. Speeding Impedes the Effectiveness of Vehicle Safety Equipment. You'll Pay More for Gas as Speeding Increases Fuel Consumption."
    },
    {
        yearMonth : 202203,
        recommendation : 'According to data from the Insurance Institute for Highway Safety (IIHS), truck accidents cause approximately 4,000 deaths each year. In 2019, the number of deaths stood at 4,119. Compared to figures from 2009, there has been 31% increase in truck accident fatalities.'
    },
    {
        yearMonth : 202204,
        recommendation : 'Of all the causes of trucking accidents, driver error is 10 times more likely to be the cause of a crash than any other factor, according to a recent study conducted by the Federal Motor Carrier Safety Administration (FMCSA).'
    },
    {
        yearMonth : 202205,
        recommendation : 'The National Highway Traffic Safety Administration has developed a list of driver behaviors that are factors in fatal crashes. Speeding is at the top of the list of related factors for drivers involved in fatal crashes. In 2019, 8,746 drivers who were involved in fatal crashes (or 17 percent) were speeding.'
    },
    {
        yearMonth : 202206,
        recommendation : 'Of all the causes of trucking accidents, driver error is 10 times more likely to be the cause of a crash than any other factor, according to a recent study conducted by the Federal Motor Carrier Safety Administration (FMCSA).'
    },
    {
        yearMonth : 202207,
        recommendation : 'How to build a Safe Driving Program - Create foundation for a safety culture, Continually reinforce the safety Message, Publicly monitor your employee driving records and safety trends, Recognize and reward good and improved performance'
    },
    {
        yearMonth : 202208,
        recommendation : "Driver Coaching - One of the primary goals of driver coaching is to change driver behaviors and lessen the number of accidents.  Reduction in accidents has many benefits which include less administrative costs associated with crash investigation, less insurance claim handling, less vehicle repairs' handling, reassigning of personnel, rescheduling of jobs, vehicle down time and employee replacement and training.  There is no better fix for accidents than not having one in the first place."
    },
    {
        yearMonth : 202209,
        recommendation : "Distracted Driving - Activities that take drivers' attention off the road, including talking or texting on cellphones, eating, talking with passengers, adjusting vehicle controls and other distractions, are a major safety threat.  In 2019, 3,142 people were killed in crashes involving distractions."
    },
    {
        yearMonth : 202210,
        recommendation : 'The National Highway Traffic Safety Administration (NHTSA) states that driver coaching works.  It helps control costs related to vehicle crashes and creates a worthwhile investment.  Driver coaching benefits both the company and the driver.  The results of these driver coaching techniques can reduce operating costs.   Improving driving habits can reduce vehicle wear and tear, reduce vehicle repairs and replacement, reduce fuel costs, as well as increase insurance savings and vehicle-up time.'
    },
    {
        yearMonth : 202211,
        recommendation : "One of the most effective types of driver coaching is evidence-based coaching that utilizes real-world evidence to coach drivers and provoke meaningful discussions. Telematics is a great tool for providing this evidence.  Coaching is much different from micromanaging. A micromanager is often over-involved and creates a negative impact.  Telling drivers what to do, even if it's with good intentions, is not the same as coaching.  Coaching is a process to help an individual to improve their own performance and help them to learn."
    },
    {
        yearMonth : 202212,
        recommendation : 'Key Coaching Skills - Skills to be used in driver coaching include listening, empathy, asking questions, have drivers find their own solution, focus on solutions, providing feedback and setting goals.  When driver coaching, always end driver coaching sessions on a positive note.  Thank the drivers and make sure they know that they are valued by the company and you are always willing to listen to any problems or topics they wish to discuss in the future.'
    },
    {
        yearMonth : 202301,
        recommendation : 'Driver Coaching - Driver coaching done correctly creates constructive communication between company managers and drivers. The residual benefits are better working relationships and employees understanding that their company cares about their safety and well-being. Providing supportive feedback and/or rewards for when drivers are improving or doing well, creates a positive work environment which improves driver retention.'
    },
    {
        yearMonth : 202302,
        recommendation : "Speeding - For more than two decades, speeding has been involved in approximately one-third of all motor vehicle fatalities. In 2019, speeding was a contributing factor in 26% of all traffic fatalities.  Speed also affects your safety even when you are driving at the speed limit but too fast for road conditions, such as during bad weather, when a road is under repair, or in an area at night that isn't well lit.  Speeding endangers not only the life of the speeder, but all of the people on the road around them."
    },
    {
        yearMonth : 202303,
        recommendation : 'In 2019, about 50 percent of fatal crashes occurred on Friday, Saturday or Sunday, according to NHTSA.'
    },
    {
        yearMonth : 202304,
        recommendation : 'Driver Coaching - Getting to know your drivers as people is an important step in the coaching process. Knowing their strengths, weaknesses, and motivations will support your coaching efforts. One way to build your relationship and build a foundation for coaching is to be transparent. Transparency means practicing open and regular communication with drivers. Talk about your own goals and challenges. Having an honest conversation on both sides could encourage drivers to open up and share their ideas and solutions.'
    },
]

export default Recommendations;
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, PhonePipe } from './app.component';
import { LoginComponent } from './Components/login/login.component';
import { PolicyholdersComponent } from './Components/policyholders/policyholders.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './Services/interceptor.service';
import { HomeComponent } from './Components/home/home.component';
import { ActivateComponent } from './Components/activate/activate.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatSortModule} from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import { NvD3Module} from 'ng2-nvd3';
import { ChartsModule } from 'ng2-charts';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { CommonModule } from '@angular/common';
import { McDrilldownComponent } from './Components/mc-drilldown/mc-drilldown.component';
import { ConsentModalComponent } from './Components/consent-modal/consent-modal.component';
import { PopUpModalComponent } from './Components/pop-up-modal/pop-up-modal.component';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { UtilizationComponent } from './Components/dashboard/utilization/utilization.component';
import { RadiusGaragingComponent } from './Components/dashboard/radius-garaging/radius-garaging.component';
import { TerritoryComponent } from './Components/dashboard/territory/territory.component';
import { SpeedingComponent } from './Components/dashboard/speeding/speeding.component';
import { HarsheventComponent } from './Components/dashboard/harshevent/harshevent.component';
import { FootprintComponent } from './Components/dashboard/footprint/footprint.component';
import { DriverscorecardComponent } from './Components/dashboard/driverscorecard/driverscorecard.component';
import { DashdataexportModalComponent } from './Components/dashdataexport-modal/dashdataexport-modal.component';
import { UserAdminComponent } from './Components/user-admin/user-admin.component';
import { UsersModalComponent } from './Components/users-modal/users-modal.component';
import { ResetComponent } from './Components/reset/reset.component';
import { McUploadComponent } from './Components/mc-upload/mc-upload.component';
import { UserGuideComponent } from './Components/user-guide/user-guide.component';
import { PrivacyComponent } from './Components/privacy/privacy.component';
import { ContactComponent } from './Components/contact/contact.component';
import { CompanyInfoComponent } from './Components/company-info/company-info.component';
import { ConsentComponent } from './Components/consent/consent.component';
import { SignatureModalComponent } from './Components/signature-modal/signature-modal.component';
import { ClaimsLocatorComponent } from './Components/claims-locator/claims-locator.component';
import { UserAgreementComponent } from './Components/user-agreement/user-agreement.component';
import { DriverHarsheventComponent } from './Components/dashboard/harshevent/driver-harshevent/driver-harshevent.component';
import { DriverSpeedingComponent } from './Components/dashboard/speeding/driver-speeding/driver-speeding.component';
import { DriverVehicleToggleComponent } from './Components/customComponents/driver-vehicle-toggle/driver-vehicle-toggle.component';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PolicyholdersComponent,
    HomeComponent,
    ActivateComponent,
    McDrilldownComponent,
    ConsentModalComponent,
    PopUpModalComponent,
    DashboardComponent,
    UtilizationComponent,
    RadiusGaragingComponent,
    TerritoryComponent,
    SpeedingComponent,
    HarsheventComponent,
    FootprintComponent,
    DriverscorecardComponent,
    DashdataexportModalComponent,
    UserAdminComponent,
    PhonePipe,
    UsersModalComponent,
    ResetComponent,
    McUploadComponent,
    UserGuideComponent,
    PrivacyComponent,
    ContactComponent,
    CompanyInfoComponent,
    ConsentComponent,
    SignatureModalComponent,
    ClaimsLocatorComponent,
    UserAgreementComponent,
    DriverHarsheventComponent,
    DriverSpeedingComponent,
    DriverVehicleToggleComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatDialogModule,
    MatSnackBarModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    NvD3Module,
    ChartsModule,
    NgxMaskModule.forRoot(),
    MatButtonToggleModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true}, Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

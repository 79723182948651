import { Component, OnInit , ViewChild} from '@angular/core';
import { Router } from '@angular/router'
import {DataService} from '../../Services/data.service'
import {AuthService} from '../../Services/auth.service'
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {UsersModalComponent } from '../users-modal/users-modal.component'


@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.css']
})
export class UserAdminComponent implements OnInit {

  subUsers : any;

  userTblDataSource :any = [];
  userColumns : string[] = ['Name', 'Phone', 'email' ];
  @ViewChild('sortUser') sortUser: MatSort;
  @ViewChild('paginatorUser') paginatorUser: MatPaginator;
  

  constructor(private dataService : DataService,
     private authService : AuthService,
     private router : Router,
     private dialog : MatDialog) { }

  validatesmUA() {
    const authData = JSON.parse(localStorage.getItem('authorizationData'));
    this.authService._getsmData(authData.userRole, authData.groupType, "UserAdminHtml")
      .subscribe((result) => {
        if(!this.authService._accessFilter(result, "UserAdminHtml")){
          this.authService._logOut();
          this.router.navigate(['/']);
          //move user to home page
        }                                                
      })
  };

  loadData() 
  {
    this.dataService._getSubUsers()
      .subscribe((subUserInfo) => {

        //console.log('USERS', subUserInfo);
        this.subUsers = subUserInfo;
        this.generateTable();
      })
  };

  generateTable()
  {
    this.userTblDataSource = new MatTableDataSource(this.subUsers);
    this.userTblDataSource.sort = this.sortUser;
    this.userTblDataSource.paginator = this.paginatorUser;

  }

  open(user?)
  {
    let dialogRef = this.dialog.open(UsersModalComponent, {
      data: user,
      width: '35%',
      //height: '35%',
      id: 'modal-component', //to match the global css
      position: { 
        top: '1%',
        left :'33%',
        right: ''
      }
    });

    //only reload when updating and adding
    dialogRef.afterClosed().subscribe( result => {
      if(result) {this.loadData();}
    });
  }



  ngOnInit(): void {
    this.validatesmUA();
    this.loadData();
  }

}

<div class="background-image"></div>
<div class="slogan" style="width:110%;padding-top: 8%;"> <!-- <div class="row hero"> -->
    <div class="text-center"> <!-- <div class="col-md-12 text-center"> -->
        <h1 class="text-center" style="font-family: 'Montserrat', Sans-serif; font-weight: bold; color: #ffffff; font-size: 84pt; text-transform: uppercase; text-shadow:1px 1px 5px #666666;">Insight that drives performance
        <span style="font-family: 'Montserrat', Sans-serif; font-weight: bold; color: #0062ff;text-shadow:1px 1px 5px #666666; font-size: 84pt;margin-left:-1.5%;">.</span></h1>   
         <!-- <h1>Insight that drives performance.</h1> -->
         
        <button type="button" class="btn btn-lg btn-pill btn-info" style="margin-top: 2.5%;" (click) ='startNow()' *ngIf = "!isLoggedIn()">Start Now</button>
    </div>
</div>
<!-- <canvas id="particles"></canvas> -->
<div id="particles"></div>

<style>
	.slogan {
	    height: 200px;
	    width: 400px;
	    margin: -100px 0 0 -100px;
	    top: 50%;
	    left: 50%;
	}
</style>



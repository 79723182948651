import { Component, Directive, OnInit, Pipe} from '@angular/core';
import { Title } from '@angular/platform-browser';  
import { AuthService } from './Services/auth.service';
import { DataService } from './Services/data.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'
import { fadeInAnimation } from './_animations/_fadeInAnimation'
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';  
import {UserGuideComponent } from './Components/user-guide/user-guide.component'






@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations : [fadeInAnimation]
  
})
export class AppComponent{

  authentication;
  smIndex;
  groupInfo = {};
  year = new Date();
  backToSuper = {};
  navbarExpanded = false;

  announcement : any = {
    announcing: false,
    announcementTxt: ''

  };


  constructor(private authService : AuthService, 
    private dataService : DataService,
    private router : Router, private dialog : MatDialog,
    private activatedRoute : ActivatedRoute,
    private titleService : Title)
  {
    this.authService._fillAuthData();
    this.authService._fillsmData();
    this.authentication =  this.authService._authentication;
    this.smIndex = this.authService._smIndex;
  }

  logOut()
  {
    /*convert this later
     //test super user
     if(localStorageService.get("authorizationData") && localStorageService.get("authorizationData").groupType == appConst.groupType.system)
     {
         localStorageService.remove("suLoginAs"); 
     }
     //test super user
     */
    this.authService._logOut();
    this.router.navigate(['/']);
    // move user to home page
  }

  openUserGuide()
  {
    let dialogRef = this.dialog.open(UserGuideComponent, {
      //data: user,
      width: '50%',
      //height: '85%',
      id: 'modal-component', //to match the global css
      position: { 
        //top: '1%',
        //left :'25%',
        //right: ''
      }
    });
  }

  getChild(activatedRoute: ActivatedRoute) {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  
  }  

  ngOnInit() {  
    this.router.events.pipe(  
        filter(event => event instanceof NavigationEnd),  
      ).subscribe(() => {  
        const rt : ActivatedRoute = this.getChild(this.activatedRoute);  
        rt.data.subscribe(data => {  
          //console.log(data);  
          this.titleService.setTitle(`${data ? data.title : ''} | Telematic Exchange Angular`)
        }); 

        if(this.authentication.isAuth)
        {
          this.dataService._getAnnouncement().subscribe(
            (result : any) =>
            {
              if(result && result.status === 'isOn')
              {
                this.announcement.announcing = true;
                this.announcement.announcementTxt = result.announcementTxt;
              }
              else
              {
                this.announcement.announcing = false;
                this.announcement.announcementTxt = '';
              }
            }
          );
        }
      });
  }  

 
  


  
  
  

}

@Pipe({
  name: "phone"
})
export class PhonePipe {
  transform(tel) {

    if (!tel) {
      return '';
    }

    let value = tel.toString().trim().replace(/^\+/, '');

    if (value.match(/[^0-9]/)) {
      return tel;
    }

    let country, city, number;

    switch (value.length) {
      case 1:
      case 2:
      case 3:
        city = value;
        break;

      default:
        city = value.slice(0, 3);
        number = value.slice(3);
    }

    if (number) {
        if (number.length > 3) {
          number = number.slice(0, 3) + '-' + number.slice(3, 7);
        } else {
          number = number;
        }

        return ("(" + city + ") " + number).trim();
    } else {
      return "(" + city;
    }
  }
}







 



 


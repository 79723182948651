import { Injectable , Injector} from '@angular/core';
import {observable, Observable} from 'rxjs';
import {catchError, filter, map, tap} from 'rxjs/operators';
import { HttpClient} from '@angular/common/http';
//import {SERVICEBASE} from '../app.config';
import {environment} from '../../environments/environment';




@Injectable({
  providedIn: 'root'
})
export class ClaimLocatorService {

  constructor(private httpClient : HttpClient) { }

   _getVehiclesByMC(mcID : number) : Observable<any> {
    
    let getURL = environment.serviceBase + 'api/ClaimLocator/GetVehiclesByMC?McId=' + mcID;

    return this.httpClient.get(getURL);
  }

  _findVehicles(clData) : Observable<any> {
  
   let url = environment.serviceBase + "api/ClaimLocator/FindVehicles";

   return this.httpClient.post(url, clData, {headers: { 'Content-Type': 'application/json'} , observe : 'response' });
  }

  _addClaimLocation(cls) : Observable<any> {
    
    let url = environment.serviceBase + "api/ClaimLocator/AddClaimLocation";

    return this.httpClient.post(url, cls, { headers: { 'Content-Type': 'application/json'} , observe : 'response' });
  }

  _getJSONFile(path) : Observable<object> {
    
    // $.getJSON("appdata/ClaimsLocatorReportDef.json", { _: new Date().getTime() }, (def) => {
    //     deferred.resolve(def);
    // }).fail(function () {
    //     deferred.reject(path);
    // });
    // return deferred.promise;

    
    return this.httpClient.get('appdata/ClaimsLocatorReportDef.json');
  };

  _getClaimLocatorReportData(cls) : Observable<any> {
    
    let url = environment.serviceBase + "api/ClaimLocator/GetClaimLocatorReportData";

    return this.httpClient.post(url, cls, { headers: { 'Content-Type': 'application/json'} , observe : 'response' });
  };

  _generateClaimLocatorReport(cls) : Observable<any> {
    
    let url = environment.serviceBase + "api/ClaimLocator/GenerateClaimLocatorReport";

    return this.httpClient.post(url, cls, { headers: { 'Content-Type': 'application/json'} , observe : 'response' });
  }
}

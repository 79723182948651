import { Component, OnInit, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-driver-vehicle-toggle',
  templateUrl: './driver-vehicle-toggle.component.html',
  styleUrls: ['./driver-vehicle-toggle.component.css']
})
export class DriverVehicleToggleComponent implements OnInit {

  @Output() driverOrVehvent = new EventEmitter<string>();

  driverOrVeh = "vehicleView";

  constructor() { }

  onValChange() : void {
    this.driverOrVehvent.emit(this.driverOrVeh);
  }

  ngOnInit(): void {
  }

}

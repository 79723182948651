import { Component, OnInit , ViewChild, ElementRef, AfterViewInit, HostListener, Renderer2, Input, OnChanges } from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';

import * as _ from 'underscore';
declare let d3 : any;

@Component({
  selector: 'app-driver-harshevent',
  templateUrl: './driver-harshevent.component.html',
  styleUrls: ['./driver-harshevent.component.css']
})
export class DriverHarsheventComponent implements OnInit {

  @Input() harshEventData_Driver : any;
  @Input() harshEventSumData_Driver : any;

  activeHarshEventDriver = null;
  driverHarshEventTblDataSource :any = [];
  driverHarshEventColumns : string[] = ['driverName', 'sum_Harsh_Brake', 'derived_sum_Harsh_Brake_Per1k', 'sum_Harsh_Corner','derived_sum_Harsh_Corner_Per1k', 'sum_Harsh_Quick_Accelaration', 'derived_sum_Harsh_Quick_Accelaration_Per1k', 'sum_TotalMiles'];
  @ViewChild('sortDriverHarshEvent') sortDriverHarshEvent: MatSort;
  @ViewChild('paginatorDriverHarshEvent') paginatorDriverHarshEvent: MatPaginator;

  harshEventInTrafficOptions : any;
  harshEventCategoryOptions : any;

  driverHarshEventInTrafficChartData = [{ key: "Brake", values: [] }, { key: "Corner", values: [] }, { key: "Accel", values: [] }];
  driverHarshEventCategoryChartData = [{ key: "Brake", values: [] }, { key: "Corner", values: [] }, { key: "Accel", values: [] }];

  //driver pencentile
  driverHarshPercentile : number =  0;
  toggleDriverHarshEventbtnClassLst : any;
  driverHarshEventProgmDropClassLst : any;
  companyInsProgramDr : any = '';
  @Input() dashboardCompanyProgram : any;


  constructor(private render : Renderer2) { }

  generateDriverHarshEventTable() {

    if(!this.harshEventSumData_Driver) return;
    //sum up harshEventData for the table

    let harshEventDataSum = this.harshEventSumData_Driver;

      

    let harshEventViewData = _.toArray(harshEventDataSum);
    
    this.driverHarshEventTblDataSource = new MatTableDataSource(harshEventViewData);

    //sort on derived columns
    this.driverHarshEventTblDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'derived_sum_Harsh_Brake_Per1k':
          return ((item.sum_TotalMiles == 0 || item.sum_Harsh_Brake == 0) ? 0: item.sum_Harsh_Brake  * 1000 / item.sum_TotalMiles );
        case 'derived_sum_Harsh_Corner_Per1k':
          return ((item.sum_TotalMiles == 0 || item.sum_Harsh_Corner == 0) ? 0: item.sum_Harsh_Corner * 1000 / item.sum_TotalMiles);

        case 'derived_sum_Harsh_Quick_Accelaration_Per1k' :
          return ((item.sum_TotalMiles == 0 || item.sum_Harsh_Quick_Accelaration == 0) ? 0 : item.sum_Harsh_Quick_Accelaration * 1000 / item.sum_TotalMiles);
        
        default:
          return item[property];
      }
    };

    this.driverHarshEventTblDataSource.sort = this.sortDriverHarshEvent;
    this.driverHarshEventTblDataSource.paginator = this.paginatorDriverHarshEvent;


  }

  setActiveHarshEventDriver(driver, event) {

    let rows = Array.from(document.getElementById('driverHarshEvent').getElementsByTagName('tr'));
    rows.forEach(item => {
      this.render.removeClass(item, 'selected');
    });

    this.render.addClass(event.currentTarget, 'selected');

    this.activeHarshEventDriver = driver;

    // events in Traffic
     //clear existing harsh event in traffic data
    this.driverHarshEventInTrafficChartData = [{ key: "Brake", values: [] }, { key: "Corner", values: [] }, { key: "Accel", values: [] }];

    //push new value
    var trafficTypeLst = _.filter(this.harshEventData_Driver, (driverObj) => { return driverObj.driverId == driver.driverId &&
     (driverObj.categoryType === 1 || driverObj.categoryType === 2 || driverObj.categoryType === 3);});

    trafficTypeLst = trafficTypeLst.sort((a, b) => { return a.categoryType - b.categoryType});
    //console.log("trafficLst",trafficTypeLst );

    let valueBrakeLst = [];
    let valueCornerLst = [];
    let valueAccelLst = [];

    for(let i = 0; i < trafficTypeLst.length ; i++)
    {
      valueBrakeLst.push({"label": trafficTypeLst[i].categoryTypeDesc.split(" ")[0], "value": driver.sum_TotalMiles == 0 ? 0 : trafficTypeLst[i].sum_Harsh_Brake * 1000 / driver.sum_TotalMiles });
      valueCornerLst.push({"label": trafficTypeLst[i].categoryTypeDesc.split(" ")[0], "value": driver.sum_TotalMiles == 0 ? 0 : trafficTypeLst[i].sum_Harsh_Corner * 1000 / driver.sum_TotalMiles });
      valueAccelLst.push({"label": trafficTypeLst[i].categoryTypeDesc.split(" ")[0], "value": driver.sum_TotalMiles == 0 ? 0 : trafficTypeLst[i].sum_Harsh_Quick_Accelaration * 1000 / driver.sum_TotalMiles });
    }
    
    for (let i = 0 ; i < valueBrakeLst.length; i++) 
    {
      _.find(this.driverHarshEventInTrafficChartData, {"key": "Brake"}).values.push(valueBrakeLst[i]);
      _.find(this.driverHarshEventInTrafficChartData, {"key": "Corner"}).values.push(valueCornerLst[i]);
      _.find(this.driverHarshEventInTrafficChartData, {"key": "Accel"}).values.push(valueAccelLst[i]);
    } 
    //console.log("testingTraffic", $scope.harshEventInTrafficChartData);
    // events in Traffic

    //categorical events
    //clear existing data
    this.driverHarshEventCategoryChartData = [{ key: "Brake", values: [] }, { key: "Corner", values: [] }, { key: "Accel", values: [] }];

    //push new value
    let categoryLst = _.filter(this.harshEventData_Driver, (driverObj) => { return driverObj.driverId == driver.driverId &&
     !(driverObj.categoryType === 1 || driverObj.categoryType === 2 || driverObj.categoryType === 3);});

    categoryLst = categoryLst.sort((a, b) => { return a.categoryType - b.categoryType});
    //console.log("categoryLst",categoryLst );

    let valueBrakeCategoryLst = [];
    let valueCornerCategoryLst = [];
    let valueAccelCategoryLst = [];

    for(let i = 0; i < categoryLst.length ; i++)
    {
      valueBrakeCategoryLst.push({"label": categoryLst[i].categoryTypeDesc, "value": driver.sum_TotalMiles == 0 ? 0 : categoryLst[i].sum_Harsh_Brake * 1000 / driver.sum_TotalMiles });
      valueCornerCategoryLst.push({"label": categoryLst[i].categoryTypeDesc, "value": driver.sum_TotalMiles == 0 ? 0 : categoryLst[i].sum_Harsh_Corner * 1000 / driver.sum_TotalMiles });
      valueAccelCategoryLst.push({"label": categoryLst[i].categoryTypeDesc, "value": driver.sum_TotalMiles == 0 ? 0 : categoryLst[i].sum_Harsh_Quick_Accelaration * 1000 / driver.sum_TotalMiles });
    }
    
    for (let i = 0 ; i < valueBrakeCategoryLst.length; i++) 
    {
      _.find(this.driverHarshEventCategoryChartData, {"key": "Brake"}).values.push(valueBrakeCategoryLst[i]);
      _.find(this.driverHarshEventCategoryChartData, {"key": "Corner"}).values.push(valueCornerCategoryLst[i]);
      _.find(this.driverHarshEventCategoryChartData, {"key": "Accel"}).values.push(valueAccelCategoryLst[i]);
    }
    //categorical events
  }

  setDriverHarshEventInTrafficOptions()
  {
    this.harshEventInTrafficOptions = 
    {
      chart: {
        type: 'multiBarHorizontalChart',
        height: 380,
        groupSpacing: 0.25,
        //width: 365,
        x: (d) => {return d.label;},
        y: (d) => {return d.value;},
        //yErr: function(d){ return [-Math.abs(d.value * Math.random() * 0.3), Math.abs(d.value * Math.random() * 0.3)] },
        showControls: false,
        showValues: false,
        duration: 500,
        xAxis: {
          showMaxMin: false
        },
        yAxis: {
          axisLabel: '',
          tickFormat: (d) => {
            return d3.format(',.1f')(d);
          }
        },
        margin: {
          top: -1,
          right: 20,//110,
          bottom: 35,
          left:100
        },

        showYAxis: false,
        showXAxis: true,
        stacked: false,
        color: ['rgb(11,108,205)', 'rgb(220,220,220)', 'rgb(13,139,197)'],
        showLegend: false,
        forceY: [0, 10],
        //yRange: [0,237],
      }
    }
  }

  setDriverHarshEventCategoryOptions()
  {
    this.harshEventCategoryOptions = {
      chart: {
        type: 'multiBarHorizontalChart',
        height: 550,
        groupSpacing: 0.25,
        //width: 365,
        x: (d) => {return d.label;},
        y: (d) => {return d.value;},
        //yErr: function(d){ return [-Math.abs(d.value * Math.random() * 0.3), Math.abs(d.value * Math.random() * 0.3)] },
        showControls: false,
        showValues: false,
        duration: 500,
        xAxis: {
          showMaxMin: false
        },
        yAxis: {
          axisLabel: '',
          tickFormat: (d) => {
            return d3.format(',.1f')(d);
          }
        },
        margin: {
          top: -1,
          right: 20,
          bottom: 65,
          left: 100
        },

        showYAxis: true,
        showXAxis: true,
        stacked: false,
        color: ['rgb(11,108,205)', 'rgb(220,220,220)', 'rgb(13,139,197)'], 
        showLegend: true,
        forceY: [0, 10],
        //yRange: [0,237],
      }
    }
  }

  calculatePercentile(driverLst)
  {
    driverLst.forEach((item) => {
      if(item.sum_TotalMiles > 0)
      {
        item.brake_Percentile = 100 * (item.rankBrakePerc - 0.5) / item.numOfDriverInProgram;
        item.accel_Percentile = 100 * (item.rankAccelPerc - 0.5) / item.numOfDriverInProgram;
        item.corner_Percentile = 100 * (item.rankCornerPerc - 0.5) / item.numOfDriverInProgram;
        

        item.brake_Percentile_AllProgm = 100 * (item.rankBrakeAllProgramPerc - 0.5) / item.numOfDriverInAllProgram;
        item.accel_Percentile_AllProgm = 100 * (item.rankAccelAllProgramPerc - 0.5) / item.numOfDriverInAllProgram;
        item.corner_Percentile_AllProgm = 100 * (item.rankCornerAllProgramPerc - 0.5) / item.numOfDriverInAllProgram;
        
      }
      else
      {
        item.brake_Percentile = 0;
        item.accel_Percentile = 0;
        item.corner_Percentile = 0;
        

        item.brake_Percentile_AllProgm = 0;
        item.accel_Percentile_AllProgm = 0;
        item.corner_Percentile_AllProgm = 0;
        
      }
    });
    return driverLst;
  }

  togglePercentileDriverHarshEventTbl(percentile)
  {
    if(percentile == 0)
    {
        this.driverHarshPercentile = 1;
        this.driverHarshEventColumns = 
        [ 'driverName',  'brake_Percentile', 'corner_Percentile',  'accel_Percentile', 'sum_TotalMiles'];
        
        let element = document.getElementById("DriverHarshPRToggleDv");
        element.classList.remove('col-md-12');
        element.classList.add('col-md-10');

        let elementDropdown = document.getElementById("DriverHarshPRDropDownDv");
        elementDropdown.classList.add('col-md-2');

        let toggleElement = document.getElementById("driverHarshPRToggleBtn");
        toggleElement.style.marginRight = '0%';

        this.toggleDriverHarshEventbtnClassLst = element.classList;
        this.driverHarshEventProgmDropClassLst = elementDropdown.classList;
    }
    else if(percentile == 1)
    {
        this.driverHarshEventColumns = 
        ['driverName', 'sum_Harsh_Brake', 'derived_sum_Harsh_Brake_Per1k', 'sum_Harsh_Corner','derived_sum_Harsh_Corner_Per1k', 'sum_Harsh_Quick_Accelaration', 'derived_sum_Harsh_Quick_Accelaration_Per1k', 'sum_TotalMiles'];
        
        let element = document.getElementById("DriverHarshPRToggleDv");
        element.classList.remove('col-md-10');
        element.classList.add('col-md-12');

        let elementDropdown = document.getElementById("DriverHarshPRDropDownDv");
        elementDropdown.classList.remove('col-md-2');

        let toggleElement = document.getElementById("driverHarshPRToggleBtn");
        toggleElement.style.marginRight = '0%';
        this.driverHarshPercentile = 0; 


        this.toggleDriverHarshEventbtnClassLst = element.classList;
        this.driverHarshEventProgmDropClassLst = elementDropdown.classList;
    }
  }

  ngOnInit(): void {

    this.companyInsProgramDr = this.dashboardCompanyProgram;// set initial value for percentil program dropdown
    this.setDriverHarshEventCategoryOptions();
    this.setDriverHarshEventInTrafficOptions();
  }
  
  ngOnChanges(): void
  {
    this.generateDriverHarshEventTable();
    this.activeHarshEventDriver = null
  }

  
}

<div class="row">
    <div class="col-md-7">
         <div class="row">
            <button class="btn btn-info btn" style="float: right;margin-right: 2%;" (click)="togglePercentRadiusTbl(percentage)">{{ (percentage ? '#' : '%') }}</button>
        </div>
        <div class="table-responsive dashtable">
            <table id = 'garaging' mat-table [dataSource] = "radiusTblDataSource" #sortRadius ='matSort' matSort  class="table table-condensed table-striped">
                <ng-container matColumnDef="sortBase">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        VIN/Vehicle ID
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.vin && element.vin != 'N/A' ? (element.vin.replace(element.vin.slice(0, (element.vin.length) - 6), "") + '/') : ('/' + element.vehicleId)}}
                     </td>
                </ng-container>
                  
                <ng-container matColumnDef="vehicleType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300">
                        Vehicle Type
                    </th>
                    <td mat-cell *matCellDef="let element" > {{element.vehicleType}} </td>
                </ng-container>
                  
                <ng-container matColumnDef="sum_Miles_Under_50">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        0-50
                    </th>
                    <td mat-cell *matCellDef="let element" class="col-md-2"> {{element.sum_Miles_Under_50 | number: '1.0-2'}} </td>
                </ng-container>
                <ng-container matColumnDef="derived_percent_sum_Miles_Under_50" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        0-50
                    </th>
                    <td mat-cell *matCellDef="let element"  class="col-md-2"> 
                        {{ (element.sum_TotalMiles == 0 ? 0 : element.sum_Miles_Under_50 / element.sum_TotalMiles * 100) | number: '1.0-1' }}%
                    </td>
                </ng-container>

                <ng-container matColumnDef="derived_sum_Miles_50_200">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        50-200
                    </th>
                    <td mat-cell *matCellDef="let element"  class="col-md-2"> 
                        {{ element.sum_Miles_50_100 + element.sum_Miles_100_150 + element.sum_Miles_150_200 | number: '1.0-2' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="derived_percent_sum_Miles_50_200">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        50-200
                    </th>
                    <td mat-cell *matCellDef="let element" class="col-md-2">
                        {{ (element.sum_TotalMiles == 0 ? 0 : (element.sum_Miles_50_100 + element.sum_Miles_100_150 + element.sum_Miles_150_200) / element.sum_TotalMiles * 100) | number: '1.0-1' }}%
                    </td>
                </ng-container>

                <ng-container matColumnDef="derived_sum_Miles_Over_200">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Over 200
                    </th>
                    <td mat-cell *matCellDef="let element" class="col-md-2">
                        {{ element.sum_Miles_200_400 + element.sum_Miles_400_700 + element.sum_Miles_Over_700 | number: '1.0-2' }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="derived_percent_sum_Miles_Over_200">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Over 200
                    </th>
                    <td mat-cell *matCellDef="let element" class="col-md-2">
                        {{ (element.sum_TotalMiles == 0 ? 0 : (element.sum_Miles_200_400 + element.sum_Miles_400_700 + element.sum_Miles_Over_700) / element.sum_TotalMiles * 100) | number: '1.0-1' }}%
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="radiusColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: radiusColumns;" style="height:0px;" (click) = 'setActiveGaragingVehicle(row, $event)'
                [ngClass] = "(activeGarageIndex != null) && (getGaragingNumber(row) == activeGarageIndex + 1) ? 'selected': ''"></tr>
            </table>
            <mat-paginator #paginatorRadius [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons style="margin-bottom: 4%;margin-top:-1%; background: none; color: whitesmoke;"></mat-paginator>
        </div>
    </div>
    <div class="col-md-5">
        <div class="row">
            <div class="col-md-12 text-center" >
                <div id ='mapContainer' #mapContainer style="width: 100%; height: 445px;" class="img-responsive"> </div> 
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive dashtable">
                    <table id = 'garages' mat-table [dataSource] = "garagesTblDataSource" class="table table-hover table-condensed table-condensedNew">
                        <ng-container matColumnDef="derived_#">
                            <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                                #
                            </th>
                            <td mat-cell *matCellDef="let element; let index = index">
                                {{ paginatorGarages.pageIndex * paginatorGarages.pageSize + index + 1 }}
                             </td>
                        </ng-container>
                          
                        <ng-container matColumnDef="street">
                            <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300">
                                Street
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.street}} </td>
                        </ng-container>
                          
                        <ng-container matColumnDef="city">
                            <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                                City
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.city}} </td>
                        </ng-container>
        
                        <ng-container matColumnDef="state">
                            <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                                State
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{ element.state }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="count">
                            <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                                Count
                            </th>
                            <td mat-cell *matCellDef="let element" class="col-sm-1">
                                {{ element.count }}
                            </td>
                        </ng-container>
        
                        <tr mat-header-row *matHeaderRowDef="garagesColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: garagesColumns; let index = index" style="height:0px;" 
                        (click) = 'setActiveGarage(paginatorGarages.pageIndex * paginatorGarages.pageSize + index)'
                        [ngClass] = "(paginatorGarages.pageIndex * paginatorGarages.pageSize + index) == activeGarageIndex ? 'selected' : ''"></tr>
                    </table>
                    <mat-paginator #paginatorGarages [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons style="margin-bottom: 2%;margin-top:-1%; background: none; color: #fff;"></mat-paginator>
                    
                </div>
            </div>
        </div>

    </div>
</div>
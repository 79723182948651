<div class="row">
    <div class="col-md-12">
        <div class="dashhead">
            <div class="dashhead-titles">
                <h6 class="dashhead-subtitle">Company Info</h6>
                <h2 class="dashhead-title">{{ groupInfo.CompanyName || '--' }}</h2>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="panel panel-info">
            <div class="panel-heading"></div>
            <div class="panel-body">
                <div class="row">
                    <div class="form-group col-md-12">
                        <h3 class="jumboTitle">Company Info</h3>
                        <div class="row padFive">
                            <div class="col-md-6">
                                <div class="form-group label-floating">
                                    <label [ngClass]="editing ? 'editing' : 'displaying'" for="i4" class="control-label">Company Name:</label>
                                    <!-- <input type="text" class="form-control" ng-model="groupInfo.CompanyName" ng-if="editing"> -->
                                    <h4>{{ groupInfo.CompanyName || '--' }}</h4><!-- ng-if="!editing" -->
                                </div>
                                <div class="form-group label-floating">
                                    <label [ngClass]="editing ? 'editing' : 'displaying'" for="i4" class="control-label">Insurance Company:</label>
                                    <!-- <input type="text" class="form-control" ng-model="iccInfo.CompanyName" ng-if="editing" disabled> -->
                                    <h4>{{ iccInfo.CompanyName || '--' }}</h4><!-- ng-if="!editing" -->
                                </div>
                                <div class="form-group label-floating">
                                    <label [ngClass]="editing ? 'editing' : 'displaying'" for="i4" class="control-label">Agent:</label>
                                    <!-- <input type="text" class="form-control" ng-model="groupInfo.Agent" ng-if="editing"> -->
                                    <h4>{{ groupInfo.Agent || '--' }}</h4> <!-- ng-if="!editing" -->
                                </div>
                            </div>
                            <div class="col-md-4" >
                                <div class="form-group label-floating">
                                    <label [ngClass]="editing ? 'editing' : 'displaying'" for="i4" class="control-label">Point of Contact Name:</label>
                                    <select class="form-control" [(ngModel)]="groupInfo.AdminId" *ngIf="editing">
                                        <option *ngFor="let user of subUsers" [value] = 'user.UserId' > {{user.Name }}</option>
                                    </select>
                                    <h4 style="margin-left: 3%;" *ngIf="!editing">{{ adminInfo?.Name || '--' }}</h4>
                                </div>
                                <div class="form-group label-floating" *ngIf="!editing">
                                    <label [ngClass]="editing ? 'editing' : 'displaying'" for="i4" class="control-label">Point of Contact Email:</label>
                                    <input type="text" class="form-control" [(ngModel)]="adminInfo.email" *ngIf="editing" disabled>
                                    <h4 style="margin-left: 3%;" *ngIf="!editing">{{ adminInfo?.email || '--' }}</h4>
                                </div>
                                <div class="form-group label-floating" *ngIf="!editing">
                                    <label [ngClass]="editing ? 'editing' : 'displaying'" for="i4" class="control-label">Point of Contact Phone:</label>
                                    <input type="text" class="form-control" [(ngModel)]="adminInfo.Phone" *ngIf="editing" disabled>
                                    <h4 style="margin-left: 3%;" ng-if="!editing">{{ adminInfo?.Phone || '--' | phone }}</h4>
                                </div>
                            </div>
                        </div>
                        <h3 class="jumboTitle">Address</h3>
                        <div class="row padFive">
                            <div class="col-md-6">
                                <div class="form-group label-floating">
                                    <label [ngClass]="editing ? 'editing' : 'displaying'" for="i4" class="control-label">Address:</label>
                                    <input type="text" class="form-control" [(ngModel)]="groupInfo.StreetAddress1" *ngIf="editing">
                                    <h4 *ngIf="!editing">{{ groupInfo.StreetAddress1 || '--' }}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="row padFive">
                            <div class="col-md-6">
                                <div class= "form-group label-floating">
                                    <label [ngClass]="editing ? 'editing' : 'displaying'" for="i4" class="control-label">Address Line 2:</label>
                                    <input type="text" class="form-control" [(ngModel)]="groupInfo.StreetAddress2" *ngIf="editing">
                                    <h4 *ngIf="!editing">{{ groupInfo.StreetAddress2 || '--' }}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="row padFive">
                                <div class="col-md-3">
                                    <div class="form-group label-floating">
                                        <label [ngClass]="editing ? 'editing' : 'displaying'" for="i4" class="control-label">City:</label>
                                        <input type="text" class="form-control" [(ngModel)]="groupInfo.City" *ngIf="editing">
                                        <h4 *ngIf="!editing">{{ groupInfo.City || '--' }}</h4>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group label-floating">
                                        <label [ngClass]="editing ? 'editing' : 'displaying'" class="control-label">State:</label>
                                        <select class="form-control" [(ngModel)]="groupInfo.State" *ngIf="editing">
                                            <option *ngFor="let state of states" [value]="state.abbreviation">{{ state.abbreviation || '--' }}</option>
                                        </select>
                                        <h4 *ngIf="!editing">{{ groupInfo.State || '--' }}</h4>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group label-floating">
                                        <label [ngClass]="editing ? 'editing' : 'displaying'" for="i4" class="control-label">Zip Code:</label>
                                        <input type="text" class="form-control" [(ngModel)]="groupInfo.Zipcode" *ngIf="editing">
                                        <h4 *ngIf="!editing">{{ groupInfo.Zipcode || '--' }}</h4>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <!-- <div class="form-group col-md-6">
                        <div class="col-md-12">
                            <h3 class="jumboTitle">Users</h3>
                            <div class="row padFive">
                                <div class="col-md-12">
                                    <div class="table-responsive">
                                        <table ng-table="usersTable" class="table table-hover table-condensed table-consent" show-filter="false">
                                            <tr ng-repeat="record in data" ng-class="user.status" ng-click="open(record)">
                                                <td title="'Name'" filter="{ Name: 'text'}" sortable="'Name'">
                                                    {{record.Name}}
                                                </td>
                                                <td title="'Phone'" filter="{ Phone: 'text'}" sortable="'Phone'" class="text-center">
                                                    {{record.Phone}}
                                                </td>
                                                <td title="'Email'" filter="{ Phone: 'text'}" sortable="'Phone'" class="text-center">
                                                    {{record.email}}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <button class="btn btn-raised btn-success no-animate" (click)="edit()" *ngIf="(!editing) && smCI.EditCompanyInfoBtn">Edit</button>
                        <button class="btn btn-raised btn-success no-animate" (click)="updateCompanyInfo()" *ngIf="editing && smCI.EditCompanyInfoBtn">Update</button>
                        <button class="btn btn-raised btn-warning no-animate" (click)="cancelUpdate()" *ngIf="editing && smCI.EditCompanyInfoBtn">Cancel</button>
                    </div>
                    <!-- <div class="col-md-6 text-right">
                        <button class="btn btn-info" ng-click="open(user)">Add User</button>
                    </div> -->
                </div>
                <!-- <div class="row"> -->
                <!-- <div class="col-md-12 text-right"> -->
                <!-- <button class="btn btn-raised btn-default">Cancel</button> -->
                <!-- <button class="btn btn-raised btn-success" ng-click="updateActive()" ng-disabled="!mcInfoChanged">Update</button> -->
                <!-- <button type="button" class="btn btn-default" data-content="This is a <b>snackbar!</b> Lorem lipsum dolor sit amet..." data-toggle="snackbar" data-timeout="5000" data-html-allowed="true">Show snackbar</button> -->
                <!-- </div> -->
                <!-- </div> -->
            </div>
        </div>
    </div>
</div>

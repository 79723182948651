import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {AuthService} from '../../Services/auth.service';
import {DataService} from '../../Services/data.service';
import {Router} from '@angular/router'
import { map, tap, switchMap, mergeMap, filter, concatMap,takeUntil } from 'rxjs/operators';
import { concat, forkJoin, merge, Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ngxCsv} from 'ngx-csv/ngx-csv';
import { rowsAnimation} from '../../_animations/_fadeInAnimation'


@Component({
  selector: 'app-policyholders',
  templateUrl: './policyholders.component.html',
  styleUrls: ['./policyholders.component.css'],
  animations: [rowsAnimation]
})
export class PolicyholdersComponent implements OnInit {

  groupInfo : any = {};
  date = new Date();
  status : any = [];
  loading : boolean = true;
  filters : object = {
    status: [
    {
        id: "",
        title: "ALL"
    },{
        id: 1,
        title: "Consented"
    }, {
        id: 0,
        title: "Revoked"
    }, {
        id: 2,
        title: "Pending"
    },{
        id: 3,
        title: "DeActivated"
    },{
      id: 4,
      title: "Connected"
    },{
      id: 5,
      title: "Disconnected"
    }]
  }
  filterInput : any =
  {
    CompanyName : '',
    PolicyNumber : '',
    Agent : '',
    Timestamp : '',
    Status : null as any,
  }
  private mcListSource : any

  authentication = this.authService._authentication;
  smIndex = this.authService._smIndex;

  mcLstColumns: string[] = ['CompanyName', 'PolicyNumber', 'Agent', 'timestamp', 'status'];
  mcLstDataSource : any= [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  subNotifier = new Subject();

  constructor(private httpClient: HttpClient, 
    private authService : AuthService, 
    private router : Router,
    private dataService : DataService
    ) {


  }

  validatesm() {
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    this.authService._getsmData(authData.userRole, authData.groupType, "MCListViewHtml")
      .subscribe((result) => {
        if(!this.authService._accessFilter(result, "MCListViewHtml")){
          this.router.navigate(['/']);
        }                                                
      })
    return;
  };

  applyFilter(filterValue: string)
  {

    let filterSource = this.mcListSource;
    let filters = this.filterInput;
    
    let filteredData = filterSource
      .filter(item => item.CompanyName.toLowerCase().includes(filters.CompanyName.toLowerCase()) &&
      item.PolicyNumber.toLowerCase().includes(filters.PolicyNumber.toLowerCase()) &&
      item.Agent.toLowerCase().includes(filters.Agent.toLowerCase()) &&
      (item.timestamp ? item.timestamp.includes(filters.Timestamp) : true) &&
      ((!filters.Status  || filters.Status.id === '') ? true  : (item.status !== null ? item.status  === filters.Status.id : false))
    );
  
    this.mcLstDataSource = new MatTableDataSource(filteredData);//filterValue.trim().toLowerCase();
    this.mcLstDataSource.paginator = this.paginator;
    this.mcLstDataSource.sort = this.sort;
  }

  renderCSV(input : string)
  {
    //JSON.stringfy to deepcopy the data
    //var all = JSON.stringify(this.mcLstDataSource.sortData(this.mcLstDataSource.data, this.mcLstDataSource.sort));
    //var current = JSON.stringify(this.mcLstDataSource._renderData.getValue());
    
    var all = this.mcLstDataSource.sortData(this.mcLstDataSource.data, this.mcLstDataSource.sort);
    var current = this.mcLstDataSource._renderData.getValue();

    if(input === 'current')
    {
      let currentDataToCsv = [];
      current.map(item => {
        let currentItemToCsv = {
          InsuranceCompanyName : item.ICCName,
          PolicyHolderID: item.McId,
          IccId : item.IccId,
          AdminId : item.AdminId,
          ExposureProgram : item.ExposureProgramType,
          CompanyName : item.CompanyName,
          StreetAddress1 : item.StreetAddress1,
          City: item.City,
          State : item.St,
          PostalCode : item.ZipCode,
          PolicyNumber : item.PolicyNumber,
          Expiration : item.ExpDate,
          Agent : item.Agent,
          status : item.status
        };

        if(typeof item === 'object')
        {
          switch (item.status) {
            case 1:
                currentItemToCsv.status = "Consented"
                break;

            case 0:
              currentItemToCsv.status = "Revoked"
                break;
            case 3:
              currentItemToCsv.status = "DeActivated"
            break;

            case 4:
              currentItemToCsv.status = "Connected"
            break;

            case 5:
              currentItemToCsv.status = "Disconnected"
            break;

            default:
              currentItemToCsv.status = "Pending"
          }
        }
        currentDataToCsv.push(currentItemToCsv);
      });
      let csvOpition = {headers : this.getHeaders()}
      new ngxCsv(currentDataToCsv, 'Insured Filtered List Export -' + moment().format('MM-DD-yyyy'), csvOpition);
    }
    else if(input === 'all')
    {
      let allDataToCsv = [];
      all.map(item => {
        let allItemToCsv = {
          InsuranceCompanyName : item.ICCName,
          PolicyHolderID: item.McId,
          IccId : item.IccId,
          AdminId : item.AdminId,
          ExposureProgram : item.ExposureProgramType,
          CompanyName : item.CompanyName,
          StreetAddress1 : item.StreetAddress1,
          City: item.City,
          State : item.St,
          PostalCode : item.ZipCode,
          PolicyNumber : item.PolicyNumber,
          Expiration : item.ExpDate,
          Agent : item.Agent,
          status : item.status
        };

        if(typeof item === 'object')
        {
          switch (item.status) {
            case 1:
                allItemToCsv.status = "Consented"
                break;

            case 0:
              allItemToCsv.status = "Revoked"
                break;
            case 3:
              allItemToCsv.status = "DeActivated"
            break;

            case 4:
              allItemToCsv.status = "Connected"
            break;

            case 5:
              allItemToCsv.status = "Disconnected"
            break;

            default:
              allItemToCsv.status = "Pending"
          }
        }
        allDataToCsv.push(allItemToCsv);
      });
      let csvOpition = {headers : this.getHeaders()}
      new ngxCsv(allDataToCsv, 'Insured List Export -' + moment().format('MM-DD-yyyy'), csvOpition);
    }

  }
  private getHeaders () {
    if (this.groupInfo.GroupType == 1002) {
        return ['Insurance Company', 'PolicyHolderID', 'IccId', 'AdminId', 'Exposure Program Type', 'Company Name', 'Street Address 1', 'City', 'State', 'Postal Code', 'Policy Number', 'Expiration Date', 'Agent', 'Consent Status', 'Last Transaction Date'];
    } else {
        return ['Insurance Company', 'CustomerId', 'IccId', 'AdminId', 'Exposure Program Type', 'Company Name', 'Street Address 1', 'City', 'State', 'Postal Code', 'Policy Number', 'Expiration Date', 'Agent', 'Consent Status', 'Last Transaction Date'];
    }
  }

  private toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  

  ngOnInit(): void {

    this.validatesm();
    
    this.dataService._getGroupInfo().pipe(
      concatMap((groupInfo) => {
        this.groupInfo = groupInfo;

        return this.dataService._getMCSampleData();
      }),
      concatMap(samples => {

        return this.dataService._getMutualCustomers().pipe(
          tap(mut => {
            let users = mut;
            this.loading = false;
    
    
            mut.map(group => {
              if(!group.Enabled)
              {
                group.status = 3;// deactivated
                group.timestamp = group.ModifiedDate;
    
                return;
              }
              forkJoin([this.dataService._getMCStatus(group.McId), this.dataService._getDeactivateRecordMC(group.McId, 1001, group.IccId)]).pipe(takeUntil(this.subNotifier)).subscribe(
                result => {
                  if(result[0] == null || result[0] === 'null')
                  {
                    group.status = 2 // assign 2 for pending in UI code, 2 is not related to DB code, this is for filter only 
                    group.timestamp = null
                  }
                  else {
    
                    group.status = result[0].TemplateType;
                    group.timestamp = result[0].ModifiedDate;
    
                    //compare deactivate timestamp with consent(revoke) for re-upload same MC purpose. the re-uploaded mc, the staus will be Pending
                    if(result[1])
                    {
                        let deActvatedDate = moment(result[1].DeActiviatedDate, 'YYYY-MM-DD HH:mm:ss');
                        let signDate = moment(result[0].ModifiedDate, 'YYYY-MM-DD HH:mm:ss');
                        if(deActvatedDate > signDate)
                        {
                            group.status = 2; // assign 2 for pending in UI code, 2 is not related to DB code, this is for filter only 
                            group.timestamp = null;
                        }
                    }
                  }
    
                  //2 new status of data for client, Connected and Disconnected
    
                  let mcSamples = samples.filter(f => f.McId === group.McId);
                  let hasSourceData : Boolean = mcSamples.length > 0 ? true : false; //mcSample includes the mc summary table history since the raw data only be kept for 3 months, 
                  //var t = moment(new Date()).diff(moment(mcSamples[0].SampleUtcDate), 'day');
                  let disConnected : Boolean = mcSamples.length > 0 && moment(new Date()).diff(moment(mcSamples[0].SampleUtcDate), 'day') > 5 ? true: false;  // 5 days for to determine if it is disconnected or connected
                  
                  if(!hasSourceData &&  group.status === 1) // consented and no data
                  {
                    group.status = 1;
    
                  }
                  else if(hasSourceData && disConnected && group.status === 1) // consented and  stopped having data
                  {
                    group.status = 5;
    
                  }
                  else if(hasSourceData && !disConnected && group.status === 1) // consented and having data
                  {
                    group.status = 4;
    
                  }
                  // two new status
                  
    
                  //populate the data for UI table
                  this.mcLstDataSource = new MatTableDataSource(users);
                  this.mcLstDataSource.paginator = this.paginator;
                  this.mcLstDataSource.sort = this.sort;
                  this.mcListSource = users;
                  //below solve the mat table sorting case senstive issue
                  this.mcLstDataSource.sortingDataAccessor = (data : any, sortHeaderId: string): string => {
                    if (typeof data[sortHeaderId] === 'string') {
                      return data[sortHeaderId].toLocaleLowerCase();
                    }
                    return data[sortHeaderId];
                  };
                  
                }
              );
            })
          })

        );

      })
      
    ).pipe(takeUntil(this.subNotifier)).subscribe();
  }

  ngOnDestroy() {
    //unsubscribe
    this.subNotifier.next();
    this.subNotifier.complete();
  }
 

}

<div class="row">
    <div class="col-md-11">
        <div class="panel panel-info">
            <div class="panel-body">
                <div class="row padFive pre-scrollable" style="display: none;">
                </div>

                <div >
                    <object width="855" height="700"  type="application/pdf" [data]="guidePath"></object>
                </div>
            </div>
        </div>
    </div>    
</div>

import { Injectable , Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, filter, map, tap} from 'rxjs/operators';
import { HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
//import {SERVICEBASE} from '../app.config';
import {environment} from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  _authentication = {
    isAuth: false,
    userName: '',
    userID: '',
    userRole: '',
    groupID: '',
    groupType: null as number,
    userAgreed: false,
    here_app_id: '',
    here_app_code: '',
    here_api_key: ''
  }

  _smIndex = {
    CompanyInfoHyperlink: false,
    ConsentHistoryHyperlink: false,
    DashboardHyperlink: false,
    MCListHyperlink: false,
    ConsentManagementHyperlink: false,
    UploadPolicyholderHyperlink: false,
    UserAdminHyperlink: false,
    ClaimsLocatorHyperlink: false
  };


  constructor(private httpClient : HttpClient, private router: Router, private injector : Injector) {
  }
  

  _login(loginData : any) : Observable<any>
  {
    let data = "grant_type=password&username=" + loginData.userName + "&password=" + loginData.password + (loginData.superUserLoginAs ? "&asSuperUserName=" 
      + loginData.superUserLoginAs : '');
    let url = environment.serviceBase + 'api/Users/Token'; 
    //let option = { headers: { 'Content-Type': 'application/x-www-form-urlencoded'}};

    return this.httpClient.post(url, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded'}, observe: 'response'}).pipe(
      tap(result => {
        if(result.status === 200)
        {
          localStorage.setItem('authorizationData', JSON.stringify(
            {
              token:  result.body.access_token, 
              userName: loginData.userName, 
              userID:  result.body.UserId, 
              userRole:  result.body.UserInfo.Role, 
              groupID:  result.body.UserInfo.GroupId, 
              groupType:  result.body.UserInfo.GroupType, 
              userAgreed:  result.body.UserAgreed 
            }));
          
          this._authentication.isAuth = true;
          this._authentication.userName = loginData.userName;
          this._authentication.userID = result.body.UserId;
          this._authentication.userRole = result.body.UserInfo.Role;
          this._authentication.groupID = result.body.UserInfo.GroupId;
          this._authentication.groupType = result.body.UserInfo.GroupType;
          this._authentication.userAgreed = result.body.UserAgreed;

          
          if(this._authentication.userAgreed){
            this._getsmIndexData();                              
          }
          
          this._getHereMapAccess(result.body.UserInfo.Role, result.body.UserInfo.GroupType).subscribe(
            data => {
              if(data && data !== "null")
              {
                //HereMap access
                localStorage.setItem('authorizationData', JSON.stringify({ 
                    token: result.body.access_token, 
                    userName: loginData.userName, 
                    userID: result.body.UserId, 
                    userRole: result.body.UserInfo.Role, 
                    groupID: result.body.UserInfo.GroupId, 
                    groupType: result.body.UserInfo.GroupType, 
                    userAgreed: result.body.UserAgreed,
                    here_app_id: data.app_id,
                    here_app_code: data.app_code,
                    here_api_key: data.api_key
                }));
              }
            });
        }
      })
     );
  }
  
  _logOut()
  {
    let serviceBase = environment.serviceBase;
    let authData = localStorage.getItem('authorizationData') ? JSON.parse(localStorage.getItem('authorizationData')): null;

    if(authData && (authData.userID))
    {
      let url = serviceBase + 'api/users/Logoff';
      
      this.httpClient
      .post(url, authData.userID, { headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Bearer ' + authData.token }})
      .subscribe();
    }

    this._authentication.isAuth = false;
    this._authentication.userName = null;
    this._authentication.userID = null;
    this._authentication.userRole = null;
    this._authentication.groupID = null;
    this._authentication.groupType = null;
    this._authentication.userAgreed = false;
    this._resetsm();
    
    //return localStorageService.clearAll(); orignal
    // test superusersuper
    if(authData && authData.groupType === 1000)//temp until set up appConst convertion. appConst.groupType.system)
    {
      localStorage.removeItem("authorizationData"); 
    }
    else
    {
      localStorage.clear(); 
    }
    // test superusersuper
    
    this.router.navigate(['/login', {}]);
  }

  _getsmIndexData() {
    return this._getsmData(this._authentication.userRole, this._authentication.groupType, "IndexHtml")
      .subscribe((result) => {
        this._smIndex.CompanyInfoHyperlink = this._accessFilter(result, "CompanyInfoHyperlink");
        this._smIndex.ConsentHistoryHyperlink = this._accessFilter(result, "ConsentHistoryHyperlink");
        this._smIndex.DashboardHyperlink = this._accessFilter(result, "DashboardHyperlink");
        this._smIndex.MCListHyperlink = this._accessFilter(result, "MCListHyperlink");
        this._smIndex.ConsentManagementHyperlink = this._accessFilter(result, "ConsentManagementHyperlink");
        this._smIndex.UploadPolicyholderHyperlink = this._accessFilter(result, "UploadPolicyholderHyperlink");
        this._smIndex.UserAdminHyperlink = this._accessFilter(result, "UserAdminHyperlink");
        //this._smIndex.SuperAminHyperlink = _accessFilter(result, "SuperAminHyperlink"); // test super user
        this._smIndex.ClaimsLocatorHyperlink = this._accessFilter(result, "ClaimsLocatorHyperlink"); //true;
        
        localStorage.setItem('smIndexData', JSON.stringify({
            CompanyInfoHyperlink: this._smIndex.CompanyInfoHyperlink, 
            ConsentHistoryHyperlink: this._smIndex.ConsentHistoryHyperlink, 
            DashboardHyperlink: this._smIndex.DashboardHyperlink,
            MCListHyperlink: this._smIndex.MCListHyperlink, 
            ConsentManagementHyperlink: this._smIndex.ConsentManagementHyperlink, 
            UploadPolicyholderHyperlink: this._smIndex.UploadPolicyholderHyperlink, 
            UserAdminHyperlink: this._smIndex.UserAdminHyperlink, 
            //SuperAminHyperlink: this._smIndex.SuperAminHyperlink, // test super user
            ClaimsLocatorHyperlink: this._smIndex.ClaimsLocatorHyperlink
        }));

        return this._smIndex;
    });  
  }
  _getsmData(role: any, group:any, pagename: any) : Observable<any>
  {
    let serviceBase = environment.serviceBase;
  
    let getURL = serviceBase + 'api/authorize/GetRoleSMForPage?role=' + role + "&group=" + group + "&page=" + pagename;

    return this.httpClient.get(getURL);
  };

  _accessFilter(data: any, objectName: any)
  {
    if((data === null) || data === undefined || data.length === undefined){
        return false;
    }

    let tempobj = data.filter(function(sm) {return sm.Object === objectName;})[0];

    if((tempobj === null) || tempobj === undefined){
        return false;
    }

    return(tempobj.AccessType === "I");            
  }

  _resetsm ()
  {
    this._smIndex.CompanyInfoHyperlink = false;
    this._smIndex.ConsentHistoryHyperlink = false;
    this._smIndex.DashboardHyperlink = false;
    this._smIndex.MCListHyperlink = false;
    this._smIndex.ConsentManagementHyperlink = false;
    this._smIndex.UploadPolicyholderHyperlink = false;
    this._smIndex.UserAdminHyperlink = false;
    this._smIndex.ClaimsLocatorHyperlink = false;
    //this._smIndex.SuperAminHyperlink = false;
  }
  _fillAuthData () 
  {
    var authData = JSON.parse(localStorage.getItem('authorizationData') || null);
    if (authData) {
        this._authentication.isAuth = true;
        this._authentication.userName = authData.userName;
    
        this._authentication.userID = authData.userID;
        this._authentication.userRole = authData.userRole;
        this._authentication.groupID = authData.groupID;
        this._authentication.groupType = authData.groupType;
        this._authentication.userAgreed = authData.userAgreed;
        this._authentication.here_app_id = authData.here_app_id;
        this._authentication.here_app_code = authData.here_app_code;
        this._authentication.here_api_key = authData.here_api_key;
    }
  } 

  _fillsmData () 
  {
    var data = JSON.parse(localStorage.getItem('smIndexData') || null);
    if(data){
        this._smIndex.CompanyInfoHyperlink = data.CompanyInfoHyperlink;
        this._smIndex.ConsentHistoryHyperlink = data.ConsentHistoryHyperlink;
        this._smIndex.DashboardHyperlink = data.DashboardHyperlink;
        this._smIndex.MCListHyperlink = data.MCListHyperlink;
        this._smIndex.ConsentManagementHyperlink = data.ConsentManagementHyperlink;
        this._smIndex.UploadPolicyholderHyperlink = data.UploadPolicyholderHyperlink;
        this._smIndex.UserAdminHyperlink = data.UserAdminHyperlink;
        //this._smIndex.SuperAminHyperlink = data.SuperAminHyperlink; //test super user
        this._smIndex.ClaimsLocatorHyperlink = data.ClaimsLocatorHyperlink;
    }
    return true;
  }

  _setAuthData (authData: any) {
    localStorage.setItem('authorizationData', JSON.stringify({ 
      token: authData.token, 
      userName: authData.userName, 
      userID: authData.userID, 
      userRole: authData.userRole, 
      groupID: authData.groupID, 
      groupType: authData.groupType, 
      userAgreed: authData.userAgreed,
      here_app_id: authData.here_app_id ? authData.here_app_id : null,
      here_app_code: authData.here_app_code ? authData.here_app_code : null,
      here_api_key: authData.here_api_key ? authData.here_api_key : null
    }));
  }

  _verifyAccount(verificationData: any) : Observable<any>
  {
    let data = "grant_type=&username=" + verificationData.userName + "&password=" + verificationData.password;
    
    return this.httpClient.post(environment.serviceBase + 'api/Users/Token', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }})
  }

  _validateEmailTest(em: any)
  {
    var re = /\S+@\S+\.\S+/;
    return re.test(em);
  }

  _resetPass(email : any) : Observable<any> {

    let postURL = environment.serviceBase + 'api/Reset/ResetPW?un=' + email;
    
    return this.httpClient.post(postURL,  { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, observe: 'response' });
  }

  _getHereMapAccess(RoleId : number, GroupType : number) : Observable<any>
  {
     let serviceBase = environment.serviceBase;
     var getURL = serviceBase + 'api/Values/GetHereMapAccess?RoleId=' + RoleId + "&GroupType=" + GroupType;

    return this.httpClient.get(getURL);
  }

  _getHelloSignAccess() : Observable<any>
  {
    let getURL = environment.serviceBase + 'api/Values/GetHelloSignAccess?isTest=' + environment.isTest;

    return this.httpClient.get(getURL);
  }




}

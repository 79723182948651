<div class="row">
	<div class="col-md-8">
		<div class="dashhead">
			<div class="dashhead-titles">
				<h6 class="dashhead-subtitle">Dashboard</h6>
				<h2 class="dashhead-title">{{ groupInfo.CompanyName }}</h2>
				<h4 class="dashhead-title" *ngIf="authentication.groupType == 1002"> {{dashboardCompanyName}}</h4>
				<h5 class="dashhead-title goBack" [routerLink]="['/mcList' , dashboardMCID]" *ngIf="authentication.groupType == 1002"><i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i> Go Back</h5>
			</div>
			
		</div>
	</div>
	<div class="col-md-2">
		<div class="form-group">
				<div class="dropdown form-group">
					<label for="dropdownStart">Start Date</label>
					<div class="input-group">
						<!-- <input class="form-control" placeholder="Select a date" [(ngModel)]="dashboardDate.start" [ngModelOptions]="{ updateOn: 'blur' }" /> -->
						<span  class="input-group-addon" (click)="dp.toggle()">
							<i class="glyphicon glyphicon-calendar"></i>
						</span>
						<input type="text" placeholder="Datepicker" [(ngModel)]="dashboardDate.start"  class="form-control" #dp="bsDatepicker" [bsConfig]="bsConfig"
						(ngModelChange) = 'datePickerChange()' bsDatepicker readonly />
					</div>
				</div>
		</div>
	</div>

	<div class="col-md-2">
		<div class="form-group">
			<div class="dropdown form-group">
				<label for="dropdownEnd">End Date</label>
				<div class="input-group">
					<span  class="input-group-addon" (click)="dp2.toggle()">
						<i class="glyphicon glyphicon-calendar"></i>
					</span>
					<input type="text" placeholder="Datepicker" [(ngModel)]="dashboardDate.end"  class="form-control" #dp2="bsDatepicker" [bsConfig]="bsConfig"
					(ngModelChange) = 'datePickerChange()' bsDatepicker readonly />
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div>
			<button style="float:right;margin-right: 2%;" class="btn btn-narrow btn-info" *ngIf="authentication.groupType == 1002" [disabled] ="!hasDataToExport"
			(click)="openExport()"><i class="fa fa-download" aria-hidden="true"></i> Export</button>
		</div>
	</div>
 
</div>
<div class= 'row'>
	<div class="col-md-12">
		<div class="panel panel-info">
			<div class="panel-heading with-nav">
                <ul class="nav nav-tabs">
                    <li *ngFor="let dash of dashViews; let first = index" [ngClass]="first ==0 ? 'active' : ''" (click)="setDash(dash, $event)"><a>{{ dash }}</a></li>
                </ul>
            </div>

			<div class="panel-body padding" [hidden] ="!(dashboards.activeDash === 'Scoring')"><!-- use hidden instead of *ngIf whcih casue the undefined in the component when trying to get dom-->
                <span *ngIf="!scoreReady"  class="onTopDiv">
                    <div class="row"> 
                        <div class="col-md-12 text-center">
                            <img class="img-responsive spinner" src="/assets/img/ripple.svg" alt="loading_spinner" style="width:100px;height: 100px;">
                        </div>
                    </div>
                </span>
                
                <div [ngClass]="{'my-disable': !scoreReady}">
                    <div class="row">
                        <div class="row" [hidden] ='!scoringBandDivOn'>
                            <div class="col-md-5" > 
                                <div class="col-md-12"><!-- style="display: grid; width: 85%;"> -->
                                    <label class="form-group label-floating" style="font-weight: bold;padding-bottom: 5%;">Score</label>
                                    <div class="form-group">
                                        <div id= 'scoringSlider' #scoringSlider></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 20px;">
                        <div class="col-md-5">
                            <div class="table-responsive dashtable">
                                <table mat-table [dataSource] = "scoringTblDataSource" matSort (matSortChange)="scoringSortChange($event)" class="table table-condensed table-striped">
                                    <ng-container matColumnDef="category">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                                                Category
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="col-md-6"> {{element.category}} </td>
                                    </ng-container>
                                      
                                    <ng-container matColumnDef="mean">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300">
                                            Mean
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="col-md-4"> {{element.mean}} </td>
                                    </ng-container>
                                      
                                    <ng-container matColumnDef="point">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                                            Points
                                        </th>
                                        <td mat-cell *matCellDef="let element" class="col-md-2"> {{element.point}} </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="scoringColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: scoringColumns;" style="height:0px;"></tr>
                                </table>
                            </div>
                            <div class="table-responsive" *ngIf ='individualPerData'>
                                <table class="table table-condensed" >
                                    <tbody *ngFor="let item of individualPerData">
                                        <tr>
                                            <td style="width:75%;">{{item.category}}</td>
                                            <td class="text-center">
                                                {{ item.value }}
                                            </td>
                                      </tr> 
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-md-7">
                            <div *ngIf='scoringChartOn'>
								<!-- todo: add chart -->
                                <nvd3 id="scoringChart" [options]="scoringChartOptions" [data]="scoringChartData"></nvd3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="panel-body padding" [hidden] ="!(dashboards.activeDash === 'Utilization')">
                <div>
                <app-utilization [utilData] = 'utilData' [totalDays] = 'totalDays' [utilData_Driver] = 'utilData_Driver'></app-utilization></div>
                <!-- <div class="row">
                    <div class="col-md-8">
                        <div class="table-responsive dashtable">
                            <table id = 'utilization' mat-table [dataSource] = "utilTblDataSource" #sortUtil ='matSort' matSort  class="table table-condensed table-striped">
                                <ng-container matColumnDef="sortBase">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                                        VIN/Vehicle ID
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.vin && element.vin != 'N/A' ? (element.vin.replace(element.vin.slice(0, (element.vin.length) - 6), "") + '/') : ('/' + element.vehicleId)}}
                                     </td>
                                </ng-container>
                                  
                                <ng-container matColumnDef="vehicleType">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300">
                                        Vehicle Type
                                    </th>
                                    <td mat-cell *matCellDef="let element" > {{element.vehicleType}} </td>
                                </ng-container>
                                  
                                <ng-container matColumnDef="daysOperational">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                                        Days Operational
                                    </th>
                                    <td mat-cell *matCellDef="let element" > {{ element.daysOperational }} ({{element.daysOperational / totalDays * 100 | number: '1.0-0'}}%) </td>
                                </ng-container>

                                <ng-container matColumnDef="totalMileage">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                                        Total Mi.
                                    </th>
                                    <td mat-cell *matCellDef="let element" > {{element.totalMileage | number: '1.0-2'}} </td>
                                </ng-container>

                                <ng-container matColumnDef="sum_NetOnTimeHr">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                                        Total On Time
                                    </th>
                                    <td mat-cell *matCellDef="let element" > {{element.sum_NetOnTimeHr | number: '1.0-2' }} </td>
                                </ng-container>

                                <ng-container matColumnDef="averageOfSum_NetOnTimeHr">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                                        Av. Shift
                                    </th>
                                    <td mat-cell *matCellDef="let element" > {{element.averageOfSum_NetOnTimeHr | number: '1.0-2'}} </td>
                                </ng-container>

                                <ng-container matColumnDef="dailyMiles">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                                        Mi./Day
                                    </th>
                                    <td mat-cell *matCellDef="let element" > {{element.dailyMiles | number: '1.0-2'  }} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="utilColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: utilColumns;" style="height:0px;" (click) = 'setActiveUtilization(row, $event)'></tr>
                            </table>
                            <mat-paginator #paginatorUtil [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons style="margin-bottom: 4%;margin-top:-1%; background: none; color: whitesmoke;"></mat-paginator>
                        </div>
                    </div>

                </div> -->
            </div>

            <div class="panel-body padding" *ngIf ="(dashboards.activeDash === 'Radius &amp; Garaging')"> <!-- use ngIf to display map on tab click -->
                <div>
                    <app-radius-garaging [radiusData] = 'radiusData' [app_id] = 'app_id' [app_code] = 'app_code' [apikey] = 'api_key'></app-radius-garaging>
                </div>
            </div>

            <div class="panel-body padding" [hidden] ="!(dashboards.activeDash === 'Territory')">
                <div>
                    <app-territory [territoryData] = 'territoryData' [radiusData] = 'radiusData'></app-territory>
                </div>
                
            </div>

            <div class="panel-body padding" [hidden] ="!(dashboards.activeDash === 'Speeding')"> 
                <div>
                    <app-speeding 
                    [speedingData] = 'speedingData' 
                    [speedingRoadTypeData] = 'speedingRoadTypeData' 
                    [dashboardCompanyProgram] = 'dashboardCompanyProgram'
                    [dashboardDate] = 'dashboardDate'
                    [speedingData_Driver] = 'speedingData_Driver'
                    [speedingRoadTypeData_Driver] = 'speedingRoadTypeData_Driver'>
                </app-speeding>
                </div>
            </div>

            <div class="panel-body padding" [hidden] ="!(dashboards.activeDash === 'Harsh Events')"> 
                <div>
                    <app-harshevent [harshEventData] = 'harshEventData' 
                    [harshEventData_Driver] = 'harshEventData_Driver'
                    [dashboardCompanyProgram] = 'dashboardCompanyProgram'
                    [harshEventSumData] = 'harshEventSumData'
                    [harshEventSumData_Driver] = 'harshEventSumData_Driver' ></app-harshevent>
                </div>
            </div>

            <div class="panel-body padding" *ngIf ="(dashboards.activeDash === 'Fleet Footprint')"> 
                <div>
                    <app-footprint [geoFileLst] = 'geoFileLst' [app_id] = 'app_id' [app_code] = 'app_code' [apikey] = 'api_key'></app-footprint>
                </div>
            </div>

            <div class="panel-body padding" [hidden] ="!(dashboards.activeDash === 'Driver Score Card')"> 
                <div>
                    <app-driverscorecard [driverScoreCardData] = 'driverScoreCardData' 
                    [dashboardCompanyProgram] = 'dashboardCompanyProgram'
                    [dashboardCompanyName] = 'dashboardCompanyName'
                    [dashboardDate] = 'dashboardDate'
                    [DSCardDataToExport] = 'DSCardDataToExport'></app-driverscorecard>
                </div>
            </div>

            



            




		</div>

	</div>

</div>

<div>
  <nav class="navbar navbar-info navbar-fixed-top" role="navigation" >
   
    <div class="container">
      <div class="navbar-header">
        <button class="btn btn-success navbar-toggle" onClick="navbarExpanded =!navbarExpanded"  data-toggle="collapse" data-target="#collapseDiv">
          <span class="glyphicon glyphicon-chevron-down"></span>
        </button>

      <div class="navbar-brand">
        <a routerLink="/"><img src="/assets/img/TE_logo_white_R_crop(new).png" alt="Telematic Exchange Logo"></a>
      </div>
    </div>
      <div id = "collapseDiv" class="collapse navbar-collapse" >
        <ul class="nav navbar-nav navbar-right">
          <li *ngIf= "authentication.isAuth && smIndex.CompanyInfoHyperlink"><a routerLink="/companyInfo">Company Info</a></li>
          <li *ngIf= "authentication.isAuth && smIndex.ConsentHistoryHyperlink"><a routerLink="/consentHistory">Consent History</a></li>
          <li *ngIf= "authentication.isAuth && smIndex.DashboardHyperlink"><a [routerLink]="['/dashboard', authentication.groupID, 'Dashboard', '', '']">Dashboards</a></li>
          <li *ngIf= "authentication.isAuth && smIndex.ConsentManagementHyperlink"><a routerLink="/template">Consent Management</a></li>
          <li *ngIf= "authentication.isAuth && smIndex.MCListHyperlink"><a routerLink="/mcList">{{(authentication.groupType == 1002) ? 'Policyholders' : 'Customers'}}</a></li>
          <li *ngIf= "authentication.isAuth && smIndex.UploadPolicyholderHyperlink"><a routerLink="/mc-upload">Upload Policyholders</a></li>
          <li *ngIf= "authentication.isAuth && smIndex.ClaimsLocatorHyperlink"><a routerLink="/claimsLocator">Claims Locator</a></li>
          <li *ngIf="!authentication.isAuth"><a routerLink="/login">Login</a></li>
                            

          <li class="dropdown" *ngIf="authentication.isAuth">
            <a data-toggle="dropdown">{{authentication.userName}} <b class="caret"></b></a>
            <ul class="dropdown-menu">
              <li><a routerLink="/userAdmin" *ngIf="smIndex.UserAdminHyperlink">Admin</a></li>
                                              
              <li><a routerLink="/reset">Change Password</a></li>
              <li><a href="" (click)="logOut()" *ngIf="backToSuper">Logout</a></li>
            </ul>
          </li>
          
           
        </ul>
      </div>
    </div>
  </nav>
  <div class="onTopDiv" *ngIf ="announcement?.announcing">
    <h5 style="color: #f9ffff;">{{announcement.announcementTxt }}</h5>
  </div>
</div>

<div class="container ui-view-container te-modified-container">
<main [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
  <div><!-- <div class="container ui-view-container te-modified-container"> -->
    <div ui-view>
      <router-outlet #o="outlet"></router-outlet>
    </div>
  </div>
</main></div>
<div>
 <footer class="site-footer footer" role="contentinfo" >
    <div class="row">
        <div class="col-md-6">
            Copyright © | <a href="http://www.etechnologyservices.com/">eTechnology Services</a>
        </div>
        <div class="col-md-6 text-right">
         
            <a *ngIf ='authentication.isAuth' (click) = 'openUserGuide()'>User Guide</a>
            <a *ngIf ='authentication.isAuth' routerLink="/contact">Contact</a>
            <a routerLink="/privacy">Privacy Policy</a>
        </div>
    </div>
  </footer>
</div>



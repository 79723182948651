<div class="row">
	<div class="col-md-12">
		<div class="dashhead">
			<div class="dashhead-titles">
				<h2 class="dashhead-title">Manage Users</h2>
			</div>
		</div>
	</div>
</div>
<div class="row">
	<div class="panel panel-info">
		<div style="background-color: #3C4252;">
			<div class="row padFive">
				<div class="col-md-11">
					<div class="table-responsive">
                        <table mat-table [dataSource] = "userTblDataSource" #sortUser ='matSort' matSort  class="table table-hover table-condensedNew">
                            <ng-container matColumnDef="Name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                                    Name
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.Name}}
                                 </td>
                            </ng-container>
                              
                            <ng-container matColumnDef="Phone">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;width: 33%;">
                                    Phone
                                </th>
                                <td mat-cell *matCellDef="let element" > {{element.Phone || '' | phone}} </td>
                            </ng-container>
                              
                            <ng-container matColumnDef="email">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300; width: 33%;">
                                    Email
                                </th>
                                <td mat-cell *matCellDef="let element" > {{ element.email }} </td>
                            </ng-container>
            
                            
                            <tr mat-header-row *matHeaderRowDef="userColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: userColumns;" style="height:0px;" (click) = 'open(row)'></tr>
                        </table>
                        <mat-paginator #paginatorUser [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons style="margin-bottom: 2%;margin-top:-1%; background: none; color: whitesmoke;"></mat-paginator>
						
					</div>
				</div>
			</div>
            <div class="row">
                <div class="col-md-6 text-right">
                    <button class="btn btn-info" (click)="open()">Add User</button>
                </div>
            </div>
		</div>
	</div>
</div>

import { Component, OnInit, Input, OnChanges, ViewChild, Renderer2, AfterViewInit, ElementRef } from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

import * as _ from 'underscore';


@Component({
  selector: 'app-territory',
  templateUrl: './territory.component.html',
  styleUrls: ['./territory.component.css']
})
export class TerritoryComponent implements OnInit {


  territoryTblDataSource : any =[];
  territoryColumns : string[] = ['sortBase', 'vehicleType', 'primary_Garaging_City','primary_Garaging_State', 'primary_Garaging_Zip', 'garageZone', 'derived_TeleFactor', 'Sum_TotalMiles'];
  @ViewChild('sortTerritory') sortTerritory: MatSort;
  @ViewChild('paginatorTerritory') paginatorTerritory: MatPaginator;

  @Input() territoryData : any;
  @Input() radiusData : any;

  activeTerritoryVehicle : any;
  radarOptions : any;
  labels : any;
  colors: any;
  territoryChartData : any;
  territoryChartType = 'radar';

  constructor(private render :  Renderer2) { 

  }

  generateTerritoryTable() {
    if(!this.territoryData) return;

    //add primary garaging info to the territorytable
    this.territoryData.forEach((item) => {

      let primaryLocObjVeh = _.find(this.radiusData, function (tItem) { return tItem.vehicleId == item.stats.VehicleNumber});
      if(primaryLocObjVeh)
      {
        item.stats.vehicleType = primaryLocObjVeh.vehicleType;
        item.stats.primary_Garaging_City = primaryLocObjVeh.primary_Garaging_city;
        item.stats.primary_Garaging_State = primaryLocObjVeh.primary_Garaging_State;
        item.stats.primary_Garaging_Zip = primaryLocObjVeh.primary_Garaging_Zip;
      }
    });
    
    //for sorting on contional VIN or Vehicleid 
    this.territoryData.forEach((item) => {
      item.sortBase = item.stats.vin && item.stats.vin !== 'N/A' ? item.stats.vin.replace(item.stats.vin.slice(0, (item.stats.vin.length) - 6), "").toString() +'/' : '/' + item.stats.VehicleNumber
    });

    let territoryViewData = _.toArray(this.territoryData);
    this.territoryTblDataSource = new MatTableDataSource(territoryViewData);

    //sort on derived columns
    this.territoryTblDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'primary_Garaging_City':
          return item.stats.primary_Garaging_City;
        case 'primary_Garaging_State':
          return item.stats.primary_Garaging_State;

        case 'primary_Garaging_Zip' :
          return item.stats.primary_Garaging_Zip
        
        case 'garageZone':
          return item.stats.garageZone;
        case 'derived_TeleFactor':
          return item.stats.garagerateMultMonthAve == 0 ? 0: item.stats.telerateMultMonthAve / item.stats.garagerateMultMonthAve;
        case 'Sum_TotalMiles':
          return item.stats.Sum_TotalMiles;
        case 'vehicleType':
          return item.stats.vehicleType;
        
        default:
          return item[property];
      }
    };

    this.territoryTblDataSource.sort = this.sortTerritory;
    this.territoryTblDataSource.paginator = this.paginatorTerritory;
  }

  territoryFactorSum()
  {
    let sum= 0;
    let sumTotalMilesAllVehicle= 0;
    let sumGarageRateAv= 0;

    if(!this.territoryData) return;
    
    this.territoryData.forEach((item) =>
    { 
      sum += item.stats.telerateMultMonthAve * item.stats.Sum_TotalMiles; 
      sumGarageRateAv += item.stats.garagerateMultMonthAve * item.stats.Sum_TotalMiles;
      sumTotalMilesAllVehicle += item.stats.Sum_TotalMiles;
    });

    return (sum/sumTotalMilesAllVehicle) / (sumGarageRateAv /sumTotalMilesAllVehicle);
  }

  setActiveTerritory(activeTerritoryVehicle, event) {
    let rows = Array.from(document.getElementById('territory').getElementsByTagName('tr'));
    rows.forEach(item => {
      this.render.removeClass(item, 'selected');
    });
    this.render.addClass(event.currentTarget, 'selected');

    let activeVehicleIndex = _.indexOf(this.territoryData, activeTerritoryVehicle);
    

    this.activeTerritoryVehicle = this.territoryData[activeVehicleIndex]; 

    let allTerritoryVals = [];

    //change here
    //angular.forEach($scope.activeTerritoryVehicle.territories, function(value, key) {
      //  allTerritoryVals.push(value);
    //});

    Object.entries(this.activeTerritoryVehicle.territoriesDriven).forEach((value, key) => {
        allTerritoryVals.push(value[1]);
    });

    var territoryMaxVal = _.max(allTerritoryVals); //*100;
    var roundedTerritoryMaxVal = Math.round(territoryMaxVal); //console.log('rounded', roundedTerritoryMaxVal); //(Math.ceil((territoryMaxVal) / 10) * 10); 
    //original $scope.radarOptions.scale.ticks.max = roundedTerritoryMaxVal > 0 ? roundedTerritoryMaxVal +10  : 50;
    //console.log("ceil", roundedTerritoryMaxVal);

    //no need to have below two lines with 'suggestedMax in the RadarOpition'
    //this.setRadarOpition();//re- generate the opition, in order to have next line of statement to change the max tick. order has to be like this
    //this.radarOptions.scale.ticks.max = roundedTerritoryMaxVal > 0 ? Math.round((Math.ceil(roundedTerritoryMaxVal / this.activeTerritoryVehicle.stats.Sum_TotalMiles * 10) / 10 * 100 + 10))  : 50;
    
    this.displayVehicleData(activeVehicleIndex);
  }

  displayVehicleData(vehicle) {
    //console.log('vehicle', vehicle);
    this.labels = [];
    this.territoryChartData = [];
    let tempArray = [];
    // loop through each territory obj, get key/values
    Object.entries(this.territoryData[vehicle].territoriesDriven).forEach((value : any, key) =>{
        // push key/value into arrays
        this.labels.push(value[0]);
        //tempArray.push(value * 100); initial
        tempArray.push(Math.round(value[1] / this.territoryData[vehicle].stats.Sum_TotalMiles * 100)); 
    });
    //this.territoryChartData.push(tempArray);
    this.territoryChartData.push( {data: tempArray});

    this.colors = [
      { 
        backgroundColor: 'rgba(137, 196, 244, 0.2)', borderColor : 'rgb(137, 196, 244)', 
        pointBackgroundColor: 'rgba(137, 196, 244, 0.8)',
        pointBorderColor: '#fff', }]
}

  setRadarOpition() {
    this.radarOptions = {
      layout :{
        padding : {
          left: 5,
          right: 5,
          top: 5,
          bottom: 5
        }
      }
      ,
      legend :{
        display: false
      },
      
      tooltips: {
        callbacks: {
          label: function(context) {
            let label = context.label || '';
            if (label) {
              label = (context.index + 1).toString();
            }
            return label;
          }
        }
      },
      responsive : true,
      scale: {
        gridLines: {
          color: "white",
          lineWidth: 1,
        },
        pointLabels: {
          fontSize: 20,
          fontColor: "white",
          callback: function(value, index, values) {
              //console.log('value', value);
              //console.log('index', index);
              //console.log('values', values);
              if (value == '17') {
                  return value;//value + '*';
              } else {
                  return value;
              }
          }
        },
        ticks: {
          beginAtZero: true,
          backdropPaddingX: 5,
          backdropPaddingY: 5,
          stepSize: 10,
          //min: 0,
          //max: 60,
          suggestedMax: 60,
          suggestedMin: 0,
          fontSize: 12,
          backdropColor: 'rgba(0,0,0,0)',
          fontColor: "white",
          callback: function(value, index, values) {
              return value + '%';
          }
        },
        angleLines: {
            display: false
        },
      }
    }
  }

  ngOnInit(): void {
    this.setRadarOpition();
  }

  ngOnChanges(): void {
    this.generateTerritoryTable();
    this.activeTerritoryVehicle = null;
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService} from '../../Services/data.service';

@Component({
  selector: 'app-signature-modal',
  templateUrl: './signature-modal.component.html',
  styleUrls: ['./signature-modal.component.css']
})
export class SignatureModalComponent implements OnInit {

  tspName : string = '';

  tspID : number;

  constructor(private dataService : DataService, @Inject(MAT_DIALOG_DATA) private modalData: any,
  public dialogRef: MatDialogRef<SignatureModalComponent>) { }

  close() {
    this.dialogRef.close();
  }

  ngOnInit(): void {

    this.tspID = this.modalData;
    this.dataService._getTSPList(this.tspID).subscribe((result) => {
      this.tspName = result[0].CompanyName;
    });

  }
}

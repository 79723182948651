import { Component, OnInit, OnChanges, Input, ViewChild , OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs'
import { takeUntil } from 'rxjs/operators';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import * as _ from 'underscore';
import * as moment from 'moment';
import * as saveAs from '../../../../lib/FileSaverjs/FileSaver.min';
import * as ExcelJS from '../../../../lib/exceljs/exceljs.min';
import { appConst } from 'src/app/app.config';
import  Recommendations  from './drivescorecardRecommdData';




@Component({
  selector: 'app-driverscorecard',
  templateUrl: './driverscorecard.component.html',
  styleUrls: ['./driverscorecard.component.css']
})
export class DriverscorecardComponent implements OnInit {

  subNotifier = new Subject();

  DSCardCompanyProgramDr : any;
  @Input() dashboardCompanyProgram : any;
  @Input() dashboardCompanyName : any;
  @Input() dashboardDate : any;
  dashMonthCompareDisplay : any

  scoreCardComparisonData : any;


  @Input() driverScoreCardData : any;

  
  DSCardTrendSeries: any;
  DSCardTrendingChartData =[];
  DSCardTrendingChartColors = 
  //[{ backgroundColor: 'rgba(0,0,0,0)', borderColor:'rgb(11,108,205)'} ,{ backgroundColor: 'rgba(0,0,0,0)', borderColor: 'rgb(13,139,197)'}, {backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgb(220,220,220)'}, { backgroundColor: 'rgba(0,0,0,0)',borderColor: 'rgb(244,20,228)'}];
  [{ backgroundColor: 'rgba(0,0,0,0)', borderColor:'rgb(11,108,205)'} ,{ backgroundColor: 'rgba(0,0,0,0)', borderColor: 'rgb(116,179,255)'}, {backgroundColor: 'rgba(0,0,0,0)',borderColor:'rgb(220,220,220)'}, { backgroundColor: 'rgba(0,0,0,0)',borderColor: 'rgb(244,20,228)'}];
  
  DSCardTrendingChartlabels = [];
  DSCardTrendingChartOptions : any;
  DSSpeedingTrendChartType = 'line';

  DSCardComparisonSeries : any;
  DSCardComparisonXSeries : any;

  DSCardComparisonChartType = 'bar';
  DSCardComparisonChartData =[];
  DSCardComparisonXChartData =[];
  //line chart color DSCardComparisonChartColors = [{ backgroundColor: 'rgba(0,0,0,0)', borderColor:'rgb(11,108,205)'} ,{ backgroundColor: 'rgba(0,0,0,0)', borderColor: 'rgb(255,140,0)'}]; //rgb(220,220,220)
  //04/19/2022 LP team would like bar chart now
  //04/22/2022 color change requested by Megan
  //DSCardComparisonChartColors = [{ backgroundColor  :'rgb(11,108,205)', borderColor  :'rgb(11,108,205)'} ,{backgroundColor  : 'rgb(255,140,0)', borderColor  : 'rgb(255,140,0)'}]; //rgb(220,220,220) 
  DSCardComparisonChartColors = [{ backgroundColor  :'rgb(11,108,205)', borderColor  :'rgb(11,108,205)'} ,{backgroundColor  : 'rgb(245,126,32)', borderColor  : 'rgb(245,126,32)'}];
  DSCardComparisonChartlabels = ['Under 5', '5 - 10', '10 - 15', 'Over 15'];
  DSCardComparisonChartOptions : any;

  speedingTrendTblDataSource : any;
  @ViewChild('sortSpeedingTrend') sortSpeedingTrend: MatSort;

  speedingComparisonTblDataSource : any;

  speedingTrendColumns = [ 'YearMonth', 'derived_Under5','derived_5To10', 'derived_10To15', 'derived_Over15'];
  speedingComparisonColumns = ['categories', 'derived_Under5','derived_5To10', 'derived_10To15', 'derived_Over15'];

  comparisonXShow : boolean =  false;

  @Input() DSCardDataToExport;
              

  constructor() { }

  DSCardProgramDrChange (value)
  {
    this.DSCardCompanyProgramDr = value;
    this.generateDriverScoreCardTable();
  }

  generateDriverScoreCardTable() {
    if(this.driverScoreCardData)
    {   
        //table 1
        
        let speedingTrendViewData = _.toArray(this.driverScoreCardData.speedingTrendData);
        this.speedingTrendTblDataSource =  new MatTableDataSource(speedingTrendViewData);//new MatTableDataSource(this.driverScoreCardData.speedingTrendData);
        //sort on derived columns
        this.speedingTrendTblDataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'derived_Under5':
              return item.Sum_MovingTime_Hr == 0 ? 0 : (item.SpeedingTrend_Under_5_ByTime / 3600 * 100 / item.Sum_MovingTime_Hr);
            case 'derived_5To10':
              return item.Sum_MovingTime_Hr == 0 ? 0 : (item.SpeedingTrend_5_10_ByTime / 3600 * 100 / item.Sum_MovingTime_Hr);

            case 'derived_10To15' :
              return item.Sum_MovingTime_Hr == 0 ? 0 : (item.SpeedingTrend_10_15_ByTime /3600 * 100 / item.Sum_MovingTime_Hr);
            
            case 'derived_Over15':
              return item.Sum_MovingTime_Hr == 0 ? 0 : (item.SpeedingTrend_Over_15_ByTime / 3600 * 100 / item.Sum_MovingTime_Hr);
            
            default:
              return item[property];
          }
        };
        this.speedingTrendTblDataSource.sort = this.sortSpeedingTrend;

        //table 2
        var comparisonData = this.driverScoreCardData.companyComparisonData
        var comparisonDashData = [];

        if(comparisonData)
        {
          comparisonDashData.push(
            { 
              categories: 'Your Company',
              sum_Speeding_Under_5mph_ByTime : comparisonData.companyDataDash.Sum_Speeding_Under_5mph_ByTime,
              sum_Speeding_5_10mph_ByTime : comparisonData.companyDataDash.Sum_Speeding_5_10mph_ByTime,
              sum_Speeding_10_15mph_ByTime : comparisonData.companyDataDash.Sum_Speeding_10_15mph_ByTime,
              sum_Speeding_Over_15mph_ByTime : comparisonData.companyDataDash.Sum_Speeding_Over_15mph_ByTime,
              sum_MovingTime_Hr : comparisonData.companyDataDash.Sum_MovingTime_Hr
            });


          comparisonDashData.push(
            { 
              categories: 'eCaptiv ' + (this.DSCardCompanyProgramDr != 2 ? this.dashboardCompanyProgram + '-IC' : 'All Programs'),
              sum_Speeding_Under_5mph_ByTime : this.DSCardCompanyProgramDr != 2 ? comparisonData.programCompanyDataDash.Sum_Speeding_Under_5mph_ByTime: comparisonData.allCompanyDataDash.Sum_Speeding_Under_5mph_ByTime,
              sum_Speeding_5_10mph_ByTime : this.DSCardCompanyProgramDr != 2 ? comparisonData.programCompanyDataDash.Sum_Speeding_5_10mph_ByTime : comparisonData.allCompanyDataDash.Sum_Speeding_5_10mph_ByTime,
              sum_Speeding_10_15mph_ByTime : this.DSCardCompanyProgramDr != 2 ? comparisonData.programCompanyDataDash.Sum_Speeding_10_15mph_ByTime : comparisonData.allCompanyDataDash.Sum_Speeding_10_15mph_ByTime,
              sum_Speeding_Over_15mph_ByTime : this.DSCardCompanyProgramDr != 2 ? comparisonData.programCompanyDataDash.Sum_Speeding_Over_15mph_ByTime : comparisonData.allCompanyDataDash.Sum_Speeding_Over_15mph_ByTime,
              sum_MovingTime_Hr : this.DSCardCompanyProgramDr != 2 ? comparisonData.programCompanyDataDash.Sum_MovingTime_Hr : comparisonData.allCompanyDataDash.Sum_MovingTime_Hr
              

              /*sum_Speeding_Under_5mph_ByTime : comparisonData.allCompanyDataDash.Sum_Speeding_Under_5mph_ByTime,
              sum_Speeding_5_10mph_ByTime : comparisonData.allCompanyDataDash.Sum_Speeding_5_10mph_ByTime,
              sum_Speeding_10_15mph_ByTime : comparisonData.allCompanyDataDash.Sum_Speeding_10_15mph_ByTime,
              sum_Speeding_Over_15mph_ByTime :  comparisonData.allCompanyDataDash.Sum_Speeding_Over_15mph_ByTime,
              sum_MovingTime_Hr : comparisonData.allCompanyDataDash.Sum_MovingTime_Hr,

              sum_Speeding_Under_5mph_ByTimeCompanyProgram :  comparisonData.programCompanyDataDash.Sum_Speeding_Under_5mph_ByTime,
              sum_Speeding_5_10mph_ByTimeCompanyProgram :  comparisonData.programCompanyDataDash.Sum_Speeding_5_10mph_ByTime,
              sum_Speeding_10_15mph_ByTimeCompanyProgram : comparisonData.programCompanyDataDash.Sum_Speeding_10_15mph_ByTime,
              sum_Speeding_Over_15mph_ByTimeCompanyProgram : comparisonData.programCompanyDataDash.Sum_Speeding_Over_15mph_ByTime,
              sum_MovingTime_HrCompanyProgram : comparisonData.programCompanyDataDash.Sum_MovingTime_Hr*/
            });

            
            // poulate to this.scoreCardComparisonData from comparisonDashData data for table 2
            this.speedingComparisonTblDataSource = new MatTableDataSource(comparisonDashData);

            this.dashMonthCompareDisplay = comparisonData.companyDataDash.YearMonth;
        }
        else
        {
          comparisonDashData = [];
          this.scoreCardComparisonData = null;
          this.speedingComparisonTblDataSource = null;
        }
        //table2

        this.setDriverScoreCardCharts(this.driverScoreCardData.speedingTrendData, comparisonDashData, comparisonData);
    }
  }

  setDriverScoreCardCharts (trendingChartData, companyCompareData, originalComparisonData)
  {   
    //trending chart
    this.DSCardTrendingChartData = [];
    this.DSCardTrendingChartlabels = [];
    if(trendingChartData && trendingChartData.length >0)
    {

      //chartjs trending
      //this.DSCardTrendingChartData = [];
      //this.DSCardTrendingChartlabels = [];
      let Under_5 = [];
      let _5_10 = [];
      let _10_15 = [];
      let Over_15 = [];
      //this.DSCardTrendSeries = ['Under 5', '5 To 10', '10 To 15', 'Over 15'];


      let startingIndex = trendingChartData.length - 6;//04/19/2022 after convertion, the maxima months shown in the line chart is 6
      trendingChartData.forEach((item, index) => {
        if(index >= startingIndex) // since the real estate is good for 5 months, the chart has minimal 3 months and not more than 6 months to display
        {
          Under_5.push(
            item.Sum_MovingTime_Hr != 0 ? item.SpeedingTrend_Under_5_ByTime / 3600 * 100 / item.Sum_MovingTime_Hr : 0
          );

          _5_10.push(
            item.Sum_MovingTime_Hr != 0 ? item.SpeedingTrend_5_10_ByTime / 3600 * 100 / item.Sum_MovingTime_Hr : 0
          );

          _10_15.push(
            item.Sum_MovingTime_Hr != 0 ? item.SpeedingTrend_10_15_ByTime / 3600 * 100 / item.Sum_MovingTime_Hr : 0
          );

          Over_15.push(
            item.Sum_MovingTime_Hr != 0 ? item.SpeedingTrend_Over_15_ByTime / 3600 * 100 / item.Sum_MovingTime_Hr : 0
          );

          this.DSCardTrendingChartlabels.push(item.YearMonth.toString().slice(4) + '-' + item.YearMonth.toString().slice(0,4));
        }
      });

      this.DSCardTrendingChartData.push({ data : Under_5, label :'Under 5' });
      this.DSCardTrendingChartData.push({ data : _5_10, label : '5 To 10'});
      this.DSCardTrendingChartData.push({ data : _10_15, label: '10 To 15' });
      this.DSCardTrendingChartData.push({data : Over_15, label : 'Over 15'});
      //chart js 
    }
    //trending chart

    //comparison chart
    // chartjs comparison
    this.DSCardComparisonChartData =[];
    let yourCompany = [];
    let captive = [];
    let comparsionDropdownValue = this.DSCardCompanyProgramDr;
    this.DSCardComparisonSeries = ['Your Company', 'eCaptiv ' + (comparsionDropdownValue != 2 ? this.dashboardCompanyProgram + '-IC': 'All Programs')];
    

    if(companyCompareData && companyCompareData.length > 0)
    {
      yourCompany.push(companyCompareData[0].sum_Speeding_Under_5mph_ByTime / 3600 * 100 / companyCompareData[0].sum_MovingTime_Hr);
      yourCompany.push(companyCompareData[0].sum_Speeding_5_10mph_ByTime / 3600 * 100 / companyCompareData[0].sum_MovingTime_Hr);
      yourCompany.push(companyCompareData[0].sum_Speeding_10_15mph_ByTime / 3600 * 100 / companyCompareData[0].sum_MovingTime_Hr);
      yourCompany.push(companyCompareData[0].sum_Speeding_Over_15mph_ByTime / 3600 * 100 / companyCompareData[0].sum_MovingTime_Hr);


      captive.push(companyCompareData[1].sum_Speeding_Under_5mph_ByTime / 3600 * 100 / companyCompareData[1].sum_MovingTime_Hr);
      captive.push(companyCompareData[1].sum_Speeding_5_10mph_ByTime / 3600 * 100 / companyCompareData[1].sum_MovingTime_Hr);
      captive.push(companyCompareData[1].sum_Speeding_10_15mph_ByTime / 3600 * 100 / companyCompareData[1].sum_MovingTime_Hr);
      captive.push(companyCompareData[1].sum_Speeding_Over_15mph_ByTime / 3600 * 100 / companyCompareData[1].sum_MovingTime_Hr);

      this.DSCardComparisonChartData.push({ data : yourCompany, label : 'Your Company'});
      this.DSCardComparisonChartData.push({data :  captive , label : 'eCaptiv ' + (comparsionDropdownValue != 2 ? this.dashboardCompanyProgram + '-IC' : 'All Programs') });
    }



    // add comparisonX chart virtually for export based on dropdown selection at the moment of exporting
    
    if(originalComparisonData)
    {
      let yourCompanyX = [];
      let captiveX = [];
      this.DSCardComparisonXSeries = ['Your Company', 'eCaptiv ' + (comparsionDropdownValue != 2 ? 'All Programs': this.dashboardCompanyProgram + '-IC')]; // reverse for X
      this.DSCardComparisonXChartData = []
  
      if(this.DSCardCompanyProgramDr != 2) // for comparisonX, do reverse here, if the dropdown is company program, set X to all program
      {
        yourCompanyX.push(originalComparisonData.companyDataDash.Sum_Speeding_Under_5mph_ByTime / 3600 * 100 / originalComparisonData.companyDataDash.Sum_MovingTime_Hr);
        yourCompanyX.push(originalComparisonData.companyDataDash.Sum_Speeding_5_10mph_ByTime / 3600 * 100 / originalComparisonData.companyDataDash.Sum_MovingTime_Hr);
        yourCompanyX.push(originalComparisonData.companyDataDash.Sum_Speeding_10_15mph_ByTime / 3600 * 100 / originalComparisonData.companyDataDash.Sum_MovingTime_Hr);
        yourCompanyX.push(originalComparisonData.companyDataDash.Sum_Speeding_Over_15mph_ByTime / 3600 * 100 / originalComparisonData.companyDataDash.Sum_MovingTime_Hr);


        captiveX.push(originalComparisonData.allCompanyDataDash.Sum_Speeding_Under_5mph_ByTime / 3600 * 100 / originalComparisonData.allCompanyDataDash.Sum_MovingTime_Hr);
        captiveX.push(originalComparisonData.allCompanyDataDash.Sum_Speeding_5_10mph_ByTime / 3600 * 100 / originalComparisonData.allCompanyDataDash.Sum_MovingTime_Hr);
        captiveX.push(originalComparisonData.allCompanyDataDash.Sum_Speeding_10_15mph_ByTime / 3600 * 100 / originalComparisonData.allCompanyDataDash.Sum_MovingTime_Hr);
        captiveX.push(originalComparisonData.allCompanyDataDash.Sum_Speeding_Over_15mph_ByTime / 3600 * 100 / originalComparisonData.allCompanyDataDash.Sum_MovingTime_Hr);

        this.DSCardComparisonXChartData.push({data :yourCompanyX, label : 'Your Company'});
        this.DSCardComparisonXChartData.push({data : captiveX, label : 'eCaptiv ' + (comparsionDropdownValue != 2 ? 'All Programs': this.dashboardCompanyProgram + '-IC') });
      }
      else{
        yourCompanyX.push(originalComparisonData.companyDataDash.Sum_Speeding_Under_5mph_ByTime / 3600 * 100 / originalComparisonData.companyDataDash.Sum_MovingTime_Hr);
        yourCompanyX.push(originalComparisonData.companyDataDash.Sum_Speeding_5_10mph_ByTime / 3600 * 100 / originalComparisonData.companyDataDash.Sum_MovingTime_Hr);
        yourCompanyX.push(originalComparisonData.companyDataDash.Sum_Speeding_10_15mph_ByTime / 3600 * 100 / originalComparisonData.companyDataDash.Sum_MovingTime_Hr);
        yourCompanyX.push(originalComparisonData.companyDataDash.Sum_Speeding_Over_15mph_ByTime / 3600 * 100 / originalComparisonData.companyDataDash.Sum_MovingTime_Hr);


        captiveX.push(originalComparisonData.programCompanyDataDash.Sum_Speeding_Under_5mph_ByTime / 3600 * 100 / originalComparisonData.programCompanyDataDash.Sum_MovingTime_Hr);
        captiveX.push(originalComparisonData.programCompanyDataDash.Sum_Speeding_5_10mph_ByTime / 3600 * 100 / originalComparisonData.programCompanyDataDash.Sum_MovingTime_Hr);
        captiveX.push(originalComparisonData.programCompanyDataDash.Sum_Speeding_10_15mph_ByTime / 3600 * 100 / originalComparisonData.programCompanyDataDash.Sum_MovingTime_Hr);
        captiveX.push(originalComparisonData.programCompanyDataDash.Sum_Speeding_Over_15mph_ByTime / 3600 * 100 / originalComparisonData.programCompanyDataDash.Sum_MovingTime_Hr);

        //this.DSCardComparisonXChartData.push(yourCompanyX);
        //this.DSCardComparisonXChartData.push(captiveX);
        this.DSCardComparisonXChartData.push({data :yourCompanyX, label : 'Your Company'});
        this.DSCardComparisonXChartData.push({data : captiveX, label : 'eCaptiv ' + (comparsionDropdownValue != 2 ? 'All Programs': this.dashboardCompanyProgram + '-IC') });
      }
    }
  }

  setDSCardTrendingChartOptions ()
  {
    this.DSCardTrendingChartOptions = 
    {
      scales: {
        yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          gridLines: { display: false},
          ticks: {
            suggestedMax: 10,
            callback: function(v,i,values) {
            //var t = x;
            return v.toFixed(1) + '%';
            },
            stepSize: 4,
            fontColor: 'white'
          },
        }],
        xAxes: [
        {
          gridLines: { display: false},
          ticks: {
            fontColor: 'white'
          }
        }]
      },
      tooltips: {
        callbacks: 
        { 
          label: function(item, data){
          var label = data.datasets[item.datasetIndex].label || '';
          label += ': ' + (Math.round(item.yLabel * 100) / 100).toFixed(1) + '%';
          return label;
          },
          labelColor: function(tooltipItem, chart) {
            return {
              borderColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
              backgroundColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor
            };
          },
        }
      },
      showLines: true,
      
      responsive: true,
      animation: {
        duration: 300,
        easing: 'linear'
      },
      layout: {
        padding:
        {
            left: 0,
            right: 0,
            top: 5,
            bottom: 30
        }
      },
      legend: { 
        display: true,
        labels: {
            fontColor: 'white',
            usePointStyle: true,
            boxWidth: 10,
        },
        position: 'bottom',
        //align: 'end',
      },
      elements:{
        point:{
            //backgroundColor: 'red'
        }
      }
    }
  }
  setDSCardComparisonChartOptions()
  {
    this.DSCardComparisonChartOptions =
    {
      scales: {
        yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'left',
          gridLines: { display: false},
          ticks: {
            callback: (v,i,values) =>{
            //var t = x;
            return v.toFixed(1) + '%';
            },
            stepSize: 10,

            fontColor: 'white'
          },
        }],
        xAxes: [
        {
          gridLines: { display: false},
          ticks: {
            fontColor: 'white'
          }
        }]
      },
      tooltips: {

        callbacks: 
        { 
          label: (item, data) =>{
          var label = data.datasets[item.datasetIndex].label || '';
          label += ': ' + (Math.round(item.yLabel * 100) / 100).toFixed(1) + '%';
          return label;
          },
          labelColor: (tooltipItem, chart) => {
            return {
              borderColor: chart.config.data.datasets[tooltipItem.datasetIndex].backgroundColor,//borderColor,
              backgroundColor: chart.config.data.datasets[tooltipItem.datasetIndex].backgroundColor//borderColor
            };
          },
        }
      },
      showLines: true,
      
      responsive: true,
      animation: {
        duration: 0,
        easing: 'easeInOutBounce'
      },
      layout: {
        padding:
        {
          left: 0,
          right: 0,
          top: 5,
          bottom: 100
        }
      },
      legend: {
        display: true,
        labels: {
            fontColor: 'white',
            usePointStyle: true,
            boxWidth: 10
        },
        position: 'bottom'
      //align: 'end',
      }
    }
  }

  ExportDSCard()
  {
    this.comparisonXShow = true;

    this.generateDSCardExcel().pipe(takeUntil(this.subNotifier)).subscribe((result) => 
    {
      this.comparisonXShow = false;
    });
  }

  generateDSCardExcel() : Observable<any>
  {
    return new Observable( (ob) => {

      fetch('/appdata/TEDriverScoreCardReportTemplate.xlsx', {cache: 'no-cache'})
      .then((res) => {
        let blob = new Blob();
        return res.arrayBuffer()}
      ) // Gets the response and returns it as a blob
      .then((blob) => {

        let workbook : ExcelJS.Workbook = new ExcelJS.Workbook();
        workbook.xlsx.load(blob).then(() => {

        //Cover Sheet
        let CoverSheet : ExcelJS.Worksheet = workbook.getWorksheet('Cover Sheet');
        CoverSheet.getRow(5).getCell(1).value = moment(this.dashboardDate.end, 'MM/YYYY').format('MMMM YYYY');
        CoverSheet.getRow(7).getCell(1).value = this.dashboardCompanyName;
        


        //Exec Summary 1/ Trend sheet
        let TrendSheet : ExcelJS.Worksheet = workbook.getWorksheet('Exec Summary 1');
        
        TrendSheet.getRow(4).getCell(2).value = this.dashboardCompanyName;
        //TrendSheet.getRow(5).getCell(2).value = moment(this.dashboardDate.end, 'MM/YYYY').format('MMMM YYYY');


        //total speeding ranking
        if(this.driverScoreCardData.programCompanySpeedingList.length > 0)
        {
          let sortedRanks = this.driverScoreCardData.programCompanySpeedingList;
          let currentPlace : number;
          currentPlace = sortedRanks.findIndex(f => f.mcId === this.driverScoreCardData.companyComparisonData.companyDataDash.McId
             //(x) => Object.keys(x)[0] === authState.clientId.toString()
          ) + 1;
      
          
          let supText : string = '';
          switch (currentPlace % 10) {
            case 1:
              supText = "st";
              break;
            case 2:
              supText = "nd";
              break;
            case 3:
              supText = "rd";
              break;
            default:
              supText = "th";
              break;
          }

          TrendSheet.getRow(7).getCell(2).value = `${currentPlace}${supText} of ${sortedRanks.length} Members`;

        }
        //total speeding ranking


        //trending image
        //TrendSheet.mergeCells('B16:I28');
        TrendSheet.getCell('B16').fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor: { argb: '303030'}};

        let base64encodedstringTrending = null;
        let canvasTrending = <HTMLCanvasElement>document.getElementById('trendingLinechart');//jQuery('#trendingLinechart');
        //dummy canvas for background, and put the original chart canvas on top of it
        let dummyCanvas = <HTMLCanvasElement>document.createElement('canvas');
        dummyCanvas.width = canvasTrending.width;
        dummyCanvas.height = canvasTrending.height;

        let dummyCtx = dummyCanvas.getContext('2d');
        dummyCtx.fillStyle = 'rgba(42, 46, 57, 1)';
        dummyCtx.fillRect(0, 0, canvasTrending.width, canvasTrending.height);
        dummyCtx.imageSmoothingQuality = 'high';
        dummyCtx.imageSmoothingEnabled = true;

        dummyCtx.drawImage(canvasTrending, 0, 0); //draw the  original chart canvas on top of it
        //dummy canvas for background

        base64encodedstringTrending = dummyCanvas.toDataURL('image/png', 1);//canvasTrending.toDataURL('image/jpeg', 1);

        let trendingImageId = workbook.addImage({
              base64: base64encodedstringTrending,
              extension: 'png',
            });

        TrendSheet.addImage(trendingImageId, 'B16 : I29');

        if(this.driverScoreCardData.companyComparisonData)
        {
          let comparsionExceltbl = this.driverScoreCardData.companyComparisonData

          //04/14/2022 new requirement after convertion for driver score card, automatically lower 10% for next month target
          TrendSheet.getRow(12).getCell(3).value =  comparsionExceltbl.companyDataDash.Sum_MovingTime_Hr  == 0 ? 0 + '%' : (comparsionExceltbl.companyDataDash.Sum_Speeding_Under_5mph_ByTime / 3600 / comparsionExceltbl.companyDataDash.Sum_MovingTime_Hr * 100 * 0.9).toFixed(1) + '%';
          TrendSheet.getRow(12).getCell(4).value = comparsionExceltbl.companyDataDash.Sum_MovingTime_Hr  == 0 ? 0 + '%' : (comparsionExceltbl.companyDataDash.Sum_Speeding_5_10mph_ByTime / 3600 / comparsionExceltbl.companyDataDash.Sum_MovingTime_Hr * 100 * 0.9).toFixed(1) + '%';
          TrendSheet.getRow(12).getCell(5).value = comparsionExceltbl.companyDataDash.Sum_MovingTime_Hr  == 0 ? 0 + '%' : (comparsionExceltbl.companyDataDash.Sum_Speeding_10_15mph_ByTime / 3600 / comparsionExceltbl.companyDataDash.Sum_MovingTime_Hr * 100 * 0.9).toFixed(1) + '%';
          TrendSheet.getRow(12).getCell(6).value = comparsionExceltbl.companyDataDash.Sum_MovingTime_Hr  == 0 ? 0 + '%' : (comparsionExceltbl.companyDataDash.Sum_Speeding_Over_15mph_ByTime / 3600 / comparsionExceltbl.companyDataDash.Sum_MovingTime_Hr * 100 * 0.9).toFixed(1) + '%';
          //04/14/2022 new requirement after convertion for driver score card, automatically lower 10% for next month target

          TrendSheet.getRow(13).getCell(3).value =  comparsionExceltbl.companyDataDash.Sum_MovingTime_Hr  == 0 ? 0 + '%' : (comparsionExceltbl.companyDataDash.Sum_Speeding_Under_5mph_ByTime / 3600 / comparsionExceltbl.companyDataDash.Sum_MovingTime_Hr * 100).toFixed(1) + '%';
          TrendSheet.getRow(13).getCell(4).value = comparsionExceltbl.companyDataDash.Sum_MovingTime_Hr  == 0 ? 0 + '%' : (comparsionExceltbl.companyDataDash.Sum_Speeding_5_10mph_ByTime / 3600 / comparsionExceltbl.companyDataDash.Sum_MovingTime_Hr * 100).toFixed(1) + '%';
          TrendSheet.getRow(13).getCell(5).value = comparsionExceltbl.companyDataDash.Sum_MovingTime_Hr  == 0 ? 0 + '%' : (comparsionExceltbl.companyDataDash.Sum_Speeding_10_15mph_ByTime / 3600 / comparsionExceltbl.companyDataDash.Sum_MovingTime_Hr * 100).toFixed(1) + '%';
          TrendSheet.getRow(13).getCell(6).value = comparsionExceltbl.companyDataDash.Sum_MovingTime_Hr  == 0 ? 0 + '%' : (comparsionExceltbl.companyDataDash.Sum_Speeding_Over_15mph_ByTime / 3600 / comparsionExceltbl.companyDataDash.Sum_MovingTime_Hr * 100).toFixed(1) + '%';
        }

        //Tip of the Month, new after convertion, the enhancement of the DSC report 4/14/2022
        let tipEndDate : number =  parseInt(moment(this.dashboardDate.end, 'MM/YYYY').format('YYYYMM'));
        TrendSheet.getRow(32).getCell(2).value = Recommendations.find(f => f.yearMonth === tipEndDate)?.recommendation;


        //Exec Summary 2 and 3/ Comparison sheet
        //Exec Summary 2, 

        let comparisonSheet = workbook.getWorksheet('Exec Summary 2');
        
        comparisonSheet.getRow(4).getCell(2).value = this.dashboardCompanyName;
        //comparisonSheet.getRow(6).getCell(2).value = moment(this.dashboardDate.end, 'MM/YYYY').format('MMMM YYYY');
        comparisonSheet.getRow(8).getCell(2).value = "Company Drivers'Speeding Compared to All Drivers in eCaptiv Programs";
        comparisonSheet.getRow(8).getCell(2).alignment = { vertical: 'middle', horizontal: 'center' };
        comparisonSheet.getRow(8).getCell(2).font = {bold: true, name: 'Calibri', size: 12, color: {argb : 'FFFFFFF'} };
        
        if(this.driverScoreCardData.companyComparisonData)
        {
          /*comparisonSheet.getCell('B9').fill = {
              type: 'pattern',
              pattern:'solid',
              fgColor: { argb: '303030'}};
          */
          let base64encodedstringComparison = null;
          let canvasComparison =  this.DSCardCompanyProgramDr != 2 ? <HTMLCanvasElement>document.getElementById('comparisonXLinechart') : <HTMLCanvasElement>document.getElementById('comparisonLinechart');
          //var canvasComparison =  this.DSCardCompanyProgramDr != 2 ? jQuery('#comparisonXLinechart') : jQuery('#comparisonLinechart');
          //dummy canvas for background, and put the original chart canvas on top of it
          let dummyCanvas = <HTMLCanvasElement>document.createElement('canvas');
          dummyCanvas.width = canvasComparison.width;
          dummyCanvas.height = canvasComparison.height;

          let dummyCtx = dummyCanvas.getContext('2d');
          dummyCtx.fillStyle = 'rgba(42, 46, 57, 1)';
          dummyCtx.fillRect(0, 0, canvasComparison.width, canvasComparison.height);
          dummyCtx.imageSmoothingQuality = 'high';
          dummyCtx.imageSmoothingEnabled = true;

          dummyCtx.drawImage(canvasComparison, 0, 0); //draw the  original chart canvas on top of it
          //dummy canvas for background

          //canvasComparison.getContext('2d').fillStyle = 'rgba(42, 46, 57, 1)';
          //canvasComparison.getContext('2d').fillRect(0, 0, canvasComparison.width, canvasComparison.height);
          //canvasComparison[0].getContext('2d').imageSmoothingQuality = 'high';
          //canvasComparison[0].getContext('2d').fillStyle ;
          
          base64encodedstringComparison = dummyCanvas.toDataURL('image/png', 1);//canvasComparison.toDataURL('image/png', 1);

          let comparisonImageId = workbook.addImage({
                base64: base64encodedstringComparison,
                extension: 'png',
              });

          comparisonSheet.addImage(comparisonImageId, 'B9 : G23');



          //canvas 2  move to Exec 1 06/26/2023 requester by Kevin

          comparisonSheet.getRow(27).getCell(2).value = "Company Drivers' Speeding Compared to All Drivers in Your eCaptiv Program";
          comparisonSheet.getRow(27).getCell(2).alignment = { vertical: 'middle', horizontal: 'center' };
          comparisonSheet.getRow(27).getCell(2).font = {bold: true, name: 'Calibri', size: 12, color: {argb : 'FFFFFFF' }};

          let base64encodedstringComparison2 = null;
          let canvasComparison2 : HTMLCanvasElement = 
            this.DSCardCompanyProgramDr != 2 ? <HTMLCanvasElement>document.getElementById('comparisonLinechart') : <HTMLCanvasElement>document.getElementById('comparisonXLinechart');
          //var canvasComparison2 = this.DSCardCompanyProgramDr != 2 ? jQuery('#comparisonLinechart') : jQuery('#comparisonXLinechart');
          
          //dummy canvas for background, and put the original chart canvas on top of it
          let dummyCanvas2 = <HTMLCanvasElement>document.createElement('canvas');
          dummyCanvas2.width = canvasComparison2.width;
          dummyCanvas2.height = canvasComparison2.height;

          let dummyCtx2 = dummyCanvas2.getContext('2d');
          dummyCtx2.fillStyle = 'rgba(42, 46, 57, 1)';
          dummyCtx2.fillRect(0, 0, canvasComparison2.width, canvasComparison2.height);
          dummyCtx2.imageSmoothingQuality = 'high';
          dummyCtx2.imageSmoothingEnabled = true;

          dummyCtx2.drawImage(canvasComparison2, 0, 0); //draw the  original chart canvas on top of it
          //dummy canvas for background
          
          base64encodedstringComparison2 = dummyCanvas2.toDataURL('image/png', 1);//canvasComparison2.toDataURL('image/jpeg', 1);

          let comparison2ImageId = workbook.addImage({
                base64: base64encodedstringComparison2,
                extension: 'png',
              });

          comparisonSheet.addImage(comparison2ImageId, 'B28 : G42');
          //canvas 2  move to Exec 1 06/26/2023 requester by Kevin


        }


        //Exec Summary 3, 
        let comparison2Sheet = workbook.getWorksheet('Exec Summary 3');
        
        comparison2Sheet.getRow(4).getCell(2).value = this.dashboardCompanyName;
        //comparison2Sheet.getRow(6).getCell(2).value = moment(this.dashboardDate.end, 'MM/YYYY').format('MMMM YYYY');

        //vehicle miles
        let headerRowExec = 21;
        const bassColumnsExec = 2;
        
        const maxMonthsExec = 1; //04/19/2022, only take 1 month for now
        
        const maxMilessExec = 18000;
        comparison2Sheet.mergeCells(`${comparison2Sheet.getColumn(2).letter + (headerRowExec -2)} : G${(headerRowExec -2)}}`);
        comparison2Sheet.getRow(headerRowExec -2).getCell(2).alignment = { vertical: 'middle', horizontal: 'center' };
        comparison2Sheet.getRow(headerRowExec -2).getCell(2).value = 'Excessive Mileage';
        comparison2Sheet.getRow(headerRowExec -2).getCell(2).font = {bold : true, color : {argb : 'FFFFFFFF'}, size: 12 };
        comparison2Sheet.getRow(headerRowExec -2).getCell(2).fill = {type: 'pattern', pattern:'solid', fgColor : {argb: 'FF2a2e39' }}

        comparison2Sheet.mergeCells(`${comparison2Sheet.getColumn(2).letter + (headerRowExec -1)} : G${(headerRowExec -1)}}`);
        comparison2Sheet.getRow(headerRowExec -1).getCell(2).alignment = { vertical: 'middle', horizontal: 'center' };
        comparison2Sheet.getRow(headerRowExec -1).getCell(2).value = `Vehicles driven more than ${maxMilessExec} miles in the month`;
        comparison2Sheet.getRow(headerRowExec -1).getCell(2).font = {bold : true, color : {argb : 'FFFFFFFF'}, size: 11, italic: true };
        comparison2Sheet.getRow(headerRowExec -1).getCell(2).fill = {type: 'pattern', pattern:'solid', fgColor : {argb: 'FF2a2e39' }}
        
        comparison2Sheet.getRow(headerRowExec).getCell(2).value = 'VIN/Vehicle ID';
        comparison2Sheet.mergeCells(`B${headerRowExec}: C${headerRowExec}`);
        //observationSheet.getRow(headerRow).getCell(2).value = 'VIN';
        comparison2Sheet.getRow(headerRowExec).getCell(4).value = 'Vehicle Type';
        comparison2Sheet.mergeCells(`D${headerRowExec}: E${headerRowExec}`);

        //comparison2Sheet.getColumn(bassColumnsExec).width = 21;
        //comparison2Sheet.getColumn(bassColumnsExec + 1).width = 21;

        let lenExec = this.driverScoreCardData.monthlyUtilizationLst.length;
        let threeMosLstExec = [];
        if(lenExec < maxMonthsExec)
        { threeMosLstExec = this.driverScoreCardData.monthlyUtilizationLst;}
        else {
          for(let i = (lenExec - maxMonthsExec); i < lenExec; i++)
          {
            threeMosLstExec.push(this.driverScoreCardData.monthlyUtilizationLst[i]);
          }
        }

        let thressMosXmilesLstExec = [];
        
        threeMosLstExec.forEach((item, i) => {
          //header of month for max miles
          comparison2Sheet.mergeCells(`F${headerRowExec}: G${headerRowExec}`);;
          comparison2Sheet.getRow(headerRowExec).getCell(6 + i).value = 'Total Miles';//uncoment when the multi month is wanted item[0].yearMonth.toString().substring(4) + '-' + item[0].yearMonth.toString().substring(0, 4) + ' (Total Miles)';
          //comparison2Sheet.getColumn(5 + i).width = 11;
          

          //row data
          let tempMaxMiles = item.filter(f => f.totalMileage > maxMilessExec);
          tempMaxMiles.forEach(item => {
            thressMosXmilesLstExec.push({ vehicleId : item.vehicleId, vin: item.vin, vehicleType : item.vehicleType, yearMonth : item.yearMonth, totalMileage: item.totalMileage})
          })

        
        })
        //miles
        thressMosXmilesLstExec = _.toArray(_.toArray(thressMosXmilesLstExec).groupBy("vehicleId"));
        thressMosXmilesLstExec.forEach((vehicle, index) => {


          comparison2Sheet.mergeCells(`B${headerRowExec + 1 + index} : C${headerRowExec + 1 + index} `);
          comparison2Sheet.mergeCells(`D${headerRowExec + 1 + index} : E${headerRowExec + 1 + index} `);
          comparison2Sheet.mergeCells(`F${headerRowExec + 1 + index} : G${headerRowExec + 1 + index} `);
          
          
          vehicle.sort((a,b) => {return a.yearMonth - b.yearMonth}).forEach((item, i : number) => {

            comparison2Sheet.getRow(headerRowExec + 1 + index).getCell(3).value = item.vin && item.vin !== 'N/A' ? item.vin : '/' + item.vehicleId.toString();
            //observationSheet.getRow(headerRow + 1 + index).getCell(2).value = item.vin;
            comparison2Sheet.getRow(headerRowExec + 1 + index).getCell(4).value = item.vehicleType;

            comparison2Sheet.getRow(headerRowExec + 1 + index).getCell(6 + i).value = item.totalMileage.toFixed(2);
          });
        })

        comparison2Sheet.getRow(headerRowExec)
        .eachCell({inculdeEMpty : false}, (cell, colNumber: number) => {
          if(cell?.value)
          {
            cell.fill =
            {
              type: 'pattern',
              pattern:'solid',
              fgColor:{argb:'FF0B67FF'},
              bgColor:{argb:'FF0B67FF'}
            };
            /*
            cell.border = {
              top: {style:'thin'},
              left: {style:'thin'},
              bottom: {style:'thin'},
              right: {style:'thin'}
            };*/

            cell.alignment = {wrapText : true};
            cell.style.font = {bold: true, color : {argb : 'FFFFFFFF'}};
          }
        });

        headerRowExec = headerRowExec + 1 + thressMosXmilesLstExec.length;


        //vehicle miles

        //driver miles
        headerRowExec += 5;

        comparison2Sheet.mergeCells(`${comparison2Sheet.getColumn(2).letter + (headerRowExec -2)} : G${(headerRowExec -2)}}`);
        comparison2Sheet.getRow(headerRowExec -2).getCell(2).alignment = { vertical: 'middle', horizontal: 'center' };
        comparison2Sheet.getRow(headerRowExec -2).getCell(2).value = 'Excessive Mileage';
        comparison2Sheet.getRow(headerRowExec -2).getCell(2).font = {bold : true, color : {argb : 'FFFFFFFF'}, size: 12 };
        comparison2Sheet.getRow(headerRowExec -2).getCell(2).fill = {type: 'pattern', pattern:'solid', fgColor : {argb: 'FF2a2e39' }}

        comparison2Sheet.mergeCells(`${comparison2Sheet.getColumn(2).letter + (headerRowExec -1)} : G${(headerRowExec -1)}}`);
        comparison2Sheet.getRow(headerRowExec -1).getCell(2).alignment = { vertical: 'middle', horizontal: 'center' };
        comparison2Sheet.getRow(headerRowExec -1).getCell(2).value = `Drivers driven more than ${maxMilessExec} miles in the month`;
        comparison2Sheet.getRow(headerRowExec -1).getCell(2).font = {bold : true, color : {argb : 'FFFFFFFF'}, size: 11, italic: true };
        comparison2Sheet.getRow(headerRowExec -1).getCell(2).fill = {type: 'pattern', pattern:'solid', fgColor : {argb: 'FF2a2e39' }}
        
        comparison2Sheet.getRow(headerRowExec).getCell(2).value = 'Driver Name';
        comparison2Sheet.mergeCells(`B${headerRowExec}: E${headerRowExec}`);
     
        //comparison2Sheet.getColumn(bassColumnsExec).width = 21;
        //comparison2Sheet.getColumn(bassColumnsExec + 1).width = 21;

        let lenDriverExec = this.driverScoreCardData.monthlyDriverUtilizationLst.length;
        let driverThreeMosLstExec = [];
        if(lenDriverExec < maxMonthsExec)
        { driverThreeMosLstExec = this.driverScoreCardData.monthlyDriverUtilizationLst;}
        else {
          for(let i = (lenDriverExec - maxMonthsExec); i < lenDriverExec; i++)
          {
            driverThreeMosLstExec.push(this.driverScoreCardData.monthlyDriverUtilizationLst[i]);
          }
        }

        //some clients may not have the driver data from tsp, put the note here and also populate headers

       
        let driverThressMosXmilesLstExec = [];
        
        driverThreeMosLstExec.forEach((item, i) => {
          //header of month for max miles
          comparison2Sheet.mergeCells(`F${headerRowExec}: G${headerRowExec}`);
          comparison2Sheet.getRow(headerRowExec).getCell(6 + i).value = 'Total Miles';//uncoment when the multi month is wanted item[0].yearMonth.toString().substring(4) + '-' + item[0].yearMonth.toString().substring(0, 4) + ' (Total Miles)';
          //comparison2Sheet.getColumn(5 + i).width = 11;
          

          //row data
          let tempMaxMiles = item.filter(f => f.totalMileage > maxMilessExec);
          tempMaxMiles.forEach(item => {
            driverThressMosXmilesLstExec.push({ driverName : item.driverName, yearMonth : item.yearMonth, totalMileage: item.totalMileage})
          })

        
        })
        //miles
        driverThressMosXmilesLstExec = _.toArray(_.toArray(driverThressMosXmilesLstExec).groupBy("driverId"));
        driverThressMosXmilesLstExec.forEach((driver, index) => {


          comparison2Sheet.mergeCells(`B${headerRowExec + 1 + index} : E${headerRowExec + 1 + index} `);
          //comparison2Sheet.mergeCells(`D${headerRowExec + 1 + index} : E${headerRowExec + 1 + index} `);
          comparison2Sheet.mergeCells(`F${headerRowExec + 1 + index} : G${headerRowExec + 1 + index} `);
          
          
          driver.sort((a,b) => {return a.yearMonth - b.yearMonth}).forEach((item, i : number) => {

            comparison2Sheet.getRow(headerRowExec + 1 + index).getCell(3).value = item.driverName;
            //observationSheet.getRow(headerRow + 1 + index).getCell(2).value = item.vin;
            //comparison2Sheet.getRow(headerRowExec + 1 + index).getCell(4).value = item.vehicleType;

            comparison2Sheet.getRow(headerRowExec + 1 + index).getCell(6 + i).value = item.totalMileage.toFixed(2);
          });
        })

        comparison2Sheet.getRow(headerRowExec)
        .eachCell({inculdeEMpty : false}, (cell, colNumber: number) => {
          if(cell?.value)
          {
            cell.fill =
            {
              type: 'pattern',
              pattern:'solid',
              fgColor:{argb:'FF0B67FF'},
              bgColor:{argb:'FF0B67FF'}
            };
            /*
            cell.border = {
              top: {style:'thin'},
              left: {style:'thin'},
              bottom: {style:'thin'},
              right: {style:'thin'}
            };*/

            cell.alignment = {wrapText : true};
            cell.style.font = {bold: true, color : {argb : 'FFFFFFFF'}};
          }
        });

        //driver miles
        //Exec Summary 3, 



        //Observation sheet //04/14/2022 new requirement after convertion
        let observationSheet = workbook.getWorksheet('Observations');
        observationSheet.getRow(3).getCell(1).value = this.dashboardCompanyName;

        //list of vehicles over x miles and x hours 3 months back from end Date
        const bassColumns = 2;
        const gapSection = 1;
        const maxMonths = 1; //04/19/2022, only take 1 month for now
        const maxHours = 14;
        const maxMiless = 18000;
        let headerRow = 6;

        //miles
        observationSheet.mergeCells(`${observationSheet.getColumn(1).letter + (headerRow -2)} : ${observationSheet.getColumn(bassColumns + maxMonths).letter + (headerRow -2)}`);
        observationSheet.getRow(headerRow -2).getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
        observationSheet.getRow(headerRow -2).getCell(1).value = 'Excessive Mileage';
        observationSheet.getRow(headerRow -2).getCell(1).font = {bold : true, color : {argb : 'FFFFFFFF'}, size: 12 };
        observationSheet.getRow(headerRow -2).getCell(1).fill = {type: 'pattern', pattern:'solid', fgColor : {argb: 'FF2a2e39' }}
        
        observationSheet.mergeCells(`${observationSheet.getColumn(1).letter + (headerRow -1)} : ${observationSheet.getColumn(bassColumns + maxMonths).letter + (headerRow -1)}`);
        observationSheet.getRow(headerRow -1).getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
        observationSheet.getRow(headerRow -1).getCell(1).value = `Vehicles driven more than ${maxMiless} miles in the month`;
        observationSheet.getRow(headerRow -1).getCell(1).font = {bold : true, color : {argb : 'FFFFFFFF'}, size: 11, italic: true };
        observationSheet.getRow(headerRow -1).getCell(1).fill = {type: 'pattern', pattern:'solid', fgColor : {argb: 'FF2a2e39' }}
        
        observationSheet.getRow(headerRow).getCell(1).value = 'VIN/Vehicle ID';
        //observationSheet.getRow(headerRow).getCell(2).value = 'VIN';
        observationSheet.getRow(headerRow).getCell(2).value = 'Vehicle Type';

        observationSheet.getColumn(1).width = 21;
        observationSheet.getColumn(2).width = 21;
        //observationSheet.getColumn(3).width = 18;

        //hours
        observationSheet.mergeCells(`${observationSheet.getColumn(bassColumns + maxMonths +  gapSection + 1).letter + (headerRow -2)} : ${observationSheet.getColumn(bassColumns + maxMonths +  gapSection + bassColumns + maxMonths).letter + (headerRow -2)}`);
        observationSheet.getRow(headerRow -2).getCell(bassColumns + maxMonths +  gapSection + 1).alignment = { vertical: 'middle', horizontal: 'center' };
        observationSheet.getRow(headerRow -2).getCell(bassColumns + maxMonths +  gapSection + 1).value = 'Average Shift Length';
        observationSheet.getRow(headerRow -2).getCell(bassColumns + maxMonths +  gapSection + 1).font = {bold : true, color : {argb : 'FFFFFFFF'}, size: 12 };
        observationSheet.getRow(headerRow -2).getCell(bassColumns + maxMonths +  gapSection + 1).fill = {type: 'pattern', pattern:'solid', fgColor : {argb: 'FF2a2e39' }}

        observationSheet.mergeCells(`${observationSheet.getColumn(bassColumns + maxMonths +  gapSection + 1).letter + (headerRow -1)} : ${observationSheet.getColumn(bassColumns + maxMonths +  gapSection + bassColumns + maxMonths).letter + (headerRow -1)}`);
        observationSheet.getRow(headerRow -1).getCell(bassColumns + maxMonths +  gapSection + 1).alignment = { vertical: 'middle', horizontal: 'center' };
        observationSheet.getRow(headerRow -1).getCell(bassColumns + maxMonths +  gapSection + 1).value = `Vehicles with Av. Shift length over ${maxHours} hours in the month`;
        observationSheet.getRow(headerRow -1).getCell(bassColumns + maxMonths +  gapSection + 1).font = {bold : true, color : {argb : 'FFFFFFFF'}, size: 11, italic: true };
        observationSheet.getRow(headerRow -1).getCell(bassColumns + maxMonths +  gapSection + 1).fill = {type: 'pattern', pattern:'solid', fgColor : {argb: 'FF2a2e39' }}

        
        observationSheet.getRow(headerRow).getCell(bassColumns + maxMonths +  gapSection + 1).value = 'VIN/Vehicle ID';
        //observationSheet.getRow(headerRow).getCell(bassColumns + maxMonths +  gapSection + 2).value = 'VIN';
        observationSheet.getRow(headerRow).getCell(bassColumns + maxMonths +  gapSection + 2).value = 'Vehicle Type';

        observationSheet.getColumn(bassColumns + maxMonths +  gapSection + 1).width = 21;
        observationSheet.getColumn(bassColumns + maxMonths +  gapSection + 2).width = 21;
        //observationSheet.getColumn(bassColumns + maxMonths +  gapSection + 3).width = 18;

        let len = this.driverScoreCardData.monthlyUtilizationLst.length;
        let threeMosLst = [];
        if(len < maxMonths)
        { threeMosLst = this.driverScoreCardData.monthlyUtilizationLst;}
        else {
          for(let i = (len - maxMonths); i < len; i++)
          {
            threeMosLst.push(this.driverScoreCardData.monthlyUtilizationLst[i]);
          }
        }

        let thressMosXmilesLst = [];
        let thressMosXHoursLst = [];
        threeMosLst.forEach((item, i) => {
          //header of month for max miles
          observationSheet.getRow(headerRow).getCell(3 + i).value = 'Total Miles';//uncoment when the multi month is wanted item[0].yearMonth.toString().substring(4) + '-' + item[0].yearMonth.toString().substring(0, 4) + ' (Total Miles)';
          observationSheet.getColumn(3 + i).width = 11;
          
  
          //header of month for max hours
          observationSheet.getRow(headerRow).getCell(bassColumns + maxMonths + gapSection + 3 + i).value = 'Av.Shift';//uncoment when the multi month is wanteditem[0].yearMonth.toString().substring(4) + '-' + item[0].yearMonth.toString().substring(0, 4) + ' (Av.Shift)';
          observationSheet.getColumn(bassColumns + maxMonths + gapSection + 3 + i).width = 11;

          //row data
          let tempMaxMiles = item.filter(f => f.totalMileage > maxMiless);
          tempMaxMiles.forEach(item => {
            thressMosXmilesLst.push({ vehicleId : item.vehicleId, vin: item.vin, vehicleType : item.vehicleType, yearMonth : item.yearMonth, totalMileage: item.totalMileage})
          })

          let tempMaxHours = item.filter(f => f.averageOfSum_NetOnTimeHr > maxHours);
          tempMaxHours.forEach(item => {
            thressMosXHoursLst.push({ vehicleId : item.vehicleId, vin: item.vin, vehicleType : item.vehicleType, yearMonth : item.yearMonth, aveShift: item.averageOfSum_NetOnTimeHr})
          })
        })
        //miles
        thressMosXmilesLst = _.toArray(_.toArray(thressMosXmilesLst).groupBy("vehicleId"));
        thressMosXmilesLst.forEach((vehicle, index) => {
          vehicle.sort((a,b) => {return a.yearMonth - b.yearMonth}).forEach((item, i : number) => {

            observationSheet.getRow(headerRow + 1 + index).getCell(1).value = item.vin && item.vin !== 'N/A' ? item.vin : '/' + item.vehicleId.toString();
            //observationSheet.getRow(headerRow + 1 + index).getCell(2).value = item.vin;
            observationSheet.getRow(headerRow + 1 + index).getCell(2).value = item.vehicleType;

            observationSheet.getRow(headerRow + 1 + index).getCell(3 + i).value = item.totalMileage.toFixed(2);
          });
        })
        //hours
        thressMosXHoursLst = _.toArray(_.toArray(thressMosXHoursLst).groupBy("vehicleId"));
        thressMosXHoursLst.forEach((vehicle, index) => {
          vehicle.sort((a, b) => {return a.yearMonth - b.yearMonth}).forEach((item, i : number) => {

            observationSheet.getRow(headerRow + 1 + index).getCell(bassColumns + maxMonths + gapSection + 1).value = item.vin && item.vin !== 'N/A' ? item.vin : '/' + item.vehicleId.toString();
            //observationSheet.getRow(headerRow + 1 + index).getCell(bassColumns + maxMonths + gapSection + 2).value = item.vin;
            observationSheet.getRow(headerRow + 1 + index).getCell(bassColumns + maxMonths + gapSection + 2).value = item.vehicleType;

            observationSheet.getRow(headerRow + 1 + index).getCell(bassColumns + maxMonths + gapSection + 3 + i).value = item.aveShift.toFixed(2);
          });
        })
        observationSheet.mergeCells(headerRow + 1 + thressMosXHoursLst.length + 1, bassColumns + maxMonths + gapSection + 1, headerRow + 2 + thressMosXHoursLst.length + 1, bassColumns + maxMonths + gapSection + 3);
        observationSheet.getRow(headerRow + 1 + thressMosXHoursLst.length + 1).getCell(bassColumns + maxMonths + gapSection + 1).alignment = {wrapText : true};
        observationSheet.getRow(headerRow + 1 + thressMosXHoursLst.length + 1).getCell(bassColumns + maxMonths + gapSection + 1).value =
        {
          'richText' : [
            {'font': {bold: true},'text': 'Average Shift Length '},
            {'font': {bold: false},'text': '- It is the total on time (driving and idling time) divided by the days operational.'}
          ]
        }

        //style the header
        observationSheet.getRow(headerRow)
          .eachCell({inculdeEMpty : false}, (cell, colNumber: number) => {
            if(cell?.value)
            {
              cell.fill =
              {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FF0B67FF'},
                bgColor:{argb:'FF0B67FF'}
              };
              /*
              cell.border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
              };*/
  
              cell.alignment = {wrapText : true};
              cell.style.font = {bold: true, color : {argb : 'FFFFFFFF'}};
            }
          });
        //list of vehicles over x miles and x hours
        //Observation sheet //04/14/2022 new requirement after convertion

        //Driver Observation
        //uncomment this section when Kevin wants the driver in dsc
        //Kevin wanted the driver info in the Driver score card 11132023
        
        //list of drivers over x miles and x hours 3 months back from end Date
        //overwrite the vehicle headerRow for Driver 
        headerRow = headerRow + Math.max(thressMosXmilesLst.length, thressMosXHoursLst.length) + 10;

        //miles
        observationSheet.mergeCells(`${observationSheet.getColumn(1).letter + (headerRow -2)} : ${observationSheet.getColumn(bassColumns + maxMonths).letter + (headerRow -2)}`);
        observationSheet.getRow(headerRow -2).getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
        observationSheet.getRow(headerRow -2).getCell(1).value = 'Excessive Milage';
        observationSheet.getRow(headerRow -2).getCell(1).font = {bold : true, color : {argb : 'FFFFFFFF'}, size: 12 };
        observationSheet.getRow(headerRow -2).getCell(1).fill = {type: 'pattern', pattern:'solid', fgColor : {argb: 'FF2a2e39' }}
        
        observationSheet.mergeCells(`${observationSheet.getColumn(1).letter + (headerRow -1)} : ${observationSheet.getColumn(bassColumns + maxMonths).letter + (headerRow -1)}`);
        observationSheet.getRow(headerRow -1).getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
        observationSheet.getRow(headerRow -1).getCell(1).value = `Drivers driven more than ${maxMiless} miles in the month`;
        observationSheet.getRow(headerRow -1).getCell(1).font = {bold : true, color : {argb : 'FFFFFFFF'}, size: 11, italic: true };
        observationSheet.getRow(headerRow -1).getCell(1).fill = {type: 'pattern', pattern:'solid', fgColor : {argb: 'FF2a2e39' }}
        
        observationSheet.getRow(headerRow).getCell(1).value = 'Driver Name';
        observationSheet.mergeCells(`${observationSheet.getColumn(bassColumns - 1).letter + headerRow} : ${observationSheet.getColumn(bassColumns).letter + headerRow}`);
        //observationSheet.getRow(headerRow).getCell(2).value = 'VIN';
        //observationSheet.getRow(headerRow).getCell(2).value = 'Vehicle Type';

        observationSheet.getColumn(1).width = 21;
        //observationSheet.getColumn(2).width = 21;
        //observationSheet.getColumn(3).width = 18;

        //hours
        observationSheet.mergeCells(`${observationSheet.getColumn(bassColumns + maxMonths +  gapSection + 1).letter + (headerRow -2)} : ${observationSheet.getColumn(bassColumns + maxMonths +  gapSection + bassColumns + maxMonths).letter + (headerRow -2)}`);
        observationSheet.getRow(headerRow -2).getCell(bassColumns + maxMonths +  gapSection + 1).alignment = { vertical: 'middle', horizontal: 'center' };
        observationSheet.getRow(headerRow -2).getCell(bassColumns + maxMonths +  gapSection + 1).value = 'Average Shift Length';
        observationSheet.getRow(headerRow -2).getCell(bassColumns + maxMonths +  gapSection + 1).font = {bold : true, color : {argb : 'FFFFFFFF'}, size: 12 };
        observationSheet.getRow(headerRow -2).getCell(bassColumns + maxMonths +  gapSection + 1).fill = {type: 'pattern', pattern:'solid', fgColor : {argb: 'FF2a2e39' }}

        observationSheet.mergeCells(`${observationSheet.getColumn(bassColumns + maxMonths +  gapSection + 1).letter + (headerRow -1)} : ${observationSheet.getColumn(bassColumns + maxMonths +  gapSection + bassColumns + maxMonths).letter + (headerRow -1)}`);
        observationSheet.getRow(headerRow -1).getCell(bassColumns + maxMonths +  gapSection + 1).alignment = { vertical: 'middle', horizontal: 'center' };
        observationSheet.getRow(headerRow -1).getCell(bassColumns + maxMonths +  gapSection + 1).value = `Drivers with Av. Shift length over ${maxHours} hours in the month`;
        observationSheet.getRow(headerRow -1).getCell(bassColumns + maxMonths +  gapSection + 1).font = {bold : true, color : {argb : 'FFFFFFFF'}, size: 11, italic: true };
        observationSheet.getRow(headerRow -1).getCell(bassColumns + maxMonths +  gapSection + 1).fill = {type: 'pattern', pattern:'solid', fgColor : {argb: 'FF2a2e39' }}

        
        observationSheet.getRow(headerRow).getCell(bassColumns + maxMonths +  gapSection + 1).value = 'Driver Name';
        observationSheet.mergeCells(`${observationSheet.getColumn(bassColumns + maxMonths +  gapSection + 1).letter + headerRow} : ${observationSheet.getColumn(bassColumns + maxMonths + gapSection + 2).letter + headerRow}`);
        
        //observationSheet.getRow(headerRow).getCell(bassColumns + maxMonths +  gapSection + 2).value = 'Vehicle Type';

        observationSheet.getColumn(bassColumns + maxMonths +  gapSection + 1).width = 21;
        //observationSheet.getColumn(bassColumns + maxMonths +  gapSection + 2).width = 21;
        //observationSheet.getColumn(bassColumns + maxMonths +  gapSection + 3).width = 18;

        let lenDriver = this.driverScoreCardData.monthlyDriverUtilizationLst.length;
        let driverThreeMosLst = [];
        if(lenDriver < maxMonths)
        { driverThreeMosLst = this.driverScoreCardData.monthlyDriverUtilizationLst;}
        else {
          for(let i = (lenDriver - maxMonths); i < len; i++)
          {
            driverThreeMosLst.push(this.driverScoreCardData.monthlyDriverUtilizationLst[i]);
          }
        }

        //some clients may not have the driver data from tsp, put the note here and also populate headers
        if(driverThreeMosLst.length === 0)
        {
          //header of month for max miles
          observationSheet.getRow(headerRow).getCell(3).value = 'Total Miles';//uncoment when the multi month is wanted item[0].yearMonth.toString().substring(4) + '-' + item[0].yearMonth.toString().substring(0, 4) + ' (Total Miles)';
          observationSheet.getColumn(3).width = 11;

          //default value when no driver data from tsp
          observationSheet.getRow(headerRow + 1).height = 45;
          observationSheet.getRow(headerRow + 1).getCell(1).alignment = {wrapText : true, vertical: 'top'};
          observationSheet.getRow(headerRow + 1).getCell(1).value = 'No speeding by driver data is available for the time period that was selected due to your telematic service provider does not provide driver data or your driver information is not in your telematic service provider system.';
          observationSheet.mergeCells(`${observationSheet.getColumn(bassColumns - 1).letter + (headerRow +1)} : ${observationSheet.getColumn(bassColumns + 1).letter + (headerRow +1)}`);
          
  
          //header of month for max hours
          observationSheet.getRow(headerRow).getCell(bassColumns + maxMonths + gapSection + 3).value = 'Av.Shift';//uncoment when the multi month is wanteditem[0].yearMonth.toString().substring(4) + '-' + item[0].yearMonth.toString().substring(0, 4) + ' (Av.Shift)';
          observationSheet.getColumn(bassColumns + maxMonths + gapSection + 3).width = 11;

          //default value when no driver data from tsp
          //observationSheet.getRow(headerRow + 1).height = 15;
          observationSheet.getRow(headerRow + 1).getCell(bassColumns + maxMonths +  gapSection + 1).alignment = {wrapText : true, vertical: 'top'};
          observationSheet.getRow(headerRow + 1).getCell(bassColumns + maxMonths +  gapSection + 1).value = 'No speeding by driver data is available for the time period that was selected due to your telematic service provider does not provide driver data or your driver information is not in your telematic service provider system.';
          observationSheet.mergeCells(`${observationSheet.getColumn(bassColumns + maxMonths +  gapSection + 1).letter + (headerRow +1)} : ${observationSheet.getColumn(bassColumns + 1 + maxMonths + gapSection + 2).letter + (headerRow +1)}`);
        }

        let driverThreeMosXmilesLst = [];
        let driverThreeMosXHoursLst = [];
        driverThreeMosLst.forEach((item, i) => {
          //header of month for max miles
          observationSheet.getRow(headerRow).getCell(3 + i).value = 'Total Miles';//uncoment when the multi month is wanted item[0].yearMonth.toString().substring(4) + '-' + item[0].yearMonth.toString().substring(0, 4) + ' (Total Miles)';
          observationSheet.getColumn(3 + i).width = 11;
          
  
          //header of month for max hours
          observationSheet.getRow(headerRow).getCell(bassColumns + maxMonths + gapSection + 3 + i).value = 'Av.Shift';//uncoment when the multi month is wanteditem[0].yearMonth.toString().substring(4) + '-' + item[0].yearMonth.toString().substring(0, 4) + ' (Av.Shift)';
          observationSheet.getColumn(bassColumns + maxMonths + gapSection + 3 + i).width = 11;

          //row data
          let tempMaxMiles = item.filter(f => f.totalMileage > maxMiless);
          tempMaxMiles.forEach(item => {
            driverThreeMosXmilesLst.push({ driverId : item.driverId, driverName: item.driverName, yearMonth : item.yearMonth, totalMileage: item.totalMileage})
          })

          let tempMaxHours = item.filter(f => f.averageOfSum_NetOnTimeHr > maxHours);
          tempMaxHours.forEach(item => {
            driverThreeMosXHoursLst.push({ driverId : item.driverId, driverName: item.driverName, yearMonth : item.yearMonth, aveShift: item.averageOfSum_NetOnTimeHr})
          })
        })
        //miles
        driverThreeMosXmilesLst = _.toArray(_.toArray(driverThreeMosXmilesLst).groupBy("driverId"));
        driverThreeMosXmilesLst.forEach((driver, index) => {
          driver.sort((a,b) => {return a.yearMonth - b.yearMonth}).forEach((item, i : number) => {

            observationSheet.mergeCells(`${observationSheet.getColumn(bassColumns - 1).letter + (headerRow + 1 + index)} : ${observationSheet.getColumn(bassColumns).letter + (headerRow + 1 + index)}`);
            observationSheet.getRow(headerRow + 1 + index).getCell(1).value = item.driverName

            observationSheet.getRow(headerRow + 1 + index).getCell(3 + i).value = item.totalMileage.toFixed(2);
          });
        })
        //hours
        driverThreeMosXHoursLst = _.toArray(_.toArray(driverThreeMosXHoursLst).groupBy("driverId"));
        driverThreeMosXHoursLst.forEach((driver, index) => {
          driver.sort((a, b) => {return a.yearMonth - b.yearMonth}).forEach((item, i : number) => {

            observationSheet.mergeCells(`${observationSheet.getColumn(bassColumns + maxMonths +  gapSection + 1).letter + (headerRow + 1 + index)} : ${observationSheet.getColumn(bassColumns + maxMonths + gapSection + 2).letter + (headerRow + 1 + index)}`);
            observationSheet.getRow(headerRow + 1 + index).getCell(bassColumns + maxMonths + gapSection + 1).value = item.driverName

            observationSheet.getRow(headerRow + 1 + index).getCell(bassColumns + maxMonths + gapSection + 3 + i).value = item.aveShift.toFixed(2);
          });
        })
        observationSheet.mergeCells(headerRow + 1 + driverThreeMosXHoursLst.length + 1, bassColumns + maxMonths + gapSection + 1, headerRow + 2 + driverThreeMosXHoursLst.length + 1, bassColumns + maxMonths + gapSection + 3);
        observationSheet.getRow(headerRow + 1 + driverThreeMosXHoursLst.length + 1).getCell(bassColumns + maxMonths + gapSection + 1).alignment = {wrapText : true};
        observationSheet.getRow(headerRow + 1 + driverThreeMosXHoursLst.length + 1).getCell(bassColumns + maxMonths + gapSection + 1).value =
        {
          'richText' : [
            {'font': {bold: true},'text': 'Average Shift Length '},
            {'font': {bold: false},'text': '- It is the total on time (driving and idling time) divided by the days operational.'}
          ]
        }

        //style the header
        observationSheet.getRow(headerRow)
          .eachCell({inculdeEMpty : false}, (cell, colNumber: number) => {
            if(cell?.value)
            {
              cell.fill =
              {
                type: 'pattern',
                pattern:'solid',
                fgColor:{argb:'FF0B67FF'},
                bgColor:{argb:'FF0B67FF'}
              };
              
  
              cell.alignment = {wrapText : true};
              cell.style.font = {bold: true, color : {argb : 'FFFFFFFF'}};
            }
          });
        //list of driver over x miles and x hours
 
          //Kevin wanted the driver info in the Driver score card 11132023
         //uncomment this section when Kevin wants the driver in dsc
        //Driver Observation






        //Vehicle Speeding Percentage sheet //04/14/2022 new requirement after convertion
        const speedingSheetLst = [];
        const program = this.dashboardCompanyProgram;
        this.calculatePercentile(this.driverScoreCardData.lastMoSpeedingLst).sort((a, b) => {// sort decending by totoal speeding
          let aa = a.sum_TotalMovingHr >0 ? (a.sum_Speeding_Under_5mph_ByTime + a.sum_Speeding_5_10mph_ByTime + a.sum_Speeding_10_15mph_ByTime + a.sum_Speeding_Over_15mph_ByTime) / 3600 / a.sum_TotalMovingHr : 0;
          let bb =  b.sum_TotalMovingHr >0 ? (b.sum_Speeding_Under_5mph_ByTime + b.sum_Speeding_5_10mph_ByTime + b.sum_Speeding_10_15mph_ByTime + b.sum_Speeding_Over_15mph_ByTime) / 3600 / b.sum_TotalMovingHr : 0;

          return bb - aa;
        }).forEach((item) => {
          speedingSheetLst.push({
          //"Vehicle ID" : item.vehicleId.toString(),
          //"VIN" : item.vin,
          'VIN/Vehicle ID': item.vin && item.vin !== 'N/A' ? item.vin : '/' + item.vehicleId.toString(),
          "Vehicle Type" : item.vehicleType,
          "Under 5" : item.sum_TotalMovingHr == 0 ? 0 : (item.sum_Speeding_Under_5mph_ByTime / 3600 * 100 / item.sum_TotalMovingHr).toFixed(1) + "%",
          //["Under 5 " + program + "(PR)"]: item.speeding_Under_5mph_ByTime_Percentile.toFixed(1) + "PR",
          //"Under 5 All Drivers(PR)": item.speeding_Under_5mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
    
          "5 To 10" : item.sum_TotalMovingHr == 0 ? 0 : (item.sum_Speeding_5_10mph_ByTime / 3600 * 100 / item.sum_TotalMovingHr).toFixed(1) + "%",
          //["5 To 10 " + program + "(PR)"] : item.speeding_5_10mph_ByTime_Percentile.toFixed(1) + "PR",
          //"5 To 10 All Drivers(PR)" : item.speeding_5_10mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
    
          "10 To 15" : item.sum_TotalMovingHr == 0 ? 0 : (item.sum_Speeding_10_15mph_ByTime /3600 * 100 / item.sum_TotalMovingHr).toFixed(1) + "%",
          //["10 To 15 " + program + "(PR)"]: item.speeding_10_15mph_ByTime_Percentile.toFixed(1) + "PR",
          //"10 To 15 All Drivers(PR)": item.speeding_10_15mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
    
          "Over 15" : item.sum_TotalMovingHr == 0 ? 0 : (item.sum_Speeding_Over_15mph_ByTime /3600 * 100 / item.sum_TotalMovingHr).toFixed(1) + "%", 
          //["Over 15 " + program + "(PR)"]: item.speeding_Over_15mph_ByTime_Percentile.toFixed(1) + "PR",
          //"Over 15 All Drivers(PR)": item.speeding_Over_15mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
    
          "Total Speeding": (item.sum_TotalMovingHr == 0 ? 0 : 
              (item.sum_Speeding_Under_5mph_ByTime + item.sum_Speeding_5_10mph_ByTime + item.sum_Speeding_10_15mph_ByTime + item.sum_Speeding_Over_15mph_ByTime)/ 3600 *100 / item.sum_TotalMovingHr).toFixed(1) + "%",
          //["Total Speeding " + program + "(PR)"]: item.total_Speeding_ByTime_Percentile.toFixed(1) + "PR",
          //"Total Speeding All Drivers(PR)": item.total_Speeding_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
    
          "Total Mileage": item.sum_TotalMiles.toFixed(1),
          });
        });
    
        let speedingSheet = workbook.getWorksheet('Vehicle Speeding Percentage');
        speedingSheet.getRow(3).getCell(1).value = this.dashboardCompanyName;

        /*
        speedingSheet.getRow(5).values= ['VIN/Vehicle ID', 'Vehicle Type', 'Under 5', `Under 5 ${program}(PR)`, 'Under 5 All Drivers(PR)', 
        '5 To 10', `5 To 10 ${program}(PR)`, '5 To 10 All Drivers(PR)', '10 To 15', `10 To 15 ${program}(PR)`, '10 To 15 All Drivers(PR)',
        'Over 15', `Over 15 ${program}(PR)`, 'Over 15 All Drivers(PR)', 'Total Speeding', `Total Speeding ${program}(PR)`, 'Total Speeding All Drivers(PR)',
        'Total Mileage'
        ]*/
        speedingSheet.getRow(5).values= ['VIN/Vehicle ID', 'Vehicle Type', 'Under 5', '5 To 10', '10 To 15', 'Over 15', 'Total Speeding', 'Total Mileage' ];
        
        speedingSheet.getRow(5).height = 32;
       
        speedingSheet.getRow(5).eachCell({inculdeEMpty : true}, (cell, colNumber: number) => {
          if(cell?.value)
          {
            cell.fill =
            {
              type: 'pattern',
              pattern:'solid',
              fgColor:{argb:'FF0B67FF'},
              bgColor:{argb:'FF0B67FF'}
            };

            cell.border = {
              top: {style:'thin'},
              left: {style:'thin'},
              bottom: {style:'thin'},
              right: {style:'thin'}
            };

            cell.alignment = {wrapText : true},
            cell.style.font = {bold: true, color : {argb : 'FFFFFFFF'}}
          }
        });

        speedingSheet.columns = [
          //{ key: 'Vehicle ID', width: 10 },
          {  key: 'VIN/Vehicle ID'},//, width: 22 },
          {  key: 'Vehicle Type'},//, width: 18},
          {  key: 'Under 5'},//, width: 10},
          //{  key: `Under 5 ${program}(PR)`},//, width: 15},
          //{  key: 'Under 5 All Drivers(PR)'},//, width: 22}, 

          { key: '5 To 10'},//, width: 10},
          //{ key: `5 To 10 ${program}(PR)`},//, width: 15},
          //{ key: '5 To 10 All Drivers(PR)'},//, width: 22},

          { key: '10 To 15' },//, width: 10},
          //{ key: `10 To 15 ${program}(PR)` },//, width: 15},
          //{ key: '10 To 15 All Drivers(PR)'},//, width: 22},

          { key: 'Over 15'},//, width: 10},
          //{ key: `Over 15 ${program}(PR)` },//, width: 15},
          //{ key: 'Over 15 All Drivers(PR)'},//, width: 22},

          { key: 'Total Speeding' },//, width: 18},
          //{ key: `Total Speeding ${program}(PR)` },//, width: 22},
          //{ key: 'Total Speeding All Drivers(PR)' },//, width: 28},

          { key: 'Total Mileage'}] //, width: 15}]

        speedingSheetLst.forEach(item => {
          const newRow = speedingSheet.addRow(item);
          
          newRow.eachCell({inculdeEMpty : true}, (cell, colNumber: number) => {
            if(cell?.value)
            {
              cell.border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
              };

              if(speedingSheet.getColumn(colNumber).key === 'VIN/Vehicle ID' || speedingSheet.getColumn(colNumber).key ==='Vehicle Type')
              {speedingSheet.getColumn(colNumber).width = 21; }
              else
              {speedingSheet.getColumn(colNumber).width = 12; }
            }
          });
        });
        //VehicleSpeeding sheet

        //Vehicle Speeding  Percentile sheet //04/14/2022 new requirement after convertion to make original speeding sheet narrow 
        const speedingPRSheetLst = [];
        //const program = this.dashboardCompanyProgram;
        this.driverScoreCardData.lastMoSpeedingLst.sort((a, b) => {// sort decending by totoal speeding PR
          return b.total_Speeding_ByTime_Percentile - a.total_Speeding_ByTime_Percentile;
        }).forEach((item) => {
          speedingPRSheetLst.push({
          //"Vehicle ID" : item.vehicleId.toString(),
          //"VIN" : item.vin,
          'VIN/Vehicle ID': item.vin && item.vin !== 'N/A' ? item.vin : '/' + item.vehicleId.toString(),
          "Vehicle Type" : item.vehicleType,
          
          ["Under 5 " + program + "(PR)"]: item.speeding_Under_5mph_ByTime_Percentile.toFixed(1) + "PR",
          "Under 5 All Drivers(PR)": item.speeding_Under_5mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
    
          
          ["5 To 10 " + program + "(PR)"] : item.speeding_5_10mph_ByTime_Percentile.toFixed(1) + "PR",
          "5 To 10 All Drivers(PR)" : item.speeding_5_10mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
    
          
          ["10 To 15 " + program + "(PR)"]: item.speeding_10_15mph_ByTime_Percentile.toFixed(1) + "PR",
          "10 To 15 All Drivers(PR)": item.speeding_10_15mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
    
          ["Over 15 " + program + "(PR)"]: item.speeding_Over_15mph_ByTime_Percentile.toFixed(1) + "PR",
          "Over 15 All Drivers(PR)": item.speeding_Over_15mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
    
          
          ["Total Speeding " + program + "(PR)"]: item.total_Speeding_ByTime_Percentile.toFixed(1) + "PR",
          "Total Speeding All Drivers(PR)": item.total_Speeding_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
    
          "Total Mileage": item.sum_TotalMiles.toFixed(1),
          });
        });
    
        let speedingPRSheet = workbook.getWorksheet('Vehicle Speeding PR. Ranking');
        speedingPRSheet.getRow(3).getCell(1).value = this.dashboardCompanyName;


        speedingPRSheet.getRow(13).values= ['VIN/Vehicle ID', 'Vehicle Type', `Under 5 ${program}(PR)`, 'Under 5 All Drivers(PR)', 
        `5 To 10 ${program}(PR)`, '5 To 10 All Drivers(PR)', `10 To 15 ${program}(PR)`, '10 To 15 All Drivers(PR)',
        `Over 15 ${program}(PR)`, 'Over 15 All Drivers(PR)', `Total Speeding ${program}(PR)`, 'Total Speeding All Drivers(PR)',
        'Total Mileage'
        ]
        speedingPRSheet.getRow(13).height = 60;
       
        speedingPRSheet.getRow(13).eachCell({inculdeEMpty : true}, (cell, colNumber: number) => {
          if(cell?.value)
          {
            cell.fill =
            {
              type: 'pattern',
              pattern:'solid',
              fgColor:{argb:'FF0B67FF'},
              bgColor:{argb:'FF0B67FF'}
            };

            cell.border = {
              top: {style:'thin'},
              left: {style:'thin'},
              bottom: {style:'thin'},
              right: {style:'thin'}
            };

            cell.alignment = {wrapText : true};
            cell.style.font = {bold: true, color : {argb : 'FFFFFFFF'}};
          }
        });

        speedingPRSheet.columns = [
          //{ key: 'Vehicle ID', width: 10 },
          {  key: 'VIN/Vehicle ID'},//, width: 22 },
          {  key: 'Vehicle Type'},//, width: 18},
          
          {  key: `Under 5 ${program}(PR)`},//, width: 15},
          {  key: 'Under 5 All Drivers(PR)'},//, width: 22}, 

          
          { key: `5 To 10 ${program}(PR)`},//, width: 15},
          { key: '5 To 10 All Drivers(PR)'},//, width: 22},

          
          { key: `10 To 15 ${program}(PR)` },//, width: 15},
          { key: '10 To 15 All Drivers(PR)'},//, width: 22},

          
          { key: `Over 15 ${program}(PR)` },//, width: 15},
          { key: 'Over 15 All Drivers(PR)'},//, width: 22},

          
          { key: `Total Speeding ${program}(PR)` },//, width: 22},
          { key: 'Total Speeding All Drivers(PR)' },//, width: 28},

          { key: 'Total Mileage'}] //, width: 15}]

        speedingPRSheetLst.forEach(item => {
          const newRow = speedingPRSheet.addRow(item);
          
          newRow.eachCell({inculdeEMpty : true}, (cell, colNumber: number) => {
            if(cell?.value)
            {
              cell.border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
              };

              if(speedingPRSheet.getColumn(colNumber).key === 'VIN/Vehicle ID' || speedingPRSheet.getColumn(colNumber).key ==='Vehicle Type')
              {speedingPRSheet.getColumn(colNumber).width = 21; }
              else
              {speedingPRSheet.getColumn(colNumber).width = 11; }
            }
          });
        });
        //VehicleSpeeding percentile sheet


        //Driver speeding sheet
        
        const driverSpeedingSheetLst = [];
        
        this.driverScoreCardData.lastMoDriverSpeedingLst.sort((a, b) => {// sort decending by totoal speeding
          let aa = a.sum_TotalMovingHr >0 ? (a.sum_Speeding_Under_5mph_ByTime + a.sum_Speeding_5_10mph_ByTime + a.sum_Speeding_10_15mph_ByTime + a.sum_Speeding_Over_15mph_ByTime) / 3600 / a.sum_TotalMovingHr : 0;
          let bb =  b.sum_TotalMovingHr >0 ? (b.sum_Speeding_Under_5mph_ByTime + b.sum_Speeding_5_10mph_ByTime + b.sum_Speeding_10_15mph_ByTime + b.sum_Speeding_Over_15mph_ByTime) / 3600 / b.sum_TotalMovingHr : 0;

          return bb - aa;
        }).forEach((item) => {
          driverSpeedingSheetLst.push({
          
          "Driver Name" : item.driverName,
          "Under 5" : item.sum_TotalMovingHr == 0 ? 0 : (item.sum_Speeding_Under_5mph_ByTime / 3600 * 100 / item.sum_TotalMovingHr).toFixed(1) + "%",
          "5 To 10" : item.sum_TotalMovingHr == 0 ? 0 : (item.sum_Speeding_5_10mph_ByTime / 3600 * 100 / item.sum_TotalMovingHr).toFixed(1) + "%",
          "10 To 15" : item.sum_TotalMovingHr == 0 ? 0 : (item.sum_Speeding_10_15mph_ByTime /3600 * 100 / item.sum_TotalMovingHr).toFixed(1) + "%",
          "Over 15" : item.sum_TotalMovingHr == 0 ? 0 : (item.sum_Speeding_Over_15mph_ByTime /3600 * 100 / item.sum_TotalMovingHr).toFixed(1) + "%", 
          "Total Speeding": (item.sum_TotalMovingHr == 0 ? 0 : 
              (item.sum_Speeding_Under_5mph_ByTime + item.sum_Speeding_5_10mph_ByTime + item.sum_Speeding_10_15mph_ByTime + item.sum_Speeding_Over_15mph_ByTime)/ 3600 *100 / item.sum_TotalMovingHr).toFixed(1) + "%",
    
          "Total Mileage": item.sum_TotalMiles.toFixed(1),
          });
        });
    
        let driverSpeedingSheet = workbook.getWorksheet('Driver Speeding Percentage');
        driverSpeedingSheet.getRow(3).getCell(1).value = this.dashboardCompanyName;
       
        driverSpeedingSheet.getRow(5).values= ['Driver Name', 'Under 5', '5 To 10', '10 To 15', 'Over 15', 'Total Speeding', 'Total Mileage' ];
        
        driverSpeedingSheet.getRow(5).height = 32;
       
        driverSpeedingSheet.getRow(5).eachCell({inculdeEMpty : true}, (cell, colNumber: number) => {
          if(cell?.value)
          {
            cell.fill =
            {
              type: 'pattern',
              pattern:'solid',
              fgColor:{argb:'FF0B67FF'},
              bgColor:{argb:'FF0B67FF'}
            };

            cell.border = {
              top: {style:'thin'},
              left: {style:'thin'},
              bottom: {style:'thin'},
              right: {style:'thin'}
            };

            cell.alignment = {wrapText : true};
            cell.style.font = {bold: true, color : {argb : 'FFFFFFFF'}};
          }
        });

        driverSpeedingSheet.columns = [
          //{ key: 'Vehicle ID', width: 10 },
          //{  key: 'VIN/Vehicle ID'},//, width: 22 },
          {  key: 'Driver Name', width: 22},
          {  key: 'Under 5', width: 12},
          { key: '5 To 10', width: 12},
          { key: '10 To 15' , width: 12},
          { key: 'Over 15', width: 12},
          { key: 'Total Speeding' , width: 12},
          { key: 'Total Mileage', width: 12}
        ]

        if(driverSpeedingSheetLst.length === 0)
        {
          driverSpeedingSheet.getRow(6).getCell(1).alignment = {wrapText : true};
          driverSpeedingSheet.mergeCells(6, 1, 7, 6);
          driverSpeedingSheet.getRow(6).getCell(1).value = 
          "No speeding by driver data is available for the time period that was selected due to your telematic service provider does not provide driver data or your driver information is not in your telematic service provider system.";
        }

        driverSpeedingSheetLst.forEach(item => {
          const newRow = driverSpeedingSheet.addRow(item);
          
          newRow.eachCell({inculdeEMpty : true}, (cell, colNumber: number) => {
            if(cell?.value)
            {
              cell.border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
              };

              if(driverSpeedingSheet.getColumn(colNumber).key === 'Driver Name')
              { driverSpeedingSheet.getColumn(colNumber).width = 30; }
              else
              { driverSpeedingSheet.getColumn(colNumber).width = 12; }
            }
          });
        });
        //Driver speeding sheet

        //Driver speeding percentile sheet

        const driverSpeedingPRSheetLst = [];
        
        this.calculateDriverPercentile(this.driverScoreCardData.lastMoDriverSpeedingLst).sort((a, b) => {// sort decending by totoal speeding PR
          return b.total_Speeding_ByTime_Percentile - a.total_Speeding_ByTime_Percentile;
        }).forEach((item) => {
          driverSpeedingPRSheetLst.push({
          //"Vehicle ID" : item.vehicleId.toString(),
          //"VIN" : item.vin,
          //'VIN/Vehicle ID': item.vin && item.vin !== 'N/A' ? item.vin : '/' + item.vehicleId.toString(),
          "Driver Name" : item.driverName,
          
          ["Under 5 " + program + "(PR)"]: item.speeding_Under_5mph_ByTime_Percentile.toFixed(1) + "PR",
          "Under 5 All Drivers(PR)": item.speeding_Under_5mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
    
          
          ["5 To 10 " + program + "(PR)"] : item.speeding_5_10mph_ByTime_Percentile.toFixed(1) + "PR",
          "5 To 10 All Drivers(PR)" : item.speeding_5_10mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
    
          
          ["10 To 15 " + program + "(PR)"]: item.speeding_10_15mph_ByTime_Percentile.toFixed(1) + "PR",
          "10 To 15 All Drivers(PR)": item.speeding_10_15mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
    
          ["Over 15 " + program + "(PR)"]: item.speeding_Over_15mph_ByTime_Percentile.toFixed(1) + "PR",
          "Over 15 All Drivers(PR)": item.speeding_Over_15mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
    
          
          ["Total Speeding " + program + "(PR)"]: item.total_Speeding_ByTime_Percentile.toFixed(1) + "PR",
          "Total Speeding All Drivers(PR)": item.total_Speeding_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
    
          "Total Mileage": item.sum_TotalMiles.toFixed(1),
          });
        });
    
        let driverSpeedingPRSheet = workbook.getWorksheet('Driver Speeding PR. Ranking');
        driverSpeedingPRSheet.getRow(3).getCell(1).value = this.dashboardCompanyName;


        driverSpeedingPRSheet.getRow(5).values= ['Driver Name', `Under 5 ${program}(PR)`, 'Under 5 All Drivers(PR)', 
        `5 To 10 ${program}(PR)`, '5 To 10 All Drivers(PR)', `10 To 15 ${program}(PR)`, '10 To 15 All Drivers(PR)',
        `Over 15 ${program}(PR)`, 'Over 15 All Drivers(PR)', `Total Speeding ${program}(PR)`, 'Total Speeding All Drivers(PR)',
        'Total Mileage'
        ]
        driverSpeedingPRSheet.getRow(5).height = 60;
       
        driverSpeedingPRSheet.getRow(5).eachCell({inculdeEMpty : true}, (cell, colNumber: number) => {
          if(cell?.value)
          {
            cell.fill =
            {
              type: 'pattern',
              pattern:'solid',
              fgColor:{argb:'FF0B67FF'},
              bgColor:{argb:'FF0B67FF'}
            };

            cell.border = {
              top: {style:'thin'},
              left: {style:'thin'},
              bottom: {style:'thin'},
              right: {style:'thin'}
            };

            cell.alignment = {wrapText : true};
            cell.style.font = {bold: true, color : {argb : 'FFFFFFFF'}};
          }
        });

        driverSpeedingPRSheet.columns = [
          //{ key: 'Vehicle ID', width: 10 },
          //{  key: 'VIN/Vehicle ID'},//, width: 22 },
          {  key: 'Driver Name' , width: 22},
          
          {  key: `Under 5 ${program}(PR)`, width: 11},
          {  key: 'Under 5 All Drivers(PR)', width: 11}, 
          
          { key: `5 To 10 ${program}(PR)`, width: 11},
          { key: '5 To 10 All Drivers(PR)', width: 11},
          
          { key: `10 To 15 ${program}(PR)` , width: 11},
          { key: '10 To 15 All Drivers(PR)', width: 11},
          
          { key: `Over 15 ${program}(PR)` , width: 11},
          { key: 'Over 15 All Drivers(PR)', width: 11},
          
          { key: `Total Speeding ${program}(PR)` , width: 11},
          { key: 'Total Speeding All Drivers(PR)' , width: 11},

          { key: 'Total Mileage', width: 11}
        ];

        if(driverSpeedingPRSheetLst.length === 0)
        {
          driverSpeedingPRSheet.getRow(6).getCell(1).alignment = {wrapText : true};
          driverSpeedingPRSheet.mergeCells(6, 1, 7, 6);
          driverSpeedingPRSheet.getRow(6).getCell(1).value = 
          "No speeding by driver data is available for the time period that was selected due to your telematic service provider does not provide driver data or your driver information is not in your telematic service provider system.";
        }

        driverSpeedingPRSheetLst.forEach(item => {
          const newRow = driverSpeedingPRSheet.addRow(item);
          
          newRow.eachCell({inculdeEMpty : true}, (cell, colNumber: number) => {
            if(cell?.value)
            {
              cell.border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
              };

              if(driverSpeedingPRSheet.getColumn(colNumber).key === 'Driver Name')
              {driverSpeedingPRSheet.getColumn(colNumber).width = 30; }
              else
              {driverSpeedingPRSheet.getColumn(colNumber).width = 11; }
            }
          });
        });
        //Driver speeding percentile sheet


        // Harshevent 
        //Vehicle harshevent  sheet //05/30/2023 
        const harsheventSheetLst = [];
        
        this.convertHarsheventData(this.driverScoreCardData.lastMoHarsheventLst, false).sort((a, b) => {// sort decending by totoal miles

          return b.sum_TotalMiles - a.sum_TotalMiles;
        }).forEach((item) => {
          harsheventSheetLst.push({
          //"Vehicle ID" : item.vehicleId.toString(),
          //"VIN" : item.vin,
          'VIN/Vehicle ID': item.vin && item.vin !== 'N/A' ? item.vin : '/' + item.vehicleId.toString(),
          "Vehicle Type" : item.vehicleType,
          "Brakes" : item.sum_Harsh_Brake.toString(),

          "Brakes(Per 1K)" : item.sum_TotalMiles == 0 ? 0 : (item.sum_Harsh_Brake  * 1000 / item.sum_TotalMiles).toFixed(2),
          
          "Corner" : item.sum_Harsh_Corner.toString(),

          "Corner(Per 1K)" : item.sum_TotalMiles == 0 ? 0 : (item.sum_Harsh_Corner  * 1000 / item.sum_TotalMiles).toFixed(2),

          "Accel." : item.sum_Harsh_Quick_Accelaration.toString(),

          "Accel.(Per 1K)" : item.sum_TotalMiles == 0 ? 0 : (item.sum_Harsh_Quick_Accelaration  * 1000 / item.sum_TotalMiles).toFixed(2),
    
         
    
          "Total Mileage": item.sum_TotalMiles.toFixed(1),
          });
        });
    
        let harsheventSheet = workbook.getWorksheet('Vehicle Harsh Events');
        harsheventSheet.getRow(3).getCell(1).value = this.dashboardCompanyName;

        /*
        speedingSheet.getRow(5).values= ['VIN/Vehicle ID', 'Vehicle Type', 'Under 5', `Under 5 ${program}(PR)`, 'Under 5 All Drivers(PR)', 
        '5 To 10', `5 To 10 ${program}(PR)`, '5 To 10 All Drivers(PR)', '10 To 15', `10 To 15 ${program}(PR)`, '10 To 15 All Drivers(PR)',
        'Over 15', `Over 15 ${program}(PR)`, 'Over 15 All Drivers(PR)', 'Total Speeding', `Total Speeding ${program}(PR)`, 'Total Speeding All Drivers(PR)',
        'Total Mileage'
        ]*/
        
        //harsheventSheet.mergeCells('A5:I5')
        harsheventSheet.getRow(5).getCell(1).value = "Harsh Events data only displays if we are able to obtain this data from your telematics service provider.";

        harsheventSheet.getRow(7).values= ['VIN/Vehicle ID', 'Vehicle Type', 'Brakes', 'Brakes(Per 1K)', 'Corner','Corner(Per 1K)','Accel.','Accel.(Per 1K)' , 'Total Mileage' ];
        
        harsheventSheet.getRow(7).height = 32;
       
        harsheventSheet.getRow(7).eachCell({inculdeEMpty : true}, (cell, colNumber: number) => {
          if(cell?.value)
          {
            cell.fill =
            {
              type: 'pattern',
              pattern:'solid',
              fgColor:{argb:'FF0B67FF'},
              bgColor:{argb:'FF0B67FF'}
            };

            cell.border = {
              top: {style:'thin'},
              left: {style:'thin'},
              bottom: {style:'thin'},
              right: {style:'thin'}
            };

            cell.alignment = {wrapText : true},
            cell.style.font = {bold: true, color : {argb : 'FFFFFFFF'}}
          }
        });

        harsheventSheet.columns = [
          //{ key: 'Vehicle ID', width: 10 },
          {  key: 'VIN/Vehicle ID'},//, width: 22 },
          {  key: 'Vehicle Type'},//, width: 18},
          
          {  key: 'Brakes'},//, width: 10},

          { key: 'Brakes(Per 1K)'},//, width: 10},
          
          { key: 'Corner' },//, width: 10},

          { key: 'Corner(Per 1K)'},//, width: 10},
         

          { key: 'Accel.' },//, width: 18},
          { key: 'Accel.(Per 1K)' },//, width: 18},
       

          { key: 'Total Mileage'}] //, width: 15}]

          harsheventSheetLst.forEach(item => {
          const newRow = harsheventSheet.addRow(item);
          
          newRow.eachCell({inculdeEMpty : true}, (cell, colNumber: number) => {
            if(cell)
            {
              cell.border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
              };

              if(harsheventSheet.getColumn(colNumber).key === 'VIN/Vehicle ID' || harsheventSheet.getColumn(colNumber).key ==='Vehicle Type')
              {harsheventSheet.getColumn(colNumber).width = 21; }
              else
              {harsheventSheet.getColumn(colNumber).width = 14; }
            }
          });
        });
        // Harshevent 

        //Harshevent Percentile
        //Vehicle harshevent Percentile sheet //05/30/2023 
        const harsheventPercentileSheetLst = [];
        
        this.convertHarsheventData(this.driverScoreCardData.lastMoHarsheventLst, false).sort((a, b) => {// sort decending by totoal miles

          return b.sum_TotalMiles - a.sum_TotalMiles;
        }).forEach((item) => {
          harsheventPercentileSheetLst.push({
          //"Vehicle ID" : item.vehicleId.toString(),
          //"VIN" : item.vin,
          'VIN/Vehicle ID': item.vin && item.vin !== 'N/A' ? item.vin : '/' + item.vehicleId.toString(),
          "Vehicle Type" : item.vehicleType,
          
          ["Brakes(Per 1K) " + program +"(PR)"] : item.brake_Percentile.toFixed(1),

          "Brakes(Per 1K) All Vehicles(PR)" : item.brake_Percentile_AllProgm.toFixed(1),
          
          ["Corner(Per 1K) " + program +"(PR)"] : item.corner_Percentile.toFixed(1),

          "Corner(Per 1K) All Vehicles(PR)" : item.corner_Percentile_AllProgm.toFixed(1),

          ["Accel.(Per 1K) " + program +"(PR)"] : item.accel_Percentile.toFixed(1),

          "Accel.(Per 1K) All Vehicles(PR)" : item.accel_Percentile_AllProgm.toFixed(1),
    
          "Total Mileage": item.sum_TotalMiles.toFixed(1),
          });
        });
    
        let harsheventPRSheet = workbook.getWorksheet('Vehicle Harsh Event PR. Ranking');
        harsheventPRSheet.getRow(3).getCell(1).value = this.dashboardCompanyName;

        /*
        speedingSheet.getRow(5).values= ['VIN/Vehicle ID', 'Vehicle Type', 'Under 5', `Under 5 ${program}(PR)`, 'Under 5 All Drivers(PR)', 
        '5 To 10', `5 To 10 ${program}(PR)`, '5 To 10 All Drivers(PR)', '10 To 15', `10 To 15 ${program}(PR)`, '10 To 15 All Drivers(PR)',
        'Over 15', `Over 15 ${program}(PR)`, 'Over 15 All Drivers(PR)', 'Total Speeding', `Total Speeding ${program}(PR)`, 'Total Speeding All Drivers(PR)',
        'Total Mileage'
        ]*/
        harsheventPRSheet.getRow(5).values= ['VIN/Vehicle ID', 'Vehicle Type', `Brakes(Per 1K) ${program}(PR)`, 'Brakes(Per 1K) All Vehicles(PR)', 
        `Corner(Per 1K) ${program}(PR)`,'Corner(Per 1K) All Vehicles(PR)',`Accel.(Per 1K) ${program}(PR)`,'Accel.(Per 1K) All Vehicles(PR)' , 'Total Mileage' ];
        
        harsheventPRSheet.getRow(5).height = 32;
       
        harsheventPRSheet.getRow(5).eachCell({inculdeEMpty : true}, (cell, colNumber: number) => {
          if(cell?.value)
          {
            cell.fill =
            {
              type: 'pattern',
              pattern:'solid',
              fgColor:{argb:'FF0B67FF'},
              bgColor:{argb:'FF0B67FF'}
            };

            cell.border = {
              top: {style:'thin'},
              left: {style:'thin'},
              bottom: {style:'thin'},
              right: {style:'thin'}
            };

            cell.alignment = {wrapText : true},
            cell.style.font = {bold: true, color : {argb : 'FFFFFFFF'}}
          }
        });

        harsheventPRSheet.columns = [
          //{ key: 'Vehicle ID', width: 10 },
          {  key: 'VIN/Vehicle ID'},//, width: 22 },
          {  key: 'Vehicle Type'},//, width: 18},
          
          {  key: `Brakes(Per 1K) ${program}(PR)`},//, width: 10},

          { key: 'Brakes(Per 1K) All Vehicles(PR)'},//, width: 10},
          
          {  key: `Corner(Per 1K) ${program}(PR)`},//, width: 10},

          { key: 'Corner(Per 1K) All Vehicles(PR)'},//, width: 10},
         

          {  key: `Accel.(Per 1K) ${program}(PR)`},//, width: 10},

          { key: 'Accel.(Per 1K) All Vehicles(PR)'},//, width: 10},
       

          { key: 'Total Mileage'}] //, width: 15}]

          harsheventPercentileSheetLst.forEach(item => {
          const newRow = harsheventPRSheet.addRow(item);
          
          newRow.eachCell({inculdeEMpty : true}, (cell, colNumber: number) => {
            if(cell)
            {
              cell.border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
              };

              if(harsheventPRSheet.getColumn(colNumber).key === 'VIN/Vehicle ID' || harsheventPRSheet.getColumn(colNumber).key ==='Vehicle Type')
              {harsheventPRSheet.getColumn(colNumber).width = 21; }
              else
              {harsheventPRSheet.getColumn(colNumber).width = 15; }
            }
          });
        });
        //Harshevent Percentile


        // driver Harshevent 
        const driverHarsheventSheetLst = [];
        
        this.convertHarsheventData(this.driverScoreCardData.lastMoDriverHarsheventLst, true).sort((a, b) => {// sort decending by totoal miles

          return b.sum_TotalMiles - a.sum_TotalMiles;
        }).forEach((item) => {
          driverHarsheventSheetLst.push({
          
          "Driver Name" : item.driverName,
          "Brakes" : item.sum_Harsh_Brake.toString(),

          "Brakes(Per 1K)" : item.sum_TotalMiles == 0 ? 0 : (item.sum_Harsh_Brake  * 1000 / item.sum_TotalMiles).toFixed(2),
          
          "Corner" : item.sum_Harsh_Corner.toString(),

          "Corner(Per 1K)" : item.sum_TotalMiles == 0 ? 0 : (item.sum_Harsh_Corner  * 1000 / item.sum_TotalMiles).toFixed(2),

          "Accel." : item.sum_Harsh_Quick_Accelaration.toString(),

          "Accel.(Per 1K)" : item.sum_TotalMiles == 0 ? 0 : (item.sum_Harsh_Quick_Accelaration  * 1000 / item.sum_TotalMiles).toFixed(2),
    
          "Total Mileage": item.sum_TotalMiles.toFixed(1),
          });
        });
    
        let driverHarsheventSheet = workbook.getWorksheet('Driver Harsh Events');
        driverHarsheventSheet.getRow(3).getCell(1).value = this.dashboardCompanyName;

        /*
        speedingSheet.getRow(5).values= ['VIN/Vehicle ID', 'Vehicle Type', 'Under 5', `Under 5 ${program}(PR)`, 'Under 5 All Drivers(PR)', 
        '5 To 10', `5 To 10 ${program}(PR)`, '5 To 10 All Drivers(PR)', '10 To 15', `10 To 15 ${program}(PR)`, '10 To 15 All Drivers(PR)',
        'Over 15', `Over 15 ${program}(PR)`, 'Over 15 All Drivers(PR)', 'Total Speeding', `Total Speeding ${program}(PR)`, 'Total Speeding All Drivers(PR)',
        'Total Mileage'
        ]*/
        driverHarsheventSheet.getRow(5).values= ['Driver Name', 'Brakes', 'Brakes(Per 1K)', 'Corner','Corner(Per 1K)','Accel.','Accel.(Per 1K)' , 'Total Mileage' ];
        
        driverHarsheventSheet.getRow(5).height = 32;
       
        driverHarsheventSheet.getRow(5).eachCell({inculdeEMpty : true}, (cell, colNumber: number) => {
          if(cell?.value)
          {
            cell.fill =
            {
              type: 'pattern',
              pattern:'solid',
              fgColor:{argb:'FF0B67FF'},
              bgColor:{argb:'FF0B67FF'}
            };

            cell.border = {
              top: {style:'thin'},
              left: {style:'thin'},
              bottom: {style:'thin'},
              right: {style:'thin'}
            };

            cell.alignment = {wrapText : true},
            cell.style.font = {bold: true, color : {argb : 'FFFFFFFF'}}
          }
        });

        driverHarsheventSheet.columns = [
          //{ key: 'Vehicle ID', width: 10 },

          {  key: 'Driver Name'},//, width: 18},
          
          {  key: 'Brakes'},//, width: 10},

          { key: 'Brakes(Per 1K)'},//, width: 10},
          
          { key: 'Corner' },//, width: 10},

          { key: 'Corner(Per 1K)'},//, width: 10},
         

          { key: 'Accel.' },//, width: 18},
          { key: 'Accel.(Per 1K)' },//, width: 18},
       

          { key: 'Total Mileage'}] //, width: 15}]

          driverHarsheventSheetLst.forEach(item => {
          const newRow = driverHarsheventSheet.addRow(item);
          
          newRow.eachCell({inculdeEMpty : true}, (cell, colNumber: number) => {
            if(cell)
            {
              cell.border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
              };

              if(driverHarsheventSheet.getColumn(colNumber).key === 'Driver Name')
              {driverHarsheventSheet.getColumn(colNumber).width = 21; }
              else
              {driverHarsheventSheet.getColumn(colNumber).width = 15; }
            }
          });
        });
        // driver Harshevent 

        //driver Harshevent PR
        const driverHarsheventPercentileSheetLst = [];
        
        this.convertHarsheventData(this.driverScoreCardData.lastMoDriverHarsheventLst, true).sort((a, b) => {// sort decending by totoal miles

          return b.sum_TotalMiles - a.sum_TotalMiles;
        }).forEach((item) => {
          driverHarsheventPercentileSheetLst.push({
          "Driver Name": item.driverName,
          ["Brakes(Per 1K) " + program +"(PR)"] : item.brake_Percentile.toFixed(1),

          "Brakes(Per 1K) All Vehicles(PR)" : item.brake_Percentile_AllProgm.toFixed(1),
          
          ["Corner(Per 1K) " + program +"(PR)"] : item.corner_Percentile.toFixed(1),

          "Corner(Per 1K) All Vehicles(PR)" : item.corner_Percentile_AllProgm.toFixed(1),

          ["Accel.(Per 1K) " + program +"(PR)"] : item.accel_Percentile.toFixed(1),

          "Accel.(Per 1K) All Vehicles(PR)" : item.accel_Percentile_AllProgm.toFixed(1),
    
          "Total Mileage": item.sum_TotalMiles.toFixed(1),
          });
        });
    
        let driverHarsheventPRSheet = workbook.getWorksheet('Driver Harsh Event PR. Ranking');
        driverHarsheventPRSheet.getRow(3).getCell(1).value = this.dashboardCompanyName;

        /*
        speedingSheet.getRow(5).values= ['VIN/Vehicle ID', 'Vehicle Type', 'Under 5', `Under 5 ${program}(PR)`, 'Under 5 All Drivers(PR)', 
        '5 To 10', `5 To 10 ${program}(PR)`, '5 To 10 All Drivers(PR)', '10 To 15', `10 To 15 ${program}(PR)`, '10 To 15 All Drivers(PR)',
        'Over 15', `Over 15 ${program}(PR)`, 'Over 15 All Drivers(PR)', 'Total Speeding', `Total Speeding ${program}(PR)`, 'Total Speeding All Drivers(PR)',
        'Total Mileage'
        ]*/
        driverHarsheventPRSheet.getRow(5).values= ['Driver Name', `Brakes(Per 1K) ${program}(PR)`, 'Brakes(Per 1K) All Vehicles(PR)', 
        `Corner(Per 1K) ${program}(PR)`,'Corner(Per 1K) All Vehicles(PR)',`Accel.(Per 1K) ${program}(PR)`,'Accel.(Per 1K) All Vehicles(PR)' , 'Total Mileage' ];
        
        driverHarsheventPRSheet.getRow(5).height = 32;
       
        driverHarsheventPRSheet.getRow(5).eachCell({inculdeEMpty : true}, (cell, colNumber: number) => {
          if(cell?.value)
          {
            cell.fill =
            {
              type: 'pattern',
              pattern:'solid',
              fgColor:{argb:'FF0B67FF'},
              bgColor:{argb:'FF0B67FF'}
            };

            cell.border = {
              top: {style:'thin'},
              left: {style:'thin'},
              bottom: {style:'thin'},
              right: {style:'thin'}
            };

            cell.alignment = {wrapText : true},
            cell.style.font = {bold: true, color : {argb : 'FFFFFFFF'}}
          }
        });

        driverHarsheventPRSheet.columns = [
          
          {  key: 'Driver Name'},//, width: 18},
          
          {  key: `Brakes(Per 1K) ${program}(PR)`},//, width: 10},

          { key: 'Brakes(Per 1K) All Vehicles(PR)'},//, width: 10},
          
          {  key: `Corner(Per 1K) ${program}(PR)`},//, width: 10},

          { key: 'Corner(Per 1K) All Vehicles(PR)'},//, width: 10},

          {  key: `Accel.(Per 1K) ${program}(PR)`},//, width: 10},

          { key: 'Accel.(Per 1K) All Vehicles(PR)'},//, width: 10},
       

          { key: 'Total Mileage'}] //, width: 15}]

          driverHarsheventPercentileSheetLst.forEach(item => {
          const newRow = driverHarsheventPRSheet.addRow(item);
          
          newRow.eachCell({inculdeEMpty : true}, (cell, colNumber: number) => {
            if(cell)
            {
              cell.border = {
                top: {style:'thin'},
                left: {style:'thin'},
                bottom: {style:'thin'},
                right: {style:'thin'}
              };

              if(driverHarsheventPRSheet.getColumn(colNumber).key === 'Driver Name')
              {driverHarsheventPRSheet.getColumn(colNumber).width = 21; }
              else
              {driverHarsheventPRSheet.getColumn(colNumber).width = 15; }
            }
          });
        });

        //driver Harshevent PR


        //
        //04/03 temporary remove the driver related sheets since just releasing the driver as requested by Kevin
        //remove these code when deciding to show driver sheets in score card excel report to insureds
      //  workbook.removeWorksheet(driverSpeedingSheet.id);
       // workbook.removeWorksheet(driverSpeedingPRSheet.id);
        
        //workbook.removeWorksheet(driverHarsheventPRSheet.id);
        //workbook.removeWorksheet(driverHarsheventSheet.id);
        // do not foget to bring back driver in Other Ovservation sheet
        //04/03 temporary remove the driver related sheets since just releasing the driver as requested by Kevin

        workbook.removeWorksheet(observationSheet.id); //06/27/2023  Kevin requested to remove Observation sheet
        
        workbook.xlsx.writeBuffer({ base64: true}).then((data) => {
            
          let blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
          saveAs(blob, 'TE Driver Score Card_' + this.dashboardCompanyName + ' ' + this.dashboardDate.start + '--' + this.dashboardDate.end + '.xlsx');
          });
          //deferred.resolve();
          ob.next('finished');
        });
      });
    });
  }

  //dsc report only needs the last month speeding data, so create this here to not use the multi month average speeding in the speeding dashboard
  calculatePercentile(vehicleLst)
  {
    vehicleLst.forEach((item) => {
      if(item.sum_TotalMovingHr > 0)
      {
        item.speeding_Under_5mph_ByTime_Percentile = 100 * (item.rankUnder_5Perc - 0.5) / item.numOfVehInProgram;
        item.speeding_5_10mph_ByTime_Percentile = 100 * (item.rank5_10Perc - 0.5) / item.numOfVehInProgram;
        item.speeding_10_15mph_ByTime_Percentile = 100 * (item.rank10_15Perc - 0.5) / item.numOfVehInProgram;
        item.speeding_Over_15mph_ByTime_Percentile = 100 * (item.rankOver_15Perc - 0.5) / item.numOfVehInProgram;
        item.total_Speeding_ByTime_Percentile = 100 * (item.rankTotalSpeedingPerc - 0.5) / item.numOfVehInProgram;

        item.speeding_Under_5mph_ByTime_Percentile_AllProgm = 100 * (item.rankUnder_5AllProgramPerc - 0.5) / item.numOfVehInAllProgram;
        item.speeding_5_10mph_ByTime_Percentile_AllProgm = 100 * (item.rank5_10AllProgramPerc - 0.5) / item.numOfVehInAllProgram;
        item.speeding_10_15mph_ByTime_Percentile_AllProgm = 100 * (item.rank10_15AllProgramPerc - 0.5) / item.numOfVehInAllProgram;
        item.speeding_Over_15mph_ByTime_Percentile_AllProgm = 100 * (item.rankOver_15AllProgramPerc - 0.5) / item.numOfVehInAllProgram;
        item.total_Speeding_ByTime_Percentile_AllProgm = 100 * (item.rankTotalSpeedingAllProgramPerc - 0.5) / item.numOfVehInAllProgram;
      }
      else
      {
        item.speeding_Under_5mph_ByTime_Percentile = 0;

        item.speeding_5_10mph_ByTime_Percentile = 0;
        item.speeding_10_15mph_ByTime_Percentile = 0;
        item.speeding_Over_15mph_ByTime_Percentile = 0;
        item.total_Speeding_ByTime_Percentile = 0;

        item.speeding_Under_5mph_ByTime_Percentile_AllProgm = 0;
        item.speeding_5_10mph_ByTime_Percentile_AllProgm = 0;
        item.speeding_10_15mph_ByTime_Percentile_AllProgm = 0;
        item.speeding_Over_15mph_ByTime_Percentile_AllProgm = 0;
        item.total_Speeding_ByTime_Percentile_AllProgm = 0;
      }
    });
    return vehicleLst;
  }

  //Driver
  calculateDriverPercentile(driverLst)
  {
    driverLst.forEach((item) => {
      if(item.sum_TotalMovingHr > 0)
      {
        item.speeding_Under_5mph_ByTime_Percentile = 100 * (item.driverRankUnder_5Perc - 0.5) / item.numOfDriverInProgram;
        item.speeding_5_10mph_ByTime_Percentile = 100 * (item.driverRank5_10Perc - 0.5) / item.numOfDriverInProgram;
        item.speeding_10_15mph_ByTime_Percentile = 100 * (item.driverRank10_15Perc - 0.5) / item.numOfDriverInProgram;
        item.speeding_Over_15mph_ByTime_Percentile = 100 * (item.driverRankOver_15Perc - 0.5) / item.numOfDriverInProgram;
        item.total_Speeding_ByTime_Percentile = 100 * (item.driverRankTotalSpeedingPerc - 0.5) / item.numOfDriverInProgram;

        item.speeding_Under_5mph_ByTime_Percentile_AllProgm = 100 * (item.driverRankUnder_5AllProgramPerc - 0.5) / item.numOfDriverInAllProgram;
        item.speeding_5_10mph_ByTime_Percentile_AllProgm = 100 * (item.driverRank5_10AllProgramPerc - 0.5) / item.numOfDriverInAllProgram;
        item.speeding_10_15mph_ByTime_Percentile_AllProgm = 100 * (item.driverRank10_15AllProgramPerc - 0.5) / item.numOfDriverInAllProgram;
        item.speeding_Over_15mph_ByTime_Percentile_AllProgm = 100 * (item.driverRankOver_15AllProgramPerc - 0.5) / item.numOfDriverInAllProgram;
        item.total_Speeding_ByTime_Percentile_AllProgm = 100 * (item.driverRankTotalSpeedingAllProgramPerc - 0.5) / item.numOfDriverInAllProgram;
      }
      else
      {
        item.speeding_Under_5mph_ByTime_Percentile = 0;

        item.speeding_5_10mph_ByTime_Percentile = 0;
        item.speeding_10_15mph_ByTime_Percentile = 0;
        item.speeding_Over_15mph_ByTime_Percentile = 0;
        item.total_Speeding_ByTime_Percentile = 0;

        item.speeding_Under_5mph_ByTime_Percentile_AllProgm = 0;
        item.speeding_5_10mph_ByTime_Percentile_AllProgm = 0;
        item.speeding_10_15mph_ByTime_Percentile_AllProgm = 0;
        item.speeding_Over_15mph_ByTime_Percentile_AllProgm = 0;
        item.total_Speeding_ByTime_Percentile_AllProgm = 0;
      }
    });
    return driverLst;
  }
  //Driver

  calculateHarsheventPercentile(harsheventLst, isDriver : boolean)
  {
    harsheventLst.forEach((item) => {
      if(item.sum_TotalMiles > 0)
      {
        item.brake_Percentile = 100 * (item.rankBrakePerc - 0.5) / (isDriver ? item.numOfDriverInProgram : item.numOfVehInProgram);
        item.accel_Percentile = 100 * (item.rankAccelPerc - 0.5) / (isDriver ? item.numOfDriverInProgram : item.numOfVehInProgram);
        item.corner_Percentile = 100 * (item.rankCornerPerc - 0.5) / (isDriver ? item.numOfDriverInProgram : item.numOfVehInProgram);
        

        item.brake_Percentile_AllProgm = 100 * (item.rankBrakeAllProgramPerc - 0.5) / (isDriver ? item.numOfDriverInAllProgram : item.numOfVehInAllProgram);
        item.accel_Percentile_AllProgm = 100 * (item.rankAccelAllProgramPerc - 0.5) / (isDriver ? item.numOfDriverInAllProgram : item.numOfVehInAllProgram);
        item.corner_Percentile_AllProgm = 100 * (item.rankCornerAllProgramPerc - 0.5) / (isDriver ? item.numOfDriverInAllProgram : item.numOfVehInAllProgram);
        
      }
      else
      {
        item.brake_Percentile = 0;
        item.accel_Percentile = 0;
        item.corner_Percentile = 0;
        

        item.brake_Percentile_AllProgm = 0;
        item.accel_Percentile_AllProgm = 0;
        item.corner_Percentile_AllProgm = 0;
        
      }
    });
    return harsheventLst;
  }

  convertHarsheventData (harshevents , isDriver : boolean)
  {

    let harsheventLst = this.calculateHarsheventPercentile(harshevents, isDriver);

    let grouped = [];
    if(isDriver)
    {
       grouped = _.toArray(_.groupBy(harsheventLst, g => g.driverId));
    }
    else{
       grouped = _.toArray( _.groupBy(harsheventLst, g => g.vehicleId));

    }
    

    let harshEventDataSum =[];

    grouped.forEach((item) => {

      let sumHarshBrake = 0;
      let sumHarshCorner = 0;
      let sumHarshQAccel = 0;

      item.forEach((element) =>{
        sumHarshBrake += element.sum_Harsh_Brake;
        sumHarshCorner += element.sum_Harsh_Corner;
        sumHarshQAccel += element.sum_Harsh_Quick_Accelaration;
      });

      if(!isDriver)
      {
        harshEventDataSum.push(
          {
            vehicleId: item[0].vehicleId, 
            vehicleNumber: item[0].vehicleNumber,
            vehicleType: item[0].vehicleType,
            vin: item[0].vin,


            mcId: item[0].mcId,
            months: item[0].months,
            sum_Harsh_Brake: sumHarshBrake,
            sum_Harsh_Corner: sumHarshCorner,
            sum_Harsh_Quick_Accelaration: sumHarshQAccel,
            sum_TotalMiles : item[0].sum_TotalMiles,
            yearMonthFrom : item[0].yearMonthFrom,
            yearMonthTo : item[0].yearMonthTo,
            

            brake_Percentile : item[0].brake_Percentile,
            accel_Percentile : item[0].accel_Percentile,
            corner_Percentile : item[0].corner_Percentile,

            brake_Percentile_AllProgm : item[0].brake_Percentile_AllProgm,
            accel_Percentile_AllProgm : item[0].accel_Percentile_AllProgm,
            corner_Percentile_AllProgm : item[0].corner_Percentile_AllProgm,

            numOfVehInProgram : item[0].numOfVehInProgram,
            numOfVehInAllProgram : item[0].numOfVehInAllProgram
          });
        }
        else {  
          harshEventDataSum.push(
            {
              driverId: item[0].driverId, 
              driverName: item[0].driverName, 
             
              mcId: item[0].mcId,
              months: item[0].months,
              sum_Harsh_Brake: sumHarshBrake,
              sum_Harsh_Corner: sumHarshCorner,
              sum_Harsh_Quick_Accelaration: sumHarshQAccel,
              sum_TotalMiles : item[0].sum_TotalMiles,
              yearMonthFrom : item[0].yearMonthFrom,
              yearMonthTo : item[0].yearMonthTo,
            
    
              brake_Percentile : item[0].brake_Percentile,
              accel_Percentile : item[0].accel_Percentile,
              corner_Percentile : item[0].corner_Percentile,
    
              brake_Percentile_AllProgm : item[0].brake_Percentile_AllProgm,
              accel_Percentile_AllProgm : item[0].accel_Percentile_AllProgm,
              corner_Percentile_AllProgm : item[0].corner_Percentile_AllProgm,
    
              numOfDriverInProgram : item[0].numOfDriverInProgram,
              numOfDriverInAllProgram : item[0].numOfDriverInAllProgram
            });
        }
    });

    return harshEventDataSum;
  }



  ngOnInit(): void {
    this.setDSCardTrendingChartOptions();
    this.setDSCardComparisonChartOptions();

    this.DSCardCompanyProgramDr = this.dashboardCompanyProgram;
  }

  ngOnChanges() : void {
    this.generateDriverScoreCardTable();

    //this.DSCardDataToExport = true;
  }
  ngOnDestroy() {
    //unsubscribe
    this.subNotifier.next();
    this.subNotifier.complete();
  }

}




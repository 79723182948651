import { Component, OnInit , Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {  DataService } from '../../Services/data.service';
import {  CommonAppService } from '../../Services/common-app.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-users-modal',
  templateUrl: './users-modal.component.html',
  styleUrls: ['./users-modal.component.css']
})
export class UsersModalComponent implements OnInit {

  private authData = JSON.parse(localStorage.getItem('authorizationData'));
  userObj: any = this.modalData || {};
  /*userObj: any = this.modalData ||  {
    Name: '',
    JobTitle: '',
    email: '',
    Phone: '',
    StreetAddress1: '',
    StreetAddress2: '',
    City: '',
    St: '',
    ZipCode: ''
  };*/
  enableDelete : boolean = true;


  constructor(public dialogRef: MatDialogRef<UsersModalComponent>,
    @Inject(MAT_DIALOG_DATA) private modalData: any,
    private dataService : DataService,
    private commonAppService: CommonAppService)
  { }

  
  close()
  {
    this.dialogRef.close();
  }

  delete()
  {
    if(!this.enableDelete){
      let message = 'Cannot self-delete';
      this.commonAppService._snackBar(false, message);
      return false;
    }
    else{
      this.dataService._disableUser(this.userObj.UserId).subscribe(result => {
        let message = 'User deleted';
        this.commonAppService._snackBar(true, message);
        this.dialogRef.close(message);
        this.userObj = {};
      });                    
    }       
  }

  ok()
  {
    // userFactory.updateSubUser($scope.userObj);
    this.dataService._updateUser(this.userObj).subscribe((result) => {
      let message = 'User updated';
      this.dialogRef.close(message);
      this.commonAppService._snackBar(true, message, 3000);
      this.userObj = {};
    }, (err) => {
      let message = '';
      if(err == '400')
      {
        message = 'Email address already exists';
      }
      this.commonAppService._snackBar(false, message, 3000);
    });

  }

  add()
  {
    this.dataService._newUser(this.userObj).subscribe((result) => {
                   
      let message = 'User added';
      this.dialogRef.close(message);
      this.commonAppService._snackBar(true, message, 3000);
      this.userObj = {};
    }, (err : HttpErrorResponse) => {
      let message = '';
      if(err) {//(err == '400'){
        message = 'Email address already exists';
      }                    
      this.commonAppService._snackBar(false, message, 3000);
    });

  }

  ngOnInit(): void {
    //this.userObj = this.modalData;
    //not self delete
    if((this.userObj) && (this.userObj.UserId)){
      this.enableDelete = !(this.authData.userID === this.userObj.UserId);                
    }

    //this.userObj = this.modalData || {};
  }

}

<div class="row">
    <div class="col-md-12">
        <div class="dashhead">
            <div class="dashhead-titles">
                <h6 class="dashhead-subtitle">Drilldown</h6>
                <h2 class="dashhead-title">{{ groupInfo.CompanyName }}</h2>
                <h5 class="dashhead-title goBack" routerLink ='/mcList' ><i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i> Go Back</h5>
            </div>
        </div>
    </div>
</div>
<!-- <div class="row">
    <div class="col-md-12">
        <img src="../assets/img/idash.jpg" class="img-thumbnail" alt="iDashboard Example">
    </div>
</div> -->
<div class="row" *ngIf="loaded">
    <div class="col-md-12">
        <div class="panel panel-info">
            <div class="panel-heading"></div>
            <div class="panel-body" *ngIf="validGroup">
                <div class="row">
                    <div class="col-md-12 text-right" *ngIf="groupType == 1002">
                        <button class="btn btn-danger"  *ngIf="smMCDD.DeActivateBtn" [disabled] = "smMCDD.DeActivateBtn && !groupInfo.Enabled" (click)="deActivate()">{{ !groupInfo.Enabled ? 'DeActivated' : 'DeActivate' }} </button>
                    </div>

                </div>
                <div class="row">
                    <div class="form-group col-md-12">
                        <h3 [ngClass]="smMCDD.DeActivateBtn ? 'jumboTitleNoPaddingTop' : 'jumboTitle'">Company Info</h3>
                        <div class="col-md-3">
                            <div class="form-group label-floating">
                                <label for="i4" class="control-label displaying">Company Name</label>
                                <!-- <input type="text" class="form-control" ng-model="groupInfo.CompanyName" disabled> -->
                                <h4>{{ groupInfo.CompanyName || '--' }}</h4>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group label-floating">
                                <label for="i4" class="control-label displaying">Point of Contact Name</label>
                                <!-- <input type="text" class="form-control" ng-model="adminInfo.Name" disabled> -->
                                <h4>{{ adminInfo?.Name || '--' }}</h4>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group label-floating">
                                <label for="i4" class="control-label displaying">Point of Contact Email</label>
                                <!-- <input type="text" class="form-control" ng-model="adminInfo.email" disabled> -->
                                <h4>{{ adminInfo?.email || '--' }}</h4>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group label-floating">
                                <label for="i4" class="control-label displaying">Point of Contact Phone</label>
                                <!-- <input type="text" class="form-control" ng-model="adminInfo.Phone" disabled> -->
                                <h4>{{ adminInfo?.Phone || '--' | phone }}</h4>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <h3 class="jumboTitle">Address</h3>
                        <div class="col-md-12">
                            <div class="form-group label-floating">
                                <label for="i4" class="control-label displaying">Address</label>
                                <!-- <input type="text" class="form-control" ng-model="groupInfo.StreetAddress1" disabled> -->
                                <h4>{{ groupInfo.StreetAddress1 || '--' }}</h4>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group label-floating">
                                <label for="i4" class="control-label displaying">Address Line 2</label>
                                <!-- <input type="text" class="form-control" ng-model="groupInfo.compAddress2" disabled> -->
                                <h4>{{ groupInfo.compAddress2 || '--' }}</h4>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group label-floating">
                                <label for="i4" class="control-label displaying">City</label>
                                <!-- <input type="text" class="form-control" ng-model="groupInfo.City" disabled> -->
                                <h4>{{ groupInfo.City || '--' }}</h4>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group label-floating">
                                <label class="control-label displaying">State</label>
                                <!-- select class="form-control" ng-model="groupInfo.State" disabled>
                                    <option ng-repeat="state in states" ng-value="state.abbreviation">{{ state.abbreviation }}</option>
                                </select> -->
                                <h4>{{ groupInfo.State || '--' }}</h4>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group label-floating">
                                <label for="i4" class="control-label displaying">Zip Code</label>
                                <!-- <input type="text" class="form-control" ng-model="groupInfo.Zipcode" disabled> -->
                                <h4>{{ groupInfo.Zipcode || '--' }}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <h3 class="jumboTitle">Consent History</h3>
                        <div class="table-responsive" >
                            <!-- use basic table instead of Material table for no mat syle -->
                            <table class="table table-hover table-condensed table-consent">
                                <thead>
                                    <tr>
                                        <th class="col-md-6 text-center">TSP Name</th>
                                        <th class="text-center">Date</th>
                                        <th class="text-center">Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let record of consentRecords; index as i" (click)='open(record)'>
                                        <td class="col-md-6 text-center">
                                            {{ record.TSPCompanyName }}
                                        </td>
                                        <td class="text-center">{{ record.ModifiedDate | date:"MMM dd, yyyy" }}</td>
                                        <td class="text-center">{{ record.TemplateType ==0  ? 'Revoke' : 'Consent'}}</td>
                                    </tr>
                                </tbody>
                              </table>
                            <!-- <table mat-table [dataSource] = "consentRecords" class="table table-hover table-condensed table-consent" matSort>

                                <ng-container matColumnDef="TSPCompanyName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        TSP Name
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="col-md-4"> {{element.TSPCompanyName}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="ModifiedDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Date
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="col-md-3"> {{element.ModifiedDate}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="TemplateType">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Type
                                    </th>
                                    <td mat-cell *matCellDef="let element" class="col-md-2">
                                        {{element.TemplateType == 0 ? 'Revoke' : 'Consent'}}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="consentRecordColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: consentRecordColumns;"></tr>
            
                            </table> -->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-right" *ngIf="groupType == 1002">
                        <button class="btn btn-info"  *ngIf="smMCDD.DashboardBtn && consentStatus" [routerLink]="['/dashboard' , drilldownMCID, 'Dashboard', groupInfo.CompanyName, groupInfo.InsuranceProgram]">View Dashboard</button>
                        <button class="btn btn-info" [routerLink]="['/dashboard', drilldownMCID, 'Report', groupInfo.CompanyName, groupInfo.InsuranceProgram]" *ngIf="smMCDD.ReportCardBtn && consentStatus">View Report Card</button>
                    </div>
                </div>
            </div>
            <div class="panel-body" *ngIf="!validGroup">
                <div class="col-md-12 text-center">
                    <h3>Invalid Group ID</h3>
                </div>
            </div>
        </div>
    </div>
</div>

const SERVICEBASE = {
   //serviceBase : 'http://devsandbox01.energi.com:8089/', //'http://localhost:20746/',
	//serviceBase : 'http://localhost:207469/',
	
	//serviceBase : 'https://api.telematicexchange.com/TelematicsAPI/',//demo api 
	//serviceBase : 'https://teleapi01.telematicexchange.com/TelematicsAPI/', //prod api
    //isTest : true // do not forget to change here at deployment
	//it is changed to use environment file instead. no need to switch the url and isTest. 
	//prod: ng build --prod, devsandbox: ng build (need to switch url in environment file) local : ng serve
}

const appConst = {
    role: {
		superUser: 100,
		admin: 200,
		user: 300		
	},
	groupType: {
		system: 1000,
		MC: 1001,
		ICC: 1002,
		TSP: 1003
	}
}

const hexColors = {
	hotspotPercentile: 0.85,
	hotspotColor: 'C732FF',
	percentileCutoff: 0.02,
	colorLst: [
		{
			index: 0, hexCode: '4EDD02'
	 	},
	 	{
			index: 1, hexCode: '71DE05'
	 	},
	 	{
			index: 2, hexCode: '95E007'
	 	},
	 	{
			index: 3, hexCode: 'B8E209'
	 	},
	 	{
			index: 4, hexCode: 'DBE40C'
	 	},
	 	{
			index: 5, hexCode: 'E5CD0E'
	 	},
	 	{
			index: 6, hexCode: 'E7AE10'
	 	},
	 	{
			index: 7, hexCode: 'E98F13'
	 	},
	 	{
			index: 8, hexCode: 'EB7015'
	 	},
	 	{
			index: 9, hexCode: 'ED5118'
	 	},
	 	{
			index: 10, hexCode: 'EE331A'
	 	},
	 	{
			index: 11, hexCode: 'F01D25'
	 	},
	 	{
			index: 12, hexCode: 'F21F48'
	 	},
	 	{
			index: 13, hexCode: 'F4226B'
	 	},
	 	{
			index: 14, hexCode: 'F6258D'
	 	},
	 	{
			index: 15, hexCode: 'F727AF'
	 	},
	 	{
			index: 16, hexCode: 'F92AD1'
	 	},
	 	{
			index: 17, hexCode: 'FB2DF3'
	 	},
	 	{
			index: 18, hexCode: 'E530FD'
	 	},
	 	{
			index: 19, hexCode: 'C732FF'
	 	},
	]
}

export {hexColors, appConst, SERVICEBASE}
import { Component, OnInit , ViewChild, ElementRef, AfterViewInit, HostListener, Renderer2, Input, OnChanges } from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';

import * as moment from 'moment';
import * as _ from 'underscore';
declare var $: any;// for convertion purpose using the jQuery here, which supposed not to in Angular 2x. will find workaround after convertion
declare let d3 : any;


@Component({
  selector: 'app-driver-speeding',
  templateUrl: './driver-speeding.component.html',
  styleUrls: ['./driver-speeding.component.css']
})
export class DriverSpeedingComponent implements OnInit {

  //Driver
  @Input() speedingData_Driver : any;
  @Input() speedingRoadTypeData_Driver : any;
  @Input() dashboardCompanyProgram : any;
  @Input() dashboardDate : any;

  //Speeding
  activeSpeedingDriver = null;
  driverSpeedingTblDataSource :any = [];
  driverSpeedingColumns : string[] = [ 'driverName', 'derived_Under_5', 'derived_5To10', 'derived_10To15', 'derived_Over_15', 'derived_Total_Speeding', 'sum_TotalMiles'];
  @ViewChild('sortDriverSpeeding') sortDriverSpeeding: MatSort;
  @ViewChild('paginatorDriverSpeeding') paginatorDriverSpeeding: MatPaginator;

  driverOrVeh : string = 'vehicleView';
  companyInsProgramDr: any ='';
  driverPercentile : number = 0;

  toggleSpeedingbtnClassLst : any;
  SpeedingProgmDropClassLst : any;

  driverSpeedingChartRoadTypeData = [{ key: "Under 5", values: [] }, { key: "5-10", values: [] }, { key: "10-15", values: [] }, { key: "Over 15", values: [] }];
  speedingRoadTypeStackedBarOptions : any;
  
  driverSpeedingChartData = [{ key: "Under 5", values: [] }, { key: "5-10", values: [] }, { key: "10-15", values: [] }, { key: "Over 15", values: [] }];
  speedingStackedBarOptions : any;

  driverSpeedingTrendChartData = [{ key: "Total Speeding", values: [] }];
  speedingTrendBarOptions : any;

  @ViewChild('driverSpeeding') driverSpeeding: MatTable<any>;


  constructor(private render : Renderer2) { }

  generateDriverSpeedingTable() {

    if(!this.speedingData_Driver) return;
   
    let driverSpeedingViewData = _.toArray(this.speedingData_Driver);
    
    this.driverSpeedingTblDataSource = new MatTableDataSource(driverSpeedingViewData);

    //sort on derived columns
    this.driverSpeedingTblDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'derived_Under_5':
          return (item.sum_TotalMovingHr == 0 ? 0 : item.sum_Speeding_Under_5mph_ByTime / 3600 * 100 / item.sum_TotalMovingHr);
        case 'derived_5To10':
          return (item.sum_TotalMovingHr == 0 ? 0 : item.sum_Speeding_5_10mph_ByTime / 3600 * 100 / item.sum_TotalMovingHr);

        case 'derived_10To15' :
          return (item.sum_TotalMovingHr == 0 ? 0 : item.sum_Speeding_10_15mph_ByTime / 3600 * 100 / item.sum_TotalMovingHr);
        
        case 'derived_Over_15':
          return (item.sum_TotalMovingHr == 0 ? 0 : item.sum_Speeding_Over_15mph_ByTime / 3600 * 100 / item.sum_TotalMovingHr);
        case 'derived_Total_Speeding':
          return item.sum_TotalMovingHr == 0 ? 0 : 
          (item.sum_Speeding_Under_5mph_ByTime + item.sum_Speeding_5_10mph_ByTime + item.sum_Speeding_10_15mph_ByTime + item.sum_Speeding_Over_15mph_ByTime) / 3600 *100 / item.sum_TotalMovingHr;
        
        default:
          return item[property];
      }
    };

    this.driverSpeedingTblDataSource.sort = this.sortDriverSpeeding;
    this.driverSpeedingTblDataSource.paginator = this.paginatorDriverSpeeding;
  }

  togglePercentileDriverTbl(percentile : number)
  {

    if(percentile == 0)
    {
      this.driverPercentile = 1;
      this.driverSpeedingColumns = 
        ['driverName', 'speeding_Under_5mph_ByTime_Percentile', 'speeding_5_10mph_ByTime_Percentile', 'speeding_10_15mph_ByTime_Percentile', 'speeding_Over_15mph_ByTime_Percentile', 'total_Speeding_ByTime_Percentile', 'sum_TotalMiles'];
      
      let element = document.getElementById("DriverPRToggleDv");
      element.classList.remove('col-md-12');
      element.classList.add('col-md-10');

      let elementDropdown = document.getElementById("DriverPRDropDownDv");
      elementDropdown.classList.add('col-md-2');

      let toggleElement = document.getElementById("driverSpeedingPRToggleBtn");
      toggleElement.style.marginRight = '0%';

      this.toggleSpeedingbtnClassLst = element.classList;
      this.SpeedingProgmDropClassLst = elementDropdown.classList;
    }
    else if(percentile == 1)
    {
      this.driverSpeedingColumns = 
        ['driverName', 'derived_Under_5', 'derived_5To10', 'derived_10To15', 'derived_Over_15', 'derived_Total_Speeding', 'sum_TotalMiles'];
      
      let element = document.getElementById("DriverPRToggleDv");
      element.classList.remove('col-md-10');
      element.classList.add('col-md-12');

      let elementDropdown = document.getElementById("DriverPRDropDownDv");
      elementDropdown.classList.remove('col-md-2');

      let toggleElement = document.getElementById("driverSpeedingPRToggleBtn");
      toggleElement.style.marginRight = '0%';
      this.driverPercentile = 0; 


      this.toggleSpeedingbtnClassLst = element.classList;
      this.SpeedingProgmDropClassLst = elementDropdown.classList;
    }
  }

  
  setDriverSpeedingRoadTypeStackedBarOptions()
  {
    this.speedingRoadTypeStackedBarOptions = 
    {
      chart: {
        type: 'multiBarHorizontalChart',
        height: 430, //470
        //width: 365,
        x: (d) => {return d.label;},
        y: (d) => {return d.value;},
        //yErr: function(d){ return [-Math.abs(d.value * Math.random() * 0.3), Math.abs(d.value * Math.random() * 0.3)] },
        showControls: false,
        showValues: true,
        duration: 500,
        xAxis: {
          showMaxMin: false,
          //axisLabel: "Road Type",
          //axisLabelDistance: -8,
        },
        yAxis: {
          axisLabel: '',
          tickFormat: (d) => {
              return d3.format(',.1f')(d)  + "%";
          }
        },
        margin: {
            top: 0,
            right: 18, //110
            bottom: 80,
            left:100
        },

        showYAxis: true,
        showXAxis: true,
        stacked: true,
        showLegend: false,
        //color: ['rgb(11,108,205)', 'rgb(13,139,197)', 'rgb(220,220,220)', 'rgb(244,20,228)'],
        color: ['rgb(11,108,205)', 'rgb(116,179,255)', 'rgb(220,220,220)', 'rgb(244,20,228)'], //04/27/2022 color change requested by LP
        forceY: [0,20],
        //yRange: [0,242]
        //yRange: [0,385]
      }
    }
  }
  
  setDriverSpeedingStackedBarOptions()
  {
    this.speedingStackedBarOptions = {
      chart: {
        type: 'multiBarHorizontalChart',
        height: 130, //150
        //width: 365,
        x: (d) => {return d.label;},
        y: (d) => {return d.value;},
        //yErr: function(d){ return [-Math.abs(d.value * Math.random() * 0.3), Math.abs(d.value * Math.random() * 0.3)] },
        showControls: false,
        showValues: true,
        duration: 500,
        xAxis: {
            showMaxMin: false
        },
        yAxis: {
          axisLabel: '',
          tickFormat: (d) =>{
            return d3.format(',.1f')(d) + "%";
          }
        },
        margin: {
          top: -1,
          right: 18,//110,
          bottom: 55,
          left:100
        },
        showYAxis: true,
        showXAxis: true,
        stacked: true,
        //color: ['rgb(11,108,205)', 'rgb(13,139,197)', 'rgb(220,220,220)', 'rgb(244,20,228)'],
        color: ['rgb(11,108,205)', 'rgb(116,179,255)', 'rgb(220,220,220)', 'rgb(244,20,228)'], //04/27/2022 color change requested by LP
        showLegend:true,
        forceY: [0, 15],
        //yRange: [0,385]
      }
    }
  }

  setDriverSpeedingTrendBarOptions()
  {
    this.speedingTrendBarOptions = {
      chart: {
        type: 'multiBarHorizontalChart',
        height: 130, //150
        //width: 365,
        x: (d) => {return d.label;},
        y: (d) => {return d.value;},
        //yErr: function(d){ return [-Math.abs(d.value * Math.random() * 0.3), Math.abs(d.value * Math.random() * 0.3)] },
        showControls: false,
        showValues: false,
        duration: 500,
        xAxis: {
            showMaxMin: false,
            //axisLabel: "Road Type",
            //axisLabelDistance: -8,
        },
        yAxis: {
            axisLabel: '',
            tickFormat: function(d){
              return d3.format(',.1f')(d)  + "%";
            }
        },
        margin: {
          top: 0,
          right: 18,//110,
          bottom: 50,
          left:100
        },

        showYAxis: true,
        showXAxis: true,
        stacked: false,
        showLegend: false,
        color: ['rgb(11,108,205)'],//['rgb(11,108,205)', 'rgb(13,139,197)', 'rgb(220,220,220)', 'rgb(244,20,228)'],
        forceY: [0,50],
        //yRange: [0,242]
        //yRange: [0,385]
      }
    }
  }

  setActiveDriver(driver, event)
  {
    let rows = Array.from(document.getElementById('driverSpeeding').getElementsByTagName('tr'));
    rows.forEach(item => {
      this.render.removeClass(item, 'selected');
    });

    this.render.addClass(event.currentTarget, 'selected');

    this.activeSpeedingDriver = driver;

    //traffic chart
    //establish the data object
    let mediumU5 = {'label': "Medium", 'value': driver.sum_TotalMovingHr == 0 ? 0 : driver.sum_MTrafficSpeeding_Under_5_ByTime / 3600 * 100 / driver.sum_TotalMovingHr};
    let heavyU5 = {'label': "Heavy", 'value': driver.sum_TotalMovingHr == 0 ? 0 : driver.sum_HTrafficSpeeding_Under_5_ByTime / 3600 * 100 / driver.sum_TotalMovingHr};
    let medium5_10 = {'label': "Medium", 'value': driver.sum_TotalMovingHr == 0 ? 0 : driver.sum_MTrafficSpeeding_5_10_ByTime /3600 * 100 / driver.sum_TotalMovingHr};
    let heavy5_10 = {'label': "Heavy", 'value': driver.sum_TotalMovingHr == 0 ? 0 : driver.sum_HTrafficSpeeding_5_10_ByTime /3600 * 100 / driver.sum_TotalMovingHr};
    let medium10_15 = {'label': "Medium", 'value': driver.sum_TotalMovingHr == 0 ? 0 : driver.sum_MTrafficSpeeding_10_15_ByTime /3600 * 100 / driver.sum_TotalMovingHr};
    let heavy10_15 = {'label': "Heavy", 'value': driver.sum_TotalMovingHr == 0 ? 0 : driver.sum_HTrafficSpeeding_10_15_ByTime /3600 * 100 / driver.sum_TotalMovingHr};
    let mediumO15 = {'label': "Medium", 'value': driver.sum_TotalMovingHr == 0 ? 0 : driver.sum_MTrafficSpeeding_Over_15_ByTime / 3600 * 100 / driver.sum_TotalMovingHr};
    let heavyO15 = {'label': "Heavy", 'value': driver.sum_TotalMovingHr == 0 ? 0 : driver.sum_HTrafficSpeeding_Over_15_ByTime /3600 * 100 / driver.sum_TotalMovingHr};


    //clear existing data
    //re assign the value to clear up to force d3 to update the chart
    this.driverSpeedingChartData = [{ key: "Under 5", values: [] }, { key: "5-10", values: [] }, { key: "10-15", values: [] }, { key: "Over 15", values: [] }];
    

    //push new value
    _.find(this.driverSpeedingChartData, {"key": "Under 5"}).values.push(mediumU5);
    _.find(this.driverSpeedingChartData, {"key": "Under 5"}).values.push(heavyU5);

    _.find(this.driverSpeedingChartData, {"key": "5-10"}).values.push(medium5_10);
    _.find(this.driverSpeedingChartData, {"key": "5-10"}).values.push(heavy5_10);

    _.find(this.driverSpeedingChartData, {"key": "10-15"}).values.push(medium10_15);
    _.find(this.driverSpeedingChartData, {"key": "10-15"}).values.push(heavy10_15);

    _.find(this.driverSpeedingChartData, {"key": "Over 15"}).values.push(mediumO15);
    _.find(this.driverSpeedingChartData, {"key": "Over 15"}).values.push(heavyO15);    
    //traffic chart

    //road type
    //clear existing data
    this.driverSpeedingChartRoadTypeData = [{ key: "Under 5", values: [] }, { key: "5-10", values: [] }, { key: "10-15", values: [] }, { key: "Over 15", values: [] }];


    let roadTypeLst = _.filter(this.speedingRoadTypeData_Driver, (driverObj) => { return driverObj.driverId == driver.driverId;});
    roadTypeLst = roadTypeLst.sort((a, b) => { return a.roadType - b.roadType});
    //console.log('roadTypeLst', roadTypeLst);
    
    let valueUnder5Lst = [];
    let value5_10Lst = [];
    let value10_15Lst = [];
    let valueOver15Lst = [];

    for(var i = 0; i < roadTypeLst.length ; i++)
    {
      valueUnder5Lst.push({"label": roadTypeLst[i].roadTypeDesc, "value": driver.sum_TotalMovingHr == 0 ? 0 : roadTypeLst[i].sum_Speeding_Under_5mph_ByTime / 3600 * 100 / driver.sum_TotalMovingHr });
      value5_10Lst.push({"label": roadTypeLst[i].roadTypeDesc, "value": driver.sum_TotalMovingHr == 0 ? 0 : roadTypeLst[i].sum_Speeding_5_10mph_ByTime / 3600 * 100 / driver.sum_TotalMovingHr });
      value10_15Lst.push({"label": roadTypeLst[i].roadTypeDesc, "value": driver.sum_TotalMovingHr == 0 ? 0 : roadTypeLst[i].sum_Speeding_10_15mph_ByTime / 3600 * 100 / driver.sum_TotalMovingHr });
      valueOver15Lst.push({"label": roadTypeLst[i].roadTypeDesc, "value": driver.sum_TotalMovingHr == 0 ? 0 : roadTypeLst[i].sum_Speeding_Over_15mph_ByTime / 3600 * 100 / driver.sum_TotalMovingHr });
    }
      
    for (var i = 0 ; i < valueUnder5Lst.length; i++) 
    {
      _.find(this.driverSpeedingChartRoadTypeData, {"key": "Under 5"}).values.push(valueUnder5Lst[i]);
      _.find(this.driverSpeedingChartRoadTypeData, {"key": "5-10"}).values.push(value5_10Lst[i]);
      _.find(this.driverSpeedingChartRoadTypeData, {"key": "10-15"}).values.push(value10_15Lst[i]);
      _.find(this.driverSpeedingChartRoadTypeData, {"key": "Over 15"}).values.push(valueOver15Lst[i]);
    }
    //console.log('speedingChartRoadTypeData', $scope.speedingChartRoadTypeData);
    //console.log('what', d3.select('#roadTypeChart').select('.nv-axislabel') );

    //speeding trend chart data
    //the trend displaying on UI is only 3 months and less. for the period all 3 months, whcihc will be for excel. so to limit the months in dashboard, only grab data from endDate and previous 2 months
    let startMonth  = this.dashboardDate.start
    let endMonth = this.dashboardDate.end
    let monthLst = [];

    for(let i = 0; i < 3 ; i++)
    {
      let yearMonth =  moment(endMonth, 'MM/YYYY').add(-i, 'months').year() + ('0' + (moment(endMonth, 'MM/YYYY').add(-i, 'months').month() + 1)).slice(-2);
      monthLst.push(yearMonth);
    }

    let vehicleTrend3M = [];
    //clear up
    //_.find(this.speedingTrendChartData, {"key": "Total Speeding"}).values = [];
    this.driverSpeedingTrendChartData = [{ key: "Total Speeding", values: [] }];

    driver.driverSpeedingTrendLst = driver.driverSpeedingTrendLst.sort((a, b) => { return a.yearMonth - b.yearMonth});

    let count = driver.driverSpeedingTrendLst.length >= 3 ? 3 : driver.driverSpeedingTrendLst.length;

    for(let i = driver.driverSpeedingTrendLst.length - count; i < driver.driverSpeedingTrendLst.length; i++)
    {
      if(_.find(monthLst, (item) => { return item == driver.driverSpeedingTrendLst[i].yearMonth }))
      {
        vehicleTrend3M.push(
          {
            label: driver.driverSpeedingTrendLst[i].yearMonth.toString().slice(4) + '-' + driver.driverSpeedingTrendLst[i].yearMonth.toString().slice(0,4),
            value: driver.driverSpeedingTrendLst[i].sum_MovingTime_Hr == 0 ? 0 : driver.driverSpeedingTrendLst[i].totalSpeedingSec / 3600 / driver.driverSpeedingTrendLst[i].sum_MovingTime_Hr * 100
          });
      }
    }
    _.find(this.driverSpeedingTrendChartData, {"key": "Total Speeding"}).values = vehicleTrend3M;
  }

  

  ngOnInit(): void {
    this.companyInsProgramDr = this.dashboardCompanyProgram;// set initial value for percentil program dropdown

    this.setDriverSpeedingStackedBarOptions();
    this.setDriverSpeedingTrendBarOptions();
    this.setDriverSpeedingRoadTypeStackedBarOptions();
  }
  ngOnChanges() : void
  {
    this.generateDriverSpeedingTable();
    this.activeSpeedingDriver = null;
  }
  ngAfterViewInit() : void
  {
    
  }


}

<div class="row">
    <div class="col-md-12">
        <div class="dashhead">
            <div class="dashhead-titles">
                <h2 class="dashhead-title">Claims Locator</h2>
            </div>
        </div>
    </div>    
</div>
<div class="row">
    <div class="col-md-12">
        <div class="panel panel-info panel-margin-top">
            <div class="panel-body">
                <style type="text/css">
                    .selectize-input, .selectize-control.single .selectize-input.input-active { background: #b8caf8 !important;}
                </style>
                <div class="col-md-6">
                    <form class="form-horizontal">
                        <div class="control-group form-group col-md-12">
                            <div class="col-md-6">
                                <label class="control-label">Policy Holder&nbsp;*</label>    
                                <input type="text" name = 'companyName' [(ngModel)]="claimsLocatorData.policyHolder.CompanyName" class="form-control" list="MCDataList" (blur)="onMCChange()"/>
                                <datalist id="MCDataList" >
                                    <option *ngFor="let mc of users" [value]="mc.CompanyName"></option>
                                </datalist>
                                <label class="control-label" *ngIf="claimsLocatorData.policyHolder.consentStatusRevoked">Consent Revoked on {{claimsLocatorData.policyHolder.consentRevokedDate}}</label>
                            </div>
                            <div class="col-md-6">
                                <label class="control-label">Vin: </label>    
                                <input type="text" name = 'vin' [(ngModel)]="claimsLocatorData.vin" class="form-control" list="VINDataList" (change)="onVINChange()" 
                                    ngModelOptions="{debounce:500}"/>
                                <datalist id="VINDataList" >
                                    <option *ngFor="let mcV of mcVehicles" [value]="mcV.VIN"></option>
                                </datalist>
                            </div>                                                
                        </div>
                        <div class="control-group form-group col-md-12">
                            <div class="col-md-6 ">
                                <label class="control-label" for="dropdownStart">Date & Time&nbsp;*</label>
                                <div class="input-group">
                                    <span  class="input-group-addon">
                                        <i class="glyphicon glyphicon-calendar" (click)="dp.toggle()"></i>
                                    </span>
                                    <input type="text" name = 'date' placeholder="MM/DD/YYYY" [(ngModel)]="claimsLocatorData.claimEventDateTimeModel"  class="form-control" #dp="bsDatepicker" [bsConfig]="bsConfig"
                                        bsDatepicker />
                                </div>
                                <div>
                                    <timepicker name = 'time' [(ngModel)] = 'claimsLocatorData.claimEventTime'></timepicker>
                                </div> 
                            </div>
                            <div class="col-md-6">
                                <label class="control-label">Time Variance: </label> 
                                <select class="form-control" name = 'timeVariance'[(ngModel)]="claimsLocatorData.timeVariance">
                                    <option value="15">15 Minutes</option>
                                    <option value="30">30 Minutes</option>
                                    <option value="45">45 Minutes</option>
                                    <option value="60">1 Hour</option>
                                    <option [hidden]="!(claimsLocatorData.vin.length > 10)" value="120">2 Hour</option>
                                    <option [hidden]="!(claimsLocatorData.vin.length > 10)" value="180">3 Hour</option>                                    
                                    <option [hidden]="!(claimsLocatorData.vin.length > 10)" value="360">6 Hour</option>
                                    <option [hidden]="!(claimsLocatorData.vin.length > 10)" value="540">9 Hour</option>
                                    <option [hidden]="!(claimsLocatorData.vin.length > 10)" value="720">12 Hour</option>
                                </select>
                            </div>
                        </div>
                        <div class="control-group form-group col-md-12">
                            <div class="col-md-8">
                                <label class="control-label">Location&nbsp;*</label>    
                                <input type="search" name = 'location' class="form-control" [(ngModel)]="claimsLocatorData.location" (change)="onSearchLocationChange()">  
                            </div>
                            <div class="col-md-2">
                                <label class="control-label"><br></label><br>
                                <button class="btn btn-raised btn-success no-animate" (click)="searchLocationFromTpl()">Locate</button>
                            </div>
                            <div class="col-md-2">
                                <label class="control-label"><br></label><br>
                                <button class="btn btn-raised btn-success no-animate" (click)="findClaim()">Find Claim</button>
                            </div>
                        </div>
                        <div class="col-md-12 " style="padding-top: 15px;">
                            <div class="table-responsive">
                                <table id = 'vehTbl' mat-table [dataSource] = "vehTblDataSource" class="table table-responsive table-hover table-condensed table-condensedNew table-selected cl-table">
                                    <ng-container matColumnDef="VehicleId">
                                        <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                                            Vehicle Id
                                        </th>
                                        <td mat-cell *matCellDef="let record; let index = index">
                                            {{ record.VehicleId }}
                                         </td>
                                    </ng-container>
                                      
                                    <ng-container matColumnDef="VIN">
                                        <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300">
                                            VIN
                                        </th>
                                        <td mat-cell *matCellDef="let record"> {{record.VIN}} </td>
                                    </ng-container>
                                      
                                    <ng-container matColumnDef="VehicleType">
                                        <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                                            Vehicle Type
                                        </th>
                                        <td mat-cell *matCellDef="let record"> {{record.VehicleType}} </td>
                                    </ng-container>
                    
                                    <ng-container matColumnDef="Color">
                                        <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                                            Color
                                        </th>
                                        <td mat-cell *matCellDef="let record">
                                            <div class="colorBox" [ngStyle]="{'background-color':record.pathColor}"></div>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Download">
                                        <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                                            Download
                                        </th>
                                        <td mat-cell *matCellDef="let record" class="col-sm-1" (click)="$event.stopPropagation()">
                                            <input type="image" [src]="(record.Degree > 1) ? 'assets/img/ExIconYellow.png' : 'assets/img/ExIcon.png'" width="20" height="20" 
                                            *ngIf="record.ClaimLocationId > 0" (click)="getClaimLocatorReport(record)" />
                                        </td>
                                    </ng-container>
                    
                                    <tr mat-header-row *matHeaderRowDef="vehColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: vehColumns; let index = index" style="height:0px;" 
                                    (click) = 'updateCLData(row)'
                                    [ngClass]="row.VehicleId == selectedVehicleId ? 'selected' : ''"></tr>
                                </table>
                                <mat-paginator #paginatorVeh [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons style="margin-bottom: 2%;margin-top:-1%; background: none; color: #fff;"></mat-paginator>
                  
                                
                                <div [hidden]="!(claimsLocatorData.timeVariance > 60 && routeSegments && (routeSegments.length > 0))">
                                    <div class="col-md-2 cl-segment-text">
                                        Segments
                                    </div>
                                    <div class="col-md-10">
                                        <table id = 'segmentTbl' mat-table [dataSource] = "segmentTblDataSource" class="table table-responsive table-hover table-condensed table-condensedNew table-selected cl-table">
                                            <ng-container matColumnDef="SegmentStartTs">
                                                <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                                                    Start
                                                </th>
                                                <td mat-cell *matCellDef="let routeSegment; let index = index">
                                                    {{ routeSegment.SegmentStartTs.toLocaleTimeString() }}
                                                 </td>
                                            </ng-container>
                                              
                                            <ng-container matColumnDef="SegmentEndTs">
                                                <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300">
                                                    End
                                                </th>
                                                <td mat-cell *matCellDef="let routeSegment"> {{routeSegment.SegmentEndTs.toLocaleTimeString()}} </td>
                                            </ng-container>
                                              
                                            <ng-container matColumnDef="Color">
                                                <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                                                    Color
                                                </th>
                                                <td mat-cell *matCellDef="let routeSegment">
                                                    <div class="colorBox" [ngStyle]="{'background-color':routeSegment.pathColor}"></div>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="Download">
                                                <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                                                    Download
                                                </th>
                                                <td mat-cell *matCellDef="let routeSegment" class="col-sm-1">
                                                    <input type="image" src="assets/img/ExIcon.png" width="20" height="20" 
                                                    *ngIf="(routeSegment.ClaimLocationId > 0)" (click)="getClaimLocatorReport(routeSegment)"/>
                                                </td>
                                            </ng-container>
                            
                                            <tr mat-header-row *matHeaderRowDef="segmentColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: segmentColumns; let index = index" style="height:0px;" 
                                            (click) = 'updateSegmentCLData(row)'>
                                            </tr>
                                        </table>
                                        <mat-paginator #paginatorSegment [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons style="margin-bottom: 2%;margin-top:-1%; background: none; color: #fff;"></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-6">
                    <div id="ClaimsLocatorMapContainer" #ClaimsLocatorMapContainer class="img-responsive" style="width: 100%; height: 700px;" ></div>
                </div>                
            </div>
        </div>        
    </div>
</div>
<div *ngIf="loading" class="loading-spinner-container">
    <img class="loading-spinner" src="/assets/img/ripple.svg">
</div>






 
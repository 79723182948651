import { Component, Injector, OnInit, Injectable } from '@angular/core';
import { AuthService } from '../../Services/auth.service'
import { CommonAppService } from '../../Services/common-app.service';
import {Router} from '@angular/router'




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  message: any;
  private authService: any;
  private commonAppService: any;

  loginData: any = {
    userName: '',
    password: ''
  }

  constructor(private injector : Injector, private router : Router) {
    this.authService = injector.get(AuthService);
    this.commonAppService = injector.get(CommonAppService);
   }

  ngOnInit(): void {
  }

  login() {
    if(this.authService._validateEmailTest(this.loginData.userName))
    {
      this.validLogin();
    }
    else{
      this.verify();

    }
  }

  validLogin() {
    this.authService._login(this.loginData).subscribe((data)=>{
      this.message = false;

      //todo commonappservice for routing to replace below
      //this.router.navigate(['/mcList', {}]);
      this.commonAppService._loginSuccessRoute();                    
      },
      error => {
        this.message = error.error.Message;
        //this.router.navigate(['/login', {}]);
      })
  }

  verify() {

    this.authService._verifyAccount(this.loginData).subscribe((response) => {
      let tempvtk = response.access_token;
      let tempuid = response.UserId;
    
      this.router.navigate(['/activate', tempvtk, tempuid]);
    },
    (err) => {
      this.message = "Temporary login is incorrect or has already been used. If password already created, click on forgot password.";
    })


  }


}

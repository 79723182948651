<div>
<div class="row">
    <div class="col-md-6 col-md-offset-3">
        <div class="panel panel-info">
            <div class="panel-heading"></div>
            <div class="panel-body">
                <h2>Log In</h2>
                <form class="form-horizontal">
                    <fieldset>
                        <div  class ="form-group label-floating">
                            <div class="col-md-8 col-md-offset-2">
                                <label class="control-label">Email</label>
                                <input type="text" class="form-control" placeholder="Username" [(ngModel)] = 'loginData.userName' name = 'userName' required autofocus>
                            </div>
                        </div>
                        <div class="form-group label-floating">
                            <div class="col-md-8 col-md-offset-2">
                                <label class="control-label">Password</label>
                                <input type="password" class="form-control" placeholder="Password" [(ngModel)] = 'loginData.password' name = 'password'  required>
                            </div>
                        </div>
                        <div class="alert alert-danger col-md-8 col-md-offset-2 text-center animate-show" [hidden]= '!message'>
                            <p>{{message}}</p>
                        </div>
                       
                        <div class="form-group">
                            <div class="col-md-8 col-md-offset-2 text-center">
                                <button routerLink ="/reset" type="button" class="btn btn-default" style="margin-right: 2%;">Forgot Password?</button>
                                <!-- <button ui-sref="activate" type="button" class="btn" ng-class="invalidAuth ? 'btn-danger' : 'btn-default'" style="margin-right: 2%;">Activate</button> -->
                                <button type="submit" class="btn btn-info" (click) = "login()" [disabled] = "!loginData.userName || !loginData.password"  >Log In</button>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="panel panel-info">
            <div class="panel-heading"></div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-12">
                        <h1>Contact Us</h1>
                        
                        <br>
                    </div>
                </div>
                <div class="row" [hidden]="submitted">
                    <form name="f" #form= 'ngForm' (ngSubmit)="submit(contact)">
                        <div class="form-group col-sm-12 col-md-4 center_div">
                            <label>Name: *</label>
                            <input type="text" class="form-control" [(ngModel)]="contact.name" name="cName" required />
                            <div [hidden]="!(submitted || form.form.controls.cName?.touched)">
                                <span [hidden]="!form.form.controls.cName?.errors?.required">Please enter your name.</span>
                            </div>
                        </div>
                        <div class="form-group col-sm-12 col-md-4 center_div">
                            <label>E-mail: *</label>
                            <input type="email" class="form-control" [(ngModel)]="contact.email" name="cEmail" required email />
                            <div [hidden]="!(submitted || form.form.controls.cEmail?.touched)">
                                <span [hidden]="!form.form.controls.cEmail?.errors?.required">Please enter your email.</span>
                                <span [hidden]="!form.form.controls.cEmail?.errors?.email">Please enter a valid email.</span>
                            </div>
                        </div>
                        <div class="form-group col-sm-12 col-md-4 center_div">
                            <label>Phone: *</label>
                            <input type="text" mask = "(000) 000-0000" class="form-control" [(ngModel)]="contact.phone" name="cPhone" required />
                            <div [hidden]="!(submitted || form.form.controls.cPhone?.touched)">
                                <div [hidden]="!form.form.controls.cPhone?.errors?.required">Please enter your phone number.</div>
                            </div>
                        </div>
                        <div class="form-group col-sm-12 col-md-4 center_div">
                            <label>Company: *</label>
                            <input type="text" class="form-control" [(ngModel)]="contact.company" name="cCompany" required />
                            <div [hidden]="!(submitted || form.form.controls.cCompany?.touched)">
                                <div [hidden]="!form.form.controls.cCompany?.errors?.required">Please enter your company.</div>
                            </div>
                        </div>
                        <div class="form-group col-xs-10" style="margin-left: 10%; padding-right:4%;">
                            <label>What can we help you with? *</label>
                            <textarea class="form-control" rows="3" [(ngModel)]="contact.message" name="cMessage" required></textarea>
                            <!-- <input type="text" class="form-control" ng-model="contact.message" name="cMessage" /> -->
                            <div [hidden]="!(submitted || form.form.controls.cMessage?.touched)">
                                <div [hidden]="!form.form.controls.cMessage?.errors?.required">Please enter your message.</div>
                            </div>
                        </div>
                        <div class="col-md-11 text-right" style="padding-right: 2%;">
                    		<button class="btn btn-success" [disabled]="form.invalid">Submit</button>
                        </div>
                        <!-- <input type="submit" class="form-control" ng-click="update(contact)" value="Save" /> -->
                    </form>
                </div>
                <div class="row" [hidden]="!submitted">
                	<div class="col-md-12 text-center">
                		<br>
                		<h2>Thank you!</h2>
                		<h4>A member of the <a href="http://www.etechnologyservices.com">eTechnology Services</a> team will be in contact with you shortly.</h4>
                	</div>
                </div>
            </div>
        </div>
    </div>
</div>

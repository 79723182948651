import { Component, OnInit , ViewChild, ElementRef, AfterViewInit, HostListener, Renderer2, Input, OnChanges } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import * as moment from 'moment';
import * as _ from 'underscore';
declare let d3 : any;

@Component({
  selector: 'app-harshevent',
  templateUrl: './harshevent.component.html',
  styleUrls: ['./harshevent.component.css']
})
export class HarsheventComponent implements OnInit {

  @Input() harshEventData : any;
  @Input() harshEventSumData : any;
  @Input() harshEventSumData_Driver : any;

  activeHarshEventVehicle = null;
  harshEventTblDataSource :any = [];
  harshEventColumns : string[] = ['sortBase', 'vehicleType', 'sum_Harsh_Brake', 'derived_sum_Harsh_Brake_Per1k', 'sum_Harsh_Corner','derived_sum_Harsh_Corner_Per1k', 'sum_Harsh_Quick_Accelaration', 'derived_sum_Harsh_Quick_Accelaration_Per1k', 'sum_TotalMiles'];
  @ViewChild('sortHarshEvent') sortHarshEvent: MatSort;
  @ViewChild('paginatorHarshEvent') paginatorHarshEvent: MatPaginator;

  harshEventInTrafficOptions : any;
  harshEventCategoryOptions : any;

  harshEventInTrafficChartData = [{ key: "Brake", values: [] }, { key: "Corner", values: [] }, { key: "Accel", values: [] }];
  harshEventCategoryChartData = [{ key: "Brake", values: [] }, { key: "Corner", values: [] }, { key: "Accel", values: [] }];

  //Driver
  @Input() harshEventData_Driver :any;
  
  driverOrVeh : string = 'vehicleView';
  //Driver

  @Input() dashboardCompanyProgram : any;
  percentile : number = 0;
  toggleHarshEventbtnClassLst : any;
  HarshEventProgmDropClassLst : any;
  companyInsProgramDr: any;


  constructor(private render : Renderer2) { }

  generateHarshEventTable() {

    if(!this.harshEventSumData) return;
    //sum up harshEventData for the table

   
    let harshEventDataSum = this.harshEventSumData;


      //for sorting on contional VIN or Vehicleid 
    harshEventDataSum.forEach((item) => {
      item.sortBase = item.vin && item.vin !== 'N/A' ? item.vin.replace(item.vin.slice(0, (item.vin.length) - 6), "").toString() +'/' : '/' + item.vehicleId
    });

    let harshEventViewData = _.toArray(harshEventDataSum);
    
    this.harshEventTblDataSource = new MatTableDataSource(harshEventViewData);

    //sort on derived columns
    this.harshEventTblDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'derived_sum_Harsh_Brake_Per1k':
          return ((item.sum_TotalMiles == 0 || item.sum_Harsh_Brake == 0) ? 0: item.sum_Harsh_Brake  * 1000 / item.sum_TotalMiles );
        case 'derived_sum_Harsh_Corner_Per1k':
          return ((item.sum_TotalMiles == 0 || item.sum_Harsh_Corner == 0) ? 0: item.sum_Harsh_Corner * 1000 / item.sum_TotalMiles);

        case 'derived_sum_Harsh_Quick_Accelaration_Per1k' :
          return ((item.sum_TotalMiles == 0 || item.sum_Harsh_Quick_Accelaration == 0) ? 0 : item.sum_Harsh_Quick_Accelaration * 1000 / item.sum_TotalMiles);

         
        
        default:
          return item[property];
      }
    };

    this.harshEventTblDataSource.sort = this.sortHarshEvent;
    this.harshEventTblDataSource.paginator = this.paginatorHarshEvent;


  }

  setActiveHarshEvent(vehicle, event) {

    let rows = Array.from(document.getElementById('harshEvent').getElementsByTagName('tr'));
    rows.forEach(item => {
      this.render.removeClass(item, 'selected');
    });

    this.render.addClass(event.currentTarget, 'selected');

    this.activeHarshEventVehicle = vehicle;

    // events in Traffic
     //clear existing harsh event in traffic data
    this.harshEventInTrafficChartData = [{ key: "Brake", values: [] }, { key: "Corner", values: [] }, { key: "Accel", values: [] }];

    //push new value
    var trafficTypeLst = _.filter(this.harshEventData, (vehicleObj) => { return vehicleObj.vehicleId == vehicle.vehicleId &&
     (vehicleObj.categoryType === 1 || vehicleObj.categoryType === 2 || vehicleObj.categoryType === 3);});

    trafficTypeLst = trafficTypeLst.sort((a, b) => { return a.categoryType - b.categoryType});
    //console.log("trafficLst",trafficTypeLst );

    let valueBrakeLst = [];
    let valueCornerLst = [];
    let valueAccelLst = [];

    for(let i = 0; i < trafficTypeLst.length ; i++)
    {
      valueBrakeLst.push({"label": trafficTypeLst[i].categoryTypeDesc.split(" ")[0], "value": vehicle.sum_TotalMiles == 0 ? 0 : trafficTypeLst[i].sum_Harsh_Brake * 1000 / vehicle.sum_TotalMiles });
      valueCornerLst.push({"label": trafficTypeLst[i].categoryTypeDesc.split(" ")[0], "value": vehicle.sum_TotalMiles == 0 ? 0 : trafficTypeLst[i].sum_Harsh_Corner * 1000 / vehicle.sum_TotalMiles });
      valueAccelLst.push({"label": trafficTypeLst[i].categoryTypeDesc.split(" ")[0], "value": vehicle.sum_TotalMiles == 0 ? 0 : trafficTypeLst[i].sum_Harsh_Quick_Accelaration * 1000 / vehicle.sum_TotalMiles });
    }
    
    for (let i = 0 ; i < valueBrakeLst.length; i++) 
    {
      _.find(this.harshEventInTrafficChartData, {"key": "Brake"}).values.push(valueBrakeLst[i]);
      _.find(this.harshEventInTrafficChartData, {"key": "Corner"}).values.push(valueCornerLst[i]);
      _.find(this.harshEventInTrafficChartData, {"key": "Accel"}).values.push(valueAccelLst[i]);
    } 
    //console.log("testingTraffic", $scope.harshEventInTrafficChartData);
    // events in Traffic

    //categorical events
    //clear existing data
    this.harshEventCategoryChartData = [{ key: "Brake", values: [] }, { key: "Corner", values: [] }, { key: "Accel", values: [] }];

    //push new value
    let categoryLst = _.filter(this.harshEventData, (vehicleObj) => { return vehicleObj.vehicleId == vehicle.vehicleId &&
     !(vehicleObj.categoryType === 1 || vehicleObj.categoryType === 2 || vehicleObj.categoryType === 3);});

    categoryLst = categoryLst.sort((a, b) => { return a.categoryType - b.categoryType});
    //console.log("categoryLst",categoryLst );

    let valueBrakeCategoryLst = [];
    let valueCornerCategoryLst = [];
    let valueAccelCategoryLst = [];

    for(let i = 0; i < categoryLst.length ; i++)
    {
      valueBrakeCategoryLst.push({"label": categoryLst[i].categoryTypeDesc, "value": vehicle.sum_TotalMiles == 0 ? 0 : categoryLst[i].sum_Harsh_Brake * 1000 / vehicle.sum_TotalMiles });
      valueCornerCategoryLst.push({"label": categoryLst[i].categoryTypeDesc, "value": vehicle.sum_TotalMiles == 0 ? 0 : categoryLst[i].sum_Harsh_Corner * 1000 / vehicle.sum_TotalMiles });
      valueAccelCategoryLst.push({"label": categoryLst[i].categoryTypeDesc, "value": vehicle.sum_TotalMiles == 0 ? 0 : categoryLst[i].sum_Harsh_Quick_Accelaration * 1000 / vehicle.sum_TotalMiles });
    }
    
    for (let i = 0 ; i < valueBrakeCategoryLst.length; i++) 
    {
      _.find(this.harshEventCategoryChartData, {"key": "Brake"}).values.push(valueBrakeCategoryLst[i]);
      _.find(this.harshEventCategoryChartData, {"key": "Corner"}).values.push(valueCornerCategoryLst[i]);
      _.find(this.harshEventCategoryChartData, {"key": "Accel"}).values.push(valueAccelCategoryLst[i]);
    }
    //categorical events
  }

  setHarshEventInTrafficOptions()
  {
    this.harshEventInTrafficOptions = 
    {
      chart: {
        type: 'multiBarHorizontalChart',
        height: 380,
        groupSpacing: 0.25,
        //width: 365,
        x: (d) => {return d.label;},
        y: (d) => {return d.value;},
        //yErr: function(d){ return [-Math.abs(d.value * Math.random() * 0.3), Math.abs(d.value * Math.random() * 0.3)] },
        showControls: false,
        showValues: false,
        duration: 500,
        xAxis: {
          showMaxMin: false
        },
        yAxis: {
          axisLabel: '',
          tickFormat: (d) => {
            return d3.format(',.1f')(d);
          }
        },
        margin: {
          top: -1,
          right: 20,//110,
          bottom: 35,
          left:100
        },

        showYAxis: false,
        showXAxis: true,
        stacked: false,
        color: ['rgb(11,108,205)', 'rgb(220,220,220)', 'rgb(13,139,197)'],
        showLegend: false,
        forceY: [0, 10],
        //yRange: [0,237],
      }
    }
  }

  setHarshEventCategoryOptions()
  {
    this.harshEventCategoryOptions = {
      chart: {
        type: 'multiBarHorizontalChart',
        height: 550,
        groupSpacing: 0.25,
        //width: 365,
        x: (d) => {return d.label;},
        y: (d) => {return d.value;},
        //yErr: function(d){ return [-Math.abs(d.value * Math.random() * 0.3), Math.abs(d.value * Math.random() * 0.3)] },
        showControls: false,
        showValues: false,
        duration: 500,
        xAxis: {
          showMaxMin: false
        },
        yAxis: {
          axisLabel: '',
          tickFormat: (d) => {
            return d3.format(',.1f')(d);
          }
        },
        margin: {
          top: -1,
          right: 20,
          bottom: 65,
          left: 100
        },

        showYAxis: true,
        showXAxis: true,
        stacked: false,
        color: ['rgb(11,108,205)', 'rgb(220,220,220)', 'rgb(13,139,197)'], 
        showLegend: true,
        forceY: [0, 10],
        //yRange: [0,237],
      }
    }
  }

  calculatePercentile(vehicleLst)
  {
    vehicleLst.forEach((item) => {
      if(item.sum_TotalMiles > 0)
      {
        item.brake_Percentile = 100 * (item.rankBrakePerc - 0.5) / item.numOfVehInProgram;
        item.accel_Percentile = 100 * (item.rankAccelPerc - 0.5) / item.numOfVehInProgram;
        item.corner_Percentile = 100 * (item.rankCornerPerc - 0.5) / item.numOfVehInProgram;
        

        item.brake_Percentile_AllProgm = 100 * (item.rankBrakeAllProgramPerc - 0.5) / item.numOfVehInAllProgram;
        item.accel_Percentile_AllProgm = 100 * (item.rankAccelAllProgramPerc - 0.5) / item.numOfVehInAllProgram;
        item.corner_Percentile_AllProgm = 100 * (item.rankCornerAllProgramPerc - 0.5) / item.numOfVehInAllProgram;
        
      }
      else
      {
        item.brake_Percentile = 0;
        item.accel_Percentile = 0;
        item.corner_Percentile = 0;
        

        item.brake_Percentile_AllProgm = 0;
        item.accel_Percentile_AllProgm = 0;
        item.corner_Percentile_AllProgm = 0;
        
      }
    });
    return vehicleLst;
  }

  togglePercentileHarshEventTbl(percentile)
  {
    if(percentile == 0)
    {
        this.percentile = 1;
        this.harshEventColumns = 
        ['sortBase', 'vehicleType',  'brake_Percentile', 'corner_Percentile',  'accel_Percentile', 'sum_TotalMiles'];
        
        let element = document.getElementById("HarshPRToggleDv");
        element.classList.remove('col-md-12');
        element.classList.add('col-md-10');

        let elementDropdown = document.getElementById("HarshPRDropDownDv");
        elementDropdown.classList.add('col-md-2');

        let toggleElement = document.getElementById("harshEventPRToggleBtn");
        toggleElement.style.marginRight = '0%';

        this.toggleHarshEventbtnClassLst = element.classList;
        this.HarshEventProgmDropClassLst = elementDropdown.classList;
    }
    else if(percentile == 1)
    {
        this.harshEventColumns = 
        ['sortBase', 'vehicleType', 'sum_Harsh_Brake', 'derived_sum_Harsh_Brake_Per1k', 'sum_Harsh_Corner','derived_sum_Harsh_Corner_Per1k', 'sum_Harsh_Quick_Accelaration', 'derived_sum_Harsh_Quick_Accelaration_Per1k', 'sum_TotalMiles'];
        
        let element = document.getElementById("HarshPRToggleDv");
        element.classList.remove('col-md-10');
        element.classList.add('col-md-12');

        let elementDropdown = document.getElementById("HarshPRDropDownDv");
        elementDropdown.classList.remove('col-md-2');

        let toggleElement = document.getElementById("harshEventPRToggleBtn");
        toggleElement.style.marginRight = '0%';
        this.percentile = 0; 


        this.toggleHarshEventbtnClassLst = element.classList;
        this.HarshEventProgmDropClassLst = elementDropdown.classList;
    }
  }

  ngOnInit(): void {

    this.companyInsProgramDr = this.dashboardCompanyProgram;// set initial value for percentil program dropdown
    this.setHarshEventCategoryOptions();
    this.setHarshEventInTrafficOptions();
  }
  
  ngOnChanges(): void
  {
    this.generateHarshEventTable();
    this.activeHarshEventVehicle = null
  }

}

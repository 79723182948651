<div class="modal-header">
    <button type="button" class="close" aria-hidden="true" (click)="close()">×</button>
    <h3 class="modal-title">Edit User</h3>
</div>
<div class="modal-body">
    <!-- <pre>{{userObj | json}}</pre> -->
    <div class="row">
        <div class="col-md-6">
            <div class="form-group label-floating">
                <label for="i4" class="control-label">First and Last Name *</label>
                <input type="text" class="form-control" [(ngModel)]="userObj.Name" required>
            </div>
            <div class="form-group label-floating">
                <label for="i4" class="control-label">Position</label>
                <input type="text" class="form-control" [(ngModel)]="userObj.JobTitle">
            </div>
            <div class="form-group label-floating">
                <label for="i4" class="control-label">Email Address *</label>
                <input type="email" class="form-control" [(ngModel)]="userObj.email" required>
            </div>
            <div class="form-group label-floating">
                <label for="i4" class="control-label">Phone Number *</label>
                <input type="text" mask = "(000) 000-0000" class="form-control" [(ngModel)]="userObj.Phone" required>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group label-floating">
                        <label for="i4" class="control-label">Address 1</label>
                        <input type="text" class="form-control" [(ngModel)]="userObj.StreetAddress1">
                    </div>
                    <div class="form-group label-floating">
                        <label for="i4" class="control-label">Address 2</label>
                        <input type="text" class="form-control" [(ngModel)]="userObj.StreetAddress2">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group label-floating">
                        <label for="i4" class="control-label">City</label>
                        <input type="text" class="form-control" [(ngModel)]="userObj.City">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group label-floating">
                        <label for="i4" class="control-label">State</label>
                        <input type="text" class="form-control" [(ngModel)]="userObj.St">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group label-floating">
                        <label for="i4" class="control-label">Zip</label>
                        <input type="text" class="form-control" [(ngModel)]="userObj.ZipCode">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf = "(userObj | json) != ({} | json) && userObj.UserId; then updateUser; else addUser">
</div>
<ng-template #updateUser>
    <div class="modal-footer">
    <button class="btn btn-danger" type="button" (click)="delete()" [disabled]="!enableDelete">Delete</button>
    <!-- <button class="btn btn-primary" type="button" ng-click="ok()" ng-disabled="!userObj.name || !userObj.email || !userObj.phone || !userObj.address">Update</button> -->
    <button class="btn btn-info" type="submit" (click)="ok()" [disabled]="!userObj.Name || !userObj.email || !userObj.Phone">Update</button>
    </div>
</ng-template>

<ng-template #addUser>
    <div class="modal-footer">
    <button class="btn btn-warning" type="button" (click)="close()">Cancel</button>
    <button class="btn btn-info" type="button" (click)="add()" [disabled]="!userObj.Name || !userObj.email || !userObj.Phone">Add</button>
    </div>
</ng-template>

<!-- <div class="modal-footer" *ngIf = '(userObj | json) == ({} | json)'>
    <button class="btn btn-warning" type="button" ng-click="close()">Cancel</button>
    <button class="btn btn-info" type="button" ng-click="add()" ng-disabled="!newUser.Name || !newUser.email || !newUser.Phone">Add</button>
</div> -->


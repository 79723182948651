import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener, Renderer2, OnDestroy } from '@angular/core';
import { map, tap, switchMap, mergeMap, filter, concatMap, takeUntil} from 'rxjs/operators';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthService} from '../../Services/auth.service';
import {DataService} from '../../Services/data.service';
import {DashService} from '../../Services/dash.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashdataexportModalComponent  } from '../dashdataexport-modal/dashdataexport-modal.component';
import { appConst} from '../../app.config';


import * as moment from 'moment';
import * as _ from 'underscore';
declare var $: any;// for convertion purpose using the jQuery here, which supposed not to in Angular 2x. will find workaround after convertion
import * as noUiSlider from '../../../lib/noUiSlider/nouislider';
import * as wNumb from '../../../lib/wnumb-1.1.0/wNumb';
import { Subject } from 'rxjs';
declare let d3 : any;



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {

  subNotifier = new Subject();

  authData =  JSON.parse(localStorage.getItem('authorizationData'));
  dashboardMCID = null;
  dashboardType = null;
  smUserRole = null;
  smGroupType = null;
  app_id = this.authData.here_app_id;//authService.authentication.here_app_id;
  app_code = this.authData.here_app_code; //authService.authentication.here_app_code;
  api_key = this.authData.here_api_key;
  dashboardCompanyName = null;
  dashboardCompanyProgram : string;
  utilData : any;
  radiusData : any;
  territoryData : any;
  territoryTotal : any;
  private scoringData : any;
  scoreReady : any;
  hasDataToExport : any;
  speedingData : any;
  speedingRoadTypeData : any;
  FleetData : any;
  DSCardDataToExport : any;
  driverScoreCardData : any;
  harshEventData : any;
  harshEventSumData : any;
  geoFileLst : any;
  totalDays : any;

  groupInfo : any = {};
  activeGarageIndex = null;            
  authentication = this.authService._authentication;
  smIndex = this.authService._smIndex;
  dashViews = [];
  dashboards : any= {};
  smDashBoard = {
      UtilizationDiv : false,
      RadiusAndGaragingDiv : false,
      TerritoryDiv : false,
      SpeedingDiv : false,
      HarshEventsDiv : false,
      FleetFootprintDiv: false,
      ScoringDiv: false,
      DriverScoreCardDiv: false
  };
  activeVehicleUtilization = null;
  activeTerritoryVehicle = null;
  activeSpeedingVehicle = null;
  activeHarshEventVehicle = null;


  dashboardDate = { 
    start: null,//start, 
    end: null//end 
  }
  
  bsConfig: Partial<BsDatepickerConfig> = 
  {
    minMode : 'month',
    dateInputFormat: 'MM/YYYY',
    //isAnimated : true
    containerClass : 'theme-default'
  }

  //scoring
  private bandScoreStore : any = {};
  scoringBandDivOn = true;
  scoringBandSlider = null;
  private scoringDataForView = [];
  individualPerData = [];
  @ViewChild('scoringSlider') scoringSlider : ElementRef;
  scoringChartOn = false;
  scoringColumns: string[] = ['category', 'mean', 'point'];
  scoringTblDataSource : any= [];
  scoringChartOptions : any = {};
  scoringChartData = [];
  scoringTblOriginal = [];
  @ViewChild(MatSort) sort: MatSort;
  //scoring

  //Utilization
  utilTblDataSource :any = [];
  utilColumns : string[] = ['sortBase', 'vehicleType', 'daysOperational', 'totalMileage', 'sum_NetOnTimeHr', 'averageOfSum_NetOnTimeHr', 'dailyMiles'];
  @ViewChild('sortUtil') sortUtil: MatSort;
  @ViewChild('paginatorUtil') paginatorUtil: MatPaginator;
  //Utilization
  
  //Driver
  speedingRoadTypeData_Driver : any;
  speedingData_Driver : any;
  utilData_Driver : any;
  harshEventData_Driver : any;
  harshEventSumData_Driver : any;
  //Driver

  

  constructor(
    private activatedRouter : ActivatedRoute,
    private router : Router,
    private authService : AuthService,
    private dataService : DataService,
    private dashService : DashService,
    private render : Renderer2,
    private dialog : MatDialog
    ) {
  }

  setDash(dash, event) {

    $('li.active').removeClass('active');
    let clickElem = $(event.target).parent();
    clickElem.addClass('active');
    this.dashboards.activeDash = dash;
    this.activeVehicleUtilization = null;
    this.activeTerritoryVehicle = null;
    this.activeSpeedingVehicle = null;
    this.activeHarshEventVehicle = null;

    if(dash === 'Scoring')//refresh the scoring chart for hidden directive when tab comes back that the chart does not expand from unhidden
    {
      this.setScoringChartOption();
    }

    if(dash == "Territory")
    {
      window.setTimeout(() => {
        let thS = document.getElementsByTagName("th");
        for(let i = 0; i < thS.length; i++)
        {
          if(thS[i].innerHTML.includes("Garaging City"))
          {
            //spans[i].style.color = "#0062ff";
            thS[i].style.background = "#50586c";
            //thS[i].style.color = "#000";
          }
          else if(thS[i].innerHTML.includes("State"))
          {
            //spans[i].style.color = "#000";
            //thS[i].style.background = "#0062ff";
            thS[i].style.background = "#50586c";
            //thS[i].style.color = "#000";
          }
          else if(thS[i].innerHTML.includes("Zip"))
          {
            //spans[i].style.color = "#000";
            //thS[i].style.background = "#0062ff";
            thS[i].style.background = "#50586c";
            //thS[i].style.color = "#000";
          }
          else if(thS[i].innerHTML.includes("Terr."))
          {
            //spans[i].style.color = "#000";
            //thS[i].style.background = "#0062ff";
            thS[i].style.background = "#50586c";
            //thS[i].style.color = "#000";
            //thS[i].style.borderColor = "#50586c"
          }
        }
      }, 150);
    }
  }

  loadData()
  {
    let doneChainCall = false;
    this.scoreReady = false;
    this.hasDataToExport = false;// disable button while loading for another period of time
    this.DSCardDataToExport = false;
                    
    this.dataService._getGroupInfo().pipe(
      concatMap(result => {
        this.groupInfo = result;

        return this.dashService._getMonthlyUtilization(this.dashboardMCID, this.dashboardDate.start, this.dashboardDate.end)
      }),
      concatMap(allData => {
        //this.utilData = allData.data;
        this.utilData = allData;
        
        this.generateUtilTable();
        //this.calculateTotalPotentialDays(allData.data[0]);
        this.calculateTotalPotentialDays(allData[0]);
        return this.dashService._getMonthlyRadiusGaraging(this.dashboardMCID, this.dashboardDate.start, this.dashboardDate.end);
      }),
      concatMap(radius => {
        this.radiusData = radius;
        //TODO
        //generateRadiusTable();
        //generateGaragesTable();
        return this.dashService._getTerritoryData(this.dashboardMCID, this.dashboardDate.start, this.dashboardDate.end);
      }),
      concatMap(territory => {
        this.territoryData = territory;
        this.territoryTotal = this.territoryData[this.territoryData.length - 1]; 
        //$scope.territoryData.splice(-1, 1); original
        
        //TODO
        //generateTerritoryTable();

        this.dashService._getMCScoring(this.dashboardMCID, this.dashboardDate.start, this.dashboardDate.end, this.groupInfo.IccID).pipe(takeUntil(this.subNotifier)).subscribe(
          scoring => {
            this.scoringData = scoring;
            //TODO
            this.generateScoringTable();
            this.setScoringChartData();
            
            this.scoreReady = true;

            this.hasDataToExport = doneChainCall && this.speedingData && this.speedingData.length >= 0; //display export button only when there is data available
            //$scope.hasDataToExport = true;//$scope.speedingData && $scope.speedingData.length > 0; //display export button only when there is data available
          }
        );

        return this.dashService._getSpeedingData(this.dashboardMCID, this.dashboardDate.start, this.dashboardDate.end);
      }),
      concatMap(speeding => {
        this.speedingData =  this.calculatePercentile(speeding);

        //TODO
        //generateSpeedingTable();
        return this.dashService._getSpeedingRoadTypeData(this.dashboardMCID, this.dashboardDate.start, this.dashboardDate.end);
      }),
      concatMap(speedingRoadType => {
        this.speedingRoadTypeData =speedingRoadType;
        this.FleetData = this.vehicleFleetSum();

        //return this.dashService._getMonthlyDriverScoreCard(this.dashboardMCID, this.dashboardDate.start, this.dashboardDate.end, this.groupInfo.IccID);
        return this.dashService._getMonthlyUtilization_Driver(this.dashboardMCID, this.dashboardDate.start, this.dashboardDate.end);
      }),
      //Driver
      concatMap(driverUtil => {
        //this.utilData = allData.data;
        this.utilData_Driver = driverUtil;
        
        //this.generateUtilTable();
        //this.calculateTotalPotentialDays(allData.data[0]);
        //this.calculateTotalPotentialDays(allData[0]);
        return this.dashService._getSpeedingData_Driver(this.dashboardMCID, this.dashboardDate.start, this.dashboardDate.end);
      }),
      concatMap(driverSpeeding => {
        
        this.speedingData_Driver = this.calculateDriverPercentile(driverSpeeding);

        return this.dashService._getSpeedingRoadTypeData_Driver(this.dashboardMCID, this.dashboardDate.start, this.dashboardDate.end);
      }),
      concatMap(driverSpeedingRoadType => {
        //this.utilData = allData.data;
        this.speedingRoadTypeData_Driver = driverSpeedingRoadType;
        
        
        return this.dashService._getHarshEventData_Driver(this.dashboardMCID, this.dashboardDate.start, this.dashboardDate.end);
      }),
      concatMap(driverHarshEvent => {
        //this.utilData = allData.data;
        this.harshEventData_Driver = driverHarshEvent;
        this.harshEventSumData_Driver = this.convertHarsheventData(driverHarshEvent, true);
        
        return this.dashService._getMonthlyDriverScoreCard(this.dashboardMCID, this.dashboardDate.start, this.dashboardDate.end, this.groupInfo.IccID);
      }),
      //Driver
      concatMap(driverScoreCardData => {
        this.driverScoreCardData = driverScoreCardData;
        
        //TODO
        //generateDriverScoreCardTable();
        if(driverScoreCardData.speedingTrendData && driverScoreCardData.speedingTrendData.length >0) { this.DSCardDataToExport = true; }

        return this.dashService._getHarshEventData(this.dashboardMCID, this.dashboardDate.start, this.dashboardDate.end);
      }),
      concatMap(harshEvent => {
        this.harshEventData = harshEvent;
        this.harshEventSumData = this.convertHarsheventData(harshEvent, false);
        //TODO
        //generateHarshEventTable();

        this.hasDataToExport = this.scoreReady;

        doneChainCall = true;
        if(this.scoreReady)
        {this.hasDataToExport = true; }//$scope.speedingData && $scope.speedingData.length > 0; //display export button only when there is data available

        return this.dashService._getHexGeoJsonFileLst(this.dashboardMCID);
      })
     
    ).pipe(takeUntil(this.subNotifier)).subscribe((geoFileLst : Array<any>) => 
      {
        if(geoFileLst && geoFileLst.length > 0)
        {
          geoFileLst.forEach((item, i) =>
          {
            geoFileLst[i].caption = item.fileName.split("-")[1].substring(4) + " " + item.fileName.split("-")[1].substring(0, 4);
          });

          this.geoFileLst = geoFileLst;
        }
      }
    );
  }

  calculatePercentile(vehicleLst)
  {
    vehicleLst.forEach((item) => {
      if(item.sum_TotalMovingHr > 0)
      {
        item.speeding_Under_5mph_ByTime_Percentile = 100 * (item.rankUnder_5Perc - 0.5) / item.numOfVehInProgram;
        item.speeding_5_10mph_ByTime_Percentile = 100 * (item.rank5_10Perc - 0.5) / item.numOfVehInProgram;
        item.speeding_10_15mph_ByTime_Percentile = 100 * (item.rank10_15Perc - 0.5) / item.numOfVehInProgram;
        item.speeding_Over_15mph_ByTime_Percentile = 100 * (item.rankOver_15Perc - 0.5) / item.numOfVehInProgram;
        item.total_Speeding_ByTime_Percentile = 100 * (item.rankTotalSpeedingPerc - 0.5) / item.numOfVehInProgram;

        item.speeding_Under_5mph_ByTime_Percentile_AllProgm = 100 * (item.rankUnder_5AllProgramPerc - 0.5) / item.numOfVehInAllProgram;
        item.speeding_5_10mph_ByTime_Percentile_AllProgm = 100 * (item.rank5_10AllProgramPerc - 0.5) / item.numOfVehInAllProgram;
        item.speeding_10_15mph_ByTime_Percentile_AllProgm = 100 * (item.rank10_15AllProgramPerc - 0.5) / item.numOfVehInAllProgram;
        item.speeding_Over_15mph_ByTime_Percentile_AllProgm = 100 * (item.rankOver_15AllProgramPerc - 0.5) / item.numOfVehInAllProgram;
        item.total_Speeding_ByTime_Percentile_AllProgm = 100 * (item.rankTotalSpeedingAllProgramPerc - 0.5) / item.numOfVehInAllProgram;
      }
      else
      {
        item.speeding_Under_5mph_ByTime_Percentile = 0;

        item.speeding_5_10mph_ByTime_Percentile = 0;
        item.speeding_10_15mph_ByTime_Percentile = 0;
        item.speeding_Over_15mph_ByTime_Percentile = 0;
        item.total_Speeding_ByTime_Percentile = 0;

        item.speeding_Under_5mph_ByTime_Percentile_AllProgm = 0;
        item.speeding_5_10mph_ByTime_Percentile_AllProgm = 0;
        item.speeding_10_15mph_ByTime_Percentile_AllProgm = 0;
        item.speeding_Over_15mph_ByTime_Percentile_AllProgm = 0;
        item.total_Speeding_ByTime_Percentile_AllProgm = 0;
      }
    });
    return vehicleLst;
  }

  vehicleFleetSum()
  {
    let dataSet = this.speedingData;
    let u5Sum = 0, _5T10Sum = 0, _10T15Sum = 0, o15Sum = 0, totalMovingHrSum =0;// totalMileSum = 0;

    dataSet.forEach(function(data)
    {
      u5Sum += data.sum_Speeding_Under_5mph_ByTime;
      _5T10Sum += data.sum_Speeding_5_10mph_ByTime;
      _10T15Sum += data.sum_Speeding_10_15mph_ByTime;
      o15Sum += data.sum_Speeding_Over_15mph_ByTime;
      totalMovingHrSum += data.sum_TotalMovingHr;
    })
    //console.log("obj", {"u5Sum": u5Sum, "_5T10Sum" : _5T10Sum, "_10T15Sum": _10T15Sum, "o15Sum": o15Sum, "totalMileSum": totalMileSum });
    return {"u5Sum": u5Sum, "_5T10Sum" : _5T10Sum, "_10T15Sum": _10T15Sum, "o15Sum": o15Sum, "totalMovingHrSum": totalMovingHrSum };
  }

  calculateTotalPotentialDays(sampleData) 
  {
    if(sampleData) // avoid the exceptions when the date range selected does not have data
    {
      let start = {
          year: parseInt(sampleData.yearMonthFrom.toString().substring(0, 4)),
          month: parseInt(sampleData.yearMonthFrom.toString().substring(4, 6)),
      };

      let end = {
          year: parseInt(sampleData.yearMonthTo.toString().substring(0, 4)),
          month: parseInt(sampleData.yearMonthTo.toString().substring(4, 6)),
      };

      let totalDays = 0;
      //orginal code, could be wrong if there is no special logic for the moment picker
      /*if (sampleData.yearMonthFrom === sampleData.yearMonthTo) {
          totalDays = moment().daysInMonth(start.year, start.month);
      } else {
          totalDays = moment().daysInMonth(start.year, start.month) + moment().daysInMonth(start.year, start.month);
      }*/
      //orginal code

      if(sampleData.yearMonthFrom === sampleData.yearMonthTo) {
          totalDays = moment(start.year + '-' + start.month, 'YYYY-MM').daysInMonth();
      } else {
          totalDays = moment(end.year + '-' + end.month + '-' + moment(end.year + '-' + end.month, 'YYYY-MM').daysInMonth(), 'YYYY-MM-DD')
          .diff(moment(start.year + '-' + (start.month) + '-01', 'YYYY-MM-DD'), 'days', true) + 1;
      }
      this.totalDays = totalDays;
    }
  }
  
  datePickerChange()
  {
    //ngx-bootstrap, databinding gives the date object instead of string
    let dateObj = this.dashboardDate;

    //var updatedValue = typeof dateObj.start === 'string' ? 'string' : 'not string';
    
    if(dateObj.start instanceof Date)
    {
      this.dashboardDate.start = 
        (dateObj.start.getMonth() < 9 && dateObj.start.getMonth() >= 0) ? '0' + (dateObj.start.getMonth() +1) +'/' + dateObj.start.getFullYear() : (dateObj.start.getMonth() +1) +'/' + dateObj.start.getFullYear();;
    }
    if(dateObj.end instanceof Date)
    {
      this.dashboardDate.end = 
        (dateObj.end.getMonth() < 9 && dateObj.end.getMonth() >= 0) ? '0' + (dateObj.end.getMonth() +1) +'/' + dateObj.end.getFullYear() : (dateObj.end.getMonth() +1) +'/' + dateObj.end.getFullYear();
    }

    
    if(moment(this.dashboardDate.start, 'MM/yyyy') > moment(this.dashboardDate.end, 'MM/yyyy')) return;
    
    this.loadData();
  }

  //scoring
  generateScoringTable(){
    this.scoringDataForView = [];

    let individualPer = _.find(this.scoringData, (item) => { return item.CategoryID == 11 || item.Category === 'Individual Performance' });
    if(individualPer)
    {
        this.individualPerData = [{category: 'Individual High Risk Drivers', unit: '', value:  individualPer ? individualPer.IndividualPerformanceNum: null }];
        this.scoringData.pop();
    }
    else{ this.individualPerData = [];}

    this.scoringData.forEach((item) => {
      if(item.CategoryID == 8)// Territory, get the territory Telefactor from UI
      {
        item.Score = this.territoryFactorSum() * item.Weighting;
      }

      this.scoringDataForView.push(
        {
          "category": item.Category, 
          "mean": item.Mean, 
          "point":Number((item.Score - item.Mean).toFixed(2))
        });
    });

    //set scoring band and data
    if(this.scoringDataForView.length > 0)
    {
      let totalScore = this.scoringDataForView.reduce((s, f) => {
        return s + f.point;},0).toFixed(2);
      
      this.scoringBandDivOn = true;
      this.bandScoreStore = totalScore;
      //if(this.dashboards.activeDash == 'Scoring')   seems that before convertion, the slider div has on-init to refresh the slider at data change
      if(true)   
      { 
        if(this.scoringBandSlider)
        {   
          //slider has weird tooltip display on -3 etc, it will generate a lot of precision, here, use innerHTML to get around the bug
          this.scoringBandSlider.noUiSlider.set(totalScore);
          this.scoringBandSlider.getElementsByClassName('noUi-tooltip')[0].innerHTML = Math.round(totalScore);


          //new value could be over the initial range, need to re-initiate the slider with bigger range
          let min =this.scoringBandSlider.noUiSlider.options.range.min[0];
          let max = this.scoringBandSlider.noUiSlider.options.range.max[0];

          if(this.bandScoreStore < min || this.bandScoreStore > max )
          {
            this.scoringBandSlider.noUiSlider.destroy();
            this.initiateScoringSlider();
          }
        } 
        else
        { this.initiateScoringSlider(); }
      }
    }
    else
    { this.scoringBandDivOn = false; }

    //scoring table
    this.scoringTblDataSource.sort = this.sort; //adding sort before add data source to prevent from some wired isse

    //populate the data for UI table
    let scoringViewTbl = _.toArray(this.scoringDataForView);
    if(this.scoringDataForView.length >0)
    {
      scoringViewTbl.push({"category": "Total", "mean": '', "point": this.scoringDataForView.reduce((s, f) => {
        return s + f.point;},0).toFixed(2)});
    }
    this.scoringTblDataSource = new MatTableDataSource(scoringViewTbl);
    //this.sort.sort({ id: '', start: 'asc', disableClear: false });
    //keep orignial order
    this.scoringTblOriginal = _.toArray(scoringViewTbl);
  }
  
  scoringSortChange(e) {
    //sort the items without Total
    this.scoringTblDataSource.filteredData.pop(); // pop out the total before sorting

    this.scoringTblDataSource.sort = this.sort;
    let sortedWithoutTotal = this.scoringTblDataSource.sortData(this.scoringTblDataSource.filteredData, this.sort);//use datasource embeded method
    //add total back after sorting
    let scoringViewTbl = _.toArray(sortedWithoutTotal);
    if(this.scoringDataForView.length >0)
    {
      scoringViewTbl.push({"category": "Total", "mean": '', "point": sortedWithoutTotal.reduce((s, f) => {
        return s + f.point;},0).toFixed(2)});
    }
    this.scoringTblDataSource = new MatTableDataSource(scoringViewTbl);
    //sort the items without Total

    //d3 chart refresh here after sorting
    if(e.direction)
    {
      this.scoringChartData[0].values = [];
      this.scoringTblDataSource.filteredData.forEach((item)=> {
        if(item.category !== 'Total')
        {
          this.scoringChartData[0].values.push(item);
        }
      });
      this.setScoringChartData(this.scoringChartData[0].values);
    }
    //to decide if it is needed to bring to original order of tble and chart
    else
    {
      //set to original for both tbl and chart
      this.scoringChartData[0].values = [];
      this.scoringTblOriginal.forEach((item)=> {
        if(item.category !== 'Total')
        {
          this.scoringChartData[0].values.push(item);
        }
      });
      this.setScoringChartData(this.scoringChartData[0].values);

      //tbl
      this.scoringTblDataSource = new MatTableDataSource(_.toArray(this.scoringTblOriginal));
    }
  }

  initiateScoringSlider() {
    //testing
    //this.bandScoreStore = '33';

    var t = _.isEmpty(this.bandScoreStore);
    if(_.isEmpty(this.bandScoreStore)) return;

    
    let lowest = this.bandScoreStore < -40 ? Number((Number(this.bandScoreStore) - (Number(this.bandScoreStore)% 10) - 20).toFixed(0)) : -40;
    let highest = this.bandScoreStore > 100 ?  Number((Number(this.bandScoreStore) - (Number(this.bandScoreStore)% 10) + 20).toFixed(0)): 100;

    const tickCount = Number(((highest - lowest) / 20).toFixed(0));

    let tickLst = [];
    let tick = lowest;
    for (let i = 0; i< tickCount; i++)
    {
      if(i == tickCount -1)
      {
          tickLst.push(highest);
      }
      else if(i == 0)
      {
          tickLst.push(lowest);
      }
      else
      {
          tickLst.push(tick += 20);
      }
    }

    
    const slider =  this.scoringSlider.nativeElement;
    //let slider = document.getElementById('scoringSlider') as HTMLInputElement; //document.getElementById('scoringSlider');

    noUiSlider.create(slider, {
      start: [lowest],
      connect:  [false, false],
      range: {
        'min': [lowest],
        'max': [highest]
      },
      step: 1,
      tooltips: [wNumb({decimal : 0})],
      pips: {

        mode: 'values',
        values: tickLst,//[lowest, 50, 100, 150, 200, 250, 300,350,highest],
        density: 20,
        stepped: true,
        filter: ()=> { return -1;} //no pipe
        //format: wNumb({decimals: 2})
      }
    });
    let connect = slider.querySelectorAll('.noUi-connect');
    let background = slider.getElementsByClassName('noUi-connects');

    let greenSeries = ['#17c266','#55e209','#71DE05', '#95E007', '#B8E209','#9de209','#afe209', '#c9e209', '#80c217'];
    let redSeries = ['#e4eb15','#E98F13', '#EB7015','#ED5118','#EE331A', '#F01D25','#F21F48'];
    let finialColors = [];

    let startIndex = null;
    if(tickLst.indexOf(0) == -1)
    {
        for(let i =0 ; i < tickLst.length; i++)
        {
            if(tickLst[i] < 0 && tickLst[i+1] > 0)
            {
                startIndex = i;
                break;
            }
        }
    }
    else
    {
        startIndex = tickLst.indexOf(0);
    }

    for(let i = 0; i <= startIndex; i++)
    {
        if(i < startIndex)
        {
            finialColors.push(greenSeries[i]);
        }
        else if(i == startIndex)
        {
            finialColors.push(greenSeries[greenSeries.length -1]);
        }
    }
    finialColors = finialColors.concat(redSeries);
    background[0].setAttribute("style", "background: linear-gradient(to right," + finialColors +");");
    // make slider color gradient according to the ticks change;

    slider.classList.add('noUi-horizontal-Scoring')// slider css, created a custom class, and added to classlist
    let element = slider.getElementsByClassName("noUi-pips-horizontal");
    $('.' + element[0].classList[1]).css('padding-top',0);

    slider.setAttribute('disabled', true);
    let bandScore = Math.round(this.bandScoreStore);

    slider.noUiSlider.set(bandScore);

    //slider has weird tooltip display on -3 etc, it will generate a lot of precision, here, use innerHTML to get around the bug
    let handle  = document.getElementsByClassName('noUi-tooltip');
    handle[0].innerHTML = bandScore.toString();

    this.scoringBandSlider = slider;
  }

  private setScoringChartData(data?) {
    if(data){ this.scoringDataForView = data;}

    if(this.scoringDataForView.length == 0)
    {
      this.scoringChartOn = false;
      this.scoringChartData = [];
      
      return;
    }

    let scoringChartDataObj = { key: "Difference from Mean", values: null };
    let scoringChartCateDataObjLst = []; 

    this.scoringDataForView.forEach((item) => {
        scoringChartCateDataObjLst.push(
          {"label": item.category, "value": item.point }
        ); 
    });

    //scoringChartCateDataObjLst.sort(function (a, b) { return a.value - b.value});
    scoringChartDataObj.values = scoringChartCateDataObjLst;
    this.scoringChartData = [];
    this.scoringChartData.push(scoringChartDataObj);

    if(this.scoringChartData.length > 0)
    {
      this.scoringChartOn = true;
    }
  }

  private setScoringChartOption()
  {
    this.scoringChartOptions = {
      chart: {
        type: 'multiBarHorizontalChart',
        height: 640, //600
        //width: 365,
        x: function(d,i){
          return d.label;
        },
        y: function(d,i){
          //if(d.key== 'Points' && d.correctedValue && d.correctedValue >0){return d.correctedValue;}
          //else{ return d.value; }
          return d.value;
        },
        //yErr: function(d){ return [-Math.abs(d.value * Math.random() * 0.3), Math.abs(d.value * Math.random() * 0.3)] },
        showControls: false,
        showValues: false,
        duration: 500,
        xAxis: {
          showMaxMin: false,
          //axisLabel: "Road Type",
          //axisLabelDistance: -8,
        },
        yAxis: {
            axisLabel: '',
            tickFormat: function(d,o){
              return d3.format(',.1f')(d);
            }
        },
        margin: {
            top: 50, //1
            right: 15,
            bottom: 30, //15
            left:140
        },
        showYAxis: true,
        showXAxis: true,
        stacked: true,
        showLegend: false,
        //color: ['rgb(11,108,205)', 'rgb(13,139,197)', 'rgb(220,220,220)', 'rgb(244,20,228)'],
        //color: ['rgb(13,139,197)', 'rgb(244,20,228)'],
        /*color: function (d,i,o){
                var t = d;
                if(d.value > 40)
                    {return 'rgb(13,139,197)';}
                else{
                    return 'rgb(244,20,228)';
                    }
        },*/
        multibar: {
            //color:  ['rgb(13,139,197)', 'rgb(244,20,228)'],
            barColor: 
            function(d,i,o)
              {
                if(d.value < 0)
                { return 'rgb(11,108,205)'; }//'rgb(13,139,197)'; 
                else
                { return 'rgb(244,20,228)'; }
              },
            margin: {
                      top:0,
                      right: 0,
                      bottom :0,
                      left:0
                    }  
        },
        forceY: [-20, 10],
        groupSpacing:0.10, //0.20
        valuePadding: 5,
        tooltip: {
          duration: 0,
          hideDelay: 0,
          valueFormatter: function(d, i, object) {
            //object1.color = 'green';
            return d;
          },
          keyFormatter: function(d, i) {
            return d ;
          },
          headerFormatter: function(d) {
            return d;
          }
        }
        //yRange: [0,242]
        //yRange: [0,385]
      }
    }
  }

  //Utilization
  generateUtilTable() {
    //for sorting on contional VIN or Vehicleid 
    this.utilData.forEach((item) => {
      item.sortBase = item.vin && item.vin !== 'N/A' ? item.vin.replace(item.vin.slice(0, (item.vin.length) - 6), "").toString() +'/' : '/' + item.vehicleId
    });

    let utilViewData = _.toArray(this.utilData);

    
    this.utilTblDataSource = new MatTableDataSource(utilViewData);
    this.utilTblDataSource.sort = this.sortUtil;
    this.utilTblDataSource.paginator = this.paginatorUtil;

  }
  // setActiveUtilization(speedPoint, event) {
  //   $('#utilization tr.selected').each(function(index) {
  //     $(this).removeClass('selected');
  //   });

  //   //var t = $('#utilization tr.selected');
  //   var tt = document.getElementById('utilization') as HTMLInputElement;
  //   this.render.removeClass(document.getElementById('utilization'), 'selected');
  //   this.render.addClass(event.currentTarget, 'selected');

  // }

  //territory
  territoryFactorSum()
  {
    let sum= 0;
    let sumTotalMilesAllVehicle= 0;
    let sumGarageRateAv= 0;
      
    this.territoryData.forEach(function(item)
    { 
      sum += item.stats.telerateMultMonthAve * item.stats.Sum_TotalMiles; 
      sumGarageRateAv += item.stats.garagerateMultMonthAve * item.stats.Sum_TotalMiles;
      sumTotalMilesAllVehicle += item.stats.Sum_TotalMiles;
    });

    return (sum/sumTotalMilesAllVehicle) / (sumGarageRateAv /sumTotalMilesAllVehicle);
  }

  openExport() {
    let dialogRef = this.dialog.open(DashdataexportModalComponent, {
      data: {'mcCompany': this.dashboardCompanyName, 'date': this.dashboardDate , 'data': this.getDashData()},
      width: '20%',
      //height: '35%',
      id: 'modal-component', //to match the global css
      position: { 
        top: '1%',
        left :'40%',
        right: ''
      }
    });


  }

  getDashData() {
                
    let program = this.dashboardCompanyProgram;
    let scoringData = this.scoringData;
    let utilData = this.utilData;
    let radiusData = this.radiusData;
    //var addrData = $scope.addrSet
    let territoryData = this.territoryData;
    let speedingData = this.speedingData;
    
    let driverScoreCardData = this.driverScoreCardData;
    let harshEventData = this.harshEventData;

    //Driver
    let driverUtilData = this.utilData_Driver;
    let driverHarshEventData = this.harshEventData_Driver;
    let driverSpeedingData = this.speedingData_Driver;

    //scoringSheet
    const scoringSheet = [];
    const individualPer = this.individualPerData;// defined and assigned in generateScoringTable function//_.find(scoringData, function(item){ return item.CategoryID == 11 || item.Category === 'Individual Performance' });
    if(!_.isEmpty(individualPer))
    {
      var individualPerData = {Category: individualPer[0].category, unit: '', Value:  individualPer ? individualPer[0].value: null };
        //scoringData.pop();
    }

    scoringData.forEach((item) => {
      if(item.CategoryID == 8)// Territory, get the territory Telefactor from UI
      {
        item.Score = this.territoryFactorSum() * item.Weighting;
      }
      scoringSheet.push({
        "Category" : item.Category,
        "Mean" : item.Mean,
        "Points": Number((item.Score - item.Mean).toFixed(2))
      });

    });
    scoringSheet.push({"Category": "Total", "Mean": '', "Points": Number(scoringSheet.reduce((s, f) => {
                    return s + f.Points;},0).toFixed(2))});


    //utilSheet
    const utilSheet = [];
    utilData.forEach((item) => {
      utilSheet.push({
        "Vehicle ID" : item.vehicleId.toString(),
        "VIN" : item.vin,
        "Vehicle Type" : item.vehicleType,
        "Days Operational": item.daysOperational + "("+ Math.trunc(item.daysOperational / this.totalDays * 100) + "%)",
        "Total Mi." : item.totalMileage,
        "Total On Time": item.sum_NetOnTimeHr,
        "Av. Shift": item.averageOfSum_NetOnTimeHr,
        "Mi./Day": item.dailyMiles
      });
    });

    //driver utilSheet
    const driverUtilSheet = [];
    driverUtilData.forEach((item) => {
      driverUtilSheet.push({
        "Driver ID" : item.driverId.toString(),
        "Driver name" : item.driverName,
        "Days Operational": item.daysOperational + "("+ Math.trunc(item.daysOperational / this.totalDays * 100) + "%)",
        "Total Mi." : item.totalMileage,
        "Total On Time": item.sum_NetOnTimeHr,
        "Av. Shift": item.averageOfSum_NetOnTimeHr,
        "Mi./Day": item.dailyMiles
      });
    });



    //radiusSheet
    const radiusSheet =  [];
    radiusData.forEach((item) => {
      radiusSheet.push({
        "Vehicle ID": item.vehicleId.toString(),
        "VIN" : item.vin,
        "Vehicle Type": item.vehicleType,
        "0 - 50": item.sum_Miles_Under_50,
        "0 - 50(%)" : (item.sum_TotalMiles == 0 ? 0 : (item.sum_Miles_Under_50 / item.sum_TotalMiles * 100)).toFixed(1) + "%",
        "50 - 200" : (item.sum_Miles_50_100 + item.sum_Miles_100_150 + item.sum_Miles_150_200).toFixed(2),
        "50 - 200(%)": (item.sum_TotalMiles == 0 ? 0 : (item.sum_Miles_50_100 + item.sum_Miles_100_150 + item.sum_Miles_150_200) / item.sum_TotalMiles * 100).toFixed(1) + "%",
        "Over 200": (item.sum_Miles_200_400 + item.sum_Miles_400_700 + item.sum_Miles_Over_700).toFixed(2),
        "Over 200(%)" : (item.sum_TotalMiles == 0 ? 0 : (item.sum_Miles_200_400 + item.sum_Miles_400_700 + item.sum_Miles_Over_700) / item.sum_TotalMiles * 100).toFixed(1) +"%",
        "Garaging Street": item.primary_Garaging_Address1,
        "Garaging City": item.primary_Garaging_city,
        "Garaging State": item.primary_Garaging_State,
        "Garaging Zip": item.primary_Garaging_Zip
      });
    });

    //territorySheet
    const territorySheet = [];
    territoryData.forEach((item)=> { 
      territorySheet.push({
        "Vehicle ID": item.stats.VehicleNumber.toString(),
        "VIN" : item.stats.vin,
        "Vehicle Type": item.stats.vehicleType,
        "Garaging City": item.stats.primary_Garaging_City,
        "State": item.stats.primary_Garaging_State,
        "Zip": item.stats.primary_Garaging_Zip,
        "Terr.": item.stats.garageZone == null ? 'N/A' : item.stats.garageZone,
        "TeleFactor" :  item.stats.garagerateMultMonthAve == 0 ? 'N/A' : (item.stats.telerateMultMonthAve / item.stats.garagerateMultMonthAve * 100).toFixed(1) + '%',
        "Total Miles Driven": item.stats.Sum_TotalMiles
      });
    });

    const teleFactor = [{"name": "Fleet TeleFactor", "value": (this.territoryFactorSum() * 100).toFixed(1) + "%"}];

    //speedingSheet
    const speedingSheet = [];
    const speedingTrend = [];
    
    speedingData.forEach((item) => {

      speedingSheet.push({
      "Vehicle ID" : item.vehicleId.toString(),
      "VIN" : item.vin,
      "Vehicle Type" : item.vehicleType,
      "Under 5" : item.sum_TotalMovingHr == 0 ? 0 : (item.sum_Speeding_Under_5mph_ByTime / 3600 * 100 / item.sum_TotalMovingHr).toFixed(1) + "%",
      ["Under 5 " + program + "(PR)"]: item.speeding_Under_5mph_ByTime_Percentile.toFixed(1) + "PR",
      "Under 5 All Drivers(PR)": item.speeding_Under_5mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",

      "5 To 10" : item.sum_TotalMovingHr == 0 ? 0 : (item.sum_Speeding_5_10mph_ByTime / 3600 * 100 / item.sum_TotalMovingHr).toFixed(1) + "%",
      ["5 To 10 " + program + "(PR)"] : item.speeding_5_10mph_ByTime_Percentile.toFixed(1) + "PR",
      "5 To 10 All Drivers(PR)" : item.speeding_5_10mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",

      "10 To 15" : item.sum_TotalMovingHr == 0 ? 0 : (item.sum_Speeding_10_15mph_ByTime /3600 * 100 / item.sum_TotalMovingHr).toFixed(1) + "%",
      ["10 To 15 " + program + "(PR)"]: item.speeding_10_15mph_ByTime_Percentile.toFixed(1) + "PR",
      "10 To 15 All Drivers(PR)": item.speeding_10_15mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",

      "Over 15" : item.sum_TotalMovingHr == 0 ? 0 : (item.sum_Speeding_Over_15mph_ByTime /3600 * 100 / item.sum_TotalMovingHr).toFixed(1) + "%", 
      ["Over 15 " + program + "(PR)"]: item.speeding_Over_15mph_ByTime_Percentile.toFixed(1) + "PR",
      "Over 15 All Drivers(PR)": item.speeding_Over_15mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",

      "Total Speeding": (item.sum_TotalMovingHr == 0 ? 0 : 
          (item.sum_Speeding_Under_5mph_ByTime + item.sum_Speeding_5_10mph_ByTime + item.sum_Speeding_10_15mph_ByTime + item.sum_Speeding_Over_15mph_ByTime)/ 3600 *100 / item.sum_TotalMovingHr).toFixed(1) + "%",
      ["Total Speeding " + program + "(PR)"]: item.total_Speeding_ByTime_Percentile.toFixed(1) + "PR",
      "Total Speeding All Drivers(PR)": item.total_Speeding_ByTime_Percentile_AllProgm.toFixed(1) + "PR",

      "Total Mileage": item.sum_TotalMiles.toFixed(1),

      });

      //speedingTrend to seprate list
      item.speedingTrendLst.forEach((trendItem) => {
        speedingTrend.push({
          vehicleId : item.vehicleId.toString(),
          totalSpeedingPerc: trendItem.sum_MovingTime_Hr ==0 || !trendItem.sum_MovingTime_Hr  ? 0 +"%" : (trendItem.totalSpeedingSec / 3600 / trendItem.sum_MovingTime_Hr * 100).toFixed(1) + "%" ,
          yearMonth : trendItem.yearMonth
        });
      });
    });

    const fleetSpeedingSumm = [ {"name":"under 5" ,"value": (this.FleetData.u5Sum / 3600 * 100/ this.FleetData.totalMovingHrSum).toFixed(1) +"%" },
                              {"name": "5- 10", "value": (this.FleetData._5T10Sum / 3600 * 100/ this.FleetData.totalMovingHrSum).toFixed(1) +"%" },
                              {"name": "10- 15", "value": (this.FleetData._10T15Sum / 3600 * 100/ this.FleetData.totalMovingHrSum).toFixed(1) +"%" },
                              {"name": "over15", "value": (this.FleetData.o15Sum /3600 * 100/ this.FleetData.totalMovingHrSum).toFixed(1) +"%" }
                            ];


    //Driver score card
    const dsCardSheet = [];
    const comparsionTbl = [];
    
    //trending
    driverScoreCardData.speedingTrendData.forEach((item) => {
      dsCardSheet.push({
        "Month": item.YearMonth.toString().slice(4) + '-' + item.YearMonth.toString().slice(0,4) ,
        "Under 5" : (item.Sum_MovingTime_Hr >0 ? item.SpeedingTrend_Under_5_ByTime / 3600 / item.Sum_MovingTime_Hr * 100 : 0).toFixed(1) + "%",
        "5 To 10": (item.Sum_MovingTime_Hr >0 ? item.SpeedingTrend_5_10_ByTime / 3600 / item.Sum_MovingTime_Hr * 100 : 0).toFixed(1) + "%",
        "10 To 15": (item.Sum_MovingTime_Hr >0 ? item.SpeedingTrend_10_15_ByTime / 3600 / item.Sum_MovingTime_Hr * 100 : 0).toFixed(1) + "%",
        "Over 15" : (item.Sum_MovingTime_Hr >0 ? item.SpeedingTrend_Over_15_ByTime / 3600 / item.Sum_MovingTime_Hr * 100 : 0).toFixed(1) + "%"
      });
    });
    //comparison
    const comparsionSource = driverScoreCardData.companyComparisonData;
    if(comparsionSource)
    {
      comparsionTbl.push({
        "Categories": "Your Company",
        "Under 5" : (comparsionSource.companyDataDash.Sum_MovingTime_Hr >0 ? comparsionSource.companyDataDash.Sum_Speeding_Under_5mph_ByTime / 3600 / comparsionSource.companyDataDash.Sum_MovingTime_Hr * 100 : 0).toFixed(1) + "%",
        "5 To 10": (comparsionSource.companyDataDash.Sum_MovingTime_Hr >0 ? comparsionSource.companyDataDash.Sum_Speeding_5_10mph_ByTime / 3600 / comparsionSource.companyDataDash.Sum_MovingTime_Hr * 100 : 0).toFixed(1) + "%",
        "10 To 15": (comparsionSource.companyDataDash.Sum_MovingTime_Hr >0 ? comparsionSource.companyDataDash.Sum_Speeding_10_15mph_ByTime / 3600 / comparsionSource.companyDataDash.Sum_MovingTime_Hr * 100 : 0).toFixed(1) + "%",
        "Over 15" : (comparsionSource.companyDataDash.Sum_MovingTime_Hr >0 ? comparsionSource.companyDataDash.Sum_Speeding_Over_15mph_ByTime / 3600 / comparsionSource.companyDataDash.Sum_MovingTime_Hr * 100 : 0).toFixed(1) + "%"
      })
      comparsionTbl.push({
        "Categories": "Captive -" + program ,
        "Under 5" : (comparsionSource.programCompanyDataDash.Sum_MovingTime_Hr >0 ? comparsionSource.programCompanyDataDash.Sum_Speeding_Under_5mph_ByTime / 3600 / comparsionSource.programCompanyDataDash.Sum_MovingTime_Hr * 100 : 0).toFixed(1) + "%",
        "5 To 10": (comparsionSource.programCompanyDataDash.Sum_MovingTime_Hr >0 ? comparsionSource.programCompanyDataDash.Sum_Speeding_5_10mph_ByTime / 3600 / comparsionSource.programCompanyDataDash.Sum_MovingTime_Hr * 100 : 0).toFixed(1) + "%",
        "10 To 15": (comparsionSource.programCompanyDataDash.Sum_MovingTime_Hr >0 ? comparsionSource.programCompanyDataDash.Sum_Speeding_10_15mph_ByTime / 3600 / comparsionSource.programCompanyDataDash.Sum_MovingTime_Hr * 100 : 0).toFixed(1) + "%",
        "Over 15" : (comparsionSource.programCompanyDataDash.Sum_MovingTime_Hr >0 ? comparsionSource.programCompanyDataDash.Sum_Speeding_Over_15mph_ByTime / 3600 / comparsionSource.programCompanyDataDash.Sum_MovingTime_Hr * 100 : 0).toFixed(1) + "%"
      })
      comparsionTbl.push({
        "Categories": "Captive - All Programs",
        "Under 5" : (comparsionSource.allCompanyDataDash.Sum_MovingTime_Hr >0 ? comparsionSource.allCompanyDataDash.Sum_Speeding_Under_5mph_ByTime / 3600 / comparsionSource.allCompanyDataDash.Sum_MovingTime_Hr * 100 : 0).toFixed(1) + "%",
        "5 To 10": (comparsionSource.allCompanyDataDash.Sum_MovingTime_Hr >0 ? comparsionSource.allCompanyDataDash.Sum_Speeding_5_10mph_ByTime / 3600 / comparsionSource.allCompanyDataDash.Sum_MovingTime_Hr * 100 : 0).toFixed(1) + "%",
        "10 To 15": (comparsionSource.allCompanyDataDash.Sum_MovingTime_Hr >0 ? comparsionSource.allCompanyDataDash.Sum_Speeding_10_15mph_ByTime / 3600 / comparsionSource.allCompanyDataDash.Sum_MovingTime_Hr * 100 : 0).toFixed(1) + "%",
        "Over 15" : (comparsionSource.allCompanyDataDash.Sum_MovingTime_Hr >0 ? comparsionSource.allCompanyDataDash.Sum_Speeding_Over_15mph_ByTime / 3600 / comparsionSource.allCompanyDataDash.Sum_MovingTime_Hr * 100 : 0).toFixed(1) + "%"
      })
    }

    //harshevent
    const hashEventSheet = [];

    let harshEventDataSum = this.convertHarsheventData(harshEventData, false);
    
    harshEventDataSum.forEach((item) => {
      hashEventSheet.push({
        "Vehicle ID" : item.vehicleId.toString(),
        "VIN" : item.vin,
        "Vehicle Type" : item.vehicleType,
        "Brakes":  item.sum_Harsh_Brake > 0 ? item.sum_Harsh_Brake : '_',
        "Brakes (per 1k)":  item.sum_TotalMiles > 0  && item.sum_Harsh_Brake > 0 ? (item.sum_Harsh_Brake * 1000 / item.sum_TotalMiles).toFixed(2) : '_',
        ["Brakes (per 1k) " + program + "(PR)"]: item.brake_Percentile.toFixed(1) + "PR",
        "Brakes (per 1k) All Drivers(PR)": item.brake_Percentile_AllProgm.toFixed(1) + "PR",

        "Corner" : item.sum_Harsh_Corner > 0 ? item.sum_Harsh_Corner : '_',
        "Corner (per 1k)" : item.sum_TotalMiles > 0 && item.sum_Harsh_Corner > 0 ? (item.sum_Harsh_Corner * 1000 / item.sum_TotalMiles).toFixed(2) : '_',
        ["Corner (per 1k) " + program + "(PR)"]: item.corner_Percentile.toFixed(1) + "PR",
        "Corner (per 1k) All Drivers(PR)": item.corner_Percentile_AllProgm.toFixed(1) + "PR",

        "Accel.": item.sum_Harsh_Quick_Accelaration >0 ? item.sum_Harsh_Quick_Accelaration : '_',
        "Accel. (per 1k)": item.sum_TotalMiles > 0 && item.sum_Harsh_Quick_Accelaration >0 ? (item.sum_Harsh_Quick_Accelaration * 1000 / item.sum_TotalMiles).toFixed(2) : '_',
        ["Accel. (per 1k) " + program + "(PR)"]: item.accel_Percentile.toFixed(1) + "PR",
        "Accel. (per 1k) All Drivers(PR)": item.accel_Percentile_AllProgm.toFixed(1) + "PR",

        "Total Mil.": item.sum_TotalMiles.toFixed(1),
      });
    });
    //harshevent

    //Driver harshevent
    const driverHashEventSheet = [];

    
    let driverHarshEventDataSum = this.convertHarsheventData(driverHarshEventData, true);

    driverHarshEventDataSum.forEach((item) => {
      driverHashEventSheet.push({
        "Driver ID" : item.driverId.toString(),
        "Driver Name" : item.driverName,
        "Brakes":  item.sum_Harsh_Brake > 0 ? item.sum_Harsh_Brake : '_',
        "Brakes (per 1k)":  item.sum_TotalMiles > 0  && item.sum_Harsh_Brake > 0 ? (item.sum_Harsh_Brake * 1000 / item.sum_TotalMiles).toFixed(2) : '_',
        ["Brakes (per 1k) " + program + "(PR)"]: item.brake_Percentile.toFixed(1) + "PR",
        "Brakes (per 1k) All Drivers(PR)": item.brake_Percentile_AllProgm.toFixed(1) + "PR",


        "Corner" : item.sum_Harsh_Corner > 0 ? item.sum_Harsh_Corner : '_',
        "Corner (per 1k)" : item.sum_TotalMiles > 0 && item.sum_Harsh_Corner > 0 ? (item.sum_Harsh_Corner * 1000 / item.sum_TotalMiles).toFixed(2) : '_',
        ["Corner (per 1k) " + program + "(PR)"]: item.corner_Percentile.toFixed(1) + "PR",
        "Corner (per 1k) All Drivers(PR)": item.corner_Percentile_AllProgm.toFixed(1) + "PR",


        "Accel.": item.sum_Harsh_Quick_Accelaration >0 ? item.sum_Harsh_Quick_Accelaration : '_',
        "Accel. (per 1k)": item.sum_TotalMiles > 0 && item.sum_Harsh_Quick_Accelaration >0 ? (item.sum_Harsh_Quick_Accelaration * 1000 / item.sum_TotalMiles).toFixed(2) : '_',
        ["Accel. (per 1k) " + program + "(PR)"]: item.accel_Percentile.toFixed(1) + "PR",
        "Accel. (per 1k) All Drivers(PR)": item.accel_Percentile_AllProgm.toFixed(1) + "PR",


        "Total Mil.": item.sum_TotalMiles.toFixed(1),
      });
    });
    //Driver harshevent

    //Driver speeding
    const driverSpeedingSheet = [];
    const driverSpeedingTrend = [];
     
    driverSpeedingData.forEach((item) => {
      driverSpeedingSheet.push({
       "Driver ID" : item.driverId.toString(),
       "Driver Name" : item.driverName,
       "Under 5" : item.sum_TotalMovingHr == 0 ? 0 : (item.sum_Speeding_Under_5mph_ByTime / 3600 * 100 / item.sum_TotalMovingHr).toFixed(1) + "%",
       ["Under 5 " + program + "(PR)"]: item.speeding_Under_5mph_ByTime_Percentile.toFixed(1) + "PR",
       "Under 5 All Drivers(PR)": item.speeding_Under_5mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
 
       "5 To 10" : item.sum_TotalMovingHr == 0 ? 0 : (item.sum_Speeding_5_10mph_ByTime / 3600 * 100 / item.sum_TotalMovingHr).toFixed(1) + "%",
       ["5 To 10 " + program + "(PR)"] : item.speeding_5_10mph_ByTime_Percentile.toFixed(1) + "PR",
       "5 To 10 All Drivers(PR)" : item.speeding_5_10mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
 
       "10 To 15" : item.sum_TotalMovingHr == 0 ? 0 : (item.sum_Speeding_10_15mph_ByTime /3600 * 100 / item.sum_TotalMovingHr).toFixed(1) + "%",
       ["10 To 15 " + program + "(PR)"]: item.speeding_10_15mph_ByTime_Percentile.toFixed(1) + "PR",
       "10 To 15 All Drivers(PR)": item.speeding_10_15mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
 
       "Over 15" : item.sum_TotalMovingHr == 0 ? 0 : (item.sum_Speeding_Over_15mph_ByTime /3600 * 100 / item.sum_TotalMovingHr).toFixed(1) + "%", 
       ["Over 15 " + program + "(PR)"]: item.speeding_Over_15mph_ByTime_Percentile.toFixed(1) + "PR",
       "Over 15 All Drivers(PR)": item.speeding_Over_15mph_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
 
       "Total Speeding": (item.sum_TotalMovingHr == 0 ? 0 : 
           (item.sum_Speeding_Under_5mph_ByTime + item.sum_Speeding_5_10mph_ByTime + item.sum_Speeding_10_15mph_ByTime + item.sum_Speeding_Over_15mph_ByTime)/ 3600 *100 / item.sum_TotalMovingHr).toFixed(1) + "%",
       ["Total Speeding " + program + "(PR)"]: item.total_Speeding_ByTime_Percentile.toFixed(1) + "PR",
       "Total Speeding All Drivers(PR)": item.total_Speeding_ByTime_Percentile_AllProgm.toFixed(1) + "PR",
 
       "Total Mileage": item.sum_TotalMiles.toFixed(1),
 
      });
 
      //speedingTrend to seprate list
      item.driverSpeedingTrendLst.forEach((trendItem) => {
        driverSpeedingTrend.push({
          driverId : item.driverId.toString(),
          totalSpeedingPerc: trendItem.sum_MovingTime_Hr ==0 || !trendItem.sum_MovingTime_Hr  ? 0 +"%" : (trendItem.totalSpeedingSec / 3600 / trendItem.sum_MovingTime_Hr * 100).toFixed(1) + "%" ,
          yearMonth : trendItem.yearMonth
        });
      });
    });
  

    const exportingSheets = [];

    exportingSheets.push({"id": 0, "sheet": "Scoring", "content": scoringSheet, "side": {"name": !_.isEmpty(individualPerData) ? individualPerData.Category :'',  "value": !_.isEmpty(individualPerData)? individualPerData.Value : '' }});
    exportingSheets.push({"id": 1, "sheet": "Utilization", "content": utilSheet, "content_driver" : driverUtilSheet});
    exportingSheets.push({"id": 2, "sheet": "Radius & Garaging", "content": radiusSheet});
    //exportingSheets.push({"id": 2, "sheet": ["Radius", "Garaging Addr."], "content": [radiusSheet, addrData]});
    //exportingSheets.push({"id": 3, "sheet": "Garaging Address", "content": addrData});
    exportingSheets.push({"id": 3, "sheet": "Territory", "content": territorySheet, "side": {"name" : "Fleet TeleFactor", "value" : teleFactor}});
    exportingSheets.push(
      {
        "id": 4, "sheet": "Speeding", 
        "content": speedingSheet, 
        "side": {"name" : "Fleet Speeding Summary", "value" : fleetSpeedingSumm},
        "side2" :{"name" : "Speeding Trend", "value" : speedingTrend},
        "content_driver": driverSpeedingSheet,
        "side_driver": {"name": "Driver Speeding Trend", "value" : driverSpeedingTrend},
      });
    exportingSheets.push({"id": 5, "sheet": "HarshEvent", "content": hashEventSheet, "content_driver": driverHashEventSheet });

    //exportingSheets.push({"id": 6, "sheet": "DriverScoreCard", "content": dsCardSheet, "side": {"name": "Speeding Trending Chart", "value": [] } , "side2": {"name": "Comparison", "value": comparsionTbl}, "side3": {"name": "Comparison Chart", "value": base64encodedstringComparison}  });

    /*                
    exportDataObj.utilSheet = utilSheet;
    exportDataObj.radiusSheet = radiusSheet;
    exportDataObj.addrSet = addrData;
    exportDataObj.territorySheet = territorySheet;
    exportDataObj.speedingSheet = speedingSheet;*/

    return exportingSheets;
  }

  //Driver
  calculateDriverPercentile(driverLst)
  {
    driverLst.forEach((item) => {
      if(item.sum_TotalMovingHr > 0)
      {
        item.speeding_Under_5mph_ByTime_Percentile = 100 * (item.driverRankUnder_5Perc - 0.5) / item.numOfDriverInProgram;
        item.speeding_5_10mph_ByTime_Percentile = 100 * (item.driverRank5_10Perc - 0.5) / item.numOfDriverInProgram;
        item.speeding_10_15mph_ByTime_Percentile = 100 * (item.driverRank10_15Perc - 0.5) / item.numOfDriverInProgram;
        item.speeding_Over_15mph_ByTime_Percentile = 100 * (item.driverRankOver_15Perc - 0.5) / item.numOfDriverInProgram;
        item.total_Speeding_ByTime_Percentile = 100 * (item.driverRankTotalSpeedingPerc - 0.5) / item.numOfDriverInProgram;

        item.speeding_Under_5mph_ByTime_Percentile_AllProgm = 100 * (item.driverRankUnder_5AllProgramPerc - 0.5) / item.numOfDriverInAllProgram;
        item.speeding_5_10mph_ByTime_Percentile_AllProgm = 100 * (item.driverRank5_10AllProgramPerc - 0.5) / item.numOfDriverInAllProgram;
        item.speeding_10_15mph_ByTime_Percentile_AllProgm = 100 * (item.driverRank10_15AllProgramPerc - 0.5) / item.numOfDriverInAllProgram;
        item.speeding_Over_15mph_ByTime_Percentile_AllProgm = 100 * (item.driverRankOver_15AllProgramPerc - 0.5) / item.numOfDriverInAllProgram;
        item.total_Speeding_ByTime_Percentile_AllProgm = 100 * (item.driverRankTotalSpeedingAllProgramPerc - 0.5) / item.numOfDriverInAllProgram;
      }
      else
      {
        item.speeding_Under_5mph_ByTime_Percentile = 0;

        item.speeding_5_10mph_ByTime_Percentile = 0;
        item.speeding_10_15mph_ByTime_Percentile = 0;
        item.speeding_Over_15mph_ByTime_Percentile = 0;
        item.total_Speeding_ByTime_Percentile = 0;

        item.speeding_Under_5mph_ByTime_Percentile_AllProgm = 0;
        item.speeding_5_10mph_ByTime_Percentile_AllProgm = 0;
        item.speeding_10_15mph_ByTime_Percentile_AllProgm = 0;
        item.speeding_Over_15mph_ByTime_Percentile_AllProgm = 0;
        item.total_Speeding_ByTime_Percentile_AllProgm = 0;
      }
    });
    return driverLst;
  }
  //Driver

  //for only Harshevent
  calculateHarsheventPercentile(harsheventLst, isDriver : boolean)
  {
    harsheventLst.forEach((item) => {
      if(item.sum_TotalMiles > 0)
      {
        item.brake_Percentile = 100 * (item.rankBrakePerc - 0.5) / (isDriver ? item.numOfDriverInProgram : item.numOfVehInProgram);
        item.accel_Percentile = 100 * (item.rankAccelPerc - 0.5) / (isDriver ? item.numOfDriverInProgram : item.numOfVehInProgram);
        item.corner_Percentile = 100 * (item.rankCornerPerc - 0.5) / (isDriver ? item.numOfDriverInProgram : item.numOfVehInProgram);
        

        item.brake_Percentile_AllProgm = 100 * (item.rankBrakeAllProgramPerc - 0.5) / (isDriver ? item.numOfDriverInAllProgram : item.numOfVehInAllProgram);
        item.accel_Percentile_AllProgm = 100 * (item.rankAccelAllProgramPerc - 0.5) / (isDriver ? item.numOfDriverInAllProgram : item.numOfVehInAllProgram);
        item.corner_Percentile_AllProgm = 100 * (item.rankCornerAllProgramPerc - 0.5) / (isDriver ? item.numOfDriverInAllProgram : item.numOfVehInAllProgram);
        
      }
      else
      {
        item.brake_Percentile = 0;
        item.accel_Percentile = 0;
        item.corner_Percentile = 0;
        

        item.brake_Percentile_AllProgm = 0;
        item.accel_Percentile_AllProgm = 0;
        item.corner_Percentile_AllProgm = 0;
        
      }
    });
    return harsheventLst;
  }

  convertHarsheventData (harshevents , isDriver : boolean)
  {

    let harsheventLst = this.calculateHarsheventPercentile(harshevents, isDriver);

    let grouped = [];
    if(isDriver)
    {
       grouped = _.toArray(_.groupBy(harsheventLst, g => g.driverId));
    }
    else{
       grouped = _.toArray( _.groupBy(harsheventLst, g => g.vehicleId));

    }
    

    let harshEventDataSum =[];

    grouped.forEach((item) => {

      let sumHarshBrake = 0;
      let sumHarshCorner = 0;
      let sumHarshQAccel = 0;

      item.forEach((element) =>{
        sumHarshBrake += element.sum_Harsh_Brake;
        sumHarshCorner += element.sum_Harsh_Corner;
        sumHarshQAccel += element.sum_Harsh_Quick_Accelaration;
      });

      if(!isDriver)
      {
        harshEventDataSum.push(
          {
            vehicleId: item[0].vehicleId, 
            vehicleNumber: item[0].vehicleNumber,
            vehicleType: item[0].vehicleType,
            vin: item[0].vin,


            mcId: item[0].mcId,
            months: item[0].months,
            sum_Harsh_Brake: sumHarshBrake,
            sum_Harsh_Corner: sumHarshCorner,
            sum_Harsh_Quick_Accelaration: sumHarshQAccel,
            sum_TotalMiles : item[0].sum_TotalMiles,
            yearMonthFrom : item[0].yearMonthFrom,
            yearMonthTo : item[0].yearMonthTo,
            

            brake_Percentile : item[0].brake_Percentile,
            accel_Percentile : item[0].accel_Percentile,
            corner_Percentile : item[0].corner_Percentile,

            brake_Percentile_AllProgm : item[0].brake_Percentile_AllProgm,
            accel_Percentile_AllProgm : item[0].accel_Percentile_AllProgm,
            corner_Percentile_AllProgm : item[0].corner_Percentile_AllProgm,

            numOfVehInProgram : item[0].numOfVehInProgram,
            numOfVehInAllProgram : item[0].numOfVehInAllProgram
          });
        }
        else {  
          harshEventDataSum.push(
            {
              driverId: item[0].driverId, 
              driverName: item[0].driverName, 
             
              mcId: item[0].mcId,
              months: item[0].months,
              sum_Harsh_Brake: sumHarshBrake,
              sum_Harsh_Corner: sumHarshCorner,
              sum_Harsh_Quick_Accelaration: sumHarshQAccel,
              sum_TotalMiles : item[0].sum_TotalMiles,
              yearMonthFrom : item[0].yearMonthFrom,
              yearMonthTo : item[0].yearMonthTo,
            
    
              brake_Percentile : item[0].brake_Percentile,
              accel_Percentile : item[0].accel_Percentile,
              corner_Percentile : item[0].corner_Percentile,
    
              brake_Percentile_AllProgm : item[0].brake_Percentile_AllProgm,
              accel_Percentile_AllProgm : item[0].accel_Percentile_AllProgm,
              corner_Percentile_AllProgm : item[0].corner_Percentile_AllProgm,
    
              numOfDriverInProgram : item[0].numOfDriverInProgram,
              numOfDriverInAllProgram : item[0].numOfDriverInAllProgram
            });
        }
    });

    return harshEventDataSum;
  }
  //Harshevent




  ngOnInit(): void {

    let routerMcID =  Number(this.activatedRouter.snapshot.paramMap.get('mcID'));
    let routerType = this.activatedRouter.snapshot.paramMap.get('type');
    let routerCompanyName = this.activatedRouter.snapshot.paramMap.get('companyName');
    let routerProgram = this.activatedRouter.snapshot.paramMap.get('insuranceProgram');

    if(this.authData.groupType == 1002) { //appConst.groupType.ICC
      this.dashboardMCID = routerMcID;//$rootScope.$stateParams.mcID;
      this.dashboardType = routerType//$rootScope.$stateParams.type;
      this.dashboardCompanyName = routerCompanyName;//$rootScope.$stateParams.companyName;
      this.dashboardCompanyProgram = routerProgram;//$rootScope.$stateParams.insuranceProgram;
      //console.log('mcID', $scope.dashboardMCID);
      //console.log('Type', $scope.dashboardType);
      if(this.dashboardType == "Report"){
        this.smUserRole = appConst.role.admin;
        this.smGroupType = appConst.groupType.MC;
      }
      else{
        this.smUserRole = this.authData.userRole;
        this.smGroupType = this.authData.groupType;
      }
    }
    else if(this.authData.groupType ==appConst.groupType.MC){ //appConst.groupType.MC
      this.dashboardMCID = this.authData.groupID;
      this.dashboardType = 'Report';

      //this is for MC only when getting into dashboard, also needs the program and companyname
      //this is the bug, and missing in the original angularJs when MC role logs in
      this.dataService._getGroupInfo(this.authData.groupID, this.authData.groupType).pipe(takeUntil(this.subNotifier)).subscribe(result => 
        {
          this.dashboardCompanyProgram = result.InsuranceProgram;
          this.dashboardCompanyName = result.CompanyName;
        }
      );
           
     
      this.smUserRole = this.authData.userRole;
      this.smGroupType = this.authData.groupType;
    }

    this.authService._getsmData(this.smUserRole, this.smGroupType, "DashBoardHtml").pipe(takeUntil(this.subNotifier)).subscribe(
      (result) => {
          if(!this.authService._accessFilter(result, "DashBoardHtml")){
              //$state.go('home');
              this.router.navigate(['/']);
          }

          this.smDashBoard.UtilizationDiv = this.authService._accessFilter(result, "UtilizationDiv");
          this.smDashBoard.RadiusAndGaragingDiv = this.authService._accessFilter(result, "RadiusAndGaragingDiv");
          this.smDashBoard.TerritoryDiv = this.authService._accessFilter(result, "TerritoryDiv");
          this.smDashBoard.SpeedingDiv = this.authService._accessFilter(result, "SpeedingDiv");
          this.smDashBoard.HarshEventsDiv = this.authService._accessFilter(result, "HarshEventsDiv");
          this.smDashBoard.FleetFootprintDiv = this.authService._accessFilter(result, "FleetFootprintDiv");
          this.smDashBoard.ScoringDiv = this.authService._accessFilter(result, "ScoringDiv");
          this.smDashBoard.DriverScoreCardDiv = this.authService._accessFilter(result, "DriverScoreCardDiv");

          if(this.smDashBoard.ScoringDiv) { this.dashViews.push("Scoring");}
          if(this.smDashBoard.UtilizationDiv) { this.dashViews.push("Utilization"); }
          if(this.smDashBoard.RadiusAndGaragingDiv) { this.dashViews.push("Radius & Garaging"); }
          if(this.smDashBoard.TerritoryDiv) { this.dashViews.push("Territory"); }
          if(this.smDashBoard.SpeedingDiv) { this.dashViews.push("Speeding"); }
          if(this.smDashBoard.HarshEventsDiv) { this.dashViews.push("Harsh Events"); }  //comment out due to lack of hash event data from ATG 
          if(this.smDashBoard.FleetFootprintDiv) { this.dashViews.push("Fleet Footprint"); }
          if(this.smDashBoard.DriverScoreCardDiv) { this.dashViews.push("Driver Score Card"); }

          this.dashboards = { activeDash: (this.dashViews.length > 0) ? this.dashViews[0] : '' };
      });
    
    let dateObj = new Date();

    let start = dateObj.getMonth() === 0 ? '12/' + (dateObj.getFullYear() -1).toString() :
        ((dateObj.getMonth() < 10 && dateObj.getMonth() > 0) ? '0' + dateObj.getMonth() +'/' + dateObj.getFullYear() : dateObj.getMonth() +'/' + dateObj.getFullYear());

    let end = dateObj.getMonth() === 0 ? '12/' + (dateObj.getFullYear() -1).toString() : 
      ((dateObj.getMonth() < 10 && dateObj.getMonth() > 0) ? '0' + dateObj.getMonth() +'/' + dateObj.getFullYear() : dateObj.getMonth() +'/' + dateObj.getFullYear());

    //start = '02/2022' //start, 
    //end = '02/2022';//end 
    this.dashboardDate.start = start;
    this.dashboardDate.end = end;

    //this.ng2Date.start = new Date(Number(start.split('/')[1]), Number(start.split('/')[0]) - 1);//takes month index here, so -1
    //this.ng2Date.end = new Date(Number(start.split('/')[1]), Number(start.split('/')[0]) - 1)

    this.loadData();//load all dashboard data

    //Load n3 opitions
    this.setScoringChartOption()
    

  }

  ngOnDestroy() {
    //unsubscribe
    this.subNotifier.next();
    this.subNotifier.complete();
  }
  

}


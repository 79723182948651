import { Component, OnInit, Output } from '@angular/core';
import { AuthService } from '../../Services/auth.service';
import { DataService } from '../../Services/data.service';
import {Router, ActivatedRoute} from '@angular/router'
import { CommonAppService } from '../../Services/common-app.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {

  private authData = JSON.parse(localStorage.getItem('authorizationData'));

  loginData = {
    userName: '',
    password: '',
    passwordCheck:''
  }
  
  message : string = '';
  success : boolean= false;
  changePass : boolean= false;
  disableUpdatePWBtn : boolean = true;

  constructor(private authService : AuthService, private dataService : DataService, private commonAppService : CommonAppService, private router : Router) { }

  reset(passedEmail : string) {
    this.authService._resetPass(passedEmail).subscribe((result) => {

      let httpResponse = parseInt(result);
      if (httpResponse == 202) {
        // Reset Worked
        this.success = true;
        this.message = "Success! Please check your email for your new password.";
      } else if (httpResponse == 400) {
        // Email Not Sent
        this.message = "Server Error: Please try again later";
      } else if (httpResponse == 403) {
        // Email Address Not Found
        this.message = "User Not Found";
      } else {
        // Unknown Error
        this.message = "Server Error: Please try again later";
      }

    }, (err) => {
      this.message = err.error.Message;
      //console.log('$scope.message', $scope.message);
    });
  };

  login() {
    this.authService._login(this.loginData).subscribe((response) => {
      this.changePass = true;
      this.success = false;
      this.loginData.password = "";
      this.loginData.passwordCheck = "";

      this.message = '';
    }, (err) => {
      this.success = false;
      this.message = err.error.Message;
      //console.log('$scope.message', $scope.message);
    
      
    
      this.router.navigate(['/reset']);
      
      this.loginData.password ='';
      this.message = 'Invalid Temporary Password. Please reset and check your email for correct password.';

    });
  };

  updatePass (loginData) {
               
    let authData = JSON.parse(localStorage.getItem('authorizationData'));

    this.dataService._updatePassword(loginData.password).subscribe((wasReset) => {

      if (wasReset) {
        this.commonAppService._snackBar(true, 'Password Updated');
        if (authData.groupType === 1001) {
            // alert('GroupType: MC');
          this.dataService._getGroupInfo().subscribe((groupInfo) => {
              //$state.go('companyInfo');
            this.commonAppService._loginSuccessRoute()
          });
        } else if (authData.groupType === 1002) {
            // alert('GroupType: ICC');
          this.dataService._getGroupInfo().subscribe((groupInfo) =>{
            //$state.go('mcList');
            this.commonAppService._loginSuccessRoute();
          });
        } else if (authData.groupType === 1003) {
          this.dataService._getGroupInfo().subscribe((groupInfo) =>{
            //$state.go('template');
            this.commonAppService._loginSuccessRoute();
          });
            // alert('GroupType: TSP');
        } 
        else if (authData.groupType === 1000) 
        {
            //super user
          this.dataService._getGroupInfo().subscribe((groupInfo) => {
            //$state.go('superAdmin'); TODO?
            this.commonAppService._loginSuccessRoute();
          });
        }
        else 
        {
          alert('GroupType: Mismatch');
        }
      }
    });
  };
  
  validatePW (value : string) {
    let pwRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})");
    if(!pwRegex.test(value)) {
      this.message = "Password must contain at least 6 characters, 1 captial letter, 1 number and 1 symbol";
      this.disableUpdatePWBtn = true;
    }
    else {
      this.disableUpdatePWBtn = false;
      this.message = null;
    } 
  };

  

  ngOnInit(): void {
    if(this.authService._authentication.isAuth)
    {
      this.loginData.userName = this.authService._authentication.userName;
    }
  }

}

<div class="modal-header">
    <button type="button" class="close" aria-hidden="true" (click)="close()">×</button>
    <h3 class="modal-title">Record Details</h3>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group label-floating">
                <label class="displaying" for="i4" class="control-label">Created By</label>
                <h4>{{ adminInfo.Name }}</h4>
            </div>
            <div class="form-group label-floating">
                <label class="displaying" for="i4" class="control-label">TSP</label>
                <h4>{{ recordObj.TSPCompanyName }}</h4>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group label-floating">
                <label class="displaying" for="i4" class="control-label">Created On</label>
                <h4>{{ recordObj.ModifiedDate }}</h4>
            </div>
            <div class="form-group label-floating">
                <label class="displaying" for="i4" class="control-label">Created At</label>
                <h4>{{ recordObj.IpAddress }}</h4>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-danger" (click)="revokeConsent()" *ngIf="recordObj.TemplateType == 1 && groupInfo.GroupType === 1001">Revoke Consent</button>
    <button class="btn btn-info"  (click)="downloadPDF(recordObj.SignatureRequestId)">Download PDF</button>
    <button class="btn btn-success" type="button" (click)="close()">Close</button>
</div>

<div>
    <div>
        <!--use custom component instead-->
        <app-driver-vehicle-toggle (driverOrVehvent)="driverOrVeh = $event"></app-driver-vehicle-toggle>
    </div>
</div>
<div class="row" [hidden] = '!(driverOrVeh === "vehicleView")'>
    <div class="col-md-8">
        <div class="row">
            <div id = "HarshPRToggleDv">
                <button id= 'harshEventPRToggleBtn'class="btn btn-info btn" style="float: right;margin-right: 2%;" 
                (click)="togglePercentileHarshEventTbl(percentile)">{{ (percentile ? '#' : 'PR') }}</button>
            </div>
            <div id = "HarshPRDropDownDv" style="float:right;" [hidden] = '!percentile'>
                <select class="form-control" [(ngModel)]="companyInsProgramDr" style="height:auto;">
                    <option [(ngValue)] = "dashboardCompanyProgram">{{dashboardCompanyProgram}}</option>
                    <!-- <option ng-repeat="geo in geoFileLst" ng-value="geo.fileName">{{ geo.caption }}</option> -->
                    <option value = 2>All Vehicles</option> 
                </select>
            </div>
        </div>
        <div class="table-responsive dashtable">
            <table id = 'harshEvent' mat-table [dataSource] = "harshEventTblDataSource" #sortHarshEvent ='matSort' matSort  class="table table-condensed table-striped">
                <ng-container matColumnDef="sortBase">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        VIN/Vehicle ID
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.vin && element.vin != 'N/A' ? (element.vin.replace(element.vin.slice(0, (element.vin.length) - 6), "") + '/') : ('/' + element.vehicleId)}}
                     </td>
                </ng-container>
                  
                <ng-container matColumnDef="vehicleType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300">
                        Vehicle Type
                    </th>
                    <td mat-cell *matCellDef="let element" > {{element.vehicleType}} </td>
                </ng-container>
                  
                <ng-container matColumnDef="sum_Harsh_Brake">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Brakes
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.sum_Harsh_Brake == 0 ? "_": element.sum_Harsh_Brake }}

                    </td>
                </ng-container>
                <ng-container matColumnDef="derived_sum_Harsh_Brake_Per1k" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Brakes (per 1k)
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.sum_TotalMiles == 0 || element.sum_Harsh_Brake == 0 ? "_": ((element.sum_Harsh_Brake  * 1000 / element.sum_TotalMiles ) | number: '1.01-2')  }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="brake_Percentile" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Brakes (per 1k)
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ (companyInsProgramDr == 2 ? element.brake_Percentile_AllProgm : element.brake_Percentile) | number: '1.01-1' }} PR
                    </td>
                </ng-container>

                <ng-container matColumnDef="sum_Harsh_Corner">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Corner
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.sum_Harsh_Corner == 0 ? "_": element.sum_Harsh_Corner }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="derived_sum_Harsh_Corner_Per1k" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Corner (per 1k)
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.sum_TotalMiles == 0 || element.sum_Harsh_Corner == 0 ? "_": ((element.sum_Harsh_Corner * 1000 / element.sum_TotalMiles) | number: '1.01-1' ) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="corner_Percentile" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Corner (per 1k)
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ (companyInsProgramDr == 2 ? element.corner_Percentile_AllProgm : element.corner_Percentile) | number: '1.01-1' }} PR
                    </td>
                </ng-container>

                <ng-container matColumnDef="sum_Harsh_Quick_Accelaration">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Accel.
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.sum_Harsh_Quick_Accelaration == 0 ? "_" : element.sum_Harsh_Quick_Accelaration }}

                    </td>
                </ng-container>
                <ng-container matColumnDef="derived_sum_Harsh_Quick_Accelaration_Per1k" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Accel. (per 1k)
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.sum_TotalMiles == 0 || element.sum_Harsh_Quick_Accelaration == 0 ? "_" : ((element.sum_Harsh_Quick_Accelaration * 1000 / element.sum_TotalMiles) | number: '1.01-2') }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="accel_Percentile" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Accel. (per 1k)
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ (companyInsProgramDr == 2 ? element.accel_Percentile_AllProgm : element.accel_Percentile) | number: '1.01-1' }} PR
                    </td>
                </ng-container>

                <ng-container matColumnDef="sum_TotalMiles" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Total Mi.
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.sum_TotalMiles | number: '1.01-1' }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="harshEventColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: harshEventColumns;" style="height:0px;" (click) = 'setActiveHarshEvent(row, $event)'></tr>
            </table>
            <mat-paginator #paginatorHarshEvent [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons style="margin-bottom: 4%;margin-top:-1%; background: none; color: whitesmoke;"></mat-paginator>
            
        </div>
    </div>
    <div class="col-md-4">
        <div class="row" *ngIf="!activeHarshEventVehicle">
            <br>
            <h4 class="text-center" style="margin-left: 1%;margin-right: 8%;">Please select a vehicle from the table for more information.</h4>
        </div>
        <div class="row" *ngIf="activeHarshEventVehicle">
            <div class="col-md-12">
                <h3 class="text-center">
                    <!-- Vehicle ID: {{activeSpeedingVehicle.vehicleId}} -->
                    VIN/Vehicle ID: {{ activeHarshEventVehicle.vin && activeHarshEventVehicle.vin != 'N/A' ? (activeHarshEventVehicle.vin.replace(activeHarshEventVehicle.vin.slice(0, (activeHarshEventVehicle.vin.length) - 6), "") + '/') : ('/' + activeHarshEventVehicle.vehicleId) }}
                </h3>
                <h5 style="font-weight:bold;margin-top: 2.5%;margin-left: -3%;">Events in Traffic</h5>
                <!--<h6 style="font:400 12px Arial; text-align: bottom;">Road Type</h6> -->
                <nvd3 id="eventInTrafficChart" [options]="harshEventInTrafficOptions" [data]="harshEventInTrafficChartData"></nvd3>

                <h5 style="font-weight:bold;margin-top: -5%;margin-left: -3%">Categorical Events</h5>
                <nvd3 id = "harshEventCategoryChart" [options]="harshEventCategoryOptions" [data]="harshEventCategoryChartData"> </nvd3>
            </div>
        </div>
    </div>
</div>
<div class="row" [hidden] = '!(driverOrVeh === "driverView")'>
    <app-driver-harshevent [harshEventData_Driver] = 'harshEventData_Driver'
    [dashboardCompanyProgram] = 'dashboardCompanyProgram'
    [harshEventSumData_Driver] = 'harshEventSumData_Driver'></app-driver-harshevent>
</div>
<div>
    <div id = "driverVehToggleDv">
        <!--use custom component instead-->
        <app-driver-vehicle-toggle (driverOrVehvent)="driverOrVeh = $event"></app-driver-vehicle-toggle>
    </div>
</div>
<div class="row" [hidden] = '!(driverOrVeh === "vehicleView")'>
    <div class="col-md-8">
        <div class="table-responsive dashtable">
            <table id = 'utilization' mat-table [dataSource] = "utilTblDataSource" #sortUtil ='matSort' matSort  class="table table-condensed table-striped">
                <ng-container matColumnDef="sortBase">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        VIN/Vehicle ID
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.vin && element.vin != 'N/A' ? (element.vin.replace(element.vin.slice(0, (element.vin.length) - 6), "") + '/') : ('/' + element.vehicleId)}}
                     </td>
                </ng-container>
                  
                <ng-container matColumnDef="vehicleType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300">
                        Vehicle Type
                    </th>
                    <td mat-cell *matCellDef="let element" > {{element.vehicleType}} </td>
                </ng-container>
                  
                <ng-container matColumnDef="daysOperational">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Days Operational
                    </th>
                    <td mat-cell *matCellDef="let element" > {{ element.daysOperational }} ({{element.daysOperational / totalDays * 100 | number: '1.0-0'}}%) </td>
                </ng-container>

                <ng-container matColumnDef="totalMileage">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Total Mi.
                    </th>
                    <td mat-cell *matCellDef="let element" > {{element.totalMileage | number: '1.0-2'}} </td>
                </ng-container>

                <ng-container matColumnDef="sum_NetOnTimeHr">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Total On Time
                    </th>
                    <td mat-cell *matCellDef="let element" > {{element.sum_NetOnTimeHr | number: '1.0-2' }} </td>
                </ng-container>

                <ng-container matColumnDef="averageOfSum_NetOnTimeHr">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Av. Shift
                    </th>
                    <td mat-cell *matCellDef="let element" > {{element.averageOfSum_NetOnTimeHr | number: '1.0-2'}} </td>
                </ng-container>

                <ng-container matColumnDef="dailyMiles">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Mi./Day
                    </th>
                    <td mat-cell *matCellDef="let element" > {{element.dailyMiles | number: '1.0-2'  }} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="utilColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: utilColumns;" style="height:0px;" (click) = 'setActiveUtilization(row, $event)'></tr>
            </table>
            <mat-paginator #paginatorUtil [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons style="margin-bottom: 4%;margin-top:-1%; background: none; color: whitesmoke;"></mat-paginator>
        </div>
    </div>
    <div class="col-md-4">
        <!--<div class="row">  Temporarily take out the selectizeScatter chart
            <div class="col-md-12">
                <nvd3 options="scatterOptions" data="scatterData" on-ready="onReady"></nvd3>
            </div>
        </div> -->
        <div class="row" *ngIf="!activeVehicleUtilization">
            <br>
            <h4 class="text-center">Please select a vehicle from the table for more information.</h4>
        </div>
        <div class="row" *ngIf="activeVehicleUtilization">
            <div class="col-md-12">
                <h3 class="text-center">
                    VIN/Vehicle ID: {{ activeVehicleUtilization.vin && activeVehicleUtilization.vin != 'N/A' ? (activeVehicleUtilization.vin.replace(activeVehicleUtilization.vin.slice(0, (activeVehicleUtilization.vin.length) - 6), "") + '/') : ('/' + activeVehicleUtilization.vehicleId) }}
                    <!-- {{activeVehicleUtilization.vehicleId}}  -->
                </h3>

                <!-- <h5>Rush Hour</h5>
                <nvd3 options="stackedBarOptions" data="timeOfDay"></nvd3>

                <h5>Day of Week</h5>
                <nvd3 options="stackedBarOptions" data="dayOfWeek"></nvd3> -->

                <div>                                    
                    <style type="text/css">
                        .no-lr-padding{ padding-right: 0px !important; padding-left: 0px !important; }
                    </style>
                    <h5 style="font-weight:bold; margin-top: 5%; margin-left: -3%">Traffic Exposure</h5>
                    <div>
                        <nvd3 id= 'utilSunburstChart' [options]="utilizationChartOptions" [data]="utilizationChartData"></nvd3>                                    
                    </div>
                    <div class="row col-md-12">
                        <div class="col-md-3 no-lr-padding">
                            <span style="margin-top:7px;padding-top:7px;">Inner Ring:</span>
                        </div>                                
                        <div class="col-md-3 no-lr-padding">
                            <div class="foo weekday"></div><span style="margin-top:7px;padding-top:7px;">Weekday</span>
                        </div>
                        <div class="col-md-6 no-lr-padding">
                            <div class="foo weekend"></div><span style="margin-top:7px;padding-top:7px;">Weekend</span>
                        </div>                                    
                    </div>
                    <div class="row col-md-12">
                        <div class="col-md-3 no-lr-padding">
                            <span style="margin-top:7px;padding-top:7px;">Outer Ring:</span>
                        </div>
                        <div class="col-md-3 no-lr-padding">
                            <div class="foo low-traffic"></div><span style="margin-top:7px;padding-top:7px;">Low</span>
                        </div>
                        <div class="col-md-3 no-lr-padding">
                            <div class="foo medium-traffic"></div><span style="margin-top:7px;padding-top:7px;">Medium</span>
                        </div>
                        <div class="col-md-3 no-lr-padding">
                            <div class="foo high-traffic"></div><span style="margin-top:7px;padding-top:7px;">High</span>
                        </div>
                    </div>                                                                       
                </div>                                
                <div>
                    <br>
                    <h5 style="font-weight:bold; margin-top: 10%; margin-left: -3%">Road Type</h5>
                    <nvd3 id="utilizationRoadTypeChart" [options]="utilizationRoadTypeStackedBarOptions" [data]="utilizationChartRoadTypeData"></nvd3>
                </div>
                <div style="padding-bottom: 15px;">
                    <h5 style="font-weight:bold; margin-top: -2%; margin-left: -3%">Utilization</h5>
                    <nvd3 id="utilizationStackedChart" [options]="stackedBarOptions" [data]="utilizationStackedData"></nvd3>
                </div>                                
            </div>
        </div>
    </div>
</div>

<div id = 'driverSection' class="row" [hidden] = '!(driverOrVeh === "driverView")'>
    <div class="col-md-8">
        <div class="row" *ngIf = '!utilData_Driver || utilData_Driver.length === 0'>
            <div class="col-md-10">
                <p style="margin:5px 0px 0px 1px" >No speeding by driver data is available for the time period that was selected due to your telematic service 
                    provider does not provide driver data or your driver information is not in your telematic service provider system.</p>
            </div>
        </div>
        <div class="table-responsive dashtable">
            <table id = 'utilizationDriver' mat-table [dataSource] = "driverUtilTblDataSource" #sortUtilDriver ='matSort' matSort  class="table table-condensed table-striped">
                <!-- <ng-container matColumnDef="sortBase">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        VIN/Vehicle ID
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.vin && element.vin != 'N/A' ? (element.vin.replace(element.vin.slice(0, (element.vin.length) - 6), "") + '/') : ('/' + element.vehicleId)}}
                     </td>
                </ng-container>
                   -->
                <ng-container matColumnDef="driverName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300">
                        Driver name
                    </th>
                    <td mat-cell *matCellDef="let element" > {{element.driverName}} </td>
                </ng-container>
                  
                <ng-container matColumnDef="daysOperational">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Days Operational
                    </th>
                    <td mat-cell *matCellDef="let element" > {{ element.daysOperational }} ({{element.daysOperational / totalDays * 100 | number: '1.0-0'}}%) </td>
                </ng-container>

                <ng-container matColumnDef="totalMileage">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Total Mi.
                    </th>
                    <td mat-cell *matCellDef="let element" > {{element.totalMileage | number: '1.0-2'}} </td>
                </ng-container>

                <ng-container matColumnDef="sum_NetOnTimeHr">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Total On Time
                    </th>
                    <td mat-cell *matCellDef="let element" > {{element.sum_NetOnTimeHr | number: '1.0-2' }} </td>
                </ng-container>

                <ng-container matColumnDef="averageOfSum_NetOnTimeHr">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Av. Shift
                    </th>
                    <td mat-cell *matCellDef="let element" > {{element.averageOfSum_NetOnTimeHr | number: '1.0-2'}} </td>
                </ng-container>

                <ng-container matColumnDef="dailyMiles">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Mi./Day
                    </th>
                    <td mat-cell *matCellDef="let element" > {{element.dailyMiles | number: '1.0-2'  }} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="driverUtilColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: driverUtilColumns;" style="height:0px;" (click) = 'setActiveUtilizationDriver(row, $event)'></tr>
            </table>
            <mat-paginator #paginatorUtilDriver [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons style="margin-bottom: 4%;margin-top:-1%; background: none; color: whitesmoke;"></mat-paginator>
        </div>
    </div>
    <!-- -->
    <div class="col-md-4">
        <!--<div class="row">  Temporarily take out the selectizeScatter chart
            <div class="col-md-12">
                <nvd3 options="scatterOptions" data="scatterData" on-ready="onReady"></nvd3>
            </div>
        </div> -->
        <div class="row" *ngIf="!activeDriverUtilization">
            <br>
            <h4 class="text-center">Please select a driver from the table for more information.</h4>
        </div>
        <div class="row" *ngIf="activeDriverUtilization">
            <div class="col-md-12">
                <h3 class="text-center">
                    Driver: {{ activeDriverUtilization.driverName ? activeDriverUtilization.driverName :  'N/A'}}
                    <!-- {{activeVehicleUtilization.vehicleId}}  -->
                </h3>

                <!-- <h5>Rush Hour</h5>
                <nvd3 options="stackedBarOptions" data="timeOfDay"></nvd3>

                <h5>Day of Week</h5>
                <nvd3 options="stackedBarOptions" data="dayOfWeek"></nvd3> -->

                <div>                                    
                    <style type="text/css">
                        .no-lr-padding{ padding-right: 0px !important; padding-left: 0px !important; }
                    </style>
                    <h5 style="font-weight:bold; margin-top: 5%; margin-left: -3%">Traffic Exposure</h5>
                    <div>
                        <nvd3 id= 'utilSunburstChart' [options]="utilizationChartOptions" [data]="driverUtilizationChartData"></nvd3>                                    
                    </div>
                    <div class="row col-md-12">
                        <div class="col-md-3 no-lr-padding">
                            <span style="margin-top:7px;padding-top:7px;">Inner Ring:</span>
                        </div>                                
                        <div class="col-md-3 no-lr-padding">
                            <div class="foo weekday"></div><span style="margin-top:7px;padding-top:7px;">Weekday</span>
                        </div>
                        <div class="col-md-6 no-lr-padding">
                            <div class="foo weekend"></div><span style="margin-top:7px;padding-top:7px;">Weekend</span>
                        </div>                                    
                    </div>
                    <div class="row col-md-12">
                        <div class="col-md-3 no-lr-padding">
                            <span style="margin-top:7px;padding-top:7px;">Outer Ring:</span>
                        </div>
                        <div class="col-md-3 no-lr-padding">
                            <div class="foo low-traffic"></div><span style="margin-top:7px;padding-top:7px;">Low</span>
                        </div>
                        <div class="col-md-3 no-lr-padding">
                            <div class="foo medium-traffic"></div><span style="margin-top:7px;padding-top:7px;">Medium</span>
                        </div>
                        <div class="col-md-3 no-lr-padding">
                            <div class="foo high-traffic"></div><span style="margin-top:7px;padding-top:7px;">High</span>
                        </div>
                    </div>                                                                       
                </div>                                
                <div>
                    <br>
                    <h5 style="font-weight:bold; margin-top: 10%; margin-left: -3%">Road Type</h5>
                    <nvd3 id="utilizationRoadTypeChart" [options]="utilizationRoadTypeStackedBarOptions" [data]="driverUtilizationChartRoadTypeData"></nvd3>
                </div>
                <div style="padding-bottom: 15px;">
                    <h5 style="font-weight:bold; margin-top: -2%; margin-left: -3%">Utilization</h5>
                    <nvd3 id="utilizationStackedChart" [options]="stackedBarOptions" [data]="driverUtilizationStackedData"></nvd3>
                </div>                                
            </div>
        </div>
    </div>
</div>

    <div class="col-md-8">
        <div class="row" *ngIf = '!speedingData_Driver || speedingData_Driver.length === 0'>
            <div class="col-md-10">
                <p style="margin:5px 0px 0px 1px" >No speeding by driver data is available for the time period that was selected due to your telematic service 
                    provider does not provide driver data or your driver information is not in your telematic service provider system.</p>
            </div>
        </div>
        <div class="row">
            <div id = "DriverPRToggleDv">
                <button id= 'driverSpeedingPRToggleBtn'class="btn btn-info btn" style="float: right;margin-right: 2%;" (click)="togglePercentileDriverTbl(driverPercentile)">{{ driverPercentile ? '% ' : 'PR' }}</button>
            </div>
            <div id = "DriverPRDropDownDv" style="float:right;" [hidden] = '!driverPercentile'>
                <select class="form-control" [(ngModel)]="companyInsProgramDr" style="height:auto;">
                    <option [(ngValue)] = "dashboardCompanyProgram">{{dashboardCompanyProgram}}</option>
                    <option value = 2>All Drivers</option>
                </select>
            </div>
        </div>
        <div class="table-responsive dashtable">
        <table id = 'driverSpeeding' mat-table [dataSource] = "driverSpeedingTblDataSource" #sortDriverSpeeding ='matSort' matSort  class="table table-condensed table-striped">
            
              
            <ng-container matColumnDef="driverName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300">
                    Driver Name
                </th>
                <td mat-cell *matCellDef="let element" > {{element.driverName}} </td>
            </ng-container>
              
            <ng-container matColumnDef="derived_Under_5">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    Under 5
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.sum_TotalMovingHr == 0 ? 0 : (element.sum_Speeding_Under_5mph_ByTime / 3600 * 100 / element.sum_TotalMovingHr) | number: '1.01-1' }}%

                </td>
            </ng-container>
            <ng-container matColumnDef="speeding_Under_5mph_ByTime_Percentile" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    Under 5
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ (companyInsProgramDr == 2 ? element.speeding_Under_5mph_ByTime_Percentile_AllProgm : element.speeding_Under_5mph_ByTime_Percentile) | number: '1.01-1' }} PR
                </td>
            </ng-container>

            <ng-container matColumnDef="derived_5To10">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    5 To 10
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.sum_TotalMovingHr == 0 ? 0 : (element.sum_Speeding_5_10mph_ByTime / 3600 * 100 / element.sum_TotalMovingHr) | number: '1.01-1' }}%

                </td>
            </ng-container>
            <ng-container matColumnDef="speeding_5_10mph_ByTime_Percentile" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    5 To 10
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ (companyInsProgramDr == 2 ? element.speeding_5_10mph_ByTime_Percentile_AllProgm : element.speeding_5_10mph_ByTime_Percentile) | number: '1.01-1' }} PR
                </td>
            </ng-container>

            <ng-container matColumnDef="derived_10To15">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    10 To 15
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.sum_TotalMovingHr == 0 ? 0 : (element.sum_Speeding_10_15mph_ByTime / 3600 * 100 / element.sum_TotalMovingHr) | number: '1.01-1' }}%

                </td>
            </ng-container>
            <ng-container matColumnDef="speeding_10_15mph_ByTime_Percentile" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    10 To 15
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ (companyInsProgramDr == 2 ? element.speeding_10_15mph_ByTime_Percentile_AllProgm : element.speeding_10_15mph_ByTime_Percentile) | number: '1.01-1' }} PR
                </td>
            </ng-container>

            <ng-container matColumnDef="derived_Over_15">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    Over 15
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.sum_TotalMovingHr == 0 ? 0 : (element.sum_Speeding_Over_15mph_ByTime / 3600 * 100 / element.sum_TotalMovingHr) | number: '1.01-1' }}%

                </td>
            </ng-container>
            <ng-container matColumnDef="speeding_Over_15mph_ByTime_Percentile" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    Over 15
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ (companyInsProgramDr == 2 ? element.speeding_Over_15mph_ByTime_Percentile_AllProgm : element.speeding_Over_15mph_ByTime_Percentile) | number: '1.01-1' }} PR
                </td>
            </ng-container>

            <ng-container matColumnDef="derived_Total_Speeding">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    Total Speeding
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.sum_TotalMovingHr == 0 ? 0 : (element.sum_Speeding_Under_5mph_ByTime + element.sum_Speeding_5_10mph_ByTime + element.sum_Speeding_10_15mph_ByTime + element.sum_Speeding_Over_15mph_ByTime) / 3600 *100 / element.sum_TotalMovingHr | number:'1.01-1' }}%

                </td>
            </ng-container>
            <ng-container matColumnDef="total_Speeding_ByTime_Percentile" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    Total Speeding
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ (companyInsProgramDr == 2 ? element.total_Speeding_ByTime_Percentile_AllProgm : element.total_Speeding_ByTime_Percentile) | number: '1.01-1' }} PR
                </td>
            </ng-container>

            <ng-container matColumnDef="sum_TotalMiles" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                    Total Mileage
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{ element.sum_TotalMiles | number: '1.01-1' }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="driverSpeedingColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: driverSpeedingColumns;" style="height:0px;" (click) = 'setActiveDriver(row, $event)'></tr>
        </table>
        <mat-paginator #paginatorDriverSpeeding [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons style="margin-bottom: 4%;margin-top:-1%; background: none; color: whitesmoke;"></mat-paginator>
        </div>
    </div>
    <div class="col-md-4">  
        <!-- <div class="row text-center">
            <div class="summaryBlock">
                <h5 class="text-center" style="margin-top: -6%;margin-bottom: 2%;font-weight:bold;">Driver Speeding Summary</h5>
                
                <h5 id="u5" class="inline"><u>Under 5</u> <br style="line-height: 1.5em;" /> {{ FleetData && FleetData.u5Sum /3600 * 100/ FleetData.totalMovingHrSum | number:'1.01-1' }}%</h5>
                <h5 id="_5-10" class="inline"><u>5 - 10</u> <br style="line-height: 1.5em;" /> {{ FleetData && FleetData._5T10Sum /3600 * 100 / FleetData.totalMovingHrSum | number:'1.01-1' }}%</h5>
                <h5 id="_10-15" class="inline"><u>10 - 15</u> <br style="line-height: 1.5em;" /> {{ FleetData && FleetData._10T15Sum /3600 * 100 / FleetData.totalMovingHrSum | number:'1.01-1' }}%</h5>
                <h5 id="o15" class="inline"><u>Over 15</u> <br style="line-height: 1.5em;" /> {{ FleetData && FleetData.o15Sum /3600 *100 / FleetData.totalMovingHrSum | number:'1.01-1' }}%</h5>
            </div>
        </div>                        -->
        <div class="row" *ngIf="!activeSpeedingDriver">
            <br>
            <h4 class="text-center" style="margin-left: 1%;margin-right: 8%;">Please select a driver from the table for more information.</h4>
        </div>
        <div class="row" *ngIf="activeSpeedingDriver">
            <div class="col-md-12">
                <h3 class="text-center">
                    Driver: {{activeSpeedingDriver.driverName}}
                </h3>
                <!--<h5>Heavy Traffic</h5>
                <nvd3 options="speedingStackedBarOptions" data="heavyTraffic">{{ speedingStackedBarOptions.chart.showYAxis = true}}</nvd3> -->

                <h5 style="font-weight:bold;margin-top: 5%;margin-left: -3%;">Speeding By Road Type</h5>
                <!--<h6 style="font:400 12px Arial; text-align: bottom;">Road Type</h6> -->
                <nvd3 id="roadTypeChart" [options]="speedingRoadTypeStackedBarOptions" [data]="driverSpeedingChartRoadTypeData"></nvd3>

                <h5 style="font-weight:bold;margin-top: -7%;margin-left: -3%">Speeding By Traffic</h5>
                <nvd3 id="trafficChart" [options]="speedingStackedBarOptions" [data]="driverSpeedingChartData"> </nvd3>

                <h5 style="font-weight:bold;margin-top: 1%;margin-left: -3%">Total Speeding Trend</h5>
                <nvd3 [options]="speedingTrendBarOptions" [data]="driverSpeedingTrendChartData"> </nvd3>
            </div>
        </div>
    </div>

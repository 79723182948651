<div class="row">
    <div class="col-md-8">
        <div class="table-responsive dashtable">
            <table id = 'territory' mat-table [dataSource] = "territoryTblDataSource" #sortTerritory ='matSort' matSort  class="table table-condensed table-striped">
                <ng-container matColumnDef="sortBase">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        VIN/Vehicle ID
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.stats.vin && element.stats.vin != 'N/A' ? (element.stats.vin.replace(element.stats.vin.slice(0, (element.stats.vin.length) - 6), "") + '/') : ('/' + element.stats.VehicleNumber)}}
                        </td>
                </ng-container>
                    
                <ng-container matColumnDef="vehicleType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300">
                        Vehicle Type
                    </th>
                    <td mat-cell *matCellDef="let element" > {{element.stats.vehicleType}} </td>
                </ng-container>
                    
                <ng-container matColumnDef="primary_Garaging_City">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Garaging City
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.stats.primary_Garaging_City}} </td>
                </ng-container>
                <ng-container matColumnDef="primary_Garaging_State" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        State
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{element.stats.primary_Garaging_State }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="primary_Garaging_Zip">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Zip
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.stats.primary_Garaging_Zip }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="garageZone">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Terr.
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.stats.garageZone == null ? 'N/A' : element.stats.garageZone }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="derived_TeleFactor">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        TeleFactor
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.stats.garagerateMultMonthAve == 0 ? 'N/A' : (element.stats.telerateMultMonthAve / element.stats.garagerateMultMonthAve * 100).toFixed(1) + '%'}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Sum_TotalMiles">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Total Miles Driven
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.stats.Sum_TotalMiles | number: '1.0-1'}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="territoryColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: territoryColumns;" style="height:0px;" (click) = 'setActiveTerritory(row, $event)'></tr>
            </table>
            <mat-paginator #paginatorTerritory [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons style="margin-bottom: 4%;margin-top:-1%; background: none; color: whitesmoke;"></mat-paginator>
        </div>
    </div>
    <div class="col-md-4">
        <div class="row text-center">
            <div class="col-md-12">
                <h2 id="telefactor">Fleet TeleFactor: {{ territoryFactorSum() * 100 | number: '1.0-1'}}%</h2>
            </div>
        </div>
        <div class="row" *ngIf="!activeTerritoryVehicle">
            <br>
            <h4 class="text-center">Please select a vehicle from the table for more information.</h4>
        </div>
        <div class="row" *ngIf="activeTerritoryVehicle">
            <div class="col-md-12">
                <h3 class="text-center" style="display:none;">
                    Vehicle ID: {{activeTerritoryVehicle.stats.VehicleNumber}}
                </h3>
            </div> 
            <div class="col-md-12">
                <h3 class="text-center" style="font-weight:bold;">
                    Mileage by Territory
                </h3>
            </div>
        </div>
        <div class="row" *ngIf="activeTerritoryVehicle">
            <div class="col-md-12">
                <!-- <div style="position: relative;width: 50vw; height: 50vw;"> -->
                <div style="margin: auto;">
                    <!-- <canvas id="radar" class="chart chart-radar" chart-data="territoryChart" chart-colors="colors" chart-options="radarOptions" chart-labels="labels"></canvas> -->
                    <canvas baseChart width="88%" height="100%"
                      [datasets]="territoryChartData"
                      [options]="radarOptions"
                      [labels]="labels"
                      [chartType]="territoryChartType"
                      [colors] = 'colors'>
                    </canvas>
                </div>
            </div>
        </div>
    </div>
</div>
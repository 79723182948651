import { Component, OnInit, Injector } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {Router, ActivatedRoute} from '@angular/router'
import { AuthService } from 'src/app/Services/auth.service';
import { DataService } from '../../Services/data.service';
import { CommonAppService } from '../../Services/common-app.service';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.css']
})
export class ActivateComponent implements OnInit {

  private dataService : any;
  private commonAppService : any
  verificationData : any = {
    userName: "",
    password: ""
  }
  userObj = <any>{
  
  };
  
  message : any = false;
  success = <any>false;
  accountData = true;
  verified : boolean = false;
  verificationTk = '';
  userDataUpdated : boolean = false;
  createNewPass = {
      oldPass:"",
      pass:"",
      confirmPass:""
  };
  validPass : boolean = false;
  tempPassTk = "";
  disableUpdatePWBtn :boolean;



  constructor(private activatedRouter : ActivatedRoute,  private router : Router ,private authService : AuthService, private location : Location, private injector : Injector) { 
    this.dataService = this.injector.get(DataService);
    this.commonAppService = this.injector.get(CommonAppService)
  }


  ngOnInit(): void {

    let vtk =  this.activatedRouter.snapshot.paramMap.get('vtk');
    let uid =  JSON.parse(this.activatedRouter.snapshot.paramMap.get('uid'));
    if(vtk && uid)
    {
      this.verificationTk = vtk;
      this.userObj.UserId = uid;
      this.verified = true;
      this.accountData = false;
    }
  }

  verifyAccount() {
    this.authService._verifyAccount(this.verificationData).subscribe((response) => {
        this.verified = true;
        this.accountData = false;
        this.success = "Success!";
        this.verificationTk = response.access_token;
        this.userObj.UserId = response.UserId;
    }, (err) => {
        this.message = err.error.Message;
    });
  }

  updateUserData () 
  {
    //validate email
    if(!this.authService._validateEmailTest(this.userObj.email)){
      this.message = "Please enter a valid email address";
      return;
    }
    
    this.dataService._updateActivatedUser(this.verificationTk, this.userObj).subscribe(
      result => {
        if(result.status == 200)
        {
          this.reset(this.userObj.email);

        }
        else
        {
          this.message = "Failed to update user data. Please try again later";
        }
      },
      error => {
        if(error.status == 400)
        {
          this.message = "Email already exists for another account";
        }
      }
    );
  }

  private reset(passedEmail) {
    this.authService._resetPass(passedEmail).subscribe(result =>
      {
        if (result == 202) {
          // Reset Worked
          this.message = false;
          this.success = "Success! A temperory password has been generated at " + "\n" + Date().toLocaleString() + ". " + "\n" + 
              "You will receive an email with your temporary password. Please use it to create your new password";
          this.userDataUpdated = true;
          this.verified = false;
          this.accountData = false;
        } else if (result == 400) {
            // Email Not Sent
            this.message = "User data is successfully updated. Please use your email id and reset your password";
        } else if (result == 403) {
            // Email Address Not Found
            this.message = "User Not Found";
        } else {
            // Unknown Error
            this.message = "Server Error: Please try again later";
        }
      },
      error => {
        this.message = error.error.Message;
      }
    );
  }

  checkNewPass() {
    this.validPass = false;
    if(this.createNewPass.oldPass && this.createNewPass.pass && this.createNewPass.confirmPass)
    {
      if((this.createNewPass.pass.length >= 6) && (this.createNewPass.confirmPass === this.createNewPass.pass))
      {
        this.validPass = true;
      }
    }
  }

  updatePass() {
    //confirm password
    if(!(this.createNewPass.pass.length >= 6)){
      this.message = "your new password should be minimum 6 characters";
      return;
    }
    if(this.createNewPass.confirmPass !== this.createNewPass.pass){
      this.message = "Confirm password - Mismatch";
      return;
    }

    //verify password
    this.verificationData.userName = this.userObj.email;
    this.verificationData.password = this.createNewPass.oldPass;

    this.authService._verifyAccount(this.verificationData).subscribe(result =>
      {
        this.tempPassTk = result.access_token;
        this.userObj.Password = this.createNewPass.pass;
        this.updateUserPass();

      },error =>{
        //verify results is 401. takes us back to login page. this is a way to keep it in the same page.
        let hackurl = '/activate';
        let vtk =  this.activatedRouter.snapshot.paramMap.get('vtk');
        let uid =  JSON.parse(this.activatedRouter.snapshot.paramMap.get('uid'));
        if(vtk && uid) {
            hackurl = hackurl + '/' + vtk + '/' + uid;
        }
       
        this.router.navigate([hackurl]);
        
        this.success = false;
        this.message = 'Invalid password. Please check your email for correct password. Or click the back button to change the email address if you need to';

      })
  }

  private updateUserPass () {
    this.dataService._updateNewUserPassword(this.tempPassTk, this.userObj).subscribe(result =>
      {
        if(result.status === 200)
        {
          let tempLoginData = {
            userName: this.userObj.email,
            password: this.userObj.Password
          };
          this.authService._login(tempLoginData).subscribe(result => {
            this.message = false;
            this.commonAppService._loginSuccessRoute();
          }, error =>{
            this.message = error.error.Message;
          })
        }
        else
        {
          this.message = "Failed!"
        }

      });
  }

  back() {
    this.userDataUpdated = false;
    this.verified = true;
    this.accountData = false;
    this.message = false;
    this.success = false;
  }

  validatePW (value : string) {
    this.disableUpdatePWBtn = false;
    let pwRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})");
    if(!pwRegex.test(value))
    {
      this.message = "Password must contain at least 6 characters, 1 captial letter, 1 number and 1 symbol";
      this.disableUpdatePWBtn = true;
    }
    else {
      this.disableUpdatePWBtn = false;
      this.message = null;
    }
    this.checkNewPass();
  }

 


  





 

}

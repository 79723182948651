import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { map, tap, switchMap, mergeMap, filter, concatMap } from 'rxjs/operators';
import { concat, forkJoin, merge, observable, Observable } from 'rxjs';
import {DataService} from '../../Services/data.service';
import { CommonAppService} from '../../Services/common-app.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-consent-modal',
  templateUrl: './consent-modal.component.html',
  styleUrls: ['./consent-modal.component.css']
})
export class ConsentModalComponent implements OnInit {

  groupInfo : any = {
    GroupType : 1002
  };
  adminInfo : any = {};
  recordObj : any = {};
  revokePrefillObj : any = {};
  iccInfo : any = {};
  subUsers :any = {};

  constructor(public dialogRef: MatDialogRef<ConsentModalComponent>,
    @Inject(MAT_DIALOG_DATA) private modalData: any,
    private dataService : DataService,
    private commonAppService : CommonAppService,
    @Inject(DOCUMENT) private document : Document)
  {
  }

  loadData() {
    this.dataService._getGroupInfo()
    .pipe(
      concatMap(result => {
        this.groupInfo = result;
        return this.dataService._getGroupInfo(result.IccID, 1002);

      }),
      concatMap(IccResult => {
        this.iccInfo = IccResult;
        return this.dataService._getSubUsers();
      }),
      concatMap(subUserInfo => {
        this.subUsers = subUserInfo;
        this.adminInfo = _.filter(this.subUsers, { UserId: this.groupInfo.AdminId })[0];

        if(this.adminInfo.GroupType == 1001)//appConst.groupType.MC)
        { 
          return this.dataService._getMCLastCROfConsent(this.adminInfo.GroupId, this.recordObj.TSPId, this.recordObj.ConsentId);
        }
        else
        {
          return  new Observable();//to avoid undefined stream error, need to return observable
        }
      })
    ).subscribe(
      result => {
        if(!result) return;

        this.revokePrefillObj.CompanyName = this.groupInfo.CompanyName;
        this.revokePrefillObj.Address = this.groupInfo.StreetAddress1 + ", " + this.groupInfo.City + ", " + this.groupInfo.State + ", " + this.groupInfo.Zipcode; 
        this.revokePrefillObj.Name = this.adminInfo.Name;
        this.revokePrefillObj.Title = this.adminInfo.JobTitle;
        this.revokePrefillObj.PhoneNumber = this.adminInfo.Phone;
        this.revokePrefillObj.EmailAddress = this.adminInfo.email;
        this.revokePrefillObj.Insurer = this.iccInfo.CompanyName;
        this.revokePrefillObj.mcTSPAcctNbr = result.MCTSPAcctNbr;
        this.revokePrefillObj.tspName = result.TSPCompanyName;
      }
    )

  }

  downloadPDF(signatureRequestID : string) {
    this.dataService._getSignedPDF(signatureRequestID)
    .subscribe(
      result => {
        let newWindow = window.open(result, '_blank');
        newWindow.document.title = "download";
      }
    )
  }

  //TODo: finish the revoke while converting the MC permission pages
  revokeConsent() {
    
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    let currentHost  = this.document.location.href;

    let body = {
      user_id: parseInt(authData.userID),
      tsp_id: this.recordObj.TSPId,
      type: 0,
      mcCompanyName: this.revokePrefillObj.CompanyName,
      mcCompanyAddress: this.revokePrefillObj.Address,
      adminEmail: this.revokePrefillObj.EmailAddress,
      adminMCIccComp: this.revokePrefillObj.Insurer,
      adminName: this.revokePrefillObj.Name,
      adminPhone: this.revokePrefillObj.PhoneNumber,
      jobTitle: this.revokePrefillObj.Title,
      mcTSPAcctNbr: this.revokePrefillObj.mcTSPAcctNbr,
      tspName: this.revokePrefillObj.tspName,
    }

    this.dataService._sigRequest(body.user_id, body.tsp_id, body.type,  [body], currentHost).subscribe(result => {

      this.close();
      this.commonAppService._snackBar(true, 'Revoke Request Email Sent');
    });
  }

  close() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.recordObj = this.modalData;

    this.loadData();
  }

}

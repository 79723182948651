import { Injectable } from '@angular/core';
import {  Observable, throwError} from 'rxjs';
import {catchError, filter, map, tap} from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpResponse} from '@angular/common/http';
//import { SERVICEBASE } from '../app.config';
import {environment} from '../../environments/environment';






@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private httpClient: HttpClient) { }

  _getGroupInfo(groupIDParam?: any, groupTypeParam?: any) : Observable<any>
  {
    //this.authService._fillsmData();
    //this.authService._fillAuthData();

    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    let groupID = groupIDParam ? groupIDParam : authData.groupID;
    let groupType = groupTypeParam ? groupTypeParam : authData.groupType;
    let getURL = environment.serviceBase + 'api/User/GetGroupInfo?GroupID=' + groupID + "&GroupType=" + groupType;

    return this.httpClient.get(getURL);
  }

  _getMCStatus(mcID : number) : Observable<any>
  {
    let getURL = environment.serviceBase + 'api/User/GetMCLastCR?McId=' + mcID;
    
    return this.httpClient.get(getURL);
  }

  _updateUser(userObj) : Observable<any>
  {
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    
    let url = environment.serviceBase + "api/User/UpdateUser";
    let currentUserId = authData.userID;

    userObj.email = userObj.email || '';
    userObj.Name = userObj.Name || '';
    userObj.Phone = userObj.Phone || '';
    userObj.JobTitle = userObj.JobTitle || '';
    userObj.email = userObj.email || '';
    userObj.StreetAddress1 = userObj.StreetAddress1 || '';
    userObj.StreetAddress2 = userObj.StreetAddress2 || '';
    userObj.City = userObj.City || '';
    userObj.St = userObj.St || '';
    userObj.ZipCode = userObj.ZipCode || '';
    currentUserId = currentUserId || '';

    // let body = 
    // "UserId=" + userObj.UserId + "&GroupId=" + userObj.GroupID + "&Role=" + userObj.Role + "&email=" + userObj.email + "&Name=" + userObj.Name + "&Phone=" + userObj.Phone + "&JobTitle=" + userObj.JobTitle + "&UserName=" + userObj.email + "&StreetAddress1=" + userObj.StreetAddress1 + "&StreetAddress2=" + userObj.StreetAddress2 + "&City=" + userObj.City + "&St=" + userObj.St + "&ZipCode=" + userObj.ZipCode + "&Enabled=" + true + "&UpdatedByUserId=" + currentUserId;
    let body = 
    {

      "UserId" : userObj.UserId,
      "GroupId" :userObj.GroupID, 
      "Role":userObj.Role,
      "email": userObj.email, 
      "Name": userObj.Name,
      "Phone": userObj.Phone, 
      "JobTitle": userObj.JobTitle, 
      "UserName": userObj.email, 
      "StreetAddress1": userObj.StreetAddress1,
      "StreetAddress2": userObj.StreetAddress2, 
      "City": userObj.City,
      "St" :userObj.St, 
      "ZipCode": userObj.ZipCode,
      "Enabled" :true,
      "UpdatedByUserId":currentUserId
    }

    return this.httpClient.post(url, body, { headers: { 'Content-Type': 'application/json' }, observe: 'response' })
  };

  _updateActivatedUser(verificationTk, userObj) : Observable<any> {
    let url = environment.serviceBase + "api/userAccount/UpdateActivatedUser";
    
    userObj.email = userObj.email || '';
    userObj.Name = userObj.Name || '';
    userObj.Phone = userObj.Phone || '';
    userObj.JobTitle = userObj.JobTitle || '';
    userObj.email = userObj.email || '';
    userObj.StreetAddress1 = userObj.StreetAddress1 || '';
    userObj.StreetAddress2 = userObj.StreetAddress2 || '';
    userObj.City = userObj.City || '';
    userObj.St = userObj.St || '';
    userObj.ZipCode = userObj.ZipCode || '';
    userObj.UserId = userObj.UserId || '';
    userObj.UpdatedByUserId = userObj.UserId;

    
    // let body = "UserId=" + userObj.UserId + 
    //             "&GroupId=" + userObj.GroupID + 
    //             "&Role=" +userObj.Role + 
    //             "&email=" + userObj.email + 
    //             "&Name=" + userObj.Name + 
    //             "&Phone=" + userObj.Phone + 
    //             "&JobTitle=" + userObj.JobTitle + 
    //             "&UserName=" + userObj.email + 
    //             "&StreetAddress1=" + userObj.StreetAddress1 + 
    //             "&StreetAddress2=" + userObj.StreetAddress2 + 
    //             "&City=" + userObj.City + 
    //             "&St=" + userObj.St + 
    //             "&ZipCode=" + userObj.ZipCode + 
    //             "&Enabled=" + true + 
    //             "&UpdatedByUserId=" + userObj.UpdatedByUserId;
    
    let body = 
    {
      "UserId": userObj.UserId,
      "GroupId": userObj.GroupID,
      "Role":userObj.Role,
      "email": userObj.email, 
      "Name": userObj.Name,
      "Phone": userObj.Phone, 
      "JobTitle": userObj.JobTitle, 
      "UserName": userObj.email, 
      "StreetAddress1": userObj.StreetAddress1,
      "StreetAddress2": userObj.StreetAddress2, 
      "City": userObj.City,
      "St": userObj.St,
      "ZipCode": userObj.ZipCode,
      "Enabled": true, 
      "UpdatedByUserId": userObj.UpdatedByUserId
    }
    return this.httpClient.post(url, body, { headers: { 'Content-Type': 'application/json',  'Authorization': 'Bearer ' + verificationTk}, observe: 'response'});
  }

  _updateUserAgreement (){
    
    let url = environment.serviceBase + "api/userAccount/UpdateUserAgreement";
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    let body = authData.userID;
    
    return this.httpClient.post(url, body, { headers: { 'Content-Type': 'application/json' } });
  };

  _updatePassword(newPass) : Observable<any> {
    
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    let url = environment.serviceBase + "api/User/UpdateUser";
    let currentUserId = authData.userID;

    let body = 
    "UserId=" + authData.userID + "&UserName=" + authData.userName + "&GroupId=" + authData.groupID +
     "&Password=" + newPass + "&UpdatedByUserId=" + currentUserId;

    return this.httpClient.post(url, body, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, observe: 'response' });
  };

  _updateNewUserPassword(verificationTk: string, userObj: any) : Observable<any> {
    let url = environment.serviceBase  + "api/userAccount/UpdateActivatedUser";
    userObj.UserId = userObj.UserId || '';
    userObj.UpdatedByUserId = userObj.UserId;
    userObj.UserName = userObj.email;

    let body = "UserId=" + userObj.UserId + "&UserName=" + userObj.UserName + "&Password=" + userObj.Password + "&UpdatedByUserId=" + userObj.UpdatedByUserId;
    
    return this.httpClient.post(url, body, { headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Bearer ' + verificationTk }, observe: 'response'});
  }

  _updateMutualCustomer(mcObj) : Observable<any> {
  
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    let url = environment.serviceBase + "api/User/UpdateMutualCustomer";
    let parentGroupID = authData.groupID;

    mcObj.GroupId = mcObj.GroupId || '';
    mcObj.IccID = mcObj.IccID || '';
    mcObj.AdminId = mcObj.AdminId || '';
    //console.log('SETTING NEW MC_ID TO', mcObj.AdminId);
    mcObj.DashboardId = mcObj.DashboardId || '';
    mcObj.ExposureProgram = mcObj.ExposureProgram || '';
    mcObj.CompanyName = mcObj.CompanyName || '';
    mcObj.StreetAddress1 = mcObj.StreetAddress1 || '';
    mcObj.StreetAddress2 = mcObj.StreetAddress2 || '';
    mcObj.City = mcObj.City || '';
    mcObj.St = mcObj.State || '';
    mcObj.Zipcode = mcObj.Zipcode || '';
    mcObj.PolicyNumber = mcObj.PolicyNumber || '';
    mcObj.ExpDate = mcObj.ExpDate || '';
    mcObj.Agent = mcObj.Agent || '';

    /*
    let body = "McId=" 
    + mcObj.GroupId + "&IccId=" + mcObj.IccID + "&AdminId=" + mcObj.AdminId + "&DashboardId=" 
    + mcObj.DashboardId + "&ExposureProgramType=" + mcObj.ExposureProgram + "&CompanyName=" 
    + mcObj.CompanyName + "&StreetAddress1=" + mcObj.StreetAddress1 + "&StreetAddress2=" 
    + mcObj.StreetAddress2 + "&City=" + mcObj.City + "&St=" + mcObj.St + "&ZipCode=" 
    + mcObj.Zipcode + "&PolicyNumber=" + mcObj.PolicyNumber + "&ExpDate=" + mcObj.ExpDate 
    + "&Agent=" + mcObj.Agent;
  */
    let body =
    {
      "McId" : mcObj.GroupId,
      "IccId" :mcObj.IccID ,
      "AdminId": mcObj.AdminId,
      "DashboardId": mcObj.DashboardId,
      "ExposureProgramType": mcObj.ExposureProgram,
      "CompanyName": mcObj.CompanyName,
      "StreetAddress1" :mcObj.StreetAddress1,
      "StreetAddress2" :mcObj.StreetAddress2,
      "City" :mcObj.City,
      "St" :mcObj.St,
      "ZipCode" :mcObj.Zipcode,
      "PolicyNumber" :mcObj.PolicyNumber,
      "ExpDate" : mcObj.ExpDate,
      "Agent":  mcObj.Agent
    }
    //return this.httpClient.post(url, body, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, observe: 'response'});
    return this.httpClient.post(url, body, { headers: { 'Content-Type': 'application/json' }, observe: 'response'});
  };
  _disableUser(userID : number) : Observable<any> {
  
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    let url = environment.serviceBase + "api/User/UpdateUser";
    let parentGroupID = authData.groupID;
    let currentUserId = authData.userID;

    let body = "UserId=" + userID + "&GroupId=" + parentGroupID + "&Enabled=" + false + "&UpdatedByUserId=" + currentUserId;

    return this.httpClient.post(url, body, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, observe: 'response' })
  };

  _getMutualCustomers() : Observable<any> {
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    let groupID = authData.groupID;
    let groupType = authData.groupType;
    let getURL = environment.serviceBase + 'api/User/GetMutualCustomers?GroupID=' + groupID + "&GroupType=" + groupType;

    return this.httpClient.get(getURL);
  }
  _getMCAdminConsentPrefillInfo(adminId: number, iccId : number) : Observable<any> {
    let getURL = environment.serviceBase + 'api/User/GetGetMCAdminConsentPrefillInfo?AdminId=' + adminId + "&IccId=" + iccId;
    return this.httpClient.get(getURL);
  }

  _getTSPList(tspID : number): Observable<any> {
    let getURL = environment.serviceBase + 'api/User/GetTelematicsServiceProviders?TspId=' + tspID;

    return this.httpClient.get(getURL)
  };

  _getDeactivateRecordMC(mcId : number, groupType : number, iccId : number) : Observable<any> {
    let url = environment.serviceBase + 'api/User/GetDeactivateRecordMC?' + 'mcId=' + mcId + '&groupType=' + groupType + '&iccId=' + iccId;
    return this.httpClient.get(url);
  }

  _getSubUsers(groupID?, groupType?) :Observable<any> {
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    groupID = groupID ? groupID : authData.groupID;
    groupType = groupType ? groupType : authData.groupType;
    let getURL = environment.serviceBase + 'api/User/GetSubUsers?GroupID=' + groupID + "&GroupType=" + groupType;

    return this.httpClient.get(getURL);
  }

  _getFullConsentHistory(mcIDParam? : number) :Observable<any> {
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    let mcQueryID = null;
    let mcID = mcIDParam;

    if(mcID)
    {
      mcQueryID = mcID;
    } else {
      if (authData.groupType === 1001) {
          mcQueryID = authData.groupID;
      } else {
        return throwError('No MC ID');
          //deferred.reject('No MC ID');
      }
    }
    
    let getURL = environment.serviceBase + 'api/User/GetFullConsentHistory?McId=' + mcQueryID;
    return this.httpClient.get(getURL);
  }

  _updateConsentStatus(consentStatusRec) : Observable<any>
  {
    let url = environment.serviceBase + 'api/User/UpdateConsentStatus';

    return this.httpClient.post(url, consentStatusRec, { headers: { 'Content-Type': 'application/json'} , observe : 'response' } )
  }

  _getMCLastCROfConsent(mcID : number, TSPId : number, ConsentId : number) : Observable<any>{

    let getURL = environment.serviceBase + 'api/User/GetMCLastCROfConsent?McId=' + mcID + '&TSPId=' + TSPId + '&ConsentId=' + ConsentId;

    return this.httpClient.get(getURL);
  }

  _getMCLastConsentSignReq (signingSignatureID) : Observable<any> {
   
    let getURL = environment.serviceBase + 'api/User/GetMCLastConsentSignReq?signingSignatureID=' + signingSignatureID;

    return this.httpClient.get(getURL);
  };

  //change to restapi instead of php
  _getSignedPDF(sigID : string) : Observable<any> {
    let body = {
      signature_id: sigID,
      signature_request_id: sigID 
    }
    let url = environment.serviceBase + 'api/User/GetSignedPDF?signingSignatureID=' + sigID;
    
    return this.httpClient.get(url);
  }
  //change to restapi instead of php
  _sigRequest (user_id, tsp_id, type, prefillInfo, currentHost) : Observable<any> {

    let mcCompanyName = prefillInfo[0].mcCompanyName;
    let mcCompanyAddress = prefillInfo[0].mcCompanyAddress;
    let adminEmail = prefillInfo[0].adminEmail;
    let adminMCIccComp = prefillInfo[0].adminMCIccComp;
    let adminName = prefillInfo[0].adminName;
    let adminPhone = prefillInfo[0].adminPhone;
    let jobTitle = prefillInfo[0].jobTitle;
    let tspName = prefillInfo[0].tspName;
    let mcTSPAcctNbr = prefillInfo[0].mcTSPAcctNbr;

    let body = {
        user_id: user_id,
        tsp_id: tsp_id,
        type: type,
        mcCompanyName: mcCompanyName,
        mcCompanyAddress: mcCompanyAddress,
        adminEmail: adminEmail,
        adminMCIccComp: adminMCIccComp,
        adminName: adminName,
        adminPhone: adminPhone,
        jobTitle: jobTitle,
        tspName: tspName,
        mcTSPAcctNbr: mcTSPAcctNbr,
        isTest: environment.isTest,
        serviceBase: environment.serviceBase,
        currentHost: currentHost
    }

    let url = environment.serviceBase + 'api/User/SignRequest';  //"https://www.telematicexchange.com/LIA/v1/sign/request"
    return this.httpClient.post(url, body, { headers: { 'Content-Type': 'application/json'} , observe : 'response' });
  }
  _getSignUrl(signature_id : string) : Observable<any>
  {
    let url = environment.serviceBase + "api/User/GetSignURL?signatureId=" + signature_id;

    return this.httpClient.get(url);
  }

  _getConsentId(signature_id : string) : Observable<any>
  {
    let url = environment.serviceBase + "api/User/GetConsentId?SignatureId=" + signature_id;

    return this.httpClient.get(url);
  }

  _deActivateMC(param) : Observable<any> {
    let body = {
      iccUserId : param.iccUserId,
      iccUserRole : param.iccUserRole,
      iccUserGroupId : param.iccUserGroupId,
      mcId : param.mcId
    }
    let url = environment.serviceBase + 'api/User/DeActivateMC';

    return this.httpClient.post(url, body);
  }

  _newUser(userObj) : Observable<any>
  {
    let url = environment.serviceBase + "api/User/NewUser";
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    let parentGroupID = authData.groupID;
    let parentGroupType = authData.groupType;
    let currentUserId = authData.userID;

    parentGroupID = parentGroupID || '';
    parentGroupType = parentGroupType || '';
    currentUserId = currentUserId || '';
    userObj.email = userObj.email || '';
    userObj.Name = userObj.Name || '';
    userObj.Phone = userObj.Phone || '';
    userObj.JobTitle = userObj.JobTitle || '';
    userObj.email = userObj.email || '';
    userObj.StreetAddress1 = userObj.StreetAddress1 || '';
    userObj.StreetAddress2 = userObj.StreetAddress2 || '';
    userObj.City = userObj.City || '';
    userObj.St = userObj.St || '';
    userObj.ZipCode = userObj.ZipCode || '';

    // let body = "GroupId=" + parentGroupID + "&GroupType=" + parentGroupType + "&Role=" + 300 + "&email=" + userObj.email + "&Name=" + userObj.Name + "&Phone=" + userObj.Phone + "&JobTitle=" + userObj.JobTitle + "&UserName=" + userObj.email + "&StreetAddress1=" + userObj.StreetAddress1 + "&StreetAddress2=" + userObj.StreetAddress2 + "&City=" + userObj.City + "&St=" + userObj.St + "&ZipCode=" + userObj.ZipCode + "&Enabled=" + true + "&AddedByUserId=" + currentUserId;
    let body =  
    {

      "GroupId":  parentGroupID,
      "GroupType":  parentGroupType,
      "Role":  300,
      "email":  userObj.email,
      "Name":  userObj.Name,
      "Phone":  userObj.Phone,
      "JobTitle":  userObj.JobTitle,
      "UserName":  userObj.email,
      "StreetAddress1":  userObj.StreetAddress1,
      "StreetAddress2":  userObj.StreetAddress2,
      "City":  userObj.City,
      "St":  userObj.St, 
      "ZipCode":  userObj.ZipCode,
      "Enabled":  true,
      "AddedByUserId":  currentUserId
    }

    return this.httpClient.post(url, body, { headers: { 'Content-Type': 'application/json' }, observe : 'response' });
  }
  _getUser(userID :number) : Observable<any>
  {
    let getURL = environment.serviceBase + 'api/User/GetUser?UserId=' + userID;

    return this.httpClient.get(getURL);
  };

  _newTempMCs(mcArray : object[]) : Observable<any>

  {
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    let url = environment.serviceBase + "api/User/NewTempMCs";

    return this.httpClient.post(url, mcArray, { headers: { 'Content-Type': 'application/json'} , observe : 'response' })
  };

  //announcement
  _getAnnouncement()
  {
    let getURL = environment.serviceBase + 'api/User/GetAnnouncement';
     
    return this.httpClient.get(getURL);
  }
  _getAnnouncementList()
  {
    let getURL = environment.serviceBase + 'api/User/GetAnnouncementList';
     
    return this.httpClient.get(getURL);
  }

  _updateAnnouncement(announObj) 
  {
    let authData = JSON.parse(localStorage.getItem('authorizationData'));

    let body : any = {
      AnnouncementId: announObj.announcementId ? announObj.announcementId : null ,
      AnnouncementTxt: announObj.announcementTxt, 
      EffectiveFromDt: announObj.effectiveFromDt,
      EffectiveToDt: announObj.effectiveToDt,
      Note: announObj.note,
      IsActiveInd: announObj.isActiveInd
    }
    announObj.UpdatedBy ? body.UpdatedByUserId = announObj.UpdatedBy : body.AddedByUserId = announObj.AddedBy; 

    let url = environment.serviceBase +'api/User/UpdateAnnouncement';
    return this.httpClient.post(url, body, { headers: { 'Content-Type': 'application/json'} , observe : 'response' });
  }

  //announcement

 _sendEmail(toEmail : string, toName : string, fromEmail : string, fromName : string, subject : string, content : string)  : Observable<HttpResponse<any>>
 {
    let url =  environment.serviceBase + 'api/User/SendEmail'; 

    let body = {
      to_email: toEmail,
      to_name: toName,
      from_email: fromEmail,
      from_name: fromName,
      subject: subject,
      content: content,
      isTest: null
    }
    return this.httpClient.post(url, body, { headers: { 'Content-Type': 'application/json'} , observe : 'response' });
  };

  _getMCSampleData() : Observable<any>
  {
    let url =  environment.serviceBase + 'api/User/GetMCSampleData'; 

    return this.httpClient.get(url);
  }





  



  


}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { map, tap, switchMap, mergeMap, filter, concatMap } from 'rxjs/operators';
import { concat, forkJoin, merge, observable, Observable } from 'rxjs';
import {DataService} from '../../Services/data.service';


@Component({
  selector: 'app-pop-up-modal',
  templateUrl: './pop-up-modal.component.html',
  styleUrls: ['./pop-up-modal.component.css']
})
export class PopUpModalComponent implements OnInit {
  popUpModelConfig : any = {};

  constructor(public dialogRef: MatDialogRef<PopUpModalComponent>,
    @Inject(MAT_DIALOG_DATA) private modalData: any)
    
  {
  }

  close() {
    this.dialogRef.close();
    this.popUpModelConfig = {};
  }

  executeDialogAction(action) {
    if(typeof this[action] === "function") {
        this[action]();
    }
  };

  confirm() {
    this.dialogRef.close(this.popUpModelConfig);
    this.popUpModelConfig = {};
  };


  ngOnInit(): void {
    this.popUpModelConfig = this.modalData;
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import{ CommonAppService} from '../../Services/common-app.service';
import{ DataService} from '../../Services/data.service';
import{ AuthService} from '../../Services/auth.service';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-user-agreement',
  templateUrl: './user-agreement.component.html',
  styleUrls: ['./user-agreement.component.css']
})
export class UserAgreementComponent implements OnInit {

  text : string = 'aggree to terms.<br>';
  subNotifier = new Subject();

  constructor(private commonAppService : CommonAppService, private dataService : DataService, private authService: AuthService) { }

  accept() {
    this.dataService._updateUserAgreement()
      .pipe(takeUntil(this.subNotifier)).subscribe(result => {
        let authData = JSON.parse(localStorage.getItem('authorizationData'));
        authData.userAgreed = true;

        
        this.authService._setAuthData(authData)
        this.authService._getsmIndexData();
        this.commonAppService._loginSuccessRoute();//this has to be last one
      });
  }

  ngOnInit(): void {
    this.commonAppService._loginSuccessRoute();

    for(var i = 0; i < 8; i++){
      this.text = this.text + this.text;
    }
  }

  ngOnDestroy() {
    //unsubscribe
    this.subNotifier.next();
    this.subNotifier.complete();
  }

}

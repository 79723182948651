import { Component, OnInit , ViewChild, Inject, Injectable} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {Router, ActivatedRoute} from '@angular/router';
import { concatMap } from 'rxjs/operators';
import {Observable, forkJoin} from 'rxjs';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AuthService } from '../../Services/auth.service';
import { DataService} from '../../Services/data.service';
import { CommonAppService} from '../../Services/common-app.service';
import { ConsentModalComponent } from '../consent-modal/consent-modal.component';
import { SignatureModalComponent } from '../signature-modal/signature-modal.component';
import { appConst } from '../../app.config';
import {environment} from '../../../environments/environment';
declare var HelloSign : any;
import * as _ from 'underscore';


@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.css']
})
export class ConsentComponent implements OnInit {

  private authData = JSON.parse(localStorage.getItem('authorizationData'));
  groupInfo : any = {};
  other : boolean = false;
  MCTSPAcctNbr : string = null;
  activeUser : any = { tsp : '' };
  mcAdminConsentPrefillData = null;
  tspList = [];
  selectedTSPCompanyName : string = '';
  newRequest : any = {};
  selectTSPBtnDisable : boolean = false;

  consentRecords = null;
  private helloSignAppId : string;

  consentTblDataSource : any = [];
  consentColumns : string[] = ['TSPCompanyName', 'ModifiedDate', 'TemplateType'];
  //@ViewChild('sortConsent') sortRadius: MatSort;
  @ViewChild('paginatorConsent') paginatorConsent: MatPaginator;


  constructor(private authService: AuthService, 
    private activatedRouter : ActivatedRoute,
    private router : Router,
    @Inject(DOCUMENT) private document : Document,
    private dataService : DataService,
    private commonAppService : CommonAppService,
    private dialog : MatDialog ) { }

  loadData() {
    //console.log("loadData");
    this.dataService._getGroupInfo().pipe(
      concatMap((result) => {
      this.groupInfo = result;
      return this.dataService._getMCAdminConsentPrefillInfo(this.groupInfo.AdminId, this.groupInfo.IccID)
    })).subscribe((result) => {

      this.mcAdminConsentPrefillData = result;
      this.mcAdminConsentPrefillData[0].mcCompanyName = this.groupInfo.CompanyName;
      this.mcAdminConsentPrefillData[0].mcCompanyAddress = this.groupInfo.StreetAddress1 + ", " + this.groupInfo.City + ", " 
      + this.groupInfo.State + ", " + this.groupInfo.Zipcode;
    });

    forkJoin([this.dataService._getTSPList(0), 
      this.dataService._getFullConsentHistory()]).subscribe(
        ([res1, res2]) => {
          //first observable
          res1.sort((a, b) => {
            if(a.CompanyName.toLowerCase() < b.CompanyName.toLowerCase()) return -1;
            if(a.CompanyName.toLowerCase() > b.CompanyName.toLowerCase()) return 1;
            return 0;
          });
          this.tspList = res1;

          //first observable
          this.consentRecords = res2;
          this.generateTable();
        }
      );
  };

  generateTable() 
  {
    this.consentTblDataSource = new MatTableDataSource(this.consentRecords);
    this.consentTblDataSource.paginator = this.paginatorConsent;
  }

  changeSelection(tspID : string) {
    this.MCTSPAcctNbr = null;
    if(tspID)
    {
      let tsp = this.tspList.find((item) => {
        return item.TSPId == tspID;
      });
      this.selectedTSPCompanyName = tsp.CompanyName;
    }
    else
      {this.selectedTSPCompanyName = null;}
  };

  toggleOther() {
    this.other = !this.other;
    this.selectedTSPCompanyName = null;
    this.newRequest.tsp_string = '';
    this.activeUser.tsp = '';
  }

  submitOther(newTSPName : string) {
    let requestContent = ("AdminId: " + this.groupInfo.AdminId + " from " + this.groupInfo.CompanyName  + " Request for new Telematics Service Provider: " + newTSPName);
    this.dataService._sendEmail('tesupport@telematicexchange.com', 'Litian Liu', 'noreply@telematicexchange.com', 'Telematic Exchange', 'New TSP Request', requestContent)
    .subscribe(() => {
      this.commonAppService._snackBar(true, 'Administrator Notified');
      this.toggleOther();
    });
  }

  open(record)
  {
    let dialogRef = this.dialog.open(ConsentModalComponent, {
      data: record,
      width: '30%',
      //height: '35%',
      id: 'modal-component', //to match the global css
      position: { 
        top: '1%',
        left :'35%',
        right: ''
      }
    });
  }

  selectTSP(tspID) {
    // var deffered = $q.defer();
    let tspName = this.tspList.find((item) => { return item.TSPId == tspID;}).CompanyName;
    this.mcAdminConsentPrefillData[0].mcTSPAcctNbr = this.MCTSPAcctNbr;
    this.mcAdminConsentPrefillData[0].tspName = tspName;
    this.selectTSPBtnDisable = true;
    let authData = this.authData;
    let currentHost = this.document.location.href;

    this.dataService._sigRequest(parseInt(authData.userID), parseInt(tspID), 1, this.mcAdminConsentPrefillData, currentHost).subscribe((result) => {

      let dialogRef = this.dialog.open(SignatureModalComponent, {
        data: tspID,
        width: '40%',
        //height: '35%',
        id: 'modal-component', //to match the global css
        position: { 
          top: '1%',
          left :'30%',
          right: ''
        }
      });

      this.selectTSPBtnDisable = false;
    });
  };

  validatesm() {
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    this.authService._getsmData(authData.userRole, authData.groupType, "ConsentHtml")
      .subscribe((result) => {
        if(!this.authService._accessFilter(result, "ConsentHtml")){
          this.router.navigate(['/']);
        }                                                
      })
    return;
  };

  getEmbedURL(signatureID : string) : Observable<any>
  {
    let emBedObj = {
      consent_id : '',
      sign_url : ''
    }
    return new Observable((ob) => {
      forkJoin([this.dataService._getConsentId(signatureID), 
        this.dataService._getSignUrl(signatureID)]).subscribe(([res1, res2]) => {
          emBedObj.consent_id = res1;
          emBedObj.sign_url = res2;
          ob.next(emBedObj);
          ob.complete();
        });
    });
  }

  hsSigning(signatureID : string)
  {
    this.dataService._getMCLastConsentSignReq(signatureID)
    .subscribe(
      result =>
      {
        let authData = JSON.parse(localStorage.getItem('authorizationData'));

        if(signatureID === result.SignatureId && authData.userID === result.UserId && result.ConsentStatus === 0)
        {
          this.getEmbedURL(signatureID).subscribe(
            result => {
              let consentID = result.consent_id;
              HelloSign.init(this.helloSignAppId);
              HelloSign.open({

                url: result.sign_url,
                allowCancel: true,
                debug: false,
                skipDomainVerification: (environment.isTest ? true : false),
                messageListener: (eventData) => {
                    //console.log("Got message data: ", eventData);
                    if(eventData.event == HelloSign.EVENT_SIGNED) 
                    {
                      this.dataService._updateConsentStatus({ConsentId : consentID, ConsentStatus : 1}).pipe(
                        concatMap(
                          result => {//need to wait until status updates then call getMCStatus
                            return this.dataService._getMCStatus(this.groupInfo.GroupId);
                          }
                        )).subscribe(
                          result => {
                            if(result && result.TemplateType === 0)
                            {
                              this.dataService._getSubUsers(this.groupInfo.IccID, appConst.groupType.ICC).pipe(
                                concatMap(
                                  result => {
                                    let iccAdminLst = _.filter(result, (item) => { return item.Role == appConst.role.admin});
                                    let domain = this.document.location.origin;

                                    //iccAdminLst = [{email:'lliu@energi.com'}];
                                    let iccAdmins = '';
                                    iccAdminLst.forEach((item => {
                                      iccAdmins += item.email + ';'
                                    }));
                                    
                                    return this.dataService._sendEmail(iccAdmins, 'ICC Admins', 'noreply@telematicexchange.com', 'Telematic Exchange', 
                                      'Consent Revoked', `MCid of ${this.groupInfo.GroupId}, ${this.groupInfo.CompanyName} has Revoked the Consent! From ${domain}`)
                                  })
                                )
                              .subscribe();
                            }
                            this.router.navigate(['/consentHistory']);//re enter the page to force reloading
                          });
                    } else if (eventData.event === HelloSign.EVENT_CANCELED) {
                      //do something else for document closing before getting signed
                    } else if (eventData.event === HelloSign.EVENT_ERROR) {
                      //do something for an error situation
                    } else if (eventData.event === HelloSign.EVENT_SENT) {
                      //not used in this example
                      //only used for embedded requesting
                    }
                  }
              });
            }
          )
        }
        else if(authData.userID !== result.UserId)
        {
          this.commonAppService._snackBar(false, 'Access Denied. Please login to the Telematics Exchange!', 7000);
        }
        else if(signatureID !== result.SignatureId && result.ConsentStatus === 0)
        {
          this.commonAppService._snackBar(false, 'The email link you have used is invalid. Please ensure you have selected the most recent email from the Telematics Exchange and be sure to check your spam folders', 10000);
        }
        else if(result.ConsentStatus === 1)
        {
          this.commonAppService._snackBar(false, 'This Consent Request Has Been Signed! Please login to check your consent status and ensure you have selected the most recent email link', 10000);
        }
      }
    );
  }

  ngOnInit(): void {
    
    this.validatesm();
    this.loadData();
    
    let signatureID = this.activatedRouter.snapshot.paramMap.get('signatureID');
    if (signatureID)
    {
      this.authService._getHelloSignAccess().subscribe(
        result => {
          this.helloSignAppId = result;
          this.hsSigning(signatureID);
        }
      );
    }
  }

}

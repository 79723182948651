import { Component, OnInit, Type, ViewChild } from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../Services/auth.service';
import {CommonAppService} from '../../Services/common-app.service';
import {DataService} from '../../Services/data.service';
import {observable, Observable} from 'rxjs'
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Papa } from 'ngx-papaparse';

import * as _ from 'underscore';


@Component({
  selector: 'app-mc-upload',
  templateUrl: './mc-upload.component.html',
  styleUrls: ['./mc-upload.component.css']
})
export class McUploadComponent implements OnInit {

  csvRows = [];
  parsed : boolean = false;
  previewed : boolean = false;
  uploadable : boolean = false;
  mcList = null;

  
  mcUploadColumns : string[] = ['CompanyName', 'StreetAddress1', 'City', 'St', 'Agent', 'NAICS', 'InsuranceProgram'];
  mcUploadLstDataSource = new MatTableDataSource();
  @ViewChild('sortMcList') sortMcList: MatSort;
  @ViewChild('paginatorMcList') paginatorMcList: MatPaginator;
  


  constructor(
    private authService : AuthService,
    private commonAppService : CommonAppService,
    private dataService : DataService,
    private router : Router,
    private papa: Papa) { 

    }

  validatesm() {
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    this.authService._getsmData(authData.userRole, authData.groupType, "MCUploadHtml")
        .subscribe((result) => {
            if(!this.authService._accessFilter(result, "MCUploadHtml")){
              this.router.navigate(['/']);
            }                                                
        })
    return;
  };
  fileInputChange(e : Event) 
  {
    this.mcList = (e.target as HTMLInputElement).files[0];

    //clean up the file in the html input element to allow uploading same file name
    (e.target as HTMLInputElement).value = null;
  }
  parseCSV() : Observable<any>
  {
    return new Observable((ob) => {
      this.papa.parse(this.mcList, {
        worker: true,
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results.errors.length == 0) {
            //if(results.data.length >0 && angular.isUndefined(results.data[0].NAICS)) // add validation if the template missing the NAICS column
            if(!results.meta.fields.includes("NAICS"))
            {
              this.parsed = false;
              this.uploadable = false;
              alert("NAICS is Required Column in the Template, Please Use the Most Recent Version of Template!");
              ob.error();
            }
            else
            {
              this.parsed = true;
              ob.next(results.data)
            }
          } else {
            alert("Upload File Error: " + results.errors[0].type);
            console.log(results.errors);
            ob.error();
          }
          ob.complete();
        }
      });
    })
  };

  displayCSV() {

    this.parseCSV().subscribe((result)=> {
      //delete empty rows from csv file
      var resultCount = result.length;
      for(let i = 1; i< resultCount; i++)
      {
        let propetyContent = '';
        for(let key in result[i])
        {

          if(!result[i][key])
          {
            propetyContent += ''
          }
          else{
            propetyContent += result[i][key];
          }
          //papa convert the empty string to null, need to make above if else check 04/04/2022
          //propetyContent += result[i][key];
        }
        if(!propetyContent.trim())
        {
          result[i] = null;
        }
      }
      result = result.filter(f => f);

      this.uploadable = true;

      let grouped = _.toArray(_.groupBy(result, g => g.CompanyName));
      
      for(let j = 0; j < grouped.length; j++)
      {
        if(_.toArray(grouped[j]).length > 1)
        {
          this.commonAppService._snackBar(false, 'You have duplicate Companies, Please check before uploading!', 5000);
          this.uploadable = false;
          break;
        }
        else if(!_.isEmpty(_.toArray(grouped[j])[0].NAICS?.toString().trim()))
        {
          let reg = RegExp('^[0-9]{6}$');
          let match = _.toArray(grouped[j])[0].NAICS?.toString().trim().match(reg);

          if(!match)
          {
            this.commonAppService._snackBar(false, 'Invalid NAICS Format!', 5000);
            this.uploadable = false;
            break;
          }
        }
      }

      this.csvRows = result;
      this.generateTable();
    }, (error) => {
      this.uploadable = false;
    });
  };

  generateTable()
  {
    this.mcUploadLstDataSource = new MatTableDataSource(this.csvRows);
    this.mcUploadLstDataSource.paginator = this.paginatorMcList;
    this.mcUploadLstDataSource.sort = this.sortMcList;

    this.previewed = true;
  }

  uploadMCList() {

    if(this.csvRows.length === 0) return;

    //add icc id to the uploading template
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    let currentTime = new Date();
    
    let batchId = authData.groupID + "_" + ("0" + (currentTime.getMonth() +1)).slice(-2) + "_" + ("0" + currentTime.getDate()).slice(-2) + "_" + currentTime.getFullYear().toString().substring(2) +
     "_" + ("0" + currentTime.getHours()).slice(-2) + "_" + ("0" + currentTime.getMinutes()).slice(-2) + "_" + ("0" + currentTime.getSeconds()).slice(-2) + "_" + currentTime.getMilliseconds();

    //batchId = '1_02_09_22_16_55_04_165'; 
    this.csvRows.forEach((item) => { 
        item.userID = authData.userID;
        item.IccID = authData.groupID; 
        item.batchId = batchId; 
        //item.createdDate = ("0" + (currentTime.getMonth() +1)).slice(-2) +'/'+ ("0" + currentTime.getDate()).slice(-2) + '/' + currentTime.getFullYear() + ' ' + 
        //("0" + currentTime.getHours()).slice(-2) + ":" + ("0" + currentTime.getMinutes()).slice(-2) + ":" + currentTime.getSeconds() + "." + currentTime.getMilliseconds();
        item.createdDate = currentTime.toLocaleString();
    });
    
    this.dataService._newTempMCs(this.csvRows).subscribe((result) => {
        //console.log('NEW TEMP MCs', result);
      if(result.status === 200) {
        // alert('Uploaded To Server');
        this.commonAppService._snackBar(true, 'Uploaded To Server');
        this.csvRows = [];
        this.mcList = null;
        this.parsed = false;
        this.previewed = false;
      }
    }, error =>
    {
      if (error.status === 400) {
        alert("List already exists - cannot upload");}
    });
  };


  ngOnInit(): void {
    this.validatesm();
  }

}

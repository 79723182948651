import { Component, OnInit , ViewChild, ElementRef, AfterViewInit, HostListener, Renderer2, Input, OnChanges } from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';


import * as moment from 'moment';
import * as _ from 'underscore';
declare var $: any;// for convertion purpose using the jQuery here, which supposed not to in Angular 2x. will find workaround after convertion
declare let d3 : any;

@Component({
  selector: 'app-utilization',
  templateUrl: './utilization.component.html',
  styleUrls: ['./utilization.component.css']
})
export class UtilizationComponent implements OnInit {

  @Input() utilData : any;
  @Input() totalDays : any;
  
  //Utilization
  activeVehicleUtilization = null;
  utilTblDataSource :any = [];
  utilColumns : string[] = ['sortBase', 'vehicleType', 'daysOperational', 'totalMileage', 'sum_NetOnTimeHr', 'averageOfSum_NetOnTimeHr', 'dailyMiles'];
  @ViewChild('sortUtil') sortUtil: MatSort;
  @ViewChild('paginatorUtil') paginatorUtil: MatPaginator;

  timeOfDay = [{ key: "Rush", values: [] }, { key: "NonRush", values: [] }];
  roadType = [{ key: "Highway Mileage", values: [] }, { key: "Non-Highway Mileage", values: [] }];
  dayOfWeek = [{ key: "Weekday", values: [] }, { key: "Weekend", values: [] }];
  trafficDensity = [{ key: "High Traffic", values: [] }, { key: "Medium Traffic", values: [] }, { key: "Normal Traffic", values: [] }];
  

  utilizationChartData = [];
  utilizationChartOptions = {};

  utilizationChartRoadTypeData = [];
  utilizationRoadTypeStackedBarOptions = {}

  utilizationStackedData : any = [{ key: "Idle Time", values: [] }, { key: "Moving Time", values: [] }];
  stackedBarOptions = {};
  //Utilization

  //Driver
  @Input() utilData_Driver : any; 
  activeDriverUtilization = null;
  driverUtilTblDataSource :any = [];
  driverUtilColumns : string[] = ['driverName', 'daysOperational', 'totalMileage', 'sum_NetOnTimeHr', 'averageOfSum_NetOnTimeHr', 'dailyMiles'];
  @ViewChild('sortUtilDriver') sortUtilDriver: MatSort;
  @ViewChild('paginatorUtilDriver') paginatorUtilDriver: MatPaginator;

  timeOfDayDriver = [{ key: "Rush", values: [] }, { key: "NonRush", values: [] }];
  roadTypeDrive = [{ key: "Highway Mileage", values: [] }, { key: "Non-Highway Mileage", values: [] }];
  dayOfWeekDrive = [{ key: "Weekday", values: [] }, { key: "Weekend", values: [] }];
  trafficDensityDrive = [{ key: "High Traffic", values: [] }, { key: "Medium Traffic", values: [] }, { key: "Normal Traffic", values: [] }];
  

  driverUtilizationChartData = [];
  driverUtilizationChartOptions = {};

  driverUtilizationChartRoadTypeData = [];
  driverUtilizationRoadTypeStackedBarOptions = {}

  driverUtilizationStackedData : any = [{ key: "Idle Time", values: [] }, { key: "Moving Time", values: [] }];
  driverStackedBarOptions = {};

  
  driverOrVeh : string = 'vehicleView';

  //Driver

  constructor(private render : Renderer2) { }

    //Utilization

 
  generateUtilTable() {
    
    if(!this.utilData) return;
    //for sorting on contional VIN or Vehicleid 
    this.utilData.forEach((item) => {
      item.sortBase = item.vin && item.vin !== 'N/A' ? item.vin.replace(item.vin.slice(0, (item.vin.length) - 6), "").toString() +'/' : '/' + item.vehicleId
    });

    let utilViewData = _.toArray(this.utilData);
    
    this.utilTblDataSource = new MatTableDataSource(utilViewData);
    this.utilTblDataSource.sort = this.sortUtil;
    this.utilTblDataSource.paginator = this.paginatorUtil;
  }

  setActiveUtilization(speedPoint, event) {
    // $('#utilization tr.selected').each(function(index) {
    //   $(this).removeClass('selected');
    // });
    let rows = Array.from(document.getElementById('utilization').getElementsByTagName('tr'));
    rows.forEach(item => {
      this.render.removeClass(item, 'selected');
    });

    this.render.addClass(event.currentTarget, 'selected');

    this.activeVehicleUtilization = speedPoint;

    // Clear All Existing Valls
    _.find(this.timeOfDay, { 'key': 'Rush' }).values = [];
    _.find(this.timeOfDay, { 'key': 'NonRush' }).values = [];

    _.find(this.dayOfWeek, { 'key': 'Weekday' }).values = [];
    _.find(this.dayOfWeek, { 'key': 'Weekend' }).values = [];

    _.find(this.trafficDensity, { 'key': 'High Traffic' }).values = [];
    _.find(this.trafficDensity, { 'key': 'Medium Traffic' }).values = [];
    _.find(this.trafficDensity, { 'key': 'Normal Traffic' }).values = [];

    //_.find(this.utilizationStackedData, { 'key': 'Idle Time' }).values = [];
    //_.find(this.utilizationStackedData, { 'key': 'Moving Time' }).values = [];

    this.utilizationStackedData = [];
    

    // Calculate New Vals
    let rhHours = { 'label': speedPoint.vehicleId, 'value': Math.round((speedPoint.sum_RushMiles) * 100) / 100 };
    let nonRhHours = { 'label': speedPoint.vehicleId, 'value': Math.round((speedPoint.sum_NonRushMiles) * 100) / 100 };

    let wkDay = { 'label': speedPoint.vehicleId, 'value': Math.round((speedPoint.sum_WeekdayMiles) * 100) / 100 };
    let wkEnd = { 'label': speedPoint.vehicleId, 'value': Math.round((speedPoint.sum_WeekendMiles) * 100) / 100 };

    let idleTime = { 'label': speedPoint.vehicleId, 'value': Math.round((speedPoint.sum_IdleTimeHr) * 100) / 100 };
    let movingTime = { 'label': speedPoint.vehicleId, 'value': Math.round((speedPoint.sum_MovingTimeHr) * 100) / 100 };

    // Set New Vals
    _.find(this.timeOfDay, { 'key': 'Rush' }).values.push(rhHours);
    _.find(this.timeOfDay, { 'key': 'NonRush' }).values.push(nonRhHours);

    _.find(this.dayOfWeek, { 'key': 'Weekday' }).values.push(wkDay);
    _.find(this.dayOfWeek, { 'key': 'Weekend' }).values.push(wkEnd);

    //_.find(this.utilizationStackedData, { 'key': 'Idle Time' }).values.push(idleTime);
    //_.find(this.utilizationStackedData, { 'key': 'Moving Time' }).values.push(movingTime);

    this.utilizationStackedData.push({ 'key': 'Idle Time', 'values': [ idleTime ]});
    this.utilizationStackedData.push({ 'key': 'Moving Time', 'values': [ movingTime ]});
    //this.setStackedBarOptions();

    if(speedPoint.vehicleRiskTrafficSummary){
      this.setUtilizationChartData(speedPoint.vehicleRiskTrafficSummary);
    }

    if(speedPoint.vehicleRiskSpeedingSummary){
      this.setUtilizationspeedingChartData(speedPoint.vehicleRiskSpeedingSummary);
    }

  }
  setUtilizationChartData(trafficData) {
    try
    { 
      let trafficType : any = {};
      //$scope.override = { stroke: ['rgb(255, 255, 255)', 'rgb(42, 46, 57)'] };

      let getTrafficType = function(trafficTypeData) {
        let trafficTypesData = [];

        for(var i =0; i< trafficTypeData.length; i++) 
        {
          trafficType  = {};                            
          trafficType.size = trafficTypeData[i].totalMiles;
          if(trafficTypeData[i].trafficTypeId != 4) {

            trafficType.name = trafficTypeData[i].trafficType + "Traffic Total Miles";
            trafficType.color = (trafficTypeData[i].trafficTypeId == 1) ? "#0d8bc5" : (
                                (trafficTypeData[i].trafficTypeId == 2) ? "#dcdcdc" : (
                                (trafficTypeData[i].trafficTypeId == 3) ? "#f414e4" : "#2a2e39"));
          } 
          else{
            trafficType.name = "";
            trafficType.color= "#2a2e39";
          }

          trafficTypesData.push(trafficType);
        }
        return trafficTypesData;
      };

      let weekdayTrafficTypes = getTrafficType(trafficData[0].weekdayData);
      let weekendTrafficTypes = getTrafficType(trafficData[0].weekendData);

      this.utilizationChartData = [
        {
          name: "",
          size: trafficData[0].totalMiles,
          color: "#2a2e39",
          children: [
            { 
              name: "Weekday Total Miles",
              size: trafficData[0].weekdayData_TotalMiles,
              color: "#0b6ccd",
              children: weekdayTrafficTypes
            },
            {
              name: "Weekend Total Miles",
              size: trafficData[0].weekendData_TotalMiles,
              color: "#3333cc",
              children: weekendTrafficTypes
            }
          ]
      }];
    }
    catch(err){
      this.utilizationChartData = [];
    }                
  }

  setUtilizationspeedingChartData(speedingData)
  {                
    try
    {
      let tempSpeedingChartData = [];
      speedingData = speedingData.sort(function (a, b) { return a.roadType - b.roadType});
      
      for(var i =0; i< speedingData.length; i++)
      {
        tempSpeedingChartData.push({
          "label": speedingData[i].roadTypeDesc, 
          "value": (speedingData[i].sum_TotalMiles > 0) ? speedingData[i].sum_TotalMiles : 0
        });
      }

      this.utilizationChartRoadTypeData = [{
        key: "Miles on Road Type",
        values: tempSpeedingChartData
      }];
    }
    catch(err){
      this.utilizationChartRoadTypeData = [];
    }
  }

  setUtilizationChartOptions() {
    this.utilizationChartOptions = {
      chart: {
        type: 'sunburstChart',
        color: ['#2a2e39', '#0d8bc5', '#dcdcdc', '#f414e4', '#0b6ccd', '#eee'],//d3.scale.category20(),
        stroke: '#2a2e39',
        height: 325,
        showLabels: false,
        // tooltip:{
        //     enabled: true,
        //     position: function() { return { left:10, top: 50 };},
        // },
        labelThreshold: 0.7,
        duration: 250,
        labelFormat: function (d){ return d.name.split(" ")[0]; },
        mode : 'size',
        transition:true
      }
    };
  }

  setUtilizationRoadTypeStackedBarOptions()
  {
    this.utilizationRoadTypeStackedBarOptions = {
      chart: {
        type: 'multiBarHorizontalChart',
        height: 380,
        x: function(d){return d.label;},
        y: function(d){return d.value;},
        showControls: false,
        showValues: true,
        valueFormat: function(d){return "";},
        duration: 500,
        groupSpacing: 0.10,
        color:['#0b6ccd'],
        xAxis: {
            showMaxMin: false
        },
        yAxis: {
          axisLabel: '',
          tickFormat: function(d){ return d3.format(',.0f')(d); }
        },
        margin: {
            top: 0,
            right: -45,
            bottom: 40,
            left:100
        },
        showYAxis: true,
        showXAxis: true,
        showLegend: false,
        stacked: false,
        forceY: [0, 500],
      }
    };
  }

  setStackedBarOptions() {
    this.stackedBarOptions = {
      chart: {
        type: 'multiBarHorizontalChart',
        height: 45, //50
        x: function(d) {
          return d.label;
        },
        y: function(d) {
          return d.value;
        },
        color: ['#0080ff', '#03A9F4', '#B8CAF8'],
        tooltip: {
          duration: 0,
          hideDelay: 0,
          valueFormatter: function(d, i) {
            return d;
          },
          keyFormatter: function(d, i) {
            return d;
          },
          headerFormatter: function(d) {
            //Driver 
            // Comment out below
            //let header = this.isDriver ? "VIN: " + d;
            //return header;
          }
        },
        margin: {
            top: 0,
            right: 0,
            bottom: 15,
            left: 100
        },
        showLegend: false,
        showValues: true,
        duration: 500,
        showYAxis: false,
        //groupSpacing: 0.30,
        showXAxis: false,
        stacked: true,
        showControls: false
      }
    };
  }

  //Driver
  generateDriverUtilTable() {
    
    if(!this.utilData_Driver) return;
    

    let driverUtilViewData = _.toArray(this.utilData_Driver);
    
    this.driverUtilTblDataSource = new MatTableDataSource(driverUtilViewData);
    this.driverUtilTblDataSource.sort = this.sortUtilDriver;
    this.driverUtilTblDataSource.paginator = this.paginatorUtilDriver;
  }

  setActiveUtilizationDriver(driverPoint, event) {
    // $('#utilization tr.selected').each(function(index) {
    //   $(this).removeClass('selected');
    // });
    let rows = Array.from(document.getElementById('utilizationDriver').getElementsByTagName('tr'));
    rows.forEach(item => {
      this.render.removeClass(item, 'selected');
    });

    this.render.addClass(event.currentTarget, 'selected');

    this.activeDriverUtilization = driverPoint;

    // Clear All Existing Valls
    _.find(this.timeOfDay, { 'key': 'Rush' }).values = [];
    _.find(this.timeOfDay, { 'key': 'NonRush' }).values = [];

    _.find(this.dayOfWeek, { 'key': 'Weekday' }).values = [];
    _.find(this.dayOfWeek, { 'key': 'Weekend' }).values = [];

    _.find(this.trafficDensity, { 'key': 'High Traffic' }).values = [];
    _.find(this.trafficDensity, { 'key': 'Medium Traffic' }).values = [];
    _.find(this.trafficDensity, { 'key': 'Normal Traffic' }).values = [];

    //_.find(this.utilizationStackedData, { 'key': 'Idle Time' }).values = [];
    //_.find(this.utilizationStackedData, { 'key': 'Moving Time' }).values = [];

    this.driverUtilizationStackedData = [];
    

    // Calculate New Vals
    let rhHours = { 'label': driverPoint.driverId, 'value': Math.round((driverPoint.sum_RushMiles) * 100) / 100 };
    let nonRhHours = { 'label': driverPoint.driverId, 'value': Math.round((driverPoint.sum_NonRushMiles) * 100) / 100 };

    let wkDay = { 'label': driverPoint.driverId, 'value': Math.round((driverPoint.sum_WeekdayMiles) * 100) / 100 };
    let wkEnd = { 'label': driverPoint.driverId, 'value': Math.round((driverPoint.sum_WeekendMiles) * 100) / 100 };

    let idleTime = { 'label': driverPoint.driverId, 'value': Math.round((driverPoint.sum_IdleTimeHr) * 100) / 100 };
    let movingTime = { 'label': driverPoint.driverId, 'value': Math.round((driverPoint.sum_MovingTimeHr) * 100) / 100 };

    // Set New Vals
    _.find(this.timeOfDay, { 'key': 'Rush' }).values.push(rhHours);
    _.find(this.timeOfDay, { 'key': 'NonRush' }).values.push(nonRhHours);

    _.find(this.dayOfWeek, { 'key': 'Weekday' }).values.push(wkDay);
    _.find(this.dayOfWeek, { 'key': 'Weekend' }).values.push(wkEnd);

    //_.find(this.utilizationStackedData, { 'key': 'Idle Time' }).values.push(idleTime);
    //_.find(this.utilizationStackedData, { 'key': 'Moving Time' }).values.push(movingTime);

    this.driverUtilizationStackedData.push({ 'key': 'Idle Time', 'values': [ idleTime ]});
    this.driverUtilizationStackedData.push({ 'key': 'Moving Time', 'values': [ movingTime ]});
    //this.setStackedBarOptions();

    if(driverPoint.driverRiskTrafficSummary){
      this.setDriverUtilizationChartData(driverPoint.driverRiskTrafficSummary);
    }

    if(driverPoint.driverRiskSpeedingSummary){
      this.setDriverUtilizationspeedingChartData(driverPoint.driverRiskSpeedingSummary);
    }

  }

  setDriverUtilizationChartData(trafficData) {
    try
    { 
      let trafficType : any = {};
      //$scope.override = { stroke: ['rgb(255, 255, 255)', 'rgb(42, 46, 57)'] };

      let getTrafficType = function(trafficTypeData) {
        let trafficTypesData = [];

        for(var i =0; i< trafficTypeData.length; i++) 
        {
          trafficType  = {};                            
          trafficType.size = trafficTypeData[i].totalMiles;
          if(trafficTypeData[i].trafficTypeId != 4) {

            trafficType.name = trafficTypeData[i].trafficType + "Traffic Total Miles";
            trafficType.color = (trafficTypeData[i].trafficTypeId == 1) ? "#0d8bc5" : (
                                (trafficTypeData[i].trafficTypeId == 2) ? "#dcdcdc" : (
                                (trafficTypeData[i].trafficTypeId == 3) ? "#f414e4" : "#2a2e39"));
          } 
          else{
            trafficType.name = "";
            trafficType.color= "#2a2e39";
          }

          trafficTypesData.push(trafficType);
        }
        return trafficTypesData;
      };

      let weekdayTrafficTypes = getTrafficType(trafficData[0].weekdayData);
      let weekendTrafficTypes = getTrafficType(trafficData[0].weekendData);

      this.driverUtilizationChartData = [
        {
          name: "",
          size: trafficData[0].totalMiles,
          color: "#2a2e39",
          children: [
            { 
              name: "Weekday Total Miles",
              size: trafficData[0].weekdayData_TotalMiles,
              color: "#0b6ccd",
              children: weekdayTrafficTypes
            },
            {
              name: "Weekend Total Miles",
              size: trafficData[0].weekendData_TotalMiles,
              color: "#3333cc",
              children: weekendTrafficTypes
            }
          ]
      }];
    }
    catch(err){
      this.driverUtilizationChartData = [];
    }                
  }

  setDriverUtilizationspeedingChartData(speedingData)
  {                
    try
    {
      let tempSpeedingChartData = [];
      speedingData = speedingData.sort(function (a, b) { return a.roadType - b.roadType});
      
      for(var i =0; i< speedingData.length; i++)
      {
        tempSpeedingChartData.push({
          "label": speedingData[i].roadTypeDesc, 
          "value": (speedingData[i].sum_TotalMiles > 0) ? speedingData[i].sum_TotalMiles : 0
        });
      }

      this.driverUtilizationChartRoadTypeData = [{
        key: "Miles on Road Type",
        values: tempSpeedingChartData
      }];
    }
    catch(err){
      this.driverUtilizationChartRoadTypeData = [];
    }
  }

 


  //Driver
  
  ngOnInit (): void {
    //this.generateUtilTable();
    this.setUtilizationChartOptions();
    this.setUtilizationRoadTypeStackedBarOptions();
    this.setStackedBarOptions();
    
  }

  ngOnChanges (): void{
    //use OnChanges when the UtilData is available from subscription instead of using ngIf causing sort issue
    this.generateUtilTable();

    this.activeVehicleUtilization = null;// when data change, reset the chart

    //Driver
    this.generateDriverUtilTable();
    this.activeDriverUtilization = null;// when data change, reset the chart
    //Driver
  }

}

<div class="row">
    <div class="col-md-6">
        <div class="dashhead">
            <div class="dashhead-titles">
                <h6 class="dashhead-subtitle">Upload</h6>
                <h2 class="dashhead-title">
                    Policyholders
                </h2>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="panel panel-info">
            <div class="panel-heading"></div>
            <div class="panel-body">
                <div class="row">
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-1">
                                <h1>1.</h1>
                            </div>
                            <div class="col-md-11">
                                <div class="input-group">
                                    <label class="input-group-btn">
                                        <button class="btn btn-block btn-info" (click) = "fileInput.click()">
                                            <span>Select Policyholder List</span>
                                            <input #fileInput type="file" class="btn btn-info" (change)="fileInputChange($event)" style="display:none;">
                                        </button>
                                    </label>
                                    <input type="text" class="form-control" [ngModel]="mcList && mcList.name" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="mcList">
                            <div class="col-md-1">
                                <h1>2.</h1>
                            </div>
                            <div class="col-md-11">
                                <button class="btn btn-block btn-info" (click)="displayCSV()">Preview File</button>
                            </div>
                        </div>
                        <div class="row" *ngIf="previewed">
                            <div class="col-md-1">
                                <h1>3.</h1>
                            </div>
                            <div class="col-md-11">
                                <button class="btn btn-block btn-info" (click)="uploadMCList()" [disabled] = "!uploadable">Upload Policyholders</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="table-responsive preview-table" [hidden]="!parsed">
                            
                            <table id = 'mcUploadTbl' mat-table [dataSource] = "mcUploadLstDataSource" #sortMcList ='matSort' matSort class="table table-condensedNew">
                                <ng-container matColumnDef="CompanyName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                                        CompanyName
                                    </th>
                                    <td mat-cell *matCellDef="let element; let index = index">
                                        {{ element.CompanyName }}
                                        </td>
                                </ng-container>
                                    
                                <ng-container matColumnDef="StreetAddress1">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300">
                                        Street Address 1
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.StreetAddress1}} </td>
                                </ng-container>
                                    
                                <ng-container matColumnDef="City">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                                        City
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{element.City}} </td>
                                </ng-container>
                
                                <ng-container matColumnDef="St">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                                        St
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.St }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Agent">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                                        Agent
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.Agent }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="NAICS">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                                        NAICS
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.NAICS }}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="InsuranceProgram">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                                        Insurance Program
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{ element.InsuranceProgram }}
                                    </td>
                                </ng-container>
                
                                <tr mat-header-row *matHeaderRowDef="mcUploadColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: mcUploadColumns; let index = index" style="height:0px;"></tr>
                            </table>
                            <mat-paginator #paginatorMcList [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons style="margin-bottom: 2%;margin-top:-1%; background: none; color: #fff;"></mat-paginator>
                            
                        
                  



                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

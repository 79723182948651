import { query, trigger, animate, transition, style, sequence, stagger, animateChild, state } from '@angular/animations';

export const fadeInAnimation =
    // trigger name for attaching this animation to an element using the [@triggerName] syntax
    trigger('fadeAnimation', [
        // The '* => *' will trigger the animation to change between any two states
        transition('* => *', [
          // The query function has three params.
          // First is the event, so this will apply on entering or when the element is added to the DOM.
          // Second is a list of styles or animations to apply.
          // Third we add a config object with optional set to true, this is to signal
          // angular that the animation may not apply as it may or may not be in the DOM.
          query(':enter', [style({ opacity: 0})], { optional: true }),
          query(
            ':leave',
            // here we apply a style and use the animate function to apply the style over 0.3 seconds
            [style({ opacity: 0.8 }), animate('0.3s linear', style({ opacity: 0}))],
            { optional: true }
          ),
          query(
            ':enter',
            [style({ opacity: 1}), animate('0.3s ease-in', style({ opacity: 1, transform: 'scale(1.005)' }))],
            { optional: true }
          )
        ])
      ]);
      
export const rowsAnimation =
trigger('rowsAnimation', [
  transition('void => *', [
    style({ height: '*', opacity: '0', transform: 'translateX(-550px)', 'box-shadow': 'none' }),
    sequence([
      animate("0.35s ease", style({ height: '*', opacity: '.2', transform: 'translateX(0)', 'box-shadow': 'none' })),
      animate("0.35s ease", style({ height: '*', opacity: 1, transform: 'translateX(0)' }))
    ])
  ])
]);

export const blub =
  trigger('blub', [
    transition(':leave', [
      style({ background: 'pink'}),
      query('*', stagger(-150, [animateChild()]), { optional: true })
    ]),
  ]);
<div class="row">
    <div class="col-md-8 col-md-offset-2">
        <div class="panel panel-info">
            <div class="panel-heading"></div>
            <div class="panel-body">
                <h2>Activate Account</h2>
                <form class="form-horizontal">
                    <fieldset *ngIf="accountData">
                        <div class="form-group label-floating">
                            <div class="col-md-8 col-md-offset-2">
                                <label class="control-label">Id</label>
                                <input type="text" class="form-control" placeholder="Username" [(ngModel)]="verificationData.userName" name = 'userName' required autofocus>
                            </div>
                        </div>
                        <div class="form-group label-floating">
                            <div class="col-md-8 col-md-offset-2">
                                <label class="control-label">Password</label>
                                <input type="password" class="form-control" placeholder="Password" [(ngModel)]="verificationData.password" name = 'password' required>
                            </div>
                        </div>
                        <div class="alert alert-danger col-md-8 col-md-offset-2 text-center animate-show" [hidden]="!message">
                            <p>{{message}}</p>
                        </div>
                        <div class="form-group">
                            <div class="col-md-8 col-md-offset-2 text-center">
                                <button type="submit" class="btn btn-info" (click)="verifyAccount()" [disabled]="!verificationData.userName || !verificationData.password">Verify</button>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset *ngIf="verified">
                        <div class="alert alert-danger col-md-8 col-md-offset-2 text-center animate-show" [hidden]="!message">
                            <p>{{message}}</p>
                        </div>
                        <div class="alert alert-success col-md-8 col-md-offset-2 text-center animate-show" [hidden]="!success">
                            <p>{{success}}</p>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="label-floating">
                                        <label class="control-label">First and Last Name *</label>
                                        <input type="text" class="form-control" [(ngModel)]="userObj.Name" name = 'Name' required>
                                    </div>
                                    <div class="label-floating">
                                        <label class="control-label">Position</label>
                                        <input type="text" class="form-control" [(ngModel)]="userObj.JobTitle" name ='JobTitle'>
                                    </div>
                                    <div class="label-floating">
                                        <label class="control-label">User Email *</label>
                                        <input type="email" class="form-control" [(ngModel)]="userObj.email" name = 'email' required>
                                    </div>
                                    <div class="label-floating">
                                        <label class="control-label">User Phone *</label>
                                        <input type="text" phone-input class="form-control" [(ngModel)]="userObj.Phone" name = 'Phone' required>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="label-floating">
                                                <label for="i4" class="control-label">Address 1</label>
                                                <input type="text" class="form-control" [(ngModel)]="userObj.StreetAddress1" name = 'StreetAddress1'>
                                            </div>
                                            <div class="label-floating">
                                                <label for="i4" class="control-label">Address 2</label>
                                                <input type="text" class="form-control" [(ngModel)] ="userObj.StreetAddress2" name = 'StreetAddress2'>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="label-floating">
                                                <label for="i4" class="control-label">City</label>
                                                <input type="text" class="form-control" [(ngModel)]="userObj.City" name = 'City'>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="label-floating">
                                                <label for="i4" class="control-label">State</label>
                                                <input type="text" class="form-control" [(ngModel)]="userObj.St" name = 'St'>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="label-floating">
                                                <label for="i4" class="control-label">Zip</label>
                                                <input type="text" class="form-control" [(ngModel)]="userObj.ZipCode" name = 'ZipCode'>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <button class="btn btn-info" type="submit" (click)="updateUserData()" [disabled]="!userObj.Name || !userObj.email || !userObj.Phone">Update</button>
                        </div>
                    </fieldset>
                    <fieldset *ngIf="userDataUpdated">
                        <h4>Create New Password</h4>
                        <div class="alert alert-success col-md-8 col-md-offset-2 text-center animate-show" [hidden]="!success">
                            <p style="white-space: pre-line;">{{success}}</p>
                        </div>
                        <div class="alert alert-danger col-md-8 col-md-offset-2 text-center animate-show" [hidden]="!message">
                            <p>{{message}}</p>
                        </div>
                        <div class="form-group label-floating">
                            <div class="col-md-8 col-md-offset-2">
                                <label class="control-label">Temporary Password</label>
                                <input type="password" class="form-control" placeholder="Password" [(ngModel)]="createNewPass.oldPass" name = 'oldPass' required>
                            </div>
                        </div>
                        <div class="form-group label-floating">
                            <div class="col-md-8 col-md-offset-2">
                                <label class="control-label">New Password</label>
                                <input type="password" class="form-control" placeholder="Password" [(ngModel)]="createNewPass.pass" (ngModelChange)="validatePW(createNewPass.pass)" name = 'pass' required>
                            </div>
                        </div>
                        <div class="form-group label-floating">
                            <div class="col-md-8 col-md-offset-2">
                                <label class="control-label">Confirm New Password</label>
                                <input type="password" class="form-control" placeholder="Password" [(ngModel)]="createNewPass.confirmPass" (ngModelChange)= "checkNewPass()" name ='confirmPass' required>
                            </div>
                        </div>
                        <div class="col-md-offset-2 col-md-8" style="display: flex;">
                            <div class="col-md-6">
                                <button class="btn" style="color: black;" type="submit" (click)="back()">Back</button>                                
                            </div>
                            <div class="col-md-6">
                                <button class="btn btn-info" style="float: right;" type="submit" (click)="updatePass()" [disabled]="disableUpdatePWBtn || !validPass">Update</button>
                            </div>
                        </div>          
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</div>
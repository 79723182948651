import { Injectable } from '@angular/core';
import { observable, Observable, throwError, of} from 'rxjs';
import {catchError, filter, map, mapTo, take, tap} from 'rxjs/operators';
import { HttpClient} from '@angular/common/http';
//import { SERVICEBASE} from '../app.config';
import {environment} from '../../environments/environment';
import * as _ from 'underscore';



@Injectable({
  providedIn: 'root'
})
export class DashService {

  constructor(private httpClient: HttpClient) { }

  _getMonthlyUtilization(McId, dateFrom, dateTo) : Observable<any>
  {
    dateFrom = dateFrom.split("/")[1].toString() + dateFrom.split("/")[0].toString();
    dateTo = dateTo.split("/")[1].toString() + dateTo.split("/")[0].toString();

    let getURL = environment.serviceBase + "api/Dashboard/GetMonthlyUtilizationSum?mcId=" + McId + "&yearMonthFrom=" + dateFrom + "&yearMonthTo=" + dateTo;
    let byCat : any = {};

    let allData : any= {
      data: null,
      byCategory: null
    }

    return this.httpClient.get(getURL).pipe(
      tap(data => {
        (data as []).forEach(function(vehicle, ind) {
          Object.entries(vehicle).forEach(function (entry) {
                // console.log(i, ':', cat);
                if (!byCat[entry[0]]) {
                  byCat[entry[0]] = [];
                }
                byCat[entry[0]].push(entry[1]);
            });
        });

        allData.byCategory = byCat;
        allData.data = data;
      })
  
    );
    //return of(allData);
  }

  _getMonthlyRadiusGaraging(McId, dateFrom, dateTo) : Observable<any>
  {
    dateFrom = dateFrom.split("/")[1].toString() + dateFrom.split("/")[0].toString(); 
    dateTo = dateTo.split("/")[1].toString() + dateTo.split("/")[0].toString(); 

    let getURL = environment.serviceBase + "api/Dashboard/GetMonthlyRadiusOfOperationGargingSum?mcId=" + McId + "&yearMonthFrom=" + dateFrom + "&yearMonthTo=" + dateTo;

    return this.httpClient.get(getURL);
  }

  _getRiskTrafficSummaryByVehicle(vehicleId, McId, dateFrom, dateTo) : Observable<any>
  {
    dateFrom = dateFrom.split("/")[1].toString() + dateFrom.split("/")[0].toString(); 
    dateTo = dateTo.split("/")[1].toString() + dateTo.split("/")[0].toString(); 

    let getURL = environment.serviceBase + "api/Dashboard/GetRiskTrafficSummaryByVehicle?vehicleId=" + vehicleId + "&mcId=" + McId + "&yearMonthFrom=" + dateFrom + "&yearMonthTo=" + dateTo;

    return this.httpClient.get(getURL)
  }

  _getSpeedingData(McId, dateFrom, dateTo) : Observable<any>
  {
    dateFrom = dateFrom.split("/")[1].toString() + dateFrom.split("/")[0].toString(); 
    dateTo = dateTo.split("/")[1].toString() + dateTo.split("/")[0].toString(); 
    // need to make parameters dynamic

    let getURL = environment.serviceBase + "api/Dashboard/GetMonthlySpeedingSum?mcId=" + McId + "&yearMonthFrom=" + dateFrom + "&yearMonthTo=" + dateTo;    
    
    return this.httpClient.get(getURL);
  }

  _getSpeedingRoadTypeData(McId, dateFrom, dateTo) : Observable<any> 
  {
    dateFrom = dateFrom.split("/")[1].toString() + dateFrom.split("/")[0].toString(); 
    dateTo = dateTo.split("/")[1].toString() + dateTo.split("/")[0].toString(); 
                // need to make parameters dynamic

    let getURL = environment.serviceBase + "api/Dashboard/GetMonthlySpeedingRoadTypeSum?mcId=" + McId + "&yearMonthFrom=" + dateFrom + "&yearMonthTo=" + dateTo;

    return this.httpClient.get(getURL);
  }

  _getRiskSpeedingSummaryByVehicle(vehicleId, McId, dateFrom, dateTo) : Observable<any>
  {
    dateFrom = dateFrom.split("/")[1].toString() + dateFrom.split("/")[0].toString(); 
    dateTo = dateTo.split("/")[1].toString() + dateTo.split("/")[0].toString(); 

    let getURL = environment.serviceBase + "api/Dashboard/GetRiskSpeedingSummaryByVehicle?vehicleId=" + vehicleId + "&mcId=" + McId + "&yearMonthFrom=" + dateFrom + "&yearMonthTo=" + dateTo;
    return this.httpClient.get(getURL);
  }

  _getHarshEventData(McId, dateFrom, dateTo) : Observable<any>
  {
    dateFrom = dateFrom.split("/")[1].toString() + dateFrom.split("/")[0].toString(); 
    dateTo = dateTo.split("/")[1].toString() + dateTo.split("/")[0].toString(); 

    let getURL = environment.serviceBase + "api/Dashboard/GetMonthlyHarshEventSum?mcId=" + McId + "&yearMonthFrom=" + dateFrom + "&yearMonthTo=" + dateTo;              

    return this.httpClient.get(getURL);
  }

  _getHexGeoJsonFile(fileName) : Observable<any>
  {
    let getURL = environment.serviceBase + "api/Dashboard/GetHexGeoJsonFile?fileName=" + fileName;              
    return this.httpClient.get(getURL);
  }

  _getHexGeoJsonFileLst(McId) : Observable<any>
  {
    let getURL = environment.serviceBase + "api/Dashboard/GetHexGeoJsonFileLst?mcId=" + McId;              
    return this.httpClient.get(getURL);
  }

  _getHexHotspotFile(fileName, hotspotPercentile) : Observable<any>
  {
    let getURL = environment.serviceBase + "api/Dashboard/GetHexHotspotFile?fileName=" + fileName + "&percentile=" + hotspotPercentile;              
    return this.httpClient.get(getURL);
  }

  _getPlaintiffCountyGeoJson() :Observable<any>
  {
    let getURL = environment.serviceBase + "api/Dashboard/GetPlaintiffCountyGeoJson"; 
    return this.httpClient.get(getURL);
  }

  _getMCScoring(McId, dateFrom, dateTo, IccId) : Observable<any>
  {
    dateFrom = dateFrom.split("/")[1].toString() + dateFrom.split("/")[0].toString(); 
    dateTo = dateTo.split("/")[1].toString() + dateTo.split("/")[0].toString(); 

    let getURL = environment.serviceBase + "api/Dashboard/GetMonthlyMCScoring?mcId=" + McId + "&yearMonthFrom=" + dateFrom + "&yearMonthTo=" + dateTo + "&iccId=" + IccId;              
    return this.httpClient.get(getURL);
  }

  _getMonthlyDriverScoreCard(McId, dateFrom, dateTo, IccId) : Observable<any>
  {
    dateFrom = dateFrom.split("/")[1].toString() + dateFrom.split("/")[0].toString(); 
    dateTo = dateTo.split("/")[1].toString() + dateTo.split("/")[0].toString(); 

    let getURL = environment.serviceBase + "api/Dashboard/GetMonthlyDriverScoreCard?mcId=" + McId + "&yearMonthFrom=" + dateFrom + "&yearMonthTo=" + dateTo +'&iccId=' + IccId;
    return this.httpClient.get(getURL);
  }

  _getTerritoryData(McId, dateFrom, dateTo) : Observable<any>
  {
    dateFrom = dateFrom.split("/")[1].toString() + dateFrom.split("/")[0].toString(); 
    dateTo = dateTo.split("/")[1].toString() + dateTo.split("/")[0].toString(); 
    let resp : any = [];
    let getURL = environment.serviceBase + "api/Dashboard/GetMonthlyTerritorySum?mcId=" + McId + "&yearMonthFrom=" + dateFrom + "&yearMonthTo=" + dateTo;

    return this.httpClient.get(getURL).pipe(
      map(data => 
        {
          if(!data || data === 'null') return;

          let sorted = (<Array<any>>data).sort((a, b) => { return a.eTechZone - b.eTechZone});

          //IE11 does not support underscore js groupby function
          //for Typescript, make reference of Array prototpe constuctor type of any in order to add "groupBy" custom function.
          //can use underscore groupBy instead.
          let ArrayVar : any = Array; 
          ArrayVar.prototype.groupBy = function(prop) {
                return this.reduce(function(groups, item) {
                  const val = item[prop]
                  groups[val] = groups[val] || []
                  groups[val].push(item)
                  return groups
                }, {})
              }
          let grouped = _.toArray(_.toArray(sorted).groupBy("vehicleId"));
          //var grouped = _.toArray(_.groupBy(sorted, g => g.vehicleId));

          //convert to Territory data structure
          for(var j = 0; j < grouped.length; j++)
          {
              let dObj : any = { "territories": {}, "stats": {} ,"territoriesDriven": {} };
              let sumMilesDriven = 0, sumTelerate = 0;
              for(var i =0; i < 20; i++) //total 20 zones static
              {
                  var maxTotalMiles = Math.max.apply(Math,grouped[j].map(function(o){return o.sum_TotalMiles;}));  //get the sum total miles of multi months
                  grouped[j].forEach(function(item){ 
                      if(Number(item.eTechZone) == i+1)
                      {
                          dObj.territories[i+1] = item.sum_Telerate;
                          dObj.stats.VehicleNumber = item.vehicleId;
                          dObj.stats.vin = item.vin;
                          dObj.stats.Sum_TotalMiles = maxTotalMiles;//item.sum_TotalMiles;
                          dObj.territoriesDriven[i+1] = item.sum_MilesDriven;
                          sumMilesDriven += item.sum_MilesDriven;
                          sumTelerate += item.sum_Telerate;

                          dObj.stats.telerateMultMonthAve = item.telerateMultiMonthAve;
                          dObj.stats.garagerateMultMonthAve = item.garagerateMultiMonthAve;
                          if(item.garageZoneInd == 1)  dObj.stats.garageZone = item.eTechZone;
                      }
                  })

                  if(dObj.territories[i+1] === undefined) //(angular.isUndefined(dObj.territories[i+1])) 
                  { dObj.territories[i+1] = 0; dObj.territoriesDriven[i+1] = 0} // make up eTechZones if not from summary table
              }
              dObj.stats.sum_MilesDriven = sumMilesDriven;
              dObj.stats.Telefactor = sumTelerate;

              resp.push(dObj);
          }
          return resp;
        })
        
    );
  }

  //Driver 10/25/2022
  _getMonthlyUtilization_Driver(McId : number, dateFrom : string, dateTo : string) : Observable<any>
  {
    dateFrom = dateFrom.split("/")[1].toString() + dateFrom.split("/")[0].toString();
    dateTo = dateTo.split("/")[1].toString() + dateTo.split("/")[0].toString();

    let getURL = environment.serviceBase + "api/Dashboard/GetDriverMonthlyUtilizationSum?mcId=" + McId + "&yearMonthFrom=" + dateFrom + "&yearMonthTo=" + dateTo;
    
    return this.httpClient.get(getURL);
  }

  _getSpeedingData_Driver(McId : number, dateFrom : string, dateTo : string) : Observable<any>
  {
    dateFrom = dateFrom.split("/")[1].toString() + dateFrom.split("/")[0].toString(); 
    dateTo = dateTo.split("/")[1].toString() + dateTo.split("/")[0].toString(); 
    // need to make parameters dynamic

    let getURL = environment.serviceBase + "api/Dashboard/GetDriverMonthlySpeedingSum?mcId=" + McId + "&yearMonthFrom=" + dateFrom + "&yearMonthTo=" + dateTo;    
    
    return this.httpClient.get(getURL);
  }

  _getSpeedingRoadTypeData_Driver(McId : number, dateFrom : string, dateTo : string) : Observable<any> 
  {
    dateFrom = dateFrom.split("/")[1].toString() + dateFrom.split("/")[0].toString(); 
    dateTo = dateTo.split("/")[1].toString() + dateTo.split("/")[0].toString(); 
                // need to make parameters dynamic

    let getURL = environment.serviceBase + "api/Dashboard/GetDriverMonthlySpeedingRoadTypeSum?mcId=" + McId + "&yearMonthFrom=" + dateFrom + "&yearMonthTo=" + dateTo;

    return this.httpClient.get(getURL);
  }

  _getHarshEventData_Driver(McId : number, dateFrom : string, dateTo : string) : Observable<any>
  {
    dateFrom = dateFrom.split("/")[1].toString() + dateFrom.split("/")[0].toString(); 
    dateTo = dateTo.split("/")[1].toString() + dateTo.split("/")[0].toString(); 

    let getURL = environment.serviceBase + "api/Dashboard/GetDriverMonthlyHarshEventSum?mcId=" + McId + "&yearMonthFrom=" + dateFrom + "&yearMonthTo=" + dateTo;              

    return this.httpClient.get(getURL);
  }
  //Driver 10/25/2022


}

<div class="row">
    <div class="row">
        <button class="btn btn-info btn" style="float: right;margin-right: 1.5%;" (click)="ExportDSCard()" [disabled] ="!DSCardDataToExport">Export</button>
    </div>

    <div class="col-md-6">
    <label style="color:white;">Speeding Trending</label> 
        <div class="table-responsive dashtable">
            <table id = 'speedingTrend' mat-table [dataSource] = "speedingTrendTblDataSource" #sortSpeedingTrend ='matSort' matSort  class="table table-condensed table-striped">
                <ng-container matColumnDef="YearMonth">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Month
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.YearMonth.toString().slice(4) + '-' + element.YearMonth.toString().slice(0, 4)   }}
                     </td>
                </ng-container>
                
                <ng-container matColumnDef="derived_Under5" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Under 5
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.Sum_MovingTime_Hr == 0 ? 0 : (element.SpeedingTrend_Under_5_ByTime / 3600 * 100 / element.Sum_MovingTime_Hr) | number: '1.01-1' }}%
                    </td>
                </ng-container>

                
                <ng-container matColumnDef="derived_5To10" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        5 To 10
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.Sum_MovingTime_Hr == 0 ? 0 : (element.SpeedingTrend_5_10_ByTime / 3600 * 100 / element.Sum_MovingTime_Hr) | number: '1.01-1' }}%
                    </td>
                </ng-container>

                
                <ng-container matColumnDef="derived_10To15" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        10 To 15
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.Sum_MovingTime_Hr == 0 ? 0 : (element.SpeedingTrend_10_15_ByTime /3600 * 100 / element.Sum_MovingTime_Hr) | number: '1.01-1' }}%
                    </td>
                </ng-container>

                
                <ng-container matColumnDef="derived_Over15" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 1.1em; font-weight: 300;">
                        Over 15
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.Sum_MovingTime_Hr == 0 ? 0 : (element.SpeedingTrend_Over_15_ByTime / 3600 * 100 / element.Sum_MovingTime_Hr) | number: '1.01-1' }}% 
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="speedingTrendColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: speedingTrendColumns;" style="height:0px;"></tr>
            </table>
            


        </div>

    </div>
    <div id ="trendChartDv" class="col-md-6" *ngIf ='DSCardTrendingChartData.length > 0'>
        <h5 style="font-weight:bold;margin-top: 1%;margin-left: 0%">Speeding Trending Data</h5>
        <!-- <nvd3 options="driverScoreCardLineOptions" data="driverScoreCardTrendChartData"> </nvd3> -->

        <canvas baseChart id="trendingLinechart" 
        [datasets]="DSCardTrendingChartData" 
        [colors]="DSCardTrendingChartColors" 
        [options]="DSCardTrendingChartOptions" 
        [labels]="DSCardTrendingChartlabels"
        [chartType] = 'DSSpeedingTrendChartType'></canvas>
    </div>
</div>
<div class="row">
    <div class="col-md-6">
    <label style="color:white;">Company Comparison - {{DSCardComparisonChartData?.length > 0 ? dashMonthCompareDisplay?.toString().slice(4) + '/' + dashMonthCompareDisplay?.toString().slice(0, 4) : ''}}</label> 
        <div class="row" style="margin-top: -1%;" [hidden]= "!(DSCardComparisonChartData.length > 0)">
            <div class="col-md-3" id = "DSCardPRDropDownDv" style="float:right;">
                <select class="form-control" [(ngModel)]="DSCardCompanyProgramDr" style="height:auto;" (change)="DSCardProgramDrChange(DSCardCompanyProgramDr)">
                    <option >{{dashboardCompanyProgram}}</option>
                    <option value = 2>All Programs</option>
                </select>
            </div>
        </div>
        
        <div class="table-responsive dashtable">
            <table id = 'speedingComparison' mat-table [dataSource] = "speedingComparisonTblDataSource"  class="table table-condensed table-striped">
                <ng-container matColumnDef="categories">
                    <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                        Categories
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.categories  }}
                     </td>
                </ng-container>
                
                <ng-container matColumnDef="derived_Under5" >
                    <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                        Under 5
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.sum_MovingTime_Hr == 0 ? 0 : (element.sum_Speeding_Under_5mph_ByTime / 3600 * 100 / element.sum_MovingTime_Hr) | number: '1.01-1' }}%
                    </td>
                </ng-container>

                
                <ng-container matColumnDef="derived_5To10" >
                    <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                        5 To 10
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.sum_MovingTime_Hr == 0 ? 0 : (element.sum_Speeding_5_10mph_ByTime / 3600 * 100 / element.sum_MovingTime_Hr) | number: '1.01-1' }}%
                    </td>
                </ng-container>

                
                <ng-container matColumnDef="derived_10To15" >
                    <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                        10 To 15
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.sum_MovingTime_Hr == 0 ? 0 : (element.sum_Speeding_10_15mph_ByTime /3600 * 100 / element.sum_MovingTime_Hr) | number: '1.01-1' }}%
                    </td>
                </ng-container>

                
                <ng-container matColumnDef="derived_Over15" >
                    <th mat-header-cell *matHeaderCellDef style="font-size: 1.1em; font-weight: 300;">
                        Over 15
                    </th>
                    <td mat-cell *matCellDef="let element"> 
                        {{ element.sum_MovingTime_Hr == 0 ? 0 : (element.sum_Speeding_Over_15mph_ByTime / 3600 * 100 / element.sum_MovingTime_Hr) | number: '1.01-1' }}% 
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="speedingComparisonColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: speedingComparisonColumns;" style="height:0px;"></tr>
            </table>
            

            

        </div>
    </div>
    <div id = "comparisonChartdDv" class="col-md-6" *ngIf= "DSCardComparisonChartData.length > 0">
        <h5 style="font-weight:bold;margin-top: 1%;margin-left: 0%">Your Company Compared to Captive- {{ (DSCardCompanyProgramDr != 2 ? dashboardCompanyProgram : 'All Programs') + ' - ' + dashMonthCompareDisplay?.toString().slice(4) + '/' + dashMonthCompareDisplay?.toString().slice(0,4) }}</h5>
        <!-- <nvd3 options="driverScoreCardComparisonLineOptions" data="driverScoreCardComparisonChartData"> </nvd3> -->
        <!--<canvas id="comparisonLinechart" class="chart chart-line" chart-data="DSCardComparisonChartData" chart-colors="DSCardComparisonChartColors" chart-options="DSCardComparisonChartOptions" chart-labels="DSCardComparisonChartlabels" chart-series= "DSCardComparisonSeries"></canvas>-->

        <canvas baseChart id="comparisonLinechart" 
        [datasets]="DSCardComparisonChartData" 
        [colors]="DSCardComparisonChartColors" 
        [options]="DSCardComparisonChartOptions" 
        [labels]="DSCardComparisonChartlabels"
        [chartType] = 'DSCardComparisonChartType'></canvas>
    </div>
</div>
<div class="row" *ngIf="comparisonXShow">
    <div id = comparisonXChartdDv class="col-md-6" >
        <!--<canvas id="comparisonXLinechart" class="chart chart-line" chart-data="DSCardComparisonXChartData" chart-colors="DSCardComparisonChartColors" chart-options="DSCardComparisonChartOptions" chart-labels="DSCardComparisonChartlabels" chart-series= "DSCardComparisonXSeries"></canvas>-->

        <canvas baseChart id="comparisonXLinechart" 
        [datasets]="DSCardComparisonXChartData" 
        [colors]="DSCardComparisonChartColors" 
        [options]="DSCardComparisonChartOptions" 
        [labels]="DSCardComparisonChartlabels"
        [chartType] = 'DSCardComparisonChartType'></canvas>
    </div>
</div>


import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
declare var particlesJS: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router) { }

  
  ngOnInit(): void {

    setTimeout(function () {particlesJS.load('particles', 'assets/js/particlesjs-config.json', function() {
      
    });}, 500);
  
  }

  startNow ()
  {
    let authData = JSON.parse(localStorage.getItem('authorizationData') || null);

    if(!authData)
    {
      this.router.navigate(['login']);
    }
  }

  isLoggedIn() {
    let authData = JSON.parse(localStorage.getItem('authorizationData') || null);
    return authData;
  }
}

import { Component, OnInit, SecurityContext} from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeValue } from '@angular/platform-browser';

@Component({
  selector: 'app-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.css']
})
export class UserGuideComponent implements OnInit {

  guidePath : SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    let groupType = JSON.parse(localStorage.getItem('authorizationData'))?.groupType;

    if(groupType === 1002)
    {
      this.guidePath =  this.sanitizer.bypassSecurityTrustResourceUrl('../appdata/TE Insurance Company User Guide.pdf');
    }
    else if (groupType === 1001){
      this.guidePath = this.sanitizer.bypassSecurityTrustResourceUrl('../appdata/TE Policyholder User Guide.pdf');
    }
  }
}



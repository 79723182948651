import { Component, OnInit, ViewChild, ElementRef, Renderer2 , OnDestroy} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { forkJoin, Observable, Subject } from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { DataService } from 'src/app/Services/data.service';
import { AuthService} from '../../Services/auth.service';
import { ClaimLocatorService} from '../../Services/claim-locator.service';
import { CommonAppService} from '../../Services/common-app.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopUpModalComponent } from '../pop-up-modal/pop-up-modal.component';


import * as _ from 'underscore';
import {firstBy} from "../../../lib/thenBy/thenby";
declare var H: any; 
//declare var navigator: any; 

declare var jsreports: any;

@Component({
  selector: 'app-claims-locator',
  templateUrl: './claims-locator.component.html',
  styleUrls: ['./claims-locator.component.css']
})
export class ClaimsLocatorComponent implements OnInit {

  subNotifier = new Subject();
  users = [];
  mcVehicles = [];
  CLData =  [];
  Vehicles = [];
  selectedVehicleData = [];
  selectedVehicleId : number;
  routeSegments;

  bsConfig: Partial<BsDatepickerConfig> = 
  {
    //minMode : 'month',
    dateInputFormat: 'MM/DD/YYYY',
    //isAnimated : true
    containerClass : 'theme-default'
  }

  claimsLocatorData : any = {
    timeVariance : "60",
    policyHolder : {
      consentStatusRevoked : false,
      consentRevokedDate: null
    },
    claimEventDateTime : new Date(),
    claimEventTime : new Date(),
    vin : "",
    location : "peabody,ma,01960"//"42.2987048000,-71.8024412000",
  }
  isPositionLocated : boolean = false;
  bubbleVehicleRecord : any = {};

  app_id : string = this.authService._authentication.here_app_id;
  app_code : string = this.authService._authentication.here_app_code;
  apikey : string= this.authService._authentication.here_api_key;

  loading : boolean;

  //globle map
  @ViewChild('ClaimsLocatorMapContainer') mapContainer: ElementRef;
  //@ViewChild('CANVAS') CANVAS: ElementRef;

  position = { lat: 42.5253884, lng: -70.9530805 };
  platform;
  // platform = new H.service.Platform({
  //   'app_id': this.app_id,
  //   'app_code': this.app_code,
  //   'useHTTPS': true
  // });
  defaultLayers; //= this.platform.createDefaultLayers({ ppi: 500 });
  options = { enableHighAccuracy: true, timeout: 5000, maximumAge: 0, 'useHTTPS': true };
  map = null;
  ui = null;
  mapEvents = null;
  behavior = null;
  bubble = null;
  mapSettings = null;
  zoom = null;
  scalebar = null;
  
  //tables
  vehTblDataSource :any = [];
  vehColumns : string[] = ['VehicleId', 'VIN', 'VehicleType', 'Color', 'Download'];
  @ViewChild('sortVeh') sortVeh: MatSort;
  @ViewChild('paginatorVeh') paginatorVeh: MatPaginator;

  segmentTblDataSource :any = [];
  segmentColumns : string[] = ['SegmentStartTs', 'SegmentEndTs', 'Color', 'Download'];
  @ViewChild('sortSegment') sortSegment: MatSort;
  @ViewChild('paginatorSegment') paginatorSegment: MatPaginator;

  

  constructor(private claimLocatorService : ClaimLocatorService,
    private authService : AuthService,
    private dataService : DataService,
    private commonAppService : CommonAppService,
    private dialog : MatDialog,
    private router : Router,
    private render : Renderer2) { }


  generateVehiclesTable()
  {
    this.selectedVehicleId = 0;

    let vehViewData = _.toArray(this.Vehicles);
    
    this.vehTblDataSource = new MatTableDataSource(vehViewData);
    this.vehTblDataSource.sort = this.sortVeh;
    this.vehTblDataSource.paginator = this.paginatorVeh;
  }
    
  generateSegmentsTable() {

    let segmentViewData = _.toArray(this.routeSegments);
    
    this.segmentTblDataSource = new MatTableDataSource(segmentViewData);
    this.segmentTblDataSource.sort = this.sortSegment;
    this.segmentTblDataSource.paginator = this.paginatorSegment;
  }

  initialMapLoad() {
    const success = (pos) => { this.position.lat = pos.coords.latitude; this.position.lng = pos.coords.longitude; };
    const error = (err) => { };
    try { navigator.geolocation.getCurrentPosition(success, error, this.options); } catch (ex) { 
      console.log('err: ', ex);
    }
    this.platform = new H.service.Platform({
      //'app_id': this.app_id,
      //'app_code': this.app_code,
      'apikey': this.apikey,
      'useHTTPS': true
    });
    this.defaultLayers = this.platform.createDefaultLayers();

    //satellite map
    this.map = new H.Map(
      this.mapContainer.nativeElement,
      this.defaultLayers.raster.satellite.map,
      {
        zoom: 12,
        center: { lat: this.position.lat, lng: this.position.lng },
        engineType: H.map.render.RenderEngine.EngineType.P2D//use legacy rendering which is way faster than 3.1 version
      }
    );

    this.ui = H.ui.UI.createDefault(this.map, this.defaultLayers);
    this.mapEvents = new H.mapevents.MapEvents(this.map);
    this.behavior = new H.mapevents.Behavior(this.mapEvents);

    this.mapSettings = this.ui.getControl('mapsettings');
    this.zoom = this.ui.getControl('zoom');
    this.scalebar = this.ui.getControl('scalebar');

    this.mapSettings.setAlignment('top-left');
    this.zoom.setAlignment('top-left');
    this.scalebar.setAlignment('top-left');
    this.ui.setUnitSystem(H.ui.UnitSystem.IMPERIAL);


    // disable the default draggability of the underlying map when starting to drag a marker object:
    this.map.addEventListener('dragstart', (ev) =>{
      let target = ev.target;
      if (target instanceof H.map.Marker) {
        this.behavior.disable();
      }
    }, false);

  // re-enable the default draggability of the underlying map when dragging has completed
    this.map.addEventListener('dragend', (ev) => {
      let target = ev.target;
      if (target instanceof H.map.Marker) {
        this.behavior.enable();
        this.position = target.getGeometry();
        this.resetMapCenterPosition();
        this.getAddressofMarkerCurrentPosition();
      }
    }, false);

    // Listen to the drag event and move the position of the marker as necessary
    this.map.addEventListener('drag', (ev) => {
      let target = ev.target,
      pointer = ev.currentPointer;
      if (target instanceof H.map.Marker) {
        target.setGeometry(this.map.screenToGeo(pointer.viewportX, pointer.viewportY));  //setPosition is changed to setGeometry in 3.1
      }
    }, false);

    this.addDraggableMarker();
  }

  resetMapCenterPosition() {
    
    
    //this.map.setCenter(this.position, false);
    //this.map.setZoom(12, true);

    this.map.getViewModel().setLookAtData(
      {
        position: this.position,
        zoom: 12
      },
      true
    );
  };

  getAddressofMarkerCurrentPosition() {
    let geocoder = this.platform.getSearchService();//getGeocodingService();

    // let parameters = {
    //   prox: this.position.lat + "," + this.position.lng + ",20",
    //   mode: "retrieveAddresses",
    //   maxresults: "1",
    //   gen: "8"
    // };
    let parameters = {

        at: this.position.lat + "," + this.position.lng ,
        limit: 1
      };


    geocoder.reverseGeocode(parameters,
      (result) =>{
        if (result.items) 
        {
          if (result.items.length > 0) 
          {
            this.claimsLocatorData.location = result.items[0].address.label;

            //Commented below because the address doent need to be restricted to street level address.
            // $scope.claimsLocatorData.location = "";

            // for (var i = 0; i < result.Response.View["0"].Result.length; i++) {
            //     if(result.Response.View["0"].Result[i].MatchLevel.toLowerCase() != "housenumber"){
            //         $scope.claimsLocatorData.location = result.Response.View["0"].Result[i].Location.Address.Label;
            //         break;
            //     }
            // }

            // if($scope.claimsLocatorData.location == ""){
            //     $scope.claimsLocatorData.location = result.Response.View["0"].Result["0"].Location.Address.Label;                                    
            // }

            //$scope.$apply();
          }
        }
      }, (error) =>{
      });
    this.isPositionLocated = true;
  };

  addDraggableMarker()
  {
    //Adds a  draggable marker to the map
    let marker = new H.map.Marker(this.position);
    marker.draggable = true;

    //check for clustered waypoints. if those are there, then trigger the tap.
    marker.addEventListener('tap', (evt) => {
      let mapLayers = this.map.getLayers().asArray();
      for(let i = 0; i < mapLayers.length; i++)
      {
        // Jj:object layer - check provider
        if (mapLayers[i].getProvider() instanceof H.clustering.Provider) {
          // trigger tap event here. when atleast one cluster exists. and then break loop.
          let bubblePosition = { Latitude: evt.target.getGeometry().lat, Longitude: evt.target.getGeometry().lng}; //getPosition is changed to getGeometry in 3.1
          let bubbleContent = '<div style="width:150px;"> Anonymous location as Claim location' +
              '<br><button style="background-color:transparent;"><img style="width:20px; height:20px;" src="/assets/img/ExIcon.png"></button></div>';
          this.openBubble(bubblePosition, bubbleContent);
          break;
        }
      }                
    });
    
    this.map.addObject(marker);
  }

  setClaimEventMarkerPosition (claimEventPosition, claimEventDegree, claimEventNote) 
  {
    //to re-set the current position of claim event Marker
    let mapObjects = this.map.getObjects();
    let claimEventMarkerExists = false;
    claimEventDegree = claimEventDegree ? claimEventDegree : 1;
    claimEventNote = claimEventNote ? claimEventNote : "";
    var claimEventData = { Degree: claimEventDegree, Note: claimEventNote};

    mapObjects.forEach((mapObject) => {
      if (mapObject instanceof H.map.Marker) {
        if (!mapObject.draggable) {
          let mapObjectData = mapObject.getData();
          if(mapObjectData && mapObjectData.Degree && (mapObjectData.Degree != claimEventDegree)){
            let iconPath = claimEventDegree > 1 ? 'assets/img/ExIconYellow.png' : 'assets/img/ExIcon.png';
            let icon = new H.map.Icon(iconPath, { size: { w: 20, h: 20 }, anchor: { x: 10, y: 10 } });
            mapObject.setIcon(icon);                                
          }

          mapObject.setData(claimEventData);
          mapObject.setGeometry(claimEventPosition);
          claimEventMarkerExists = true;
        }
      }
    });

    if (!claimEventMarkerExists) {
      this.addClaimEventMarker(claimEventPosition, claimEventData);
    }
  };

  openBubble(bubbleVehicleRecord, text)
  {
    this.bubbleVehicleRecord = bubbleVehicleRecord;
    let position = { lat: bubbleVehicleRecord.Latitude, lng: bubbleVehicleRecord.Longitude };
    if (!this.bubble) {
      this.bubble = new H.ui.InfoBubble(
        position,
        // The FO property holds the province name.
        { content: text }
      );
      this.ui.addBubble(this.bubble);
    }
    else {
      this.bubble.setPosition(position);
      this.bubble.setContent(text);
      this.bubble.open();
    }

    let bubbleContent = this.bubble.getContentElement();
    let claimSelectorBtn = bubbleContent.getElementsByTagName("button")[0];
    if (claimSelectorBtn) {
      claimSelectorBtn.addEventListener('click', (ev) => {
        this.markAsClaimEvent(this.bubbleVehicleRecord);
      });
    }
  }

  private getLogo(src) : Observable<any>
  {
    return new Observable(ob => {

      let img = new Image();
      img.src = src;
      img.onload = () => {
        try {

          let canvas= this.render.createElement('CANVAS');
          var ctx = canvas.getContext('2d');
          canvas.height = img.naturalHeight;
          canvas.width = img.naturalWidth;
          ctx.drawImage(img, 0, 0);
          let dataUrl = canvas.toDataURL();
          
          ob.next(dataUrl);
          ob.complete();
        }
        catch(ex) {
            //deferred.reject();
          ob.error();
        }
      };
    });
  }
  private getMapScreenShot() : Observable<any>
  {
    return new Observable(ob => {

      let dw = 0, dh = 0, requiredW = 0, requiredH = 0;
      try {

        //need 3X4 size                    
        let canvasH = this.map.getViewPort().height;
        let canvasW = this.map.getViewPort().width;
        let canvasHXWRatio = canvasH / canvasW;
        let requiredHXWRatio = 3.5 / 4.5;
        let HXWRatioDiff = Math.abs(canvasHXWRatio - requiredHXWRatio);
        if (HXWRatioDiff > 0.05) {
          requiredW = (canvasHXWRatio > requiredHXWRatio) ? canvasW : canvasH;
          requiredH = requiredW * requiredHXWRatio;
          dw = Math.round((canvasHXWRatio > requiredHXWRatio) ? 0 : (canvasW - requiredW) / 2);
          dh = Math.round((canvasHXWRatio > requiredHXWRatio) ? (canvasH - requiredH) / 2 : 0);
        }
      }
      catch (ex){ 
      }
    
      this.map.capture((canvas) => {
        if (canvas) {
          let dataUrl = canvas.toDataURL('image/jpeg', 1.0);
          //deferred.resolve(dataUrl);
          ob.next(dataUrl);
          ob.complete();
        }
        else {
          //deferred.reject();
          ob.error();
        }
      }, [], dw, dh, requiredW + dw, requiredH + dh);
    });
  };


  addClaimEventMarker (claimEventPosition, claimEventData) {
    //Adds a  Claim Event marker to the map
    let iconPath = claimEventData.Degree > 1 ? 'assets/img/ExIconYellow.png' : 'assets/img/ExIcon.png';
    let icon = new H.map.Icon(iconPath, { size: { w: 20, h: 20 }, anchor: { x: 10, y: 10 } });
    let marker = new H.map.Marker(claimEventPosition, { icon: icon, data: claimEventData});

    marker.addEventListener('tap', (evt) => { 
      let bubbleContent = '<div><br></div>';
      let claimEventData = evt.target.getData();
      if(claimEventData && claimEventData.Note){
          bubbleContent = '<div>' + claimEventData.Note + '</div>';
      }

      let claimEventMarkerPosition = evt.target.getGeometry();
      let bubblePosition = { Latitude: claimEventMarkerPosition.lat, Longitude: claimEventMarkerPosition.lng};

      this.openBubble(bubblePosition, bubbleContent);

    });//TO DO: May need to re work on this call back function added to the listener 3/18/2022

    this.map.addObject(marker);
  };
  private claimEventMarkerClick (evt?) {
    let bubbleContent = '<div><br></div>';
    let claimEventData = evt.target.getData();
    if(claimEventData && claimEventData.Note){
        bubbleContent = '<div>' + claimEventData.Note + '</div>';
    }

    let claimEventMarkerPosition = evt.target.getGeometry();
    let bubblePosition = { Latitude: claimEventMarkerPosition.lat, Longitude: claimEventMarkerPosition.lng};

    this.openBubble(bubblePosition, bubbleContent);
  };

  setMarkerPosition()
  {
    //to re-set the current position of Marker
    let mapObjects = this.map.getObjects();
    mapObjects.forEach((mapObject, index) => {
      if (mapObject instanceof H.map.Marker) {
        if (mapObject.draggable) {
          mapObject.setGeometry(this.position);
        }
      }
    });
  }

  resetRouteObjects() {
    let mapObjects = this.map.getObjects();
    let removableObjects = [];

    mapObjects.forEach((mapObject) => {
      //xi:marker, pg: Polyline
      if ((mapObject instanceof H.map.Polyline) || (mapObject instanceof H.map.Group)) {
        removableObjects.push(mapObject);
      }
    });
    if (removableObjects.length > 0) {
      this.map.removeObjects(removableObjects);
    }
    this.closeBubble();
  }

  resetMarkerClusters() {
    let mapLayers = this.map.getLayers().asArray();
    mapLayers.forEach((mapLayer) => {
      // Jj:object layer - check provider
      if (mapLayer.getProvider() instanceof H.clustering.Provider) {
        this.map.removeLayer(mapLayer);
      }
    });

    let mapObjects = this.map.getObjects();
    mapObjects.forEach((mapObject) => {
      //xi:marker, pg: Polyline
      if (mapObject instanceof H.map.Marker) {
        if (!mapObject.draggable) {
          this.map.removeObject(mapObject);
        }
      }
    });
  };

  closeBubble() {
    if (this.bubble) {
      this.bubble.close();
    }
  }

  private pickPathColor(index : number) {
    let randomColors = ['blue', 'green', 'red', 'black', 'purple', 'maroon', 'skyblue', 'orange', 'cyan', 'crimson', 'grey', 'yellow', 'pink', 'chartreuse', 
        'chocolate', 'darkslateblue', 'greenyellow', 'navy', 'slateblue', 'sienna'];
    index = index % randomColors.length;
    return randomColors[index];                                        
  };

  private splitRouteSegments(cls, vehicle){
    let retLst = [];
    let ts = 120; //120 mins
    let segmentsCt = 2 * this.claimsLocatorData.timeVariance / 120;
    let minT = new Date();
    minT.setTime(this.claimsLocatorData.claimEventDateTime.getTime() - (this.claimsLocatorData.timeVariance * 60 * 1000))
    let segmentStartT = new Date();
    segmentStartT.setTime(minT.getTime());
    let segmentEndT = new Date();
    segmentEndT.setTime(segmentStartT.getTime() + (ts * 60 * 1000));
    
    for(let i = 0; i < segmentsCt; i++) {
      let segment : any = {};
      segment.McId = vehicle.McId;

      segment.SegmentStartTs = new Date();
      segment.SegmentStartTs.setTime(segmentStartT.getTime());
      segment.SegmentEndTs = new Date();
      segment.SegmentEndTs.setTime(segmentEndT.getTime());
      segment.pathColor = this.pickPathColor(i);
      
      segment.cL_ClaimLocatorSourceVms = cls.filter(x => new Date(x.LocalDatetime) < segmentEndT && new Date(x.LocalDatetime) >= segmentStartT);
      retLst.push(segment);

      segmentStartT.setTime(segmentEndT.getTime());
      segmentEndT.setTime(segmentEndT.getTime() + (ts * 60 * 1000));
    }
    return retLst;
  };

  private getRoutingParameters(vObject)  {
    //vObject = $filter('orderBy')(vObject, 'UTCDatetime');

    vObject = vObject.sort((a, b) => ( new Date(a.UTCDatetime) > new Date(b.UTCDatetime)) ? 1 : -1);

    let splitRoutes = [];
    let splitRoute = [];
    for (let i = 0; i < vObject.length; i++) {
      if(!(vObject[i] && vObject[i].RowID)){
        continue;
      }
      else if(vObject[i].event && vObject[i].event.toLowerCase() === "StartUp".toLowerCase()){
        if(splitRoute.length > 0){
          splitRoutes.push(splitRoute);                        
        }
        splitRoute = [];
        splitRoute.push(vObject[i]);
      }
      else{
        splitRoute.push(vObject[i]);                    
      }                                
    }
    splitRoutes.push(splitRoute);

    let splitRoutingParameters = [];
    //5/26/2022 Heremap js 3.1 upgrade and rout v8 upgrade changed the way taking the calculateRoute parameters
    //comment this old version
    /*
    splitRoutes.forEach(sr => {
      let routingParameters = {
        //'mode': 'balanced;truck', commentted this, we lose the permisson to use truck rout  
        'mode': 'balanced;car',
        'representation': 'navigation'//'display'
      };

      routingParameters['waypoint0'] = sr[0].Latitude + ',' + sr[0].Longitude + ";;" + sr[0].RowID;

      let routeLastIndex = sr.length - 1;
      routingParameters['waypoint' + routeLastIndex] = sr[routeLastIndex].Latitude + ',' + sr[routeLastIndex].Longitude + ";;" + sr[routeLastIndex].RowID;

      for (let i = 1; i < sr.length - 1; i++) {
        routingParameters['waypoint' + i] = 'passThrough!' + sr[i].Latitude + ',' + sr[i].Longitude + ";;" + sr[i].RowID;
      }

      splitRoutingParameters.push(routingParameters);
    });*/
    //comment this old version

    splitRoutes.forEach((sr, index) => {
      let routingParameters = {
        //'mode': 'balanced;truck', commentted this, we lose the permisson to use truck rout  
        'transportMode': 'car',
        //'representation': 'navigation',//'display'
        'return' : 'polyline'
      };

      routingParameters['origin'] = sr[0].Latitude + ',' + sr[0].Longitude + "!passThrough=false";
      

      let routeLastIndex = sr.length - 1;
      //routingParameters['waypoint' + routeLastIndex] = sr[routeLastIndex].Latitude + ',' + sr[routeLastIndex].Longitude + ";;" + sr[routeLastIndex].RowID;
      routingParameters['destination'] = sr[routeLastIndex].Latitude + ',' + sr[routeLastIndex].Longitude  + "!passThrough=false";

      //Li routing v8 does not take custom data which is our rowId, the alternative solution to identify when the sections got sent back
      sr[0].wayPointId = sr[0].Vehicleid + '_' + index +'_' + 0;
      sr[routeLastIndex].wayPointId = sr[0].Vehicleid + '_' + index +'_' + routeLastIndex;
      sr.forEach((item) => {
        item.routeNum =index;
      });


      let viaWayPoints : string[] = [];
      for (let i = 1; i < sr.length - 1; i++) {
        //routingParameters['waypoint' + i] = 'passThrough!' + sr[i].Latitude + ',' + sr[i].Longitude + ";;" + sr[i].RowID;
        viaWayPoints.push(sr[i].Latitude + ',' + sr[i].Longitude  +"!passThrough=false");//  + sr[i].RowID)
        sr[i].wayPointId = sr[i].Vehicleid + '_' + index +'_' + i;

      }
      routingParameters['via'] = new H.service.Url.MultiValueQueryParameter(viaWayPoints),

      splitRoutingParameters.push(routingParameters);
    });
    //5/26/2022 Heremap js 3.1 upgrade and rout v8 upgrade changed the way taking the calculateRoute parameters

    return splitRoutingParameters;
  };

  private plotVehiclePath(vObject, pathColor, isSelectedVehicle? : boolean) 
  {
    if (!(vObject && vObject.length > 1)) {
      if (isSelectedVehicle) {
          this.commonAppService._snackBar(false, 'Not enough data points to show route.');
      }
      return;
    }

    if(vObject.length > 110){
      //the only case that should reach here is when - time variance is more than 60 mins and try to plot route from main table
      this.commonAppService._snackBar(false, 'Please select individual route segments.'); 
      return;
    }

    if (isSelectedVehicle) {
        //get claim event if exists
      let claimEventPosition, markedEventObject, claimEventDegree, claimEventNote;
      
      let markedCLS = vObject.filter(item => item.ClaimLocationId > 0);
      if (markedCLS.length > 0) {
        markedEventObject = markedCLS.sort((x, y) => (y.ClaimLocationId - x.ClaimLocationId))[0];
        if (markedEventObject) {
            //add marker for this position to show on map
          claimEventPosition = { lat: markedEventObject.Latitude, lng: markedEventObject.Longitude };
          claimEventDegree = markedEventObject.Degree;
          claimEventNote = markedEventObject.Note;
        }
      }
      //Check for claimevent that was marked in current session. if exists, then override.
      if (this.Vehicles && (this.Vehicles.length > 0)) {
        let markedVObject = this.Vehicles.find(item => item.VehicleId == vObject[0].Vehicleid);
        if (markedVObject && (markedVObject.ClaimLocationId > 0)) {
          if (markedEventObject && (markedVObject.ClaimLocationId > markedEventObject.ClaimLocationId)) {
            claimEventPosition = { lat: markedVObject.Latitude, lng: markedVObject.Longitude };
            claimEventDegree = markedVObject.Degree;
            claimEventNote = markedVObject.Note;
          }
        }
      }
      //result of both cases
      if (claimEventPosition) {
        this.setClaimEventMarkerPosition(claimEventPosition, claimEventDegree, claimEventNote);
      }
    }

    //routingParameters
    let routingParameters = this.getRoutingParameters(vObject);
    
    // Define a callback function to process the routing response:
    const onResult = (result) =>
    {
      let route, routeShape, startPoint, endPoint, linestring;

      if (result && result.routes) {
        // Pick the first route from the response:
        route = result.routes[0];
        /*
        // Pick the route's shape:
        routeShape = route.shape;

        // Create a linestring to use as a point source for the route line
        linestring = new H.geo.LineString();

        // Push all the points in the shape into the linestring:
        routeShape.forEach((point) => {
          const parts = point.split(',');
          linestring.pushLatLngAlt(parts[0], parts[1]);
        });*/

        const sections = route.sections;
        const lineStrings = [];
        sections.forEach((section) => {
        // convert Flexible Polyline encoded string to geometry
        lineStrings.push(H.geo.LineString.fromFlexiblePolyline(section.polyline));
        });
        const multiLineString = new H.geo.MultiLineString(lineStrings);



        // Retrieve the mapped positions of the requested waypoints:
        startPoint = route.sections[0].departure.place.location;
        endPoint = route.sections[route.sections.length - 1].arrival.place.location;

        // Create a polyline to display the route:
        let routeLine = new H.map.Polyline(multiLineString, {
          style: { strokeColor: pathColor, lineWidth: 6 }
        });

        //Set arrows
        if (isSelectedVehicle) {
          routeLine.setArrows({ fillColor: 'white', frequency: 5, width: 1, length: 1 })
        }

        // Add the route polyline and the two markers to the map:
        this.map.addObjects([routeLine]);

        // Set the map's viewport to make the whole route visible:
        var currentViewBounds =  this.map.getViewModel().getLookAtData();//this.map.getViewBounds();
        if (!currentViewBounds.bounds.getBoundingBox().containsRect(routeLine.getBoundingBox())) {
          this.resetMapCenterPosition();
        }

        // Add waypoints with info bubble. And add waypoints for the selected vehicle only. --means when there is only on vehicle in the routes list
        if (isSelectedVehicle) {
          //$scope.addWaypointsToMap(route.waypoint, pathColor);
          this.addClusteredWaypointsToMap(route.sections, pathColor);
        }
      }
    };

    //Error handling function
    const onError = (error) => {
      let err = error;
    };

    // Get an instance of the routing service:
    let router = this.platform.getRoutingService(null, 8);

    // Call calculateRoute() with the routing parameters, the callback and an error callback function (called if a communication error occurs):
    routingParameters.forEach(sr => {
      router.calculateRoute(sr, onResult, onError);
    });
    //router.calculateRoute(routingParameters, onResult, onError);           
  };

  addClusteredWaypointsToMap(routeWaypoints, pathColor) 
  {
    let dataPoints = routeWaypoints.map((point, index) => {
        //return new H.clustering.DataPoint(point.originalPosition.latitude, point.originalPosition.longitude, null, point.userLabel);

        //new routing v8, the response is section, whcih will have 1 less count of waypoints in v7 so add the last section's arrival as last datapoint
         return new H.clustering.DataPoint(point.departure.place.originalLocation.lat, point.departure.place.originalLocation.lng, null, index);
    });
    //new routing v8, the response is section, whcih will have 1 less count of waypoints in v7 so add the last section's arrival as last datapoint
    dataPoints.push(new H.clustering.DataPoint(routeWaypoints[routeWaypoints.length-1].arrival.place.originalLocation.lat, 
      routeWaypoints[routeWaypoints.length-1].arrival.place.originalLocation.lng, null, routeWaypoints.length));

    // Create a clustering provider with custom options for clusterizing the input
    let clusteredDataProvider = new H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        // Maximum radius of the neighbourhood
        eps: 2,
        // minimum weight of points required to form a cluster
        minWeight: 3
      }
    });

    let defaultTheme = clusteredDataProvider.getTheme();
    let customTheme = {
      getClusterPresentation: (cluster) => {
        let clusterMarker = defaultTheme.getClusterPresentation.call(defaultTheme, cluster);
        return clusterMarker;
      },
      getNoisePresentation: (noisePoint) => {

        //new Route api v8 does not have userLabel property to save custom user data, checking the lat and lng to identify the nosie point, Li 06/15/2022
        //let noiseVehiclePoint = this.selectedVehicleData.filter(item => noisePoint.getData() == item.RowID)[0];
        let grouped = _.toArray(_.groupBy(_.toArray(this.selectedVehicleData), g => g.routeNum));
        let targetRoute = grouped.find(f => f.length === dataPoints.length);
        let noiseVehiclePoint = targetRoute.filter(item => noisePoint.getData() == item.wayPointId.split('_')[2])[0];
        

        let noiseMarker = defaultTheme.getNoisePresentation.call(defaultTheme, noisePoint);

        if(noiseVehiclePoint.event){

          if (noiseVehiclePoint.event.toLowerCase() === ("StartUp".toLowerCase())) {
            let startIcon = new H.map.Icon('assets/img/start.png', { size: { w: 17, h: 17 }, anchor: { x: 15, y: 15 } });
            noiseMarker.setIcon(startIcon);
          }
          else if (noiseVehiclePoint.event.toLowerCase() === ("Shutdown".toLowerCase())) {
            let stopIcon = new H.map.Icon('assets/img/stop.png', { size: { w: 17, h: 17 }, anchor: { x: 15, y: 15 } });
            noiseMarker.setIcon(stopIcon);
          }
          else if (noiseVehiclePoint.event.toLowerCase() === ("Idling".toLowerCase())) {
            let pauseIcon = new H.map.Icon('assets/img/pause.png', { size: { w: 17, h: 17 }, anchor: { x: 15, y: 15 } });
            noiseMarker.setIcon(pauseIcon);
          }
          else if(noiseVehiclePoint.event.toLowerCase() === ("Parking".toLowerCase())){
            let parkingIcon = new H.map.Icon('assets/img/parking.png', { size: { w: 17, h: 17 }, anchor: { x: 15, y: 15 } });
            noiseMarker.setIcon(parkingIcon);
          }
        }
        return noiseMarker;
      }
    }
    clusteredDataProvider.setTheme(customTheme);

    clusteredDataProvider.addEventListener('tap', (evt) => {
      //use this to match up the route and waypoint identifier beacuse so far route v8 does not have feature to attach the custom data and send back in the response to identify section
      let grouped = _.toArray(_.groupBy(_.toArray(this.selectedVehicleData), g => g.routeNum));
      let targetRoute = grouped.find(f => f.length === dataPoints.length);
        
      let bubbleContent = "";
      if (evt.target.getData().isCluster()) {
        bubbleContent = '<div><button style="background-color:transparent;"><img style="width:20px; height:20px;" src="/assets/img/ExIcon.png"></button></div>';
        
        let clusteredRowIds = [];
        
        evt.target.getData().forEachDataPoint((item) => {
          clusteredRowIds.push(item.getData().toString());
        });

        
        this.bubbleVehicleRecord = targetRoute.filter(item => clusteredRowIds.includes(item.wayPointId.split('_')[2].toString())).sort(firstBy("LocalDatetime"))[0];
        //comment line below, use one above beacuse so far route v8 does not have feature to attach the custom data and send back in the response to identify section
        //this.bubbleVehicleRecord = this.selectedVehicleData.filter(item => clusteredRowIds.includes(item.RowID.toString())).sort(firstBy("LocalDatetime"))[0];

        this.openBubble(this.bubbleVehicleRecord, bubbleContent);
      } else {
        
        this.bubbleVehicleRecord = targetRoute.filter(item => item.wayPointId.split('_')[2].toString() == evt.target.getData().getData())[0];
        //comment line below, use one above beacuse so far route v8 does not have feature to attach the custom data and send back in the response to identify section
        //this.bubbleVehicleRecord = this.selectedVehicleData.filter(item => item.RowID == evt.target.getData().getData())[0];
        
        let bubbleContentVehicledata = (this.bubbleVehicleRecord.event ? "<label>Event: </label>" + this.bubbleVehicleRecord.event + "<br>" : "") +
            (this.bubbleVehicleRecord.Speed ? "<label>Speed: </label>" + this.bubbleVehicleRecord.Speed + "<br>" : "") +
            (new Date(this.bubbleVehicleRecord.LocalDatetime).toLocaleTimeString());

        bubbleContent = '<div style="width:150px;">' + bubbleContentVehicledata +
            '<br><button style="background-color:transparent;"><img style="width:20px; height:20px;" src="/assets/img/ExIcon.png"></button></div>';

        this.openBubble(this.bubbleVehicleRecord, bubbleContent);
      }
    });

    // Create a layer tha will consume objects from our clustering provider
    let clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);

    // To make objects from clustering provder visible,
    // we need to add our layer to the map
    this.map.addLayer(clusteringLayer);
  };



  onMCChange() {

    if(this.claimsLocatorData.policyHolder.CompanyName){
      let selectedMCs = this.users.find(x => x.CompanyName.trim() === this.claimsLocatorData.policyHolder.CompanyName.trim());
      if(selectedMCs){
        this.claimsLocatorData.policyHolder.McId = selectedMCs.McId;    
      }
      else{
        this.claimsLocatorData.policyHolder.McId = 0;
      }
    }
    else{
      this.claimsLocatorData.policyHolder.McId = 0;
    }

    if(!this.claimsLocatorData.policyHolder.McId){
        this.mcVehicles = [];
        this.claimsLocatorData.vin = "";
        this.onVINChange();
        return;
    }
    
    this.dataService._getMCStatus(this.claimsLocatorData.policyHolder.McId).subscribe((status) => {
      if (status && status.ConsentStatus && (status.TemplateType == 0)) {
        this.claimsLocatorData.policyHolder.consentStatusRevoked = true;
        this.claimsLocatorData.policyHolder.consentRevokedDate = status.ModifiedDate;
      }
      else {
        this.claimsLocatorData.policyHolder.consentStatusRevoked = false;
        this.claimsLocatorData.policyHolder.consentRevokedDate = null;
      }
    });

    this.claimLocatorService._getVehiclesByMC(this.claimsLocatorData.policyHolder.McId).subscribe((vData) => {
      this.mcVehicles = vData;
    });

    this.claimsLocatorData.vin = ""; //***Restet vin
  }

  onVINChange()
  {
    if(this.claimsLocatorData.timeVariance > 60)
    {
      this.claimsLocatorData.timeVariance = "60";
    }
  }

  loadData()
  {
    this.dataService._getMutualCustomers().subscribe((mut) => {
      this.users = mut;
    });
  }

  onSearchLocationChange(){
    this.isPositionLocated = false;
  }

  searchLocationFromTpl()
  {
    this.searchLocation().subscribe();
  }
  searchLocation() : Observable<any> {

    return new Observable( (ob) =>
    {
      this.isPositionLocated = true;
      let tempLat = 0;
      let tempLng = 0;
      let splittedSearchText = this.claimsLocatorData.location.split(",");
      if ((!splittedSearchText) || (splittedSearchText.length != 2)) {
        splittedSearchText = this.claimsLocatorData.location.split(" ");
      }

      if ((splittedSearchText) && (splittedSearchText.length === 2)) {

        tempLat = parseFloat(splittedSearchText[0]);
        tempLng = parseFloat(splittedSearchText[1]);
        if ((tempLat > -90) && (tempLat < 90) && (tempLng > -180) && (tempLng < 180)) {
          this.position.lat = tempLat;
          this.position.lng = tempLng;

          this.resetMapCenterPosition();
          this.setMarkerPosition();

          //deferred.resolve($scope.position);
          ob.next(this.position);
          ob.complete();
        }
      }

      let geocoder = this.platform.getSearchService();//.getGeocodingService()
      // let geocodingParameters = {
      //   searchText: this.claimsLocatorData.location,
      //   jsonattributes: 1
      // };

      let geocodingParameters = {
        q: this.claimsLocatorData.location,
        //jsonattributes: 1
      };

      const ongeocodeError = (error) => { };
      const ongeocodeSuccess = (result) => {
        try {

          let locations = result.items;
          if (locations && (locations.length > 0)) {
            this.position.lat = locations[0].position.lat;
            this.position.lng = locations[0].position.lng

            this.resetMapCenterPosition();
            this.setMarkerPosition();

            //deferred.resolve($scope.position);
            ob.next(this.position)
            ob.complete();
          }
        }
        catch (e) { }
      };
      geocoder.geocode(geocodingParameters, ongeocodeSuccess, ongeocodeError);
    })
  }

  findClaim() 
  {
    if (!this.isPositionLocated) {
      this.searchLocation().subscribe(
        ()=> {
         this.findVehicles();
        }

      );
      
    }
    else {
      this.findVehicles();
    }
  }

  resetVehicleData () {
    //Reset
    this.Vehicles = [];
    this.generateVehiclesTable();
    this.routeSegments = [];
    this.generateSegmentsTable();
    this.CLData = [];

    //Show message
    this.commonAppService._snackBar(false, 'No data returned', 4000);
  };

  findVehicles() {
    this.loading = true;
    this.claimsLocatorData.geoLocation = this.position;
    this.claimsLocatorData.geoLocationRadius = 5;
    if(this.claimsLocatorData.claimEventDateTimeModel){

      let h = this.claimsLocatorData.claimEventTime?.getHours();
      let m = this.claimsLocatorData.claimEventTime?.getMinutes();

      if(!(m >= 0 || h >= 0) && !(m && h)) 
      {
        this.claimsLocatorData.claimEventTime = new Date();//set to midnight
        this.claimsLocatorData.claimEventTime?.setHours(0);
        this.claimsLocatorData.claimEventTime?.setMinutes(0);
      
        h = this.claimsLocatorData.claimEventTime?.getHours();
        m = this.claimsLocatorData.claimEventTime?.getMinutes();
      }

      this.claimsLocatorData.claimEventDateTimeModel.setMinutes(m);
      this.claimsLocatorData.claimEventDateTimeModel.setHours(h);

      this.claimsLocatorData.claimEventDateTime = this.claimsLocatorData.claimEventDateTimeModel;
    }
    else{
    }

    this.resetRouteObjects(); //To clear existing plots
    this.resetMarkerClusters(); //To clear existing clusters

    this.claimLocatorService._findVehicles(this.claimsLocatorData).pipe(takeUntil(this.subNotifier)).subscribe((result) =>
    {
      if (result && result.body?.length > 0) 
      {
        this.CLData = result.body;
        this.Vehicles = [];
        
        result.body.forEach((vObject, index) => {

          let vehicle = this.mcVehicles.find((item) => { return item.VehicleId == vObject[0].Vehicleid; });
          
          let MarkedClaimEvent;
          let markedCLS = vObject.filter(item => item.ClaimLocationId > 0);
          if (markedCLS.length > 0) {
            MarkedClaimEvent = markedCLS.sort((x, y) => (y.ClaimLocationId - x.ClaimLocationId))[0];
          }
          vehicle.pathColor = this.pickPathColor(index);
          if (MarkedClaimEvent) {
            vehicle.ClaimLocationId = MarkedClaimEvent.ClaimLocationId;
            vehicle.Degree = MarkedClaimEvent.Degree;
            vehicle.Note = MarkedClaimEvent.Note;
          }
          else {
            vehicle.ClaimLocationId = 0;
            vehicle.Degree = null;
            vehicle.Note = null;
          }

          this.Vehicles.push(vehicle);

          //split route if time variance is more than 60 mins
          //and this case will have only one vehicle.
          if(this.claimsLocatorData.timeVariance > 60){
            this.selectedVehicleId = vehicle.VehicleId;
            this.routeSegments = this.splitRouteSegments(vObject, vehicle);
            
            if(MarkedClaimEvent){
              let claimEventSegment = this.routeSegments.filter(x => 
                x.SegmentStartTs <= new Date(MarkedClaimEvent.LocalDatetime) && x.SegmentEndTs > new Date(MarkedClaimEvent.LocalDatetime))[0];

                claimEventSegment.ClaimLocationId = MarkedClaimEvent.ClaimLocationId;
            }
          }
          else {
              this.plotVehiclePath(vObject, vehicle.pathColor);
            }
        });

        this.generateVehiclesTable();

        if((this.claimsLocatorData.timeVariance > 60) && (this.routeSegments && this.routeSegments.length > 0)){
          this.selectedVehicleId = this.Vehicles[0].VehicleId;
          this.generateSegmentsTable(); 
        }
        else{
          this.routeSegments = [];
        }
      }
      else {
        this.resetVehicleData();
      }
      this.loading = false;
    }, (err) => {
      this.resetVehicleData();
      this.loading = false;
    });
  };

  updateCLData(vehicleRecord) 
  {
    this.selectedVehicleId = vehicleRecord.VehicleId;
    this.claimsLocatorData.vin = vehicleRecord.VIN;
    this.resetRouteObjects();
    this.resetMarkerClusters();
    
    this.selectedVehicleData = this.CLData.filter((d) => { return d[0].Vehicleid == vehicleRecord.VehicleId; })[0];
    this.plotVehiclePath(this.selectedVehicleData, vehicleRecord.pathColor, true);
  };

  updateSegmentCLData(routeSegment)
  {
    this.routeSegments.forEach(x => {
        x.Selected = false;
    });
    routeSegment.Selected = true;
    this.resetRouteObjects();
    this.resetMarkerClusters();

    this.selectedVehicleData = routeSegment.cL_ClaimLocatorSourceVms;
    this.plotVehiclePath(this.selectedVehicleData, routeSegment.pathColor, true);
  }

  markAsClaimEvent(claimVehicleRecord) 
  {
    //*****to adjust map zoom and center it to claim event
    this.map.setCenter({ lat: claimVehicleRecord.Latitude, lng: claimVehicleRecord.Longitude }, true);
    let currentZoom = this.map.getZoom();
    if (currentZoom > 17 || currentZoom < 14) {
        //set zoom to 15
      this.map.setZoom(14);
    }
    //*** */
    let popUpDropDown = null;
    if(!claimVehicleRecord.RowID)
    {
      popUpDropDown = {
        label: "Select the previous data point time stamp",
        options: []
      };
      this.selectedVehicleData.forEach(x => {
        popUpDropDown.options.push({id: x, value: new Date(x.LocalDatetime).toLocaleString()});
      });
    }
    
    let popUpModelConfig = {
      title: "Please confirm!",
      message: "Are you sure this is the Claim event?",
      buttons: [{
        label: "Confirm",
        action: "confirm"
      }],
      claimVehicleRecord: claimVehicleRecord,
      isReadOnlyNote: false,
      checkBox: {
        label: "Low Probability Claim"
      },
      dropDown: popUpDropDown
    };

    let dialogRef = this.dialog.open(PopUpModalComponent, {
      data: popUpModelConfig,
      width: '30%',
      //height: '35%',
      id: 'modal-component', //to match the global css
      position: { 
        top: '1%',
        left :'35%',
        right: ''
      }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.subNotifier)).subscribe(data => {
      if (data && data.claimVehicleRecord) {
        var claimEventDegree = data.checkBox.checked ? 2 : 1;

        if(data.dropDown && data.dropDown.selectedOption){
            data.claimVehicleRecord.cL_ClaimLocatorSourceVms = [];
            let previousDataPoint = this.getDataPointByTs(data.dropDown.selectedOption);
            if(previousDataPoint){
                data.claimVehicleRecord.cL_ClaimLocatorSourceVms.push(previousDataPoint);
                //data.claimVehicleRecord. = $scope.claimsLocatorData.location;
            }                        
        }

        this.setClaimEventMarkerPosition({ lat: data.claimVehicleRecord.Latitude, lng: data.claimVehicleRecord.Longitude }, claimEventDegree, data.note);
        
        //set 1s timeout here until the new claim marker on map is finished instead of in original code to call getMapScreenShot twice
        setTimeout(() => this.getMapScreenShot().pipe(takeUntil(this.subNotifier)).subscribe((encodedImage) => {
          data.claimVehicleRecord.MapScreenShot = encodedImage;
          data.claimVehicleRecord.Note = data.note;
          data.claimVehicleRecord.Degree = claimEventDegree;
          this.downloadReport(data.claimVehicleRecord);

          //refresh the vehicle table and sgement table without reset the map plot
          this.claimLocatorService._findVehicles(this.claimsLocatorData).pipe(takeUntil(this.subNotifier)).subscribe((result) =>
          {
            if (result && result.body?.length > 0) 
            {
              this.CLData = result.body;
              this.Vehicles = [];
              
              result.body.forEach((vObject, index) => {
                let vehicle = this.mcVehicles.find((item) => { return item.VehicleId == vObject[0].Vehicleid; });
                let MarkedClaimEvent;
                let markedCLS = vObject.filter(item => item.ClaimLocationId > 0);
                if (markedCLS.length > 0) {
                  MarkedClaimEvent = markedCLS.sort((x, y) => (y.ClaimLocationId - x.ClaimLocationId))[0];
                }
                vehicle.pathColor = this.pickPathColor(index);
                if (MarkedClaimEvent) {
                  vehicle.ClaimLocationId = MarkedClaimEvent.ClaimLocationId;
                  vehicle.Degree = MarkedClaimEvent.Degree;
                  vehicle.Note = MarkedClaimEvent.Note;
                }
                else {
                  vehicle.ClaimLocationId = 0;
                  vehicle.Degree = null;
                  vehicle.Note = null;
                }

                this.Vehicles.push(vehicle);

                //split route if time variance is more than 60 mins
                //and this case will have only one vehicle.
                if(this.claimsLocatorData.timeVariance > 60){
                  this.selectedVehicleId = vehicle.VehicleId;
                  this.routeSegments = this.splitRouteSegments(vObject, vehicle);
                  
                  if(MarkedClaimEvent){
                    let claimEventSegment = this.routeSegments.filter(x => 
                      x.SegmentStartTs <= new Date(MarkedClaimEvent.LocalDatetime) && x.SegmentEndTs > new Date(MarkedClaimEvent.LocalDatetime))[0];

                      claimEventSegment.ClaimLocationId = MarkedClaimEvent.ClaimLocationId;
                  }
                }
                else {
                    this.plotVehiclePath(vObject, vehicle.pathColor);
                  }
              });

              this.generateVehiclesTable();

              if((this.claimsLocatorData.timeVariance > 60) && (this.routeSegments && this.routeSegments.length > 0)){
                this.selectedVehicleId = this.Vehicles[0].VehicleId;
                this.generateSegmentsTable(); 
              }
              else{
                this.routeSegments = [];
              }
            }
            else {
              this.resetVehicleData();
            }
            this.loading = false;
          });
        }), 800);
      }
    });
  };

  private getDataPointByTs(ts) {
    if(this.selectedVehicleData && this.selectedVehicleData.length > 1){                
      return this.selectedVehicleData.find(x => new Date(x.LocalDatetime).toLocaleString() == ts);
    }
  };

  markClaimEventInVehicleTable(claimVehicleRecord, claimLocationId) 
  {
    if (this.Vehicles && (this.Vehicles.length > 0)) {

      let markedVObject = this.Vehicles.find(item => item.VehicleId == claimVehicleRecord.Vehicleid);
      if (markedVObject) {
        markedVObject.ClaimLocationId = claimLocationId;
        markedVObject.Latitude = claimVehicleRecord.Latitude;
        markedVObject.Longitude = claimVehicleRecord.Longitude;
        markedVObject.Degree = claimVehicleRecord.Degree;
        markedVObject.Note = claimVehicleRecord.Note;
        this.generateVehiclesTable();
        this.selectedVehicleId = markedVObject.VehicleId;
      }
    }
  };


  // report
  downloadReport(locationData) {
    let reportDef = {};
    let claimsLocatorReportDS = [];
    let note = "";

    if (!locationData) {
      return;
    }
    else{
      note = locationData.Note ? locationData.Note : "";
    }

    forkJoin([
      this.claimLocatorService._generateClaimLocatorReport(locationData),
      this.claimLocatorService._getJSONFile("appdata/ClaimsLocatorReportDef.json"),
      this.getLogo("/assets/img/eTech_TM-sm.png")
    ]).pipe(takeUntil(this.subNotifier)).subscribe((result : any) => {
      result = this.formatDataTempCode(result, note);
      claimsLocatorReportDS = result[0];
      reportDef = result[1];
      this.markClaimEventInVehicleTable(locationData, claimsLocatorReportDS[0].data[0].Id);
      jsreports.export({
        report_def: reportDef,
        format: 'pdf',
        datasets: claimsLocatorReportDS
      });
    });
  };

  getClaimLocatorReport(record)
  {
    let reportDef = {};
    let claimsLocatorReportDS = [];
    let note = record.Note ? record.Note : "";

    forkJoin([this.claimLocatorService._getClaimLocatorReportData(record), 
      this.claimLocatorService._getJSONFile("appdata/ClaimsLocatorReportDef.json"),
      this.getLogo("/assets/img/eTech_TM-sm.png")]).subscribe(
        (result : any) =>
        {
          result = this.formatDataTempCode(result, note);
          claimsLocatorReportDS = result[0];
          reportDef = result[1];
          jsreports.export({
            report_def: reportDef,
            format: 'pdf',
            datasets: claimsLocatorReportDS
          });
        }
      );
  }

  private formatDataTempCode(result, note) 
  {
    let claimsLocatorReportDS = result[0].body;
    let reportDef = result[1];
    let logo = result[2];
    claimsLocatorReportDS[0].data[0].Road_NaturalSpeedOfRoad = Math.round(claimsLocatorReportDS[0].data[0].Road_NaturalSpeedOfRoad * 100) / 100;
    claimsLocatorReportDS[0].data[0].Road_SpeedLimit = Math.round(claimsLocatorReportDS[0].data[0].Road_SpeedLimit);
    claimsLocatorReportDS[0].data[0].Road_TrafficSpeed = Math.round(claimsLocatorReportDS[0].data[0].Road_TrafficSpeed * 100) / 100;
    claimsLocatorReportDS[0].data[0].DailySum_MilesDriven = Math.round(claimsLocatorReportDS[0].data[0].DailySum_MilesDriven * 100) / 100;

    claimsLocatorReportDS[0].data[0].Claim_ClaimDate = new Date(claimsLocatorReportDS[0].data[0].Claim_ClaimDate).toLocaleDateString();
    claimsLocatorReportDS[0].data[0].LOC_LocalTS = new Date(claimsLocatorReportDS[0].data[0].LOC_LocalTS).toLocaleString();
    claimsLocatorReportDS[0].data[0].DailySum_EndOfTheDay = new Date(claimsLocatorReportDS[0].data[0].DailySum_EndOfTheDay).toLocaleTimeString();
    claimsLocatorReportDS[0].data[0].DailySum_BeginningOfTheDay = new Date(claimsLocatorReportDS[0].data[0].DailySum_BeginningOfTheDay).toLocaleTimeString();

    claimsLocatorReportDS[0].data[0].DailySum_IdleTime = new Date(claimsLocatorReportDS[0].data[0].DailySum_IdleTime * 1000).getUTCHours() + "h " +
        new Date(claimsLocatorReportDS[0].data[0].DailySum_IdleTime * 1000).getUTCMinutes() + "m " +
        new Date(claimsLocatorReportDS[0].data[0].DailySum_IdleTime * 1000).getUTCSeconds() + "s";
    claimsLocatorReportDS[0].data[0].DailySum_EngineRunningTime = new Date(claimsLocatorReportDS[0].data[0].DailySum_EngineRunningTime * 1000).getUTCHours()
        + "h " + new Date(claimsLocatorReportDS[0].data[0].DailySum_EngineRunningTime * 1000).getUTCMinutes() + "m " +
        new Date(claimsLocatorReportDS[0].data[0].DailySum_EngineRunningTime * 1000).getUTCSeconds() + "s";
    claimsLocatorReportDS[0].data[0].DailySum_NonOperationalTime = new Date(claimsLocatorReportDS[0].data[0].DailySum_NonOperationalTime * 1000).getUTCHours()
        + "h " + new Date(claimsLocatorReportDS[0].data[0].DailySum_NonOperationalTime * 1000).getUTCMinutes() + "m " +
        new Date(claimsLocatorReportDS[0].data[0].DailySum_NonOperationalTime * 1000).getUTCSeconds() + "s";

    //Speeding   //12/19/2019 Li changed the speeding data to speeding by Time
    let speedingData = claimsLocatorReportDS[0].data[0].ClaimLocatorReportSpeeding;

    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    for (let i = 0; i < 3; i++) {
      if (speedingData && speedingData.length > 0 && speedingData[i] &&
        ((i == 0 && speedingData[i].Sum_TotalMiles > 0) || (i > 0 && speedingData[i].Sum_TotalMiles > speedingData[i - 1].Sum_TotalMiles))) {

        let fromMonthYear = months[speedingData[i].YearMonth_From.toString().substr(4, 2) - 1] + " " + speedingData[i].YearMonth_From.toString().substr(0, 4);
        let toMonthYear = months[speedingData[i].YearMonth_To.toString().substr(4, 2) - 1] + " " + speedingData[i].YearMonth_To.toString().substr(0, 4);
        speedingData[i].TimePeriod = (fromMonthYear == toMonthYear) ? fromMonthYear : fromMonthYear + " to " + toMonthYear;
        speedingData[i].Pct_Under_5mph = Math.round((speedingData[i].Sum_Under_5mph_ByTime / 3600 / speedingData[i].Sum_MovingTime_Hr) * 10000) / 100 + " %";
        speedingData[i].Pct_5_10mph = Math.round((speedingData[i].Sum_5_10mph_ByTime / 3600 / speedingData[i].Sum_MovingTime_Hr) * 10000) / 100 + " %";
        speedingData[i].Pct_10_15mph = Math.round((speedingData[i].Sum_10_15mph_ByTime / 3600 / speedingData[i].Sum_MovingTime_Hr) * 10000) / 100 + " %";
        speedingData[i].Pct_Over_15mph = Math.round((speedingData[i].Sum_Over_15mph_ByTime / 3600 / speedingData[i].Sum_MovingTime_Hr) * 10000) / 100 + " %";
        speedingData[i].Pct_Total = Math.round(((speedingData[i].Sum_Over_15mph_ByTime + speedingData[i].Sum_10_15mph_ByTime + speedingData[i].Sum_5_10mph_ByTime +
            speedingData[i].Sum_Under_5mph_ByTime) / 3600 / speedingData[i].Sum_MovingTime_Hr) * 10000) / 100 + " %";
        speedingData[i].Sum_TotalMiles_Round = Math.round(speedingData[i].Sum_TotalMiles);

        claimsLocatorReportDS[0].data[0]['ClaimLocatorReportSpeeding' + i] = speedingData[i];
      }
      else {
        let tempSpeedingDataItem : any = {};
        tempSpeedingDataItem.Months = (speedingData && speedingData[i]) ? speedingData[i].Months : "";
        tempSpeedingDataItem.TimePeriod = "N/A";
        tempSpeedingDataItem.Pct_Under_5mph = "";
        tempSpeedingDataItem.Pct_5_10mph = "";
        tempSpeedingDataItem.Pct_10_15mph = "";
        tempSpeedingDataItem.Pct_Over_15mph = "";
        tempSpeedingDataItem.Pct_Total = "";
        tempSpeedingDataItem.Sum_TotalMiles_Round = "";

        claimsLocatorReportDS[0].data[0]['ClaimLocatorReportSpeeding' + i] = tempSpeedingDataItem;
      }
    }

    claimsLocatorReportDS[0].data[0].Note = note;

    reportDef.body[0].extraSections.find(x => x.id == "D1-S8-MapScreenShot-Section").elements[0].url = claimsLocatorReportDS[0].data[0].MapScreenShot;
    reportDef.page_header.elements.find(x => x.id == "Report-Header-Logo").url = logo;

    return [claimsLocatorReportDS, reportDef];
  };
  // Report


  validatesm() {
    let authData = JSON.parse(localStorage.getItem('authorizationData'));
    this.authService._getsmData(authData.userRole, authData.groupType, "ClaimsLocatorHtml")
    .pipe(takeUntil(this.subNotifier)).subscribe((result) => {
        if (!this.authService._accessFilter(result, "ClaimsLocatorHtml")) {
          this.router.navigate(['/']);
        }
      })
    return;
  };


  

  ngOnInit(): void {
    this.validatesm();
    this.loadData();
  }
  
  ngAfterViewInit() : void
  {
    //load map here
    //to do: adjust the get heremap access timing during the login
    this.authService._getHereMapAccess(Number(this.authService._authentication.userRole), 
    Number(this.authService._authentication.groupType)).pipe(takeUntil(this.subNotifier)).subscribe(
      result =>
      {
        this.app_id  = result.app_id;
        this.app_code  = result.app_code;
        this.apikey = result.api_key;

        this.initialMapLoad();
      }
    );
    
  }

  ngOnDestroy() {
    this.subNotifier.next();
    this.subNotifier.complete();
  }

}

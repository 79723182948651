<div class="row">
    <div class="col-md-6 col-md-offset-3">
        <div class="panel panel-info">
            <div class="panel-heading"></div>
            <div class="panel-body">
                <h2>Reset Password</h2>
                <form class="form-horizontal">
                    <fieldset *ngIf="!changePass">
                        <div class="form-group label-floating" *ngIf="!success">
                            <div class="col-md-8 col-md-offset-2">
                                <label class="control-label">Email</label>
                                <input type="email" class="form-control" placeholder="Username"  name = 'userName' [(ngModel)]="loginData.userName" required autofocus>
                            </div>
                        </div>
                        <div [ngClass] ="success ? 'alert alert-success col-md-8 col-md-offset-2 text-center animate-show' : 'alert alert-danger col-md-8 col-md-offset-2 text-center animate-show'" [hidden]="!message">
                            <p>{{message}}</p>
                        </div>
                        <!-- <div class="alert alert-success col-md-8 col-md-offset-2 text-center animate-show" [hidden]="!success">
                            <p>{{message}}</p>
                        </div> -->
                        <div class="form-group" *ngIf="!success">
                            <div class="col-md-8 col-md-offset-2 text-center">
                                <button type="submit" class="btn btn-info" (click)="reset(loginData.userName)" [disabled]="!loginData.userName">Reset Password</button>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset *ngIf="success && !changePass">
                        <div class="form-group label-floating">
                            <div class="col-md-8 col-md-offset-2">
                                <label class="control-label">Email</label>
                                <input type="email" class="form-control" placeholder="Username" name = 'userName' [(ngModel)]="loginData.userName" required disabled>
                            </div>
                        </div>
                        <div class="form-group label-floating">
                            <div class="col-md-8 col-md-offset-2">
                                <label class="control-label">Temporary Password</label>
                                <input type="password" class="form-control" placeholder="Temporary Password" name = 'tempPass' [(ngModel)]="loginData.password" required autofocus>
                            </div>
                        </div>
                        <div class="alert alert-danger col-md-8 col-md-offset-2 text-center animate-show" [hidden]="success">
                            <p>{{message}}</p>
                        </div>
                        <div class="form-group">
                            <div class="col-md-8 col-md-offset-2 text-center">
                                <button type="submit" class="btn btn-info" (click)="login()" [disabled]="!loginData.userName || !loginData.password">Log In</button>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset *ngIf="changePass">
                        <div class="form-group label-floating">
                            <div class="col-md-8 col-md-offset-2">
                                <label class="control-label">Email</label>
                                <input type="email" class="form-control" placeholder="Username" name = 'userName'[(ngModel)]="loginData.userName" required disabled>
                            </div>
                        </div>
                        <div class="form-group label-floating">
                            <div class="col-md-8 col-md-offset-2">
                                <label class="control-label">New Password</label>
                                <input type="password" class="form-control" placeholder="New Password" name = 'newPassword' [(ngModel)]="loginData.password" (ngModelChange)="validatePW(loginData.password)" required autofocus>
                            </div>
                        </div>
                        <div class="form-group label-floating">
                            <div class="col-md-8 col-md-offset-2">
                                <label class="control-label">Confirm New Password</label>
                                <input type="password" class="form-control" placeholder="Confirm Password" name = 'confirmPassword' [(ngModel)]="loginData.passwordCheck" required>
                            </div>
                        </div>
                        <div class="alert alert-danger col-md-8 col-md-offset-2 text-center animate-show" [hidden]="!message">
                            <p>{{message}}</p>
                        </div>
                        <div class="form-group">
                            <div class="col-md-8 col-md-offset-2 text-center">
                                <button type="submit" class="btn btn-info" (click)="updatePass(loginData)" [disabled]="loginData.password !== loginData.passwordCheck || disableUpdatePWBtn === true">Update Password</button>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="panel panel-info">
            <div class="panel-body">
                <div class="row padFive pre-scrollable" style="display: none;">
                    <p id="TermsOfService" [innerHtml]="text"></p>
                </div>
                <div>
                    <object width="1000" height="650" data="../appdata/TE User Agreement Final 052219.pdf"></object>
                </div>
                <div class="row col-md-12 col-md-offset-4 text-center">
                    <div class="row col-md-2 "><button class="btn btn-raised btn-success no-animate" (click)="accept()">Accept</button></div>
                    <!-- <div class="row col-md-2"><button class="btn btn-raised btn-success no-animate" ng-click="print()">Print</button></div> -->
                </div>
            </div>
        </div>
    </div>    
</div>
<div class="modal-header">
    <button type="button" class="close" aria-hidden="true" (click)="close()">×</button>
    <h3 class="modal-title">{{ tspName }}</h3>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-12 text-center">
            <!-- <p>Please check your email for a link to complete this signature.</p> -->
            <p>Please check your email to complete the signature verification process.</p>
			<p>Click “COMPLETE SIGNATURE” to automatically access the consent authorization.</p>
			<p>If you do not receive an email, please check your Spam or Junk folders.</p>
			<p>If no email is present, select the TSP again and a new signature request will be sent.</p>
			<a style="color: #0062ff;" href="mailto:tesupport@etechnologyservices.com?subject=Consent Help"><strong>Contact Us</strong></a>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-raised btn-success" type="button" (click)="close()">Ok</button>
</div>

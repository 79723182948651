import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './Components/login/login.component';
import { PolicyholdersComponent } from './Components/policyholders/policyholders.component'
import { HomeComponent } from './Components/home/home.component'
import { ActivateComponent } from './Components/activate/activate.component';
import {  McDrilldownComponent } from './Components/mc-drilldown/mc-drilldown.component';
import {  DashboardComponent } from './Components/dashboard/dashboard.component';
import {  UserAdminComponent } from './Components/user-admin/user-admin.component';
import {  ResetComponent } from './Components/reset/reset.component';
import { McUploadComponent } from './Components/mc-upload/mc-upload.component';
import { PrivacyComponent } from './Components/privacy/privacy.component';
import { ContactComponent } from './Components/contact/contact.component';
import { CompanyInfoComponent } from './Components/company-info/company-info.component';
import { ConsentComponent } from './Components/consent/consent.component';
import { ClaimsLocatorComponent  } from './Components/claims-locator/claims-locator.component';
import { UserAgreementComponent  } from './Components/user-agreement/user-agreement.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent , data: {animation: '', title:'Login'}},
  { path: 'mcList', component: PolicyholdersComponent , data: {animation: '', title:'Overview'}},
  { path: 'mcList/:mcID', component: McDrilldownComponent , data: {animation: '', title:'Overview'}},
  { path: 'dashboard/:mcID/:type/:companyName/:insuranceProgram', component: DashboardComponent , data: {animation: '', title:'Dashboards'}},
  { path: 'userAdmin', component: UserAdminComponent , data: {animation: '', title:'Manage Users'}},
  { path: 'mc-upload', component: McUploadComponent , data: {animation: '', title: 'Account'}},
  { path: 'reset', component: ResetComponent, data: {title: 'Reset Password'}},
  { path: 'activate/:vtk/:uid', component: ActivateComponent, data: {title: 'Activate Account'}},
  { path: 'privacy', component: PrivacyComponent, data: {title: 'Privacy Policy'}},
  { path: 'contact', component: ContactComponent, data : {title: 'Contact'}},
  { path: 'companyInfo', component: CompanyInfoComponent, data : {title: 'Company Info'}},
  { path: 'consentHistory', component: ConsentComponent, data : {title: 'Consent History'}},
  { path: 'consentHistory/:signatureID', component: ConsentComponent, data : {title: 'Consent History'}},
  { path: 'claimsLocator', component: ClaimsLocatorComponent, data : {title: 'Claims Locator'}},
  { path: 'userAgreement', component: UserAgreementComponent, data : {title: 'Terms of Service'}},


  //{ path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '', component: HomeComponent , data: {animation: '', title: 'Home'}},
  {path: '**', component: HomeComponent, data: {animation: '', title: 'Home'}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
